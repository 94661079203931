import React, { useState, forwardRef } from "react";
import { Link } from 'react-router-dom';
import Select from 'react-select'
import { DatePicker } from "@y0c/react-datepicker";
import { Dropdown, Modal } from 'react-bootstrap';
import MaterialTable from "material-table";
import { Search, ViewColumn, SaveAlt, Remove, LastPage, FirstPage, FilterList, Edit, DeleteOutline, Clear, ChevronRight, ChevronLeft, Check, ArrowDownward, AddBox } from '@material-ui/icons';

const options = [
    { value: 'Kaushal Sadhu ( kaushal@nbcbanq.com )', label: 'Kaushal Sadhu ( kaushal@nbcbanq.com )' },
    { value: 'Hitesh Sadhu ( hitesh@nbcbanq.com )', label: 'Hitesh Sadhu ( hitesh@nbcbanq.com )' },
]

const materialTableIcons = {
    Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
    Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
    Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
    DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
    Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
    Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
    FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
    LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
    NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
    ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
    SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
    ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
    ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />)
};


export const Manager_List = () => {
    const [EditManager, setEditManager] = useState(false);
    const [AddManager, setAddManager] = useState(false);
    const [selectedOption, setSelectedOption] = useState(null)
    const [DisableManager, setDisableManager] = useState(false);

    return (
        <div>
            <div className="card">
                <div className="card-header page-title-space mobile-card-header">
                    <h2 className="text-black font-w600 mb-0">Manager</h2>
                    <div className="mobileFullWidth">
                        <Link to="#" className="btn btn-primary btn-rounded mr-3" onClick={() => setAddManager(true)}>
                            Add Manager
                        </Link>
                        <Modal className="modal fade" show={AddManager} size="lg">
                            <div className="modal-dialog-centered " >
                                <div className="modal-content">
                                    <div className="modal-header">
                                        <h3 className="modal-title">Add Manager</h3>
                                        <button type="button" className="close" onClick={() => setAddManager(false)}><span>&times;</span>
                                        </button>
                                    </div>
                                    <div className="modal-body">
                                        <div className="row">
                                            <div className="col-md-6">
                                                <div className="form-group position-relative">
                                                    <label className="mb-2 ">
                                                        <span>Country</span>
                                                    </label>
                                                    <select className="form-control webkit-none" id="sel1">
                                                        <option>Select Country</option>
                                                        <option>India</option>
                                                        <option>USA</option>
                                                    </select>
                                                    <i className="fa fa-caret-down down-arrow" aria-hidden="true"></i>
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label className="mb-2 ">Email ID</label>
                                                    <input type="text" className="form-control" placeholder="Enter Email ID" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="modal-footer">
                                        <button type="button" className="btn btn-light btn-rounded light" onClick={() => setAddManager(false)}>Close</button>
                                        <button type="button" className="btn btn-primary btn-rounded">Add Manager</button>
                                    </div>
                                </div>
                            </div>
                        </Modal>
                        <Dropdown className="custom-dropdown mb-0" >
                            <Dropdown.Toggle className="btn btn-primary btn-rounded i-false" variant='' >
                                <i className="las la-download scale5 mr-3"></i> Download Report
                                <i className="fa fa-caret-down text-white ml-3" aria-hidden="true"></i>
                            </Dropdown.Toggle>
                            <Dropdown.Menu className="dropdown-menu-right right-cstm">
                                <Dropdown.Item className="">CSV</Dropdown.Item >
                                <Dropdown.Item className="">PDF</Dropdown.Item >
                            </Dropdown.Menu>
                        </Dropdown>
                    </div>
                </div>
            </div>
            <div className="card">
                <div className="card-body bdr-btm-card">
                    <div className="row">
                        <div className="col-md-3">
                            <div className="form-group position-relative">
                                <label className="mb-2 ">
                                    <span>From Date</span>
                                </label>
                                <div className="col-xs-12">
                                    <DatePicker className="form-control" />
                                </div>
                            </div>
                        </div>

                        <div className="col-md-3">
                            <div className="form-group position-relative">
                                <label className="mb-2 ">
                                    <span>To Date</span>
                                </label>
                                <div className="col-xs-12">
                                    <DatePicker className="form-control" />
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 position-relative">
                            <label className="mb-2 ">
                                <span>Manager </span>
                            </label>
                            <Select className="webkit-none" defaultValue={selectedOption} onChange={setSelectedOption} options={options} />
                            <i className="fa fa-caret-down down-arrow select-arrow1" aria-hidden="true"></i>
                        </div>

                    </div>
                    <div className="table-responsive">

                    </div>
                </div>
                <div className="card-body">
                    <div className="position-relative">
                        <div className="table-responsive">
                            <MaterialTable style={{ minWidth: '1650px' }}
                                title="Manager List"
                                icons={materialTableIcons}
                                options={{ exportButton: true, paging: true, pageSize: 5, pageSizeOptions: [5, 10, 15], emptyRowsWhenPaging: false, size: 'medium' }}
                                columns={[
                                    { title: 'No', field: 'no', width: 70 },
                                    { title: 'Manager Name', field: 'ManagerName' },
                                    { title: 'Email ID', field: 'EmailID' },
                                    { title: 'Country', field: 'Country' },
                                    { title: 'Total Reward', field: 'TotalReward' },
                                    { title: 'Phone No.', field: 'Phoneno' },
                                    { title: 'Action', field: 'Action', align: 'center' },
                                    { title: 'Status', field: 'Status', align: 'center', },
                                ]}
                                data={[
                                    {
                                        "no": "01",
                                        "ManagerName": "Kaushal Sadhu",
                                        "EmailID": "kaushal@domainname.com",
                                        "Country": "India",
                                        "TotalReward": "$50.00",
                                        "Phoneno": "+91 98123 98123",
                                        "Action":
                                            <div style={{ width: 175 }}>
                                                <button className="btn btn-primary shadow btn-xs edit-shape" onClick={() => setEditManager(true)}><i className="fa fa-pencil" aria-hidden="true"></i></button>
                                                <Link to="/customer-list" className="btn btn-primary shadow btn-xs edit-shape"><i className="fa fa-user" aria-hidden="true"></i></Link>
                                                <Link to="/broker-list" className="btn btn-primary shadow btn-xs edit-shape"><i className="fa fa-user-secret" aria-hidden="true"></i></Link>
                                                <Link to="/manager-customer-transaction" className="btn btn-primary shadow btn-xs edit-shape"><i className="fa fa-list" aria-hidden="true"></i></Link>
                                            </div>,
                                        "Status": <div><button onClick={() => setDisableManager(true)} className="btn btn-outline-success btn-rounded">Active</button> </div>
                                    },
                                ]}
                            />
                        </div>
                        <Modal className="modal fade" show={EditManager} size="lg">
                            <div className="modal-dialog-centered">
                                <div className="modal-content">
                                    <div className="modal-header">
                                        <h3 className="modal-title">Edit Manager</h3>
                                        <button type="button" className="close" onClick={() => setEditManager(false)}><span>&times;</span>
                                        </button>
                                    </div>
                                    <div className="modal-body">
                                        <div className="row">
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label className="mb-2 ">First Name</label>
                                                    <input type="text" className="form-control" placeholder="Enter First Name" value={"Kaushal"} readOnly />
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label className="mb-2 ">Last Name</label>
                                                    <input type="text" className="form-control" placeholder="Enter Last Name" value={"Sadhu"} readOnly />
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label className="mb-2 ">Email</label>
                                                    <input type="text" className="form-control" placeholder="Enter Email" value={"kaushal@domainname.com"} />
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label className="mb-2 ">Phone Number</label>
                                                    <input type="text" className="form-control" placeholder="Enter Phone Number" value={"+91 98123 98123"} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="modal-footer">
                                        <button type="button" className="btn btn-light btn-rounded light" onClick={() => setEditManager(false)}>Close</button>
                                        <button type="button" className="btn btn-primary btn-rounded">Update</button>
                                    </div>
                                </div>
                            </div>
                        </Modal>

                    </div>
                </div>
                <div className="information-icon">
                    <span><i className="fa fa-pencil"></i> Edit</span>
                    <span><i className="fa fa-user"></i> Customer</span>
                    <span><i className="fa fa-user-secret"></i> Broker</span>
                    <span><i className="fa fa-list"></i> Customer Transaction</span>
                </div>
            </div>
            <Modal className="modal fade" show={DisableManager}>
                <div className="modal-dialog-centered " >
                    <div className="modal-content">
                        <div className="modal-header">
                            <h3 className="modal-title">Manager Status</h3>
                            <button type="button" className="close" onClick={() => setDisableManager(false)}><span>&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <div className="col-xs-12">
                                <div className="form-group">
                                    <p className="font-18">Are you sure you want to <strong className="text-danger">Deactive</strong> this Manager?</p>
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-light btn-rounded light" onClick={() => setDisableManager(false)}>Cancel</button>
                            <button type="button" className="btn btn-primary btn-rounded">Yes</button>
                        </div>
                    </div>
                </div>
            </Modal>
        </div>
    )

}
export default Manager_List;