import React, { useState, forwardRef, useEffect } from "react";
import MaterialTable from "material-table";
import { Dropdown } from 'react-bootstrap';
import { Search, ViewColumn, SaveAlt, Remove, LastPage, FirstPage, FilterList, Edit, DeleteOutline, Clear, ChevronRight, ChevronLeft, Check, ArrowDownward, AddBox } from '@material-ui/icons';
import btcicon from "../../../icons/crypto/btc.svg";
import ethicon from "../../../icons/crypto/eth.svg";
import usdticon from "../../../icons/crypto/usdt.svg";
import { useTranslation } from "react-i18next";
import axios from 'axios';
import moment from 'moment';
import Loader from '../../pages/Loader';
import { CsvBuilder } from 'filefy';
import jsPDF from 'jspdf';
import 'jspdf-autotable';

const materialTableIcons = {
    Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
    Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
    Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
    DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
    Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
    Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
    FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
    LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
    NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
    ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
    SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
    ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
    ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />)
};


export const Transfer_Transactions = () => {
    const { t } = useTranslation();
    const [loader, setLoader] = useState(false);
    const [SettingData, setSettingData] = useState([]);
    const [ResultData, setResultData] = useState([]);
    const [FilterData, setFilterData] = useState([]);
    const [CustomerData, setCustomerData] = useState([]);
    const [Customer, setCustomer] = useState("");
    const [FromDate, setFromDate] = useState("");
    const [ToDate, setToDate] = useState("");


    const handleChangeCSVNdPDF = (val) => {
        var FileName = "Transfer Transactions";

        var columns = [
            { title: t('ACCOUNT_TRANSACTIONS.TRANSACTIONS_ID'), field: 'TransactionsID' },
            { title: t('ACCOUNT_TRANSACTIONS.DATE'), field: 'Date' },
            { title: t('HEADER.CUS_NAME'), field: 'CustomerName' },
            { title: t('LABEL.FROM_EMAIL'), field: 'FromEmailID' },
            { title: t('LABEL.TO_EMAIL'), field: 'ToEmailID' },
            { title: t('FIAT.CURRENCY_TITLE'), field: 'CurrencyCode' },
            { title: t('TRANSACTION.AMOUNT'), field: 'Amount' },
            { title: t('LABEL.FEES_LA'), field: 'Fees' },
            { title: t('CRYPTOTRANSACTION.WALLET_ADDRESS'), field: 'WalletAddress' },
        ];
        const columnTitles = columns.map(columnDef => columnDef.title);

        const FileData = FilterData.map(rowData =>
            columns.map(columnDef => rowData[columnDef.field]),
        );

        if (val === "CSV") {
            const builder = new CsvBuilder(FileName + `.csv`)
                .setColumns(columnTitles)
                .addRows(FileData)
                .exportFile();

            return builder;
        }
        else if (val === "PDF") {
            const doc = new jsPDF();

            doc.autoTable({
                head: [columnTitles],
                body: FileData,
            });

            doc.save(FileName + `.pdf`);
        }
    }


    const handleChangeFromDate = (val) => {
        setFromDate(val);
        filter("1," + val, "", "");
    }
    const handleChangeToDate = (val) => {
        setToDate(val);
        filter("", "1," + val, "");
    }
    const handleChangeCustmer = (val) => {
        setCustomer(val);
        filter("", "", "1," + val);
    }
    const filter = (from_date = "", to_date = "", customer_email = "") => {
        var tempFromDate = FromDate;
        var tempToDate = ToDate;
        var tempCustomerEmail = Customer;

        if (from_date !== "") {
            if (from_date.split(",")[0] === "1" && from_date.split(",")[1] !== "") {
                tempFromDate = from_date.split(",")[1];
            }
            else if (from_date.split(",")[0] === "1" && from_date.split(",")[1] === "") {
                tempFromDate = "";
            }
        }

        if (to_date !== "") {
            if (to_date.split(",")[0] === "1" && to_date.split(",")[1] !== "") {
                tempToDate = to_date.split(",")[1];
            }
            else if (to_date.split(",")[0] === "1" && to_date.split(",")[1] === "") {
                tempToDate = "";
            }
        }

        if (customer_email !== "") {
            if (customer_email.split(",")[0] === "1" && customer_email.split(",")[1] !== "") {
                tempCustomerEmail = customer_email.split(",")[1];
            }
            else if (customer_email.split(",")[0] === "1" && customer_email.split(",")[1] === "") {
                tempCustomerEmail = "";
            }
        }

        let TempArr = [];
        ResultData.forEach(ResultData_ => {
            if (tempFromDate === "" && tempToDate === "" && (tempCustomerEmail !== "" && tempCustomerEmail === ResultData_.FromEmailID)) {
                TempArr.push(ResultData_);
            }
            else if ((tempFromDate !== "" && moment(ResultData_.created_datetime).format('YYYY-MM-DD') >= moment(tempFromDate).format('YYYY-MM-DD')) && tempToDate === "" && tempCustomerEmail === "") {
                TempArr.push(ResultData_);
            }
            else if (tempFromDate === "" && (tempToDate !== "" && moment(ResultData_.created_datetime).format('YYYY-MM-DD') <= moment(tempToDate).format('YYYY-MM-DD')) && tempCustomerEmail === "") {
                TempArr.push(ResultData_);
            }
            else if ((tempFromDate !== "" && moment(ResultData_.created_datetime).format('YYYY-MM-DD') >= moment(tempFromDate).format('YYYY-MM-DD')) && (tempToDate !== "" && moment(ResultData_.created_datetime).format('YYYY-MM-DD') <= moment(tempToDate).format('YYYY-MM-DD')) && tempCustomerEmail === "") {
                TempArr.push(ResultData_);
            }
            else if ((tempFromDate !== "" && moment(ResultData_.created_datetime).format('YYYY-MM-DD') >= moment(tempFromDate).format('YYYY-MM-DD')) && tempToDate === "" && (tempCustomerEmail !== "" && tempCustomerEmail === ResultData_.FromEmailID)) {
                TempArr.push(ResultData_);
            }
            else if (tempFromDate === "" && (tempToDate !== "" && moment(ResultData_.created_datetime).format('YYYY-MM-DD') <= moment(tempToDate).format('YYYY-MM-DD')) && (tempCustomerEmail !== "" && tempCustomerEmail === ResultData_.FromEmailID)) {
                TempArr.push(ResultData_);
            }
            else if ((tempFromDate !== "" && moment(ResultData_.created_datetime).format('YYYY-MM-DD') >= moment(tempFromDate).format('YYYY-MM-DD')) && (tempToDate !== "" && moment(ResultData_.created_datetime).format('YYYY-MM-DD') <= moment(tempToDate).format('YYYY-MM-DD')) && (tempCustomerEmail !== "" && tempCustomerEmail === ResultData_.FromEmailID)) {
                TempArr.push(ResultData_);
            }
            else if (tempFromDate === "" && tempToDate === "" && tempCustomerEmail === "") {
                TempArr.push(ResultData_);
            }
        });
        setFilterData(TempArr);
    }

    const handleLoad = (Setting_Data = []) => {
        let SettingDataTemp = Setting_Data;
        if (Setting_Data.length === 0) {
            SettingDataTemp = SettingData;
        }

        var jsonpar = JSON.stringify({
            'manager_id': window.sessionStorage.getItem("manager_id")
        });
        const headers = {
            'Content-Type': 'application/json',
            'manageraccesstoken': process.env.REACT_APP_MANAGER_ACCESS_TOKEN,
            'managerauthtoken': 'Bearer ' + window.sessionStorage.getItem("manager_jwt"),
        }
        axios.post(process.env.REACT_APP_API_URL + 'getAllTransferTransactions', jsonpar, {
            headers: headers
        })
            .then(function (res) {
                res = res.data;
                if (res.code === "200") {

                    let CustomerEmailTempArr = [];
                    let CustomerDataTempArr = [];
                    let TempArr = [];
                    res.data.forEach(element => {
                        if (!CustomerEmailTempArr.includes(element.user_email_id)) {
                            var CustomerTempArr = {
                                CustomerNameNEmail: element.user_first_name + " " + element.user_last_name + " (" + element.user_email_id + ")",
                                CustomerEmail: element.user_email_id,
                            }
                            CustomerDataTempArr.push(CustomerTempArr);
                            CustomerEmailTempArr.push(element.user_email_id);
                        }

                        var currency = "-";
                        switch (element.user_cw_coin_code) {
                            case "ETH":
                                currency = <div> <img alt="" src={ethicon} className="coin-img" /> </div>
                                break;

                            case "USDT":
                                currency = <div> <img alt="" src={usdticon} className="coin-img" /> </div>
                                break;

                            default:
                                currency = <div> <img alt="" src={btcicon} className="coin-img" /> </div>
                                break;
                        }

                        let CustomerName = element.user_first_name + " " + element.user_last_name;
                        let FromEmailID = element.user_email_id;
                        let ToEmailID = element.user_to_email_id;
                        if (SettingDataTemp.setting_value === "No") {
                            CustomerName = "*****";
                            FromEmailID = "*****";
                            ToEmailID = "*****";
                        }

                        let temp = {
                            TransactionsID: element.transaction_hash,
                            CustomerName: CustomerName,
                            FromEmailID: FromEmailID,
                            ToEmailID: ToEmailID,
                            Date: moment(element.created_datetime).format('MM / DD / YYYY hh:mm:ss'),
                            Currency: currency,
                            Fees: element.admin_fees,
                            Amount: element.transfer_amount,
                            WalletAddress: element.to_wallet_address,
                            created_datetime: element.created_datetime,
                            CurrencyCode: element.user_cw_coin_code,
                        }
                        TempArr.push(temp);
                    });
                    setResultData(TempArr);
                    setFilterData(TempArr);
                    setCustomerData(CustomerDataTempArr);

                    setTimeout(() => {
                        setLoader(false);
                    }, 500);
                } else {
                    setLoader(false);
                }
            })
            .catch(function (error) {
                setLoader(false);
            });
    }

    const handleLoadSetting = () => {
        var jsonpar = JSON.stringify({
            'setting_id': 1
        });
        const headers = {
            'Content-Type': 'application/json',
            'manageraccesstoken': process.env.REACT_APP_MANAGER_ACCESS_TOKEN,
            'managerauthtoken': 'Bearer ' + window.sessionStorage.getItem("manager_jwt"),
        }
        axios.post(process.env.REACT_APP_API_URL + 'getSettgingInformation', jsonpar, {
            headers: headers
        })
            .then(function (res) {
                res = res.data;
                if (res.code === "200") {
                    setSettingData(res.data);
                    handleLoad(res.data);

                    setTimeout(() => {
                        setLoader(false);
                    }, 500);
                } else {
                    handleLoad();
                    setLoader(false);
                }
            })
            .catch(function (error) {
                setLoader(false);
            });
    }


    useEffect(() => {
        setLoader(true);
        handleLoadSetting();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    return (
        <div>
            <div className="card">
                <div className="card-header page-title-space mobile-card-header">
                    <h2 className="text-black font-w600 mb-0">{t('TRANSACTION.TRANSFERTRALA')}</h2>
                    <div className="mobileFullWidth">
                        <Dropdown className="custom-dropdown mb-0" >
                            <Dropdown.Toggle className="btn btn-primary btn-rounded i-false" variant='' >
                                <i className="las la-download scale5 mr-3"></i> Download Report
                                <i className="fa fa-caret-down text-white ml-3" aria-hidden="true"></i>
                            </Dropdown.Toggle>
                            <Dropdown.Menu className="dropdown-menu-right right-cstm">
                                <Dropdown.Item className="" onClick={(e) => handleChangeCSVNdPDF("CSV")}>CSV</Dropdown.Item>
                                <Dropdown.Item className="" onClick={(e) => handleChangeCSVNdPDF("PDF")}>PDF</Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                    </div>
                </div>
            </div>
            {loader === true ?
                <div>
                    <Loader open_close={loader} />
                </div>
                :
                <div className="card">
                    <div className="card-body">
                        <div className="row">
                            <div className="col-md-3">
                                <div className="form-group position-relative">
                                    <label className="mb-2 ">
                                        <span>{t('TRANSACTION.FROM_DATE')}</span>
                                    </label>
                                    <div className="col-xs-12">
                                        <input type="date" onChange={(e) => handleChangeFromDate(e.target.value)} value={FromDate} className="form-control" />
                                    </div>
                                </div>
                            </div>

                            <div className="col-md-3">
                                <div className="form-group position-relative">
                                    <label className="mb-2 ">
                                        <span>{t('TRANSACTION.TO_DATE')}</span>
                                    </label>
                                    <div className="col-xs-12">
                                        <input type="date" onChange={(e) => handleChangeToDate(e.target.value)} value={ToDate} className="form-control" />
                                    </div>
                                </div>
                            </div>
                            {SettingData.setting_value === "Yes" ?
                                <div className="col-md-6">
                                    <label className="mb-2 ">
                                        <span>{t('HEADER.CUS_NAME')}</span>
                                    </label>
                                    <div className="form-group position-relative">
                                        <select className="form-control webkit-none" onChange={(e) => handleChangeCustmer(e.target.value)} value={Customer}>
                                            <option value="">{t('ACCOUNT_TRANSACTIONS.SELECT_ALL')}</option>
                                            {CustomerData && CustomerData.map((row) => (
                                                <option value={row.CustomerEmail} key={row.CustomerEmail}>{row.CustomerNameNEmail}</option>
                                            ))}
                                        </select>
                                        <i className="fa fa-caret-down down-arrow select-arrow1" aria-hidden="true"></i>
                                    </div>
                                </div>
                                : ""}
                        </div>
                    </div>
                    <div className="card-body">
                        <div className="position-relative">
                            <div className="table-responsive">
                                {FilterData.length > 0 ?
                                    <MaterialTable
                                        title="Transfer Transactions"
                                        icons={materialTableIcons}
                                        options={{ exportButton: false, paging: true, pageSize: 5, pageSizeOptions: [5, 10, 15], emptyRowsWhenPaging: false, }}
                                        columns={[
                                            { title: t('ACCOUNT_TRANSACTIONS.TRANSACTIONS_ID'), field: 'TransactionsID' },
                                            { title: t('ACCOUNT_TRANSACTIONS.DATE'), field: 'Date' },
                                            { title: t('HEADER.CUS_NAME'), field: 'CustomerName' },
                                            { title: t('LABEL.FROM_EMAIL'), field: 'FromEmailID' },
                                            { title: t('LABEL.TO_EMAIL'), field: 'ToEmailID' },
                                            { title: t('FIAT.CURRENCY_TITLE'), field: 'Currency' },
                                            { title: t('TRANSACTION.AMOUNT'), field: 'Amount' },
                                            { title: t('LABEL.FEES_LA'), field: 'Fees' },
                                            { title: t('CRYPTOTRANSACTION.WALLET_ADDRESS'), field: 'WalletAddress' },
                                        ]}
                                        data={FilterData}
                                    />
                                    : <div className="d-sm-flex d-block border-0 pb-0">
                                        <div className="pr-3 mr-auto mb-sm-0 mb-3 nofoundFullWidth">
                                            <h4 className="fs-20 text-black mb-1 nofoundTitle">Transfer Transactions Overview</h4>
                                            <span className="fs-12 nofoundText">No Data Found</span>
                                        </div>
                                    </div>}
                            </div>
                        </div>
                    </div>
                </div>
            }
        </div>
    )

}
export default Transfer_Transactions;