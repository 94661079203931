import React, { useState, useEffect } from "react";
import { Accordion, Card } from 'react-bootstrap';
import { useTranslation } from "react-i18next";
import axios from 'axios';
import Loader from '../../../jsx/pages/Loader';

export const FAQ = () => {
    const { t } = useTranslation();
    const [loader, setLoader] = useState(false);
    const [faqarray, setfaqarray] = useState([]);
    const [faqtype, setfaqtype] = useState([]);
    const [default_type, setdefault_type] = useState("");
    const [activeDefault, setActiveDefault] = useState(0);

    useEffect(() => {
        handleLoad();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleLoad = () => {
        const headers = {
            'Content-Type': 'application/json',
            'manageraccesstoken': process.env.REACT_APP_MANAGER_ACCESS_TOKEN,
            'managerauthtoken': 'Bearer ' + window.sessionStorage.getItem("manager_jwt"),
        }
        setLoader(true);
        axios.get(process.env.REACT_APP_API_URL + 'getFaqs', { headers: headers })
            .then(function (res) {
                res = res.data;
                if (res.code === "200") {
                    setfaqtype(res.data);
                    if (res.data.length > 0) {
                        initaliacall(res.data[0].faq_cat_id);
                        setdefault_type(res.data[0].faq_cat_id);
                    }
                }
            })
            .catch(function (error) {
                setLoader(false);
            });
    }


    const initaliacall = (value) => {
        const headers = {
            'Content-Type': 'application/json',
            'manageraccesstoken': process.env.REACT_APP_MANAGER_ACCESS_TOKEN,
            'managerauthtoken': 'Bearer ' + window.sessionStorage.getItem("manager_jwt"),
        }
        setLoader(true);
        axios.get(process.env.REACT_APP_API_URL + 'getFaqsbycat/' + value, { headers: headers })
            .then(function (res) {
                res = res.data;
                if (res.code === "200") {
                    setfaqarray(res.data);
                }
                setTimeout(() => {
                    setLoader(false);
                }, 500);

            })
            .catch(function (error) {
                setLoader(false);
            });
    }


    const validateFaq = (value) => {
        setdefault_type(value);
        initaliacall(value);
        return true;
    }

    return (
        <div>
            <div className="card">
                {loader === true ?
                    <div>
                        <Loader open_close={loader} />
                    </div>
                    :
                    <div className="card-header mobile-card-header">
                        <h2 className="text-black font-w600 mb-0">{t('HEADER.FAQ_TITLE')}</h2>
                        <div className="mobileFullWidth">
                            <div className="form-group position-relative mb-0">
                                <select className="form-control webkit-none select-account select-account-new" id="sel1" value={default_type} onChange={(e) => validateFaq(e.target.value)}>
                                    {faqtype && faqtype.map((row, i) => (
                                        <option value={row.faq_cat_id} key={row.faq_cat_id}>{row.category_name}</option>
                                    ))}
                                </select>
                                <i className="fa fa-caret-down down-arrow" aria-hidden="true"></i>
                            </div>
                        </div>
                    </div>
                }
            </div>
            <div className="card">
                {loader === true ?
                    <div>
                        <Loader open_close={loader} />
                    </div>
                    :
                    <div className="card-body">
                        {faqarray.length > 0 ?
                            <div className="table-responsive">
                                <Accordion className='accordion accordion-primary' defaultActiveKey="0">
                                    {faqarray && faqarray.map((row, i) => (
                                        <div className='accordion__item' key={`${i}`}>
                                            <Accordion.Toggle as={Card.Text} eventKey={`${i}`} className={`accordion__header rounded-lg ${activeDefault === i ? '' : 'collapsed'}`} onClick={() => setActiveDefault(activeDefault === i ? -1 : i)} >
                                                <span className='accordion__header--text'>{row.question}</span>
                                                <span className='accordion__header--indicator'></span>
                                            </Accordion.Toggle>
                                            <Accordion.Collapse eventKey={`${i}`}>
                                                <div className='accordion__body--text'>
                                                    <div className="row mt-4">
                                                        <div className="col-md-12">
                                                            {row.answer}
                                                        </div>
                                                    </div>
                                                </div>
                                            </Accordion.Collapse>
                                        </div>
                                    ))}
                                </Accordion>
                            </div>
                            : t("TRANSACTION.NOT_DATA_FOUND")}
                    </div>
                }
            </div>
        </div>

    )

}
export default FAQ;