import React, { useState, useEffect } from 'react'
/// React router dom
import { Switch, Route, useHistory } from 'react-router-dom'
import axios from 'axios';
/// Css
import './index.css'
import './chart.css'
import './step.css'

import AppLogout from './pages/AppLogout';
/// Layout
import Nav from './layouts/nav'
import Footer from './layouts/Footer'

/// Dashboard
import Home from './components/Dashboard/Home'
import MyWallet from './components/Dashboard/MyWallet'
import Invoices from './components/Dashboard/Invoices'
import CardsCenter from './components/Dashboard/CardsCenter'
import Transactions from './components/Dashboard/Transactions'
import TransactionsDetails from './components/Dashboard/TransactionsDetails'
import Task from './components/Dashboard/Task';

/// Rewards
import Rewards_Fees_Earned from './components/Rewards/Rewards_Fees_Earned';
import Rewards_Withdrawal_Request from './components/Rewards/Rewards_Withdrawal_Request';
import Rewards_Transaction from './components/Rewards/Rewards_Transaction';

/// Manager
import Manager_List from './components/Manager_old/Manager_List'
import Manager_Fees_Earned from './components/Manager_old/Manager_Fees_Earned'
import Manager_Withdrawal_Request from './components/Manager_old/Manager_Withdrawal_Request'
import Manager_Customer_Transaction from './components/Manager_old/Manager_Customer_Transaction'

/// Broker
import Broker_List from './components/Broker/Broker_List'
import Broker_Request from './components/Broker/Broker_Request'
import Broker_Customer_Transaction from './components/Broker/Broker_Customer_Transaction'

/// Customer
import Customer_List from './components/Customer/Customer_List'

/// Account Card
import Request_Cards from './components/CardAccount/Request_Cards'
import Cards_Listing from './components/CardAccount/Cards_Listing'
import Cards_Transaction from './components/CardAccount/Cards_Transaction'
import Cards_Issuance from './components/CardAccount/Cards_Issuance'
import Cards_Contact from './components/CardAccount/Cards_Contact'
import Cards_Contact_Details from './components/CardAccount/Cards_Contact_Details'
import Order_Details from './components/CardAccount/Order_Details'

/// Transaction
import Account_Transactions from './components/Transaction/Account_Transactions'
import Internal_Transfers from './components/Transaction/Internal_Transfers'
import Outgoing_Transfers from './components/Transaction/Outgoing_Transfers'
import Crypto_Exchange_Transactions from './components/Transaction/Crypto_Exchange_Transactions'
import Deposit_Transactions from './components/Transaction/Deposit_Transactions'
import Withdrawal_Transactions from './components/Transaction/Withdrawal_Transactions'
import Transfer_Transactions from './components/Transaction/Transfer_Transactions'
import Swap_Transactions from './components/Transaction/Swap_Transactions'

/// Activity Logs
import Users_Activity_Logs from './components/ActivityLogs/Users_Activity_Logs'

/// Management
import News from './components/Management/News'
import News_Details from './components/Management/News_Details'
import FAQ from './components/Management/FAQ'
import Feedback from './components/Management/Feedback'
import KYC from './components/Management/KYC'
import KYCDetails from './components/Management/KYCDetails'
import Terms from './components/Management/Terms'
import Privacy from './components/Management/Privacy'
import Contactus from './components/Management/Contactus'

/// Profile Management
import Profile from './components/Profile/profile'
import EditProfile from './components/Profile/EditProfile'
import Inquiry_Logs from './components/Inquiry/Inquiry_Logs'


/// Pages
// import Registration from './pages/Registration'
// import RegisterVerifyOTP from './pages/RegisterVerifyOTP'

// import Login from './pages/Login'
import Error400 from './pages/Error400'
import Error403 from './pages/Error403'
import Error404 from './pages/Error404'
import Error500 from './pages/Error500'
import Error503 from './pages/Error503'
// Custom Pages

//Scroll To Top
import ScrollToTop from './layouts/ScrollToTop';
import MyLinks from './components/Management/MyLinks';


const Markup = () => {
  const history = useHistory();
  let path = window.location.pathname
  path = path.split('/')
  path = path[path.length - 1]
  let pagePath = path.split('-').includes('page')
  const [activeEvent, setActiveEvent] = useState(!path)

  useEffect(() => {

    setTimeout(() => {
      if (window.sessionStorage.getItem("manager_id") <= 0) {
        history.push('/login');
      }
    }, 3000);

    if (window.sessionStorage.getItem("user_ip") !== "") {
      getData();
    }
  }, [history]);


  const getData = async () => {
    const res = await axios.get('https://geolocation-db.com/json/')
    if (res.data.IPv4 === window.sessionStorage.setItem("user_ip", res.data.ipaddress)) {
      return true;
    } else {
      if (window.sessionStorage.getItem("diffips") === "Yes") {
        localStorage.clear();
        sessionStorage.clear();
        window.location.pathname = "/";
      }
    }
  }

  const routes = [
    /// Dashboard
    { url: '', component: Home },
    { url: 'dashboard', component: Home },
    { url: 'my-wallet', component: MyWallet },
    { url: 'invoices', component: Invoices },
    { url: 'cards-center', component: CardsCenter },
    { url: 'transactions', component: Transactions },
    { url: 'transactions-details', component: TransactionsDetails },
    { url: 'task', component: Task },

    /// Rewards
    { url: 'rewards-fees-earned', component: Rewards_Fees_Earned },
    { url: 'rewards-withdrawal-request', component: Rewards_Withdrawal_Request },
    { url: 'rewards-transaction', component: Rewards_Transaction },

    /// Manager
    { url: 'manager-list', component: Manager_List },
    { url: 'manager-fees-earned', component: Manager_Fees_Earned },
    { url: 'manager-withdrawal-request', component: Manager_Withdrawal_Request },
    { url: 'manager-customer-transaction', component: Manager_Customer_Transaction },

    /// Broker
    { url: 'broker-list', component: Broker_List },
    { url: 'broker-request', component: Broker_Request },
    { url: 'broker-customer-transaction', component: Broker_Customer_Transaction },

    /// Customer
    { url: 'customer-list', component: Customer_List },

    /// Account Card
    { url: 'request-cards', component: Request_Cards },
    { url: 'cards-listing', component: Cards_Listing },
    { url: 'cards-transaction', component: Cards_Transaction },
    { url: 'cards-issuance', component: Cards_Issuance },
    { url: 'cards-contact', component: Cards_Contact },
    { url: 'cards-contact-details', component: Cards_Contact_Details },
    { url: 'order-details', component: Order_Details },

    { url: 'inquiry-log', component: Inquiry_Logs },

    /// Transaction
    { url: 'account-transactions', component: Account_Transactions },
    { url: 'internal-transfers', component: Internal_Transfers },
    { url: 'outgoing-transfers', component: Outgoing_Transfers },
    { url: 'crypto-exchange-transactions', component: Crypto_Exchange_Transactions },
    { url: 'deposit-transactions', component: Deposit_Transactions },
    { url: 'withdrawal-transactions', component: Withdrawal_Transactions },
    { url: 'transfer-transactions', component: Transfer_Transactions },
    { url: 'swap-transactions', component: Swap_Transactions },

    /// Activity Logs
    { url: 'users-activity-logs', component: Users_Activity_Logs },
    { url: 'mylinks', component: MyLinks },

    /// Management
    { url: 'news', component: News },
    { url: 'news-details/:id', component: News_Details },
    { url: 'FAQ', component: FAQ },
    { url: 'feedback', component: Feedback },
    { url: 'kyc', component: KYC },
    { url: 'kyc-details/:id', component: KYCDetails },
    { url: 'terms', component: Terms },
    { url: 'privacypolicy', component: Privacy },
    { url: 'contact-us', component: Contactus },

    /// Profile Management
    { url: 'profile', component: Profile },
    { url: 'editprofile', component: EditProfile },

    /// pages
    // { url: 'page-register', component: Registration },
    // { url: 'page-login', component: Login },
    { url: 'page-error-400', component: Error400 },
    { url: 'page-error-403', component: Error403 },
    { url: 'page-error-404', component: Error404 },
    { url: 'page-error-500', component: Error500 },
    { url: 'page-error-503', component: Error503 },
    { url: 'register-verify-otp', component: Home },
    { url: '*', component: Error404 },
  ]

  return (
    <>
      <div
        id={`${!pagePath ? 'main-wrapper' : ''}`}
        className={`${!pagePath ? 'show' : 'mh100vh'}`}
      >
        <AppLogout>
          {!pagePath && (
            <Nav
              onClick={() => setActiveEvent(!activeEvent)}
              activeEvent={activeEvent}
              onClick2={() => setActiveEvent(false)}
              onClick3={() => setActiveEvent(true)}
            />
          )}
          <div
            className={` ${!path && activeEvent ? 'rightside-event' : ''} ${!pagePath ? 'content-body' : ''
              }`}
          >
            <div
              className={`${!pagePath ? 'container-fluid' : ''}`}
              style={{ minHeight: window.screen.height - 60 }}
            >
              <Switch>
                {routes.map((data, i) => (
                  <Route
                    key={i}
                    exact
                    path={`/${data.url}`}
                    component={data.component}
                  />
                ))}
              </Switch>
            </div>
          </div>
          {!pagePath && <Footer />}
        </AppLogout>
      </div>
      <ScrollToTop />
    </>
  )
}

export default Markup
