import React, { useState, useEffect } from "react";
import MaterialTable from "@material-table/core";
import { ExportCsv, ExportPdf } from "@material-table/exporters";
import { Modal } from 'react-bootstrap';
import { Link, useHistory } from "react-router-dom";
import Snackbars from '../../../jsx/pages/Snackbars';
import { useTranslation } from "react-i18next";
import axios from 'axios';
import moment from 'moment';
import Loader from '../../pages/Loader';
import validator from 'validator';
import { CsvBuilder } from 'filefy';
import jsPDF from 'jspdf';
import 'jspdf-autotable';


export const Broker_List = () => {

    const { t } = useTranslation();
    const history = useHistory();
    const [loader, setLoader] = useState(false);
    const [error, setError] = useState(false);
    const [success, setSuccess] = useState(false);
    const [error_msg, setErrorMsg] = useState("");
    const [success_msg, setSuccessMsg] = useState("");


    const [CountryData, setCountryData] = useState([]);
    const [ResultData, setResultData] = useState([]);
    const [FilterData, setFilterData] = useState([]);
    const [CustomerData, setCustomerData] = useState([]);
    const [Customer, setCustomer] = useState("");
    const [FromDate, setFromDate] = useState("");
    const [ToDate, setToDate] = useState("");
    const [BrokerID, setBrokerID] = useState("");
    const [FirstName, setFirstName] = useState("");
    const [FirstNameError, setFirstNameError] = useState("");
    const [LastName, setLastName] = useState("");
    const [LastNameError, setLastNameError] = useState("");
    const [Email, setEmail] = useState("");
    const [EmailError, setEmailError] = useState("");
    const [CountryCode, setCountryCode] = useState("1");
    const [Phone, setPhone] = useState("");
    const [PhoneError, setPhoneError] = useState("");
    const [Status, setStatus] = useState("");
    const [CustomStatus, setCustomStatus] = useState("");


    const [Modal1, setModal1] = useState(false);
    const openModal1 = (element) => {
        setBrokerID(element.broker_id);
        setFirstName(element.first_name);
        setLastName(element.last_name);
        setEmail(element.email_id);
        setCountryCode(element.country_code);
        setPhone(element.phone_number);
        setModal1(true);
    }
    const FieldRequiredError = t('LABEL.FIELD_REQUIERD');
    const validateFirstName = (value) => {
        setFirstName(value);
        if (validator.isEmpty(value)) {
            setFirstNameError(FieldRequiredError);
            return false;
        } else {
            setFirstNameError("");
            return true;
        }
    }
    const validateLastName = (value) => {
        setLastName(value);
        if (validator.isEmpty(value)) {
            setLastNameError(FieldRequiredError);
            return false;
        } else {
            setLastNameError("");
            return true;
        }
    }
    const validateEmail = (value) => {
        setEmail(value);
        if (validator.isEmpty(value)) {
            setEmailError(FieldRequiredError);
            return false;
        } else if (!validator.isEmail(value)) {
            setEmailError(t('ERROR_MSG.CORRECT_EMAIL'));
            return false;
        } else {
            setEmailError("");
            return true;
        }
    }
    const validateCountryCode = (value) => {
        setCountryCode(value);
    }
    const validatePhone = (value) => {
        setPhone(value);
        if (validator.isEmpty(value)) {
            setPhoneError(FieldRequiredError);
            return false;
        } if (!validator.isNumeric(value)) {
            setPhoneError(t("LABEL.PLACEHOLDER_PHONE_NUMBER"));
            return false;
        } else {
            setPhoneError("");
            return true;
        }
    }
    const handlesubmit = () => {
        if (!validateFirstName(FirstName)) {
            return false;
        } else if (!validateLastName(LastName)) {
            return false;
        }
        else {
            setModal1(false);
            setLoader(true);

            var jsonpar = JSON.stringify({
                'manager_id': window.sessionStorage.getItem("manager_id"),
                'broker_id': BrokerID,
                'first_name': FirstName,
                'last_name': LastName,
            });
            const headers = {
                'Content-Type': 'application/json',
                'manageraccesstoken': process.env.REACT_APP_MANAGER_ACCESS_TOKEN,
                'managerauthtoken': 'Bearer ' + window.sessionStorage.getItem("manager_jwt"),
            }
            axios.post(process.env.REACT_APP_API_URL + 'updateManagerBroker', jsonpar, {
                headers: headers
            })
                .then(function (res) {
                    res = res.data;
                    if (res.code === "200") {
                        handleLoad();
                        setFirstName("");
                        setLastName("");
                        setEmail("");
                        setCountryCode("1");
                        setPhone("");
                        setSuccess(true);
                        setSuccessMsg(res.message);
                        setLoader(false);
                        setTimeout(() => {
                            setSuccess(false);
                        }, 6000);
                    } else {
                        setLoader(false);
                        setError(true);
                        setErrorMsg(res.message);
                        setTimeout(() => {
                            setError(false);
                        }, 6000);
                    }

                })
                .catch(function (error) {
                    setLoader(false);
                    setError(true);
                    setErrorMsg(t('ERROR_MSG.SOMETHING'));
                    setTimeout(() => {
                        setError(false);
                    }, 6000);
                });
        }
    }

    const [Modal2, setModal2] = useState(false);
    const openModal2 = (status, customStatus, brokerIDVal) => {
        setStatus(status);
        setCustomStatus(customStatus);
        setBrokerID(brokerIDVal);
        setModal2(true);
    }
    const handlesubmit2 = () => {
        setModal2(false);
        setLoader(true);

        var jsonpar = JSON.stringify({
            'manager_id': window.sessionStorage.getItem("manager_id"),
            'broker_id': BrokerID,
            'status': Status,
        });
        const headers = {
            'Content-Type': 'application/json',
            'manageraccesstoken': process.env.REACT_APP_MANAGER_ACCESS_TOKEN,
            'managerauthtoken': 'Bearer ' + window.sessionStorage.getItem("manager_jwt"),
        }
        axios.post(process.env.REACT_APP_API_URL + 'updateManagerBrokerStatus', jsonpar, {
            headers: headers
        })
            .then(function (res) {
                res = res.data;
                if (res.code === "200") {
                    handleLoad();
                    setBrokerID("");
                    setStatus("");
                    setCustomStatus("");
                    setSuccess(true);
                    setSuccessMsg(res.message);
                    setLoader(false);
                    setTimeout(() => {
                        setSuccess(false);
                    }, 6000);
                } else {
                    setLoader(false);
                    setError(true);
                    setErrorMsg(res.message);
                    setTimeout(() => {
                        setError(false);
                    }, 6000);
                }

            })
            .catch(function (error) {
                setLoader(false);
                setError(true);
                setErrorMsg(t('ERROR_MSG.SOMETHING'));
                setTimeout(() => {
                    setError(false);
                }, 6000);
            });
    }

    const handleChangeFromDate = (val) => {
        setFromDate(val);
        filter("1," + val, "", "");
    }
    const handleChangeToDate = (val) => {
        setToDate(val);
        filter("", "1," + val, "");
    }
    const handleChangeCustmer = (val) => {
        setCustomer(val);
        filter("", "", "1," + val);
    }
    const filter = (from_date = "", to_date = "", customer_email = "") => {
        var tempFromDate = FromDate;
        var tempToDate = ToDate;
        var tempCustomerEmail = Customer;

        if (from_date !== "") {
            if (from_date.split(",")[0] === "1" && from_date.split(",")[1] !== "") {
                tempFromDate = from_date.split(",")[1];
            }
            else if (from_date.split(",")[0] === "1" && from_date.split(",")[1] === "") {
                tempFromDate = "";
            }
        }

        if (to_date !== "") {
            if (to_date.split(",")[0] === "1" && to_date.split(",")[1] !== "") {
                tempToDate = to_date.split(",")[1];
            }
            else if (to_date.split(",")[0] === "1" && to_date.split(",")[1] === "") {
                tempToDate = "";
            }
        }

        if (customer_email !== "") {
            if (customer_email.split(",")[0] === "1" && customer_email.split(",")[1] !== "") {
                tempCustomerEmail = customer_email.split(",")[1];
            }
            else if (customer_email.split(",")[0] === "1" && customer_email.split(",")[1] === "") {
                tempCustomerEmail = "";
            }
        }

        let TempArr = [];
        ResultData.forEach(ResultData_ => {
            if (tempFromDate === "" && tempToDate === "" && (tempCustomerEmail !== "" && tempCustomerEmail === ResultData_.EmailID)) {
                TempArr.push(ResultData_);
            }
            else if ((tempFromDate !== "" && moment(ResultData_.created_datetime).format('YYYY-MM-DD') >= moment(tempFromDate).format('YYYY-MM-DD')) && tempToDate === "" && tempCustomerEmail === "") {
                TempArr.push(ResultData_);
            }
            else if (tempFromDate === "" && (tempToDate !== "" && moment(ResultData_.created_datetime).format('YYYY-MM-DD') <= moment(tempToDate).format('YYYY-MM-DD')) && tempCustomerEmail === "") {
                TempArr.push(ResultData_);
            }
            else if ((tempFromDate !== "" && moment(ResultData_.created_datetime).format('YYYY-MM-DD') >= moment(tempFromDate).format('YYYY-MM-DD')) && (tempToDate !== "" && moment(ResultData_.created_datetime).format('YYYY-MM-DD') <= moment(tempToDate).format('YYYY-MM-DD')) && tempCustomerEmail === "") {
                TempArr.push(ResultData_);
            }
            else if ((tempFromDate !== "" && moment(ResultData_.created_datetime).format('YYYY-MM-DD') >= moment(tempFromDate).format('YYYY-MM-DD')) && tempToDate === "" && (tempCustomerEmail !== "" && tempCustomerEmail === ResultData_.EmailID)) {
                TempArr.push(ResultData_);
            }
            else if (tempFromDate === "" && (tempToDate !== "" && moment(ResultData_.created_datetime).format('YYYY-MM-DD') <= moment(tempToDate).format('YYYY-MM-DD')) && (tempCustomerEmail !== "" && tempCustomerEmail === ResultData_.EmailID)) {
                TempArr.push(ResultData_);
            }
            else if ((tempFromDate !== "" && moment(ResultData_.created_datetime).format('YYYY-MM-DD') >= moment(tempFromDate).format('YYYY-MM-DD')) && (tempToDate !== "" && moment(ResultData_.created_datetime).format('YYYY-MM-DD') <= moment(tempToDate).format('YYYY-MM-DD')) && (tempCustomerEmail !== "" && tempCustomerEmail === ResultData_.EmailID)) {
                TempArr.push(ResultData_);
            }
            else if (tempFromDate === "" && tempToDate === "" && tempCustomerEmail === "") {
                TempArr.push(ResultData_);
            }
        });
        setFilterData(TempArr);
    }

    const handleLoad = () => {
        var jsonpar = JSON.stringify({
            'manager_id': window.sessionStorage.getItem("manager_id")
        });
        const headers = {
            'Content-Type': 'application/json',
            'manageraccesstoken': process.env.REACT_APP_MANAGER_ACCESS_TOKEN,
            'managerauthtoken': 'Bearer ' + window.sessionStorage.getItem("manager_jwt"),
        }
        axios.post(process.env.REACT_APP_API_URL + 'getAllBroker', jsonpar, {
            headers: headers
        })
            .then(function (res) {
                res = res.data;
                if (res.code === "200") {

                    let CustomerEmailTempArr = [];
                    let CustomerDataTempArr = [];
                    let TempArr = [];
                    res.data.forEach(element => {
                        if (!CustomerEmailTempArr.includes(element.email_id)) {
                            var CustomerTempArr = {
                                CustomerNameNEmail: element.first_name + " " + element.last_name + " (" + element.email_id + ")",
                                CustomerEmail: element.email_id,
                            }
                            CustomerDataTempArr.push(CustomerTempArr);
                            CustomerEmailTempArr.push(element.email_id);
                        }

                        var ActionBTN = <div>
                            <button className="btn btn-primary shadow btn-xs edit-shape" title="Change Status" onClick={(e) => openModal1(element)}><i className="fa fa-pencil" aria-hidden="true"></i></button>
                            <button className="btn btn-primary shadow btn-xs edit-shape" title="View Broker Contacts" onClick={(e) => viewcontact(element.broker_id)}><i className="flaticon-381-id-card " aria-hidden="true"></i></button>
                        </div>;

                        var StatusBTN = "-";
                        switch (element.status) {
                            case "Enable":
                                StatusBTN = <div><button className="btn btn-outline-success btn-rounded" onClick={(e) => openModal2("Disable", "Deactive", element.broker_id)}>Active</button></div>
                                break;

                            default:
                                StatusBTN = <div><button className="btn btn-outline-danger btn-rounded" onClick={(e) => openModal2("Enable", "Active", element.broker_id)}>Deactive</button></div>
                                break;
                        }

                        let temp = {
                            No: element.broker_id,
                            BrokerName: element.first_name + " " + element.last_name,
                            EmailID: element.email_id,
                            TotalReward: element.total_reward,
                            Phoneno: "+" + element.country_code + element.phone_number,
                            Action: ActionBTN,
                            Status: StatusBTN,
                            created_datetime: element.created_datetime,
                            StatusText: element.status,
                        }
                        TempArr.push(temp);
                    });
                    setResultData(TempArr);
                    setFilterData(TempArr);
                    setCustomerData(CustomerDataTempArr);

                    setTimeout(() => {
                        setLoader(false);
                    }, 500);
                } else {
                    setLoader(false);
                }
            })
            .catch(function (error) {
                setLoader(false);
            });
    }

    const handleLoadGetAllCountry = () => {
        const headers = {
            'Content-Type': 'application/json',
            'manageraccesstoken': process.env.REACT_APP_MANAGER_ACCESS_TOKEN,
            'managerauthtoken': 'Bearer ' + window.sessionStorage.getItem("manager_jwt"),
        }
        axios.get(process.env.REACT_APP_API_URL + 'getAllCountry', {
            headers: headers
        })
            .then(function (res) {
                res = res.data;
                if (res.code === "200") {
                    handleLoad();
                    setCountryData(res.data);
                } else {
                    handleLoad();
                }
            })
            .catch(function (error) {
                setLoader(false);
            });
    }


    useEffect(() => {
        setLoader(true);
        handleLoadGetAllCountry();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const viewcontact = (value) => {
        history.push({
            pathname: '/cards-contact',
            state: { broker_id: value }
        });
    }


    const handleChangeCSVNdPDF = (val) => {
        var FileName = "Broker List";

        var columns = [
            { title: t('LABEL.BROKER_NAME'), field: 'BrokerName' },
            { title: t('LABEL.TOTAL_REWARD'), field: 'TotalReward' },
            { title: t('KYC.STATUS_TITLE'), field: 'Status' },
        ];

        const columnTitles = columns.map(columnDef => columnDef.title);

        // const FileData = FilterData.map(rowData =>
        //     columns.map(columnDef => rowData[columnDef.field]),
        // );
        const FileData = FilterData.map(rowData =>
            [rowData["BrokerName"],
            rowData["TotalReward"],
            rowData["StatusText"]]
        );
        if (val === "CSV") {
            const builder = new CsvBuilder(FileName + `.csv`)
                .setColumns(columnTitles)
                .addRows(FileData)
                .exportFile();

            return builder;
        }
        else if (val === "PDF") {
            const doc = new jsPDF();

            doc.autoTable({
                head: [columnTitles],
                body: FileData,
            });

            doc.save(FileName + `.pdf`);
        }
    }


    return (
        <div>
            <div className="card">
                <div className="card-header page-title-space mobile-card-header">
                    <h2 className="text-black font-w600 mb-0">{t("LABEL.BROKER")}</h2>
                </div>
            </div>

            {error ? <Snackbars open_close={error} type="error" message={error_msg} /> : ""}
            {success ? <Snackbars open_close={success} type="success" message={success_msg} /> : ""}

            <Modal className="modal fade" show={Modal2}>
                <div className="modal-dialog-centered " >
                    <div className="modal-content">
                        <div className="modal-header">
                            <h3 className="modal-title">{t("LABEL.BROKER_STATUS")} </h3>
                            <button type="button" className="close" onClick={() => setModal2(false)}><span>&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <div className="col-xs-12">
                                <div className="form-group">
                                    {CustomStatus === "Active" ?
                                        <p className="text-muted font-18">{t('LABEL.AREYOUWANTTO')} <strong className="text-success">Active</strong> {t('LABEL.THIS_BROKER')}?</p>
                                        :
                                        <p className="text-muted font-18">{t('LABEL.AREYOUWANTTO')} <strong className="text-danger">Deactive</strong>{t('LABEL.THIS_BROKER')}?</p>
                                    }
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-light btn-rounded light" onClick={() => setModal2(false)}>{t('BUTTONS.CANCEL')}</button>
                            <button type="button" className="btn btn-primary btn-rounded" onClick={handlesubmit2}>{t('BUTTONS.SUBMIT')}</button>
                        </div>
                    </div>
                </div>
            </Modal>

            <Modal className="modal fade" show={Modal1} size="lg">
                <div className="modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h3 className="modal-title">{t("LABEL.EDIT_BROKER")}</h3>
                            <button type="button" className="close" onClick={() => setModal1(false)}><span>&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <div className="row">
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label className="mb-2 ">{t("LABEL.LABEL_FIRST_NAME")}</label>
                                        <input type="text" className="form-control" placeholder={t("PLACEHOLDER.PLACEHOLDER_FIRST_NAME")} value={FirstName} onChange={(e) => validateFirstName(e.target.value)} />
                                        {FirstNameError !== "" ? <label className='text-red'>{FirstNameError}</label> : ""}
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label className="mb-2 ">{t("LABEL.LABEL_LAST_NAME")}</label>
                                        <input type="text" className="form-control" placeholder={t("PLACEHOLDER.PLACEHOLDER_LAST_NAME")} value={LastName} onChange={(e) => validateLastName(e.target.value)} />
                                        {LastNameError !== "" ? <label className='text-red'>{LastNameError}</label> : ""}
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label className="mb-2 ">{t("LABEL.LABEL_EMAIL")}</label>
                                        <input type="email" className="form-control" placeholder={t("PLACEHOLDER.PLACEHOLDER_EMAIL")} value={Email} onChange={(e) => validateEmail(e.target.value)} disabled />
                                        {EmailError !== "" ? <label className='text-red'>{EmailError}</label> : ""}
                                    </div>
                                </div>
                                <div className="col-md-12">
                                    <div className='form-group mb-2 fullWidth'>
                                        <label className='mb-1 fullWidth'>{t("LABEL.LABEL_PHONE_NUMBER")}</label>
                                        <div className="row">
                                            <div className="form-group position-relative col-md-6">
                                                <select className="form-control webkit-none" value={CountryCode} onChange={(e) => validateCountryCode(e.target.value)} disabled>
                                                    {CountryData && CountryData.map((row) => (
                                                        <option value={row.phonecode} key={row.country_id_pk}>{"+" + row.phonecode + " (" + row.name + ")"}</option>
                                                    ))}
                                                </select>
                                                <i className="fa fa-caret-down down-arrow" aria-hidden="true"></i>
                                            </div>
                                            <div className='col-md-6'>
                                                <input type="number" className="form-control" placeholder={t("LABEL.PLACEHOLDER_PHONE_NUMBER")} value={Phone} onChange={(e) => validatePhone(e.target.value)} disabled />
                                                {PhoneError !== "" ? <label className='text-red'>{PhoneError}</label> : ""}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-light btn-rounded light" onClick={() => setModal1(false)}>{t('BUTTONS.CANCEL')}</button>
                            <button type="button" className="btn btn-primary btn-rounded" onClick={handlesubmit}>{t('BUTTONS.UPDATE')}</button>
                        </div>
                    </div>
                </div>
            </Modal>

            {loader === true ?
                <div>
                    <Loader open_close={loader} />
                </div>
                :
                <div className="card">
                    <div className="card-body bdr-btm-card">
                        <div className="row">
                            <div className="col-md-3">
                                <div className="form-group position-relative">
                                    <label className="mb-2 ">
                                        <span>{t('TRANSACTION.FROM_DATE')}</span>
                                    </label>
                                    <div className="col-xs-12">
                                        <input type="date" onChange={(e) => handleChangeFromDate(e.target.value)} value={FromDate} className="form-control" />
                                    </div>
                                </div>
                            </div>

                            <div className="col-md-3">
                                <div className="form-group position-relative">
                                    <label className="mb-2 ">
                                        <span>{t('TRANSACTION.TO_DATE')}</span>
                                    </label>
                                    <div className="col-xs-12">
                                        <input type="date" onChange={(e) => handleChangeToDate(e.target.value)} value={ToDate} className="form-control" />
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <label className="mb-2 ">
                                    <span>Broker </span>
                                </label>
                                <div className="form-group position-relative">
                                    <select className="form-control webkit-none" onChange={(e) => handleChangeCustmer(e.target.value)} value={Customer}>
                                        <option value="">{t('ACCOUNT_TRANSACTIONS.SELECT_ALL')}</option>
                                        {CustomerData && CustomerData.map((row) => (
                                            <option value={row.CustomerEmail} key={row.CustomerEmail}>{row.CustomerNameNEmail}</option>
                                        ))}
                                    </select>
                                    <i className="fa fa-caret-down down-arrow select-arrow1" aria-hidden="true"></i>
                                </div>
                            </div>

                        </div>
                        <div className="table-responsive">

                        </div>
                    </div>
                    <div className="card-body">
                        <div className="position-relative">
                            <div className="table-responsive">
                                {FilterData.length > 0 ?
                                    <MaterialTable
                                        title="Broker Lists"
                                        columns={[
                                            // { title: t('LABEL.NOA'), field: 'No', width: 70 },
                                            { title: t('LABEL.BROKER_NAME'), field: 'BrokerName' },
                                            // { title: t('LABEL.BROKER_EMAIL_ID'), field: 'EmailID' },
                                            { title: t('LABEL.TOTAL_REWARD'), field: 'TotalReward' },
                                            // { title: t('HEADER.CUS_PHONE'), field: 'Phoneno' },
                                            { title: t('KYC.ACTION'), field: 'Action' },
                                            { title: t('KYC.STATUS_TITLE'), field: 'Status' },
                                        ]}
                                        data={FilterData}
                                        options={{
                                            exportMenu: [
                                                { label: "Export PDF", exportFunc: (cols, datas) => handleChangeCSVNdPDF("PDF") },
                                                { label: "Export CSV", exportFunc: (cols, datas) => handleChangeCSVNdPDF("CSV") },
                                            ], paging: true, pageSize: 5, pageSizeOptions: [5, 10, 15], emptyRowsWhenPaging: false,
                                        }}
                                    />
                                    : <div className="d-sm-flex d-block border-0 pb-0">
                                        <div className="pr-3 mr-auto mb-sm-0 mb-3 nofoundFullWidth">
                                            <h4 className="fs-20 text-black mb-1 nofoundTitle">Broker Lists Overview</h4>
                                            <span className="fs-12 nofoundText">No Data Found</span>
                                        </div>
                                    </div>}
                            </div>
                        </div>
                    </div>
                </div>
            }
        </div>
    )

}
export default Broker_List;