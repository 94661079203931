import React, { useState, forwardRef } from "react";
import { Modal } from 'react-bootstrap';

import MaterialTable from "material-table";
import { Search, ViewColumn, SaveAlt, Remove, LastPage, FirstPage, FilterList, Edit, DeleteOutline, Clear, ChevronRight, ChevronLeft, Check, ArrowDownward, AddBox } from '@material-ui/icons';

const materialTableIcons = {
    Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
    Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
    Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
    DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
    Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
    Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
    FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
    LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
    NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
    ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
    SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
    ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
    ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />)
};

export const Feedback = () => {
    const [ViewFeedback, setViewFeedback] = useState(false)
    return (
        <div>
            <div className="card">
                <div className="card-header page-title-space">
                    <h2 className="text-black font-w600 mb-0">Feedback</h2>
                </div>
            </div>
            <div className="card">
                <div className="card-body">
                    <div className="position-relative">
                        <div className="table-responsive">
                            <MaterialTable
                                title="Feedback List"
                                icons={materialTableIcons}
                                options={{ exportButton: true, paging: true, pageSize: 5, pageSizeOptions: [5, 10, 15], emptyRowsWhenPaging: false, size: 'medium' }}
                                columns={[
                                    { title: '#', field: 'No', width: 70 },
                                    { title: 'Date', field: 'Date' },
                                    { title: 'User Name', field: 'UserName' },
                                    { title: 'User Email ID', field: 'UserEmailID' },
                                    { title: 'Rate', field: 'Rate', align: 'center' },
                                    { title: 'Action', field: 'Action', align: 'center' },
                                ]}
                                data={[
                                    {
                                        "No": "01",
                                        "Date": "April 1, 2020, 08:22 AM",
                                        "UserName": "Rushi Sukla ( Manager )",
                                        "UserEmailID": "rushi@domainname.com",
                                        "Rate": <div className="text-center table-star"><i className="fa fa-star yellow ml-1 m"></i> <i className="fa fa-star yellow"></i> <i className="fa fa-star yellow"></i> <i className="fa fa-star yellow"></i> <i className="fa fa-star yellow"></i></div>,
                                        "Action":
                                            <div>
                                                <button onClick={() => setViewFeedback(true)} className="btn btn-success shadow btn-xs edit-shape"><i className="fa fa-eye"></i></button>
                                            </div>,
                                    },
                                    {
                                        "No": "02",
                                        "Date": "April 1, 2020, 08:22 AM",
                                        "UserName": "Kaushal Sadhu ( Broker )",
                                        "UserEmailID": "kaushal@domainname.com",
                                        "Rate": <div className="text-center table-star"><i className="fa fa-star yellow"></i> <i className="fa fa-star yellow"></i> </div>,
                                        "Action":
                                            <div>
                                                <button onClick={() => setViewFeedback(true)} className="btn btn-success shadow btn-xs edit-shape"><i className="fa fa-eye"></i></button>
                                            </div>,
                                    },
                                ]}
                            />
                        </div>
                    </div>
                </div>
            </div>
            <Modal className="modal fade" show={ViewFeedback} size="lg">
                <div className="modal-dialog-centered " >
                    <div className="modal-content">
                        <div className="modal-header">
                            <h3 className="modal-title">View Feedback</h3>
                            <button type="button" className="close" onClick={() => setViewFeedback(false)}><span>&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <div className="col-xs-12">
                                <div className="form-group">
                                    <p className="text-black f-18 username-more">Rushi Sukla ( Manager )</p>
                                    <div className="rate-view">
                                        <i className="fa fa-star yellow" aria-hidden="true"></i>
                                        <i className="fa fa-star yellow" aria-hidden="true"></i>
                                        <i className="fa fa-star yellow" aria-hidden="true"></i>
                                        <i className="fa fa-star yellow" aria-hidden="true"></i>
                                        <i className="fa fa-star yellow" aria-hidden="true"></i>
                                    </div>
                                </div>
                                <div className="form-group mb-3">
                                    <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>
        </div >

    )

}
export default Feedback;