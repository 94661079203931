import React, { useState, useEffect } from "react";
import ReactApexChart from "react-apexcharts";

const TransferBarGraph = (props) => {
	const [series, setseries] = useState([{
		name: 'Fiat Transaction',
		data: ["0", "0", "0", "0", "0", "0", "0"],
	}, {
		name: 'Crypto Transaction',
		data: ["0", "0", "0", "0", "0", "0", "0"],
	}]);

	useEffect(() => {
		setTimeout(() => {
			setseries([{
				name: 'Fiat Transaction',
				data: [props.chartdata[0].fiatval[0], props.chartdata[0].fiatval[1], props.chartdata[0].fiatval[2], props.chartdata[0].fiatval[3], props.chartdata[0].fiatval[4], props.chartdata[0].fiatval[5], props.chartdata[0].fiatval[6]],
			}, {
				name: 'Crypto Transaction',
				data: [props.chartdata[0].cryptoval[0], props.chartdata[0].cryptoval[1], props.chartdata[0].cryptoval[2], props.chartdata[0].cryptoval[3], props.chartdata[0].cryptoval[4], props.chartdata[0].cryptoval[5], props.chartdata[0].cryptoval[6]],
			}])
		}, 3000);

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const [options, setoptions] = useState({
		chart: {
			height: 370,
			type: "bar",
			toolbar: {
				show: false,
			},
		},
		plotOptions: {
			bar: {
				borderRadius: 8,
				horizontal: false,
				columnWidth: '57%',
			},
		},
		colors: ['#D2D2D2', '#EBEBEB'],
		legend: {
			show: false,
			fontSize: '12px',
			labels: {
				colors: '#000000',

			},
			markers: {
				width: 18,
				height: 18,
				strokeWidth: 0,
				strokeColor: '#fff',
				fillColors: undefined,
				radius: 12,
			}
		},
		fill: {
			opacity: 1,
			colors: ['#D2D2D2', '#EBEBEB'],
		},
		dataLabels: {
			enabled: false,
		},
		stroke: {
			show: true,
			width: 4,
			colors: ['transparent'],

		},
		grid: {
			borderColor: '#eee',
		},
		xaxis: {
			categories: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
			labels: {
				style: {
					colors: '#787878',
					fontSize: '13px',
					fontFamily: 'poppins',
					fontWeight: 100,
					cssClass: 'apexcharts-xaxis-label',
				},
			},
			crosshairs: {
				show: false,
			}
		},
		yaxis: {
			labels: {
				offsetX: -16,
				style: {
					colors: '#787878',
					fontSize: '13px',
					fontFamily: 'poppins',
					fontWeight: 100,
					cssClass: 'apexcharts-xaxis-label',
				},
			},
		},
		tooltip: {
			y: {
				formatter: function (val) {
					return val + " Transactions"
				}
			}
		},
	});


	// useEffect(() => {
	// 	setseries([{
	// 		name: 'Fiat Transaction',
	// 		data: [props.chartdata[0].fiatval],
	// 	}, {
	// 		name: 'Crypto Transaction',
	// 		data: [props.chartdata[0].cryptoval],
	// 	}]);
	// 	// eslint-disable-next-line react-hooks/exhaustive-deps
	// }, []);



	return (
		<div id="chart">
			<ReactApexChart
				options={options}
				series={series}
				type="bar"
				height={350}
			/>
		</div>
	);

}

export default TransferBarGraph;
