import React, { useState, forwardRef, useEffect } from "react";
import MaterialTable from "@material-table/core"
import { Dropdown } from 'react-bootstrap';
import { useTranslation } from "react-i18next";
import axios from 'axios';
import moment from 'moment';
import Loader from '../../pages/Loader';
import { CsvBuilder } from 'filefy';
import jsPDF from 'jspdf';
import 'jspdf-autotable';


export const Account_Transactions = () => {
    const { t } = useTranslation();
    const [loader, setLoader] = useState(false);
    const [SettingData, setSettingData] = useState([]);
    const [ResultData, setResultData] = useState([]);
    const [FilterData, setFilterData] = useState([]);
    const [CustomerData, setCustomerData] = useState([]);
    const [Customer, setCustomer] = useState("");
    const [FromDate, setFromDate] = useState("");
    const [ToDate, setToDate] = useState("");


    const handleChangeCSVNdPDF = (val) => {
        var FileName = t('ACCOUNT_TRANSACTIONS.TRANSACTIONS');

        var columns = [
            { title: t('ACCOUNT_TRANSACTIONS.CUSTOMER_NAME'), field: 'CustomerName' },
            { title: t('ACCOUNT_TRANSACTIONS.CUSTOMER_EMAIL'), field: 'CustomerEmail' },
            { title: t('ACCOUNT_TRANSACTIONS.ACCOUNT_NO'), field: 'AccountNo' },
            { title: t('ACCOUNT_TRANSACTIONS.DATE'), field: 'Date' },
            { title: t('ACCOUNT_TRANSACTIONS.TRANSACTION_DETAILS'), field: 'TransactionDetails' },
            { title: t('ACCOUNT_TRANSACTIONS.DEBIT_CREDIT'), field: 'DebitCredit' },
            { title: t('ACCOUNT_TRANSACTIONS.AMOUNT'), field: 'Amount' },
            { title: t('ACCOUNT_TRANSACTIONS.BALANCE'), field: 'Balance' },
        ];
        const columnTitles = columns.map(columnDef => columnDef.title);
        const FileData = FilterData.map(rowData =>
            [rowData["TransactionsID"],
            rowData["CustomerName"],
            rowData["CustomerEmail"],
            rowData["AccountNo"],
            rowData["Date"],
            rowData["TransactionDetails"],
            rowData["DebitCredit"],
            rowData["AmountText"],
            rowData["Balance"]
            ]
        );

        if (val === "CSV") {
            const builder = new CsvBuilder(FileName + `.csv`)
                .setColumns(columnTitles)
                .addRows(FileData)
                .exportFile();

            return builder;
        }
        else if (val === "PDF") {
            const doc = new jsPDF();

            doc.autoTable({
                head: [columnTitles],
                body: FileData,
            });

            doc.save(FileName + `.pdf`);
        }
    }


    const handleChangeFromDate = (val) => {
        setFromDate(val);
        filter("1," + val, "", "");
    }
    const handleChangeToDate = (val) => {
        setToDate(val);
        filter("", "1," + val, "");
    }
    const handleChangeCustmer = (val) => {
        setCustomer(val);
        filter("", "", "1," + val);
    }
    const filter = (from_date = "", to_date = "", customer_email = "") => {
        var tempFromDate = FromDate;
        var tempToDate = ToDate;
        var tempCustomerEmail = Customer;

        if (from_date !== "") {
            if (from_date.split(",")[0] === "1" && from_date.split(",")[1] !== "") {
                tempFromDate = from_date.split(",")[1];
            }
            else if (from_date.split(",")[0] === "1" && from_date.split(",")[1] === "") {
                tempFromDate = "";
            }
        }

        if (to_date !== "") {
            if (to_date.split(",")[0] === "1" && to_date.split(",")[1] !== "") {
                tempToDate = to_date.split(",")[1];
            }
            else if (to_date.split(",")[0] === "1" && to_date.split(",")[1] === "") {
                tempToDate = "";
            }
        }

        if (customer_email !== "") {
            if (customer_email.split(",")[0] === "1" && customer_email.split(",")[1] !== "") {
                tempCustomerEmail = customer_email.split(",")[1];
            }
            else if (customer_email.split(",")[0] === "1" && customer_email.split(",")[1] === "") {
                tempCustomerEmail = "";
            }
        }

        let TempArr = [];
        ResultData.forEach(ResultData_ => {
            if (tempFromDate === "" && tempToDate === "" && (tempCustomerEmail !== "" && tempCustomerEmail === ResultData_.CustomerEmail)) {
                TempArr.push(ResultData_);
            }
            else if ((tempFromDate !== "" && moment(ResultData_.created_datetime).format('YYYY-MM-DD') >= moment(tempFromDate).format('YYYY-MM-DD')) && tempToDate === "" && tempCustomerEmail === "") {
                TempArr.push(ResultData_);
            }
            else if (tempFromDate === "" && (tempToDate !== "" && moment(ResultData_.created_datetime).format('YYYY-MM-DD') <= moment(tempToDate).format('YYYY-MM-DD')) && tempCustomerEmail === "") {
                TempArr.push(ResultData_);
            }
            else if ((tempFromDate !== "" && moment(ResultData_.created_datetime).format('YYYY-MM-DD') >= moment(tempFromDate).format('YYYY-MM-DD')) && (tempToDate !== "" && moment(ResultData_.created_datetime).format('YYYY-MM-DD') <= moment(tempToDate).format('YYYY-MM-DD')) && tempCustomerEmail === "") {
                TempArr.push(ResultData_);
            }
            else if ((tempFromDate !== "" && moment(ResultData_.created_datetime).format('YYYY-MM-DD') >= moment(tempFromDate).format('YYYY-MM-DD')) && tempToDate === "" && (tempCustomerEmail !== "" && tempCustomerEmail === ResultData_.CustomerEmail)) {
                TempArr.push(ResultData_);
            }
            else if (tempFromDate === "" && (tempToDate !== "" && moment(ResultData_.created_datetime).format('YYYY-MM-DD') <= moment(tempToDate).format('YYYY-MM-DD')) && (tempCustomerEmail !== "" && tempCustomerEmail === ResultData_.CustomerEmail)) {
                TempArr.push(ResultData_);
            }
            else if ((tempFromDate !== "" && moment(ResultData_.created_datetime).format('YYYY-MM-DD') >= moment(tempFromDate).format('YYYY-MM-DD')) && (tempToDate !== "" && moment(ResultData_.created_datetime).format('YYYY-MM-DD') <= moment(tempToDate).format('YYYY-MM-DD')) && (tempCustomerEmail !== "" && tempCustomerEmail === ResultData_.CustomerEmail)) {
                TempArr.push(ResultData_);
            }
            else if (tempFromDate === "" && tempToDate === "" && tempCustomerEmail === "") {
                TempArr.push(ResultData_);
            }
        });
        setFilterData(TempArr);
    }

    const handleLoad = (Setting_Data = []) => {
        let SettingDataTemp = Setting_Data;
        if (Setting_Data.length === 0) {
            SettingDataTemp = SettingData;
        }

        var jsonpar = JSON.stringify({
            'manager_id': window.sessionStorage.getItem("manager_id")
        });
        const headers = {
            'Content-Type': 'application/json',
            'manageraccesstoken': process.env.REACT_APP_MANAGER_ACCESS_TOKEN,
            'managerauthtoken': 'Bearer ' + window.sessionStorage.getItem("manager_jwt"),
        }
        axios.post(process.env.REACT_APP_API_URL + 'getAllAccountTransactions', jsonpar, {
            headers: headers
        })
            .then(function (res) {
                res = res.data;
                if (res.code === "200") {

                    let CustomerEmailTempArr = [];
                    let CustomerDataTempArr = [];
                    let TempArr = [];
                    res.data.forEach(element => {
                        if (!CustomerEmailTempArr.includes(element.user_email_id)) {
                            var CustomerTempArr = {
                                CustomerNameNEmail: element.user_first_name + " " + element.user_last_name + " (" + element.user_email_id + ")",
                                CustomerEmail: element.user_email_id,
                            }
                            CustomerDataTempArr.push(CustomerTempArr);
                            CustomerEmailTempArr.push(element.user_email_id);
                        }

                        let amount = "-";
                        switch (element.type) {
                            case "Debit":
                                amount = <div>
                                    <span className="me-2 oi-icon bgl-danger">
                                        <svg width="18" height="18" viewBox="0 0 18 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M6.57624 0.769646C6.77936 1.18944 6.59993 1.69725 6.18014 1.90038C5.01217 2.46236 4.02363 3.33579 3.31947 4.42928C2.59837 5.54986 2.21582 6.84986 2.21582 8.19048C2.21582 12.0363 5.34394 15.1644 9.18978 15.1644C13.0356 15.1644 16.1637 12.0363 16.1637 8.19048C16.1637 6.84986 15.7812 5.54985 15.0635 4.4259C14.3627 3.33241 13.3708 2.45897 12.2028 1.89699C11.783 1.69387 11.6036 1.18944 11.8067 0.766262C12.0098 0.34647 12.5143 0.167042 12.9374 0.370167C14.3898 1.06756 15.6187 2.1509 16.4887 3.51183C17.3825 4.90663 17.8564 6.52486 17.8564 8.19048C17.8564 10.5061 16.9559 12.6829 15.3174 14.3181C13.6822 15.9566 11.5054 16.8571 9.18978 16.8571C6.87415 16.8571 4.69733 15.9566 3.06217 14.3181C1.42363 12.6795 0.523111 10.5061 0.523111 8.19048C0.523111 6.52486 0.99707 4.90663 1.89421 3.51183C2.76764 2.15428 3.99655 1.06756 5.44551 0.370167C5.86868 0.170427 6.37311 0.34647 6.57624 0.769646Z" fill="#FF2E2E"></path>
                                            <path d="M5.89551 7.7402C5.72962 7.57431 5.64837 7.35765 5.64837 7.14098C5.64837 6.92431 5.72962 6.70765 5.89551 6.54176L8.02493 4.41233C8.33639 4.10088 8.74941 3.93161 9.18613 3.93161C9.62285 3.93161 10.0393 4.10426 10.3473 4.41233L12.4768 6.54176C12.8085 6.87353 12.8085 7.40843 12.4768 7.7402C12.145 8.07197 11.6101 8.07197 11.2783 7.7402L10.0291 6.49098L10.0291 11.3017C10.0291 11.7688 9.64993 12.148 9.18275 12.148C8.71556 12.148 8.33639 11.7688 8.33639 11.3017L8.33639 6.4876L7.08717 7.73681C6.76217 8.06858 6.22728 8.06858 5.89551 7.7402Z" fill="#FF2E2E"></path>
                                        </svg>

                                    </span>&nbsp;&nbsp;{element.amount}
                                </div>;
                                break;

                            default:
                                amount = <div>
                                    <span className="me-2 oi-icon bgl-success">
                                        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <g clip-path="url(#clip0)">
                                                <path d="M11.4238 16.2304C11.2206 15.8106 11.4001 15.3027 11.8199 15.0996C12.9878 14.5376 13.9764 13.6642 14.6805 12.5707C15.4016 11.4501 15.7842 10.1501 15.7842 8.80952C15.7842 4.96369 12.6561 1.83556 8.81022 1.83556C4.96439 1.83556 1.83626 4.96369 1.83626 8.80952C1.83626 10.1501 2.21881 11.4501 2.93652 12.5741C3.6373 13.6676 4.62923 14.541 5.7972 15.103C6.21699 15.3061 6.39642 15.8106 6.19329 16.2337C5.99017 16.6535 5.48574 16.833 5.06256 16.6298C3.61022 15.9324 2.38131 14.8491 1.51126 13.4882C0.617512 12.0934 0.143554 10.4751 0.143554 8.80952C0.143554 6.49389 1.04408 4.31707 2.68262 2.68192C4.31777 1.04337 6.4946 0.142853 8.81022 0.142854C11.1258 0.142854 13.3027 1.04337 14.9378 2.68192C16.5764 4.32046 17.4769 6.4939 17.4769 8.80952C17.4769 10.4751 17.0029 12.0934 16.1058 13.4882C15.2324 14.8457 14.0034 15.9324 12.5545 16.6298C12.1313 16.8296 11.6269 16.6535 11.4238 16.2304Z" fill="#2BC155" />
                                                <path d="M12.1045 9.2598C12.2704 9.42569 12.3516 9.64235 12.3516 9.85902C12.3516 10.0757 12.2704 10.2924 12.1045 10.4582L9.97506 12.5877C9.66361 12.8991 9.25059 13.0684 8.81387 13.0684C8.37715 13.0684 7.96074 12.8957 7.65267 12.5877L5.52324 10.4582C5.19147 10.1265 5.19147 9.59157 5.52324 9.2598C5.85501 8.92803 6.38991 8.92803 6.72168 9.2598L7.9709 10.509L7.9709 5.69834C7.9709 5.23116 8.35007 4.85199 8.81725 4.85199C9.28444 4.85199 9.66361 5.23116 9.66361 5.69834L9.66361 10.5124L10.9128 9.26319C11.2378 8.93142 11.7727 8.93142 12.1045 9.2598Z" fill="#2BC155" />
                                            </g>
                                            <defs>
                                                <clipPath id="clip0">
                                                    <rect width="17.3333" height="17.3333" fill="white" transform="matrix(-9.93477e-08 1 1 9.93477e-08 0.143555 0.142853)" />
                                                </clipPath>
                                            </defs>
                                        </svg>
                                    </span>&nbsp;&nbsp;{element.amount}
                                </div>;
                                break;
                        }

                        let current_balance = "-";
                        switch (element.currency_type) {
                            case "USD":
                                current_balance = element.current_balance + " " + element.currency_type;
                                break;

                            default:
                                current_balance = element.current_balance + " " + element.currency_type;
                                break;
                        }

                        let CustomerName = element.user_first_name + " " + element.user_last_name;
                        let CustomerEmail = element.user_email_id;
                        let AccountNo = element.user_acc_account_number;
                        if (SettingDataTemp.setting_value === "No") {
                            CustomerName = "*****";
                            CustomerEmail = "*****";
                            AccountNo = "*****";
                        }

                        let temp = {
                            TransactionsID: "#" + element.balance_sheet_id,
                            CustomerName: CustomerName,
                            CustomerEmail: CustomerEmail,
                            AccountNo: AccountNo,
                            Date: moment(element.created_datetime).format('MM / DD / YYYY hh:mm:ss'),
                            TransactionDetails: element.transaction_type,
                            DebitCredit: element.type,
                            Amount: amount,
                            Balance: current_balance,
                            created_datetime: element.created_datetime,
                            AmountText: element.amount,
                        }
                        TempArr.push(temp);
                    });
                    setResultData(TempArr);
                    setFilterData(TempArr);
                    setCustomerData(CustomerDataTempArr);

                    setTimeout(() => {
                        setLoader(false);
                    }, 500);
                } else {
                    setLoader(false);
                }
            })
            .catch(function (error) {
                setLoader(false);
            });
    }

    const handleLoadSetting = () => {
        var jsonpar = JSON.stringify({
            'setting_id': 1
        });
        const headers = {
            'Content-Type': 'application/json',
            'manageraccesstoken': process.env.REACT_APP_MANAGER_ACCESS_TOKEN,
            'managerauthtoken': 'Bearer ' + window.sessionStorage.getItem("manager_jwt"),
        }
        axios.post(process.env.REACT_APP_API_URL + 'getSettgingInformation', jsonpar, {
            headers: headers
        })
            .then(function (res) {
                res = res.data;
                if (res.code === "200") {
                    setSettingData(res.data);
                    handleLoad(res.data);

                    setTimeout(() => {
                        setLoader(false);
                    }, 500);
                } else {
                    handleLoad();
                    setLoader(false);
                }
            })
            .catch(function (error) {
                setLoader(false);
            });
    }


    useEffect(() => {
        setLoader(true);
        handleLoadSetting();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    return (
        <div>
            <div className="card">
                <div className="card-header page-title-space mobile-card-header">
                    <h2 className="text-black font-w600 mb-0">{t('ACCOUNT_TRANSACTIONS.ACCOUNT_TRANSACTIONS')}</h2>
                    <div className="mobileFullWidth">
                        <Dropdown className="custom-dropdown mb-0" >
                            <Dropdown.Toggle className="btn btn-primary btn-rounded i-false" variant='' >
                                <i className="las la-download scale5 mr-3"></i> Download Report
                                <i className="fa fa-caret-down text-white ml-3" aria-hidden="true"></i>
                            </Dropdown.Toggle>
                            <Dropdown.Menu className="dropdown-menu-right right-cstm">
                                <Dropdown.Item className="" onClick={(e) => handleChangeCSVNdPDF("CSV")}>CSV</Dropdown.Item>
                                <Dropdown.Item className="" onClick={(e) => handleChangeCSVNdPDF("PDF")}>PDF</Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                    </div>
                </div>
            </div>
            {loader === true ?
                <div>
                    <Loader open_close={loader} />
                </div>
                :
                <div className="card">
                    <div className="card-body bdr-btm-card">
                        <div className="row">
                            <div className="col-md-3">
                                <div className="form-group position-relative">
                                    <label className="mb-2 ">
                                        <span>{t('ACCOUNT_TRANSACTIONS.FROM_DATE')}</span>
                                    </label>
                                    <div className="col-xs-12">
                                        <input type="date" onChange={(e) => handleChangeFromDate(e.target.value)} value={FromDate} className="form-control" />
                                    </div>
                                </div>
                            </div>

                            <div className="col-md-3">
                                <div className="form-group position-relative">
                                    <label className="mb-2 ">
                                        <span>{t('ACCOUNT_TRANSACTIONS.TO_DATE')}</span>
                                    </label>
                                    <div className="col-xs-12">
                                        <input type="date" onChange={(e) => handleChangeToDate(e.target.value)} value={ToDate} className="form-control" />
                                    </div>
                                </div>
                            </div>
                            {SettingData.setting_value === "Yes" ?
                                <div className="col-md-6">
                                    <label className="mb-2 ">
                                        <span>{t('ACCOUNT_TRANSACTIONS.CUSTOMER')}</span>
                                    </label>
                                    <div className="form-group position-relative">
                                        <select className="form-control webkit-none" onChange={(e) => handleChangeCustmer(e.target.value)} value={Customer}>
                                            <option value="">{t('ACCOUNT_TRANSACTIONS.SELECT_ALL')}</option>
                                            {CustomerData && CustomerData.map((row) => (
                                                <option value={row.CustomerEmail} key={row.CustomerEmail}>{row.CustomerNameNEmail}</option>
                                            ))}
                                        </select>
                                        <i className="fa fa-caret-down down-arrow select-arrow1" aria-hidden="true"></i>
                                    </div>
                                </div>
                                : ""}
                        </div>
                    </div>
                    <div className="card-body">
                        <div className="position-relative">
                            <div className="table-responsive">
                                {FilterData.length > 0 ?
                                    <MaterialTable
                                        title={t('ACCOUNT_TRANSACTIONS.TRANSACTIONS')}
                                        options={{ paging: true, pageSize: 5, pageSizeOptions: [5, 10, 15], emptyRowsWhenPaging: false, size: 'medium' }}
                                        columns={[
                                            // { title: t('ACCOUNT_TRANSACTIONS.TRANSACTIONS_ID'), field: 'TransactionsID', width: 70 },
                                            { title: t('ACCOUNT_TRANSACTIONS.CUSTOMER_NAME'), field: 'CustomerName' },
                                            { title: t('ACCOUNT_TRANSACTIONS.CUSTOMER_EMAIL'), field: 'CustomerEmail' },
                                            { title: t('ACCOUNT_TRANSACTIONS.ACCOUNT_NO'), field: 'AccountNo' },
                                            { title: t('ACCOUNT_TRANSACTIONS.DATE'), field: 'Date' },
                                            { title: t('ACCOUNT_TRANSACTIONS.TRANSACTION_DETAILS'), field: 'TransactionDetails' },
                                            { title: t('ACCOUNT_TRANSACTIONS.DEBIT_CREDIT'), field: 'DebitCredit' },
                                            { title: t('ACCOUNT_TRANSACTIONS.AMOUNT'), field: 'Amount' },
                                            { title: t('ACCOUNT_TRANSACTIONS.BALANCE'), field: 'Balance' },
                                        ]}
                                        data={FilterData}
                                    />
                                    : <div className="d-sm-flex d-block border-0 pb-0">
                                        <div className="pr-3 mr-auto mb-sm-0 mb-3 nofoundFullWidth">
                                            <h4 className="fs-20 text-black mb-1 nofoundTitle">{t('ACCOUNT_TRANSACTIONS.TRANSACTIONS')} Overview</h4>
                                            <span className="fs-12 nofoundText">No Data Found</span>
                                        </div>
                                    </div>}
                            </div>
                        </div>
                    </div>
                </div>
            }
        </div>
    )

}
export default Account_Transactions;