import React, { useState, useEffect } from "react";
import { Button, Modal } from 'react-bootstrap';
import MaterialTable from "@material-table/core";
import { ExportCsv, ExportPdf } from "@material-table/exporters";
import { Search, ViewColumn, SaveAlt, Remove, LastPage, FirstPage, FilterList, Edit, DeleteOutline, Clear, ChevronRight, ChevronLeft, Check, ArrowDownward, AddBox } from '@material-ui/icons';
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import axios from 'axios';
import moment from 'moment';
import Loader from '../../pages/Loader';


export const Cards_Contact_Details = (props) => {
    const { t } = useTranslation();
    const history = useHistory();
    const [requestarray, setrequestarray] = useState([]);
    const [loader, setLoader] = useState(false);
    const [ViewDetails, setViewDetails] = useState(false);

    const [contact_id_fk, setcontact_id_fk] = useState("");
    const [memberId, setmemberId] = useState("");
    const [token, settoken] = useState("");
    const [invoiceNo, setinvoiceNo] = useState("");
    const [customerName, setcustomerName] = useState("");
    const [customerEmail, setcustomerEmail] = useState("");
    const [shippingRequired, setshippingRequired] = useState("");
    const [weight, setweight] = useState("");
    const [weightUnit, setweightUnit] = useState("");
    const [subTotal, setsubTotal] = useState("");
    const [total, settotal] = useState("");
    const [discountCode, setdiscountCode] = useState("");
    const [discountType, setdiscountType] = useState("");
    const [transactionId, settransactionId] = useState("");
    const [shippingAmount, setshippingAmount] = useState("");
    const [shippingName, setshippingName] = useState("");
    const [paid, setpaid] = useState("");
    const [paymentMethod, setpaymentMethod] = useState("");
    const [status, setstatus] = useState("");
    const [discountAmount, setdiscountAmount] = useState("");
    const [taxes, settaxes] = useState("");
    const [currency, setcurrency] = useState("");

    const [orderid, setorderid] = useState("");
    const [created, setcreated] = useState("");


    useEffect(() => {
        handleLoad();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleLoad = () => {
        var jsonpar = {
            contact_id: props.location.state.contact_id,
        }
        const headers = {
            'Content-Type': 'application/json',
            'manageraccesstoken': process.env.REACT_APP_MANAGER_ACCESS_TOKEN,
            'managerauthtoken': 'Bearer ' + window.sessionStorage.getItem("manager_jwt"),
        }
        setLoader(true);
        axios.post(process.env.REACT_APP_API_URL + 'getbrokerorderbycontact', jsonpar, { headers: headers })
            .then(function (res) {
                res = res.data;
                if (res.code === "200") {
                    var TempArr = [];
                    res.data.forEach(element => {
                        var temp = {
                            orderid: "#" + element.contact_order_id,
                            name: element.customerName,
                            email: element.customerEmail,
                            Action: <div><button onClick={(e) => viewdata(element.contact_order_id)} className="btn btn-primary btn-xs btn-rounded" title="View Contact Details"><i className="fa fa-eye"></i></button> </div>
                        };
                        TempArr.push(temp);
                    });
                    setrequestarray(TempArr);
                    setTimeout(() => {
                        setLoader(false);
                    }, 1000);
                } else {
                    setLoader(false);
                }
            })
            .catch(function (error) {
                setLoader(false);
            });
    }

    const viewdata = (val) => {
        var jsonpar = {
            order_id: val,
        }
        const headers = {
            'Content-Type': 'application/json',
            'manageraccesstoken': process.env.REACT_APP_MANAGER_ACCESS_TOKEN,
            'managerauthtoken': 'Bearer ' + window.sessionStorage.getItem("manager_jwt"),
        }
        setLoader(true);
        axios.post(process.env.REACT_APP_API_URL + 'viewcontactorderdetails', jsonpar, { headers: headers })
            .then(function (res) {
                res = res.data;
                if (res.code === "200") {
                    setcontact_id_fk(res.data[0].contact_id_fk);
                    setmemberId(res.data[0].memberId);
                    settoken(res.data[0].token);
                    setinvoiceNo(res.data[0].invoiceNo);
                    setcustomerName(res.data[0].customerName);
                    setcustomerEmail(res.data[0].customerEmail);
                    setshippingRequired(res.data[0].shippingRequired);
                    setweight(res.data[0].weight);
                    setweightUnit(res.data[0].weightUnit);
                    setsubTotal(res.data[0].subTotal);
                    settotal(res.data[0].total);
                    setdiscountCode(res.data[0].discountCode);
                    setdiscountType(res.data[0].discountType);
                    settransactionId(res.data[0].transactionId);
                    setshippingAmount(res.data[0].shippingAmount);
                    setshippingName(res.data[0].shippingName);
                    setpaid(res.data[0].paid);
                    setpaymentMethod(res.data[0].paymentMethod);
                    setstatus(res.data[0].status);
                    setdiscountAmount(res.data[0].discountAmount);
                    settaxes(res.data[0].taxes);
                    setcurrency(res.data[0].currency);

                    setorderid("#" + res.data[0].item_id);
                    var fulldata = JSON.parse(res.data[0].fulljson);
                    setcreated(moment.unix(fulldata.created).format("MM/DD/YYYY"));
                    var newstatus = "Not Paid";
                    if (res.data[0].paid === "true" || res.data[0].paid === true) {
                        newstatus = "Paid";
                    }

                    var newpayment = "Credit Card";
                    if (res.data[0].paymentMethod === "cod") {
                        newpayment = "On Delivery";
                    }
                    history.push({
                        pathname: '/order-details',
                        state: { broker_id: props.location.state.broker_id, contact_id: props.location.state.contact_id, customerName: res.data[0].customerName, total: res.data[0].total + " " + res.data[0].currency, paid: newstatus, status: res.data[0].status, paymentMethod: newpayment, orderid: res.data[0].item_id, qty: fulldata.items[0].quantity, created: moment.unix(fulldata.created).format("MM/DD/YYYY") }
                    });
                    setTimeout(() => {
                        setLoader(false);
                    }, 1000);
                } else {
                    setLoader(false);
                }
            })
            .catch(function (error) {
                setLoader(false);
            });

    }

    const viewcontact = () => {
        history.push({
            pathname: '/cards-contact',
            state: { broker_id: props.location.state.broker_id }
        });
    }

    return (
        <div>
            <div className="card">
                <div className="card-header page-title-space mobile-card-header">
                    <h2 className="text-black font-w600 mb-0">Contact Orders</h2>
                    <Button onClick={() => viewcontact()}><i class="fa fa-arrow-left"></i> Back</Button>
                </div>
            </div>
            {loader === true ?
                <div>
                    <Loader open_close={loader} />
                </div>
                :
                <div className="card">
                    <div className="card-body">
                        <div className="position-relative">
                            <div className="table-responsive">
                                {requestarray.length > 0 ?
                                    <MaterialTable
                                        title="Contact Orders"
                                        columns={[
                                            { title: '#', field: 'orderid' },
                                            { title: 'Customer Name', field: 'name' },
                                            { title: 'Customer Email', field: 'email' },
                                            { title: 'Action', field: 'Action', align: 'center', },
                                        ]}
                                        data={requestarray}
                                        options={{
                                            exportMenu: [
                                                { label: "Export PDF", exportFunc: (cols, datas) => ExportPdf(cols, datas, "Contact_Orders") },
                                                { label: "Export CSV", exportFunc: (cols, datas) => ExportCsv(cols, datas, "Contact_Orders") },
                                            ], paging: true, pageSize: 5, pageSizeOptions: [5, 10, 15], emptyRowsWhenPaging: false,
                                        }}
                                    />
                                    : <div className="d-sm-flex d-block border-0 pb-0">
                                        <div className="pr-3 mr-auto mb-sm-0 mb-3 nofoundFullWidth">
                                            <h4 className="fs-20 text-black mb-1 nofoundTitle">Contact Orders Overview</h4>
                                            <span className="fs-12 nofoundText">No Data Found</span>
                                        </div>
                                    </div>}
                            </div>
                        </div>
                    </div>
                </div>
            }
            <Modal className="modal fade" show={ViewDetails} size={"modal-sm"}>
                <div className="modal-dialog-centered modal-fullscreen">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h3 className="modal-title">Order Details</h3>
                            <button type="button" className="close" onClick={() => setViewDetails(false)}><span>&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <div className="row">
                                <div className="col-lg-12 col-md-6 mb-2">
                                    <p className="fs-14 mb-1">Customer Name : <span className="text-black">{customerName}</span></p>
                                </div>

                                <div className="col-lg-6 col-md-6 mb-2">
                                    <p className="fs-14 mb-1">Order Number : <span className="text-black">{orderid}</span></p>
                                </div>

                                <div className="col-lg-6 col-md-6 mb-2">
                                    <p className="fs-14 mb-1">Customer Name : <span className="text-black">{customerName}</span></p>
                                </div>

                                <div className="col-lg-6 col-md-6 mb-2">
                                    <p className="fs-14 mb-1">Total : <span className="text-black">{total}</span></p>
                                </div>

                                <div className="col-lg-6 col-md-6 mb-2">
                                    <p className="fs-14 mb-1">Paid Status : <span className="text-black">{paid}</span></p>
                                </div>

                                <div className="col-lg-6 col-md-6 mb-2">
                                    <p className="fs-14 mb-1">Status : <span className="text-black">{status}</span></p>
                                </div>

                                <div className="col-lg-6 col-md-6 mb-2">
                                    <p className="fs-14 mb-1">Create : <span className="text-black">{created}</span></p>
                                </div>

                                <div className="col-lg-6 col-md-6 mb-2">
                                    <p className="fs-14 mb-1">Payment Type : <span className="text-black">{paymentMethod}</span></p>
                                </div>
                                {/* <div className="col-lg-3 col-md-6 mb-2">
                                    <p className="fs-14 mb-1">Currency : <span className="text-black">{currency}</span></p>
                                </div>
                                <div className="col-lg-3 col-md-6 mb-2">
                                    <p className="fs-14 mb-1">Contact Id : <span className="text-black">{contact_id_fk}</span></p>
                                </div>
                                <div className="col-lg-3 col-md-6 mb-2">
                                    <p className="fs-14 mb-1">Member Id : <span className="text-black">{memberId}</span></p>
                                </div>
                                <div className="col-lg-3 col-md-6 mb-2">
                                    <p className="fs-14 mb-1">Token : <span className="text-black">{token}</span></p>
                                </div>
                                <div className="col-lg-3 col-md-6 mb-2">
                                    <p className="fs-14 mb-1">invoiceNo : <span className="text-black">{invoiceNo}</span></p>
                                </div>

                                <div className="col-lg-3 col-md-6 mb-2">
                                    <p className="fs-14 mb-1">Customer Name : <span className="text-black">{customerName}</span></p>
                                </div>

                                <div className="col-lg-3 col-md-6 mb-2">
                                    <p className="fs-14 mb-1">Customer Email : <span className="text-black">{customerEmail}</span></p>
                                </div>

                                <div className="col-lg-12 col-md-12 mb-2">
                                    <h3 class="modal-title-cstm">Billing Address</h3>
                                </div>

                                <div className="col-lg-3 col-md-6 mb-2">
                                    <p className="fs-14 mb-1">Name : <span className="text-black">{billing_name}</span></p>
                                </div>

                                <div className="col-lg-3 col-md-6 mb-2">
                                    <p className="fs-14 mb-1">Phone : <span className="text-black">{billing_phone}</span></p>
                                </div>

                                <div className="col-lg-3 col-md-6 mb-2">
                                    <p className="fs-14 mb-1">Company Name : <span className="text-black">{billing_companyName}</span></p>
                                </div>

                                <div className="col-lg-3 col-md-6 mb-2">
                                    <p className="fs-14 mb-1">Company Id : <span className="text-black">{billing_companyId}</span></p>
                                </div>

                                <div className="col-lg-3 col-md-6 mb-2">
                                    <p className="fs-14 mb-1">Vat Id : <span className="text-black">{billing_vatId}</span></p>
                                </div>

                                <div className="col-lg-3 col-md-6 mb-2">
                                    <p className="fs-14 mb-1">Country : <span className="text-black">{billing_country}</span></p>
                                </div>

                                <div className="col-lg-3 col-md-6 mb-2">
                                    <p className="fs-14 mb-1">State : <span className="text-black">{billing_state}</span></p>
                                </div>

                                <div className="col-lg-3 col-md-6 mb-2">
                                    <p className="fs-14 mb-1">City : <span className="text-black">{billing_city}</span></p>
                                </div>

                                <div className="col-lg-3 col-md-6 mb-2">
                                    <p className="fs-14 mb-1">Zip Code : <span className="text-black">{billing_zipCode}</span></p>
                                </div>

                                <div className="col-lg-3 col-md-6 mb-2">
                                    <p className="fs-14 mb-1">Address : <span className="text-black">{billing_address}</span></p>
                                </div>

                                <div className="col-lg-3 col-md-6 mb-2">
                                    <p className="fs-14 mb-1">Address 2 : <span className="text-black">{billing_address2}</span></p>
                                </div>

                                <div className="col-lg-12 col-md-12 mb-2">
                                    <h3 class="modal-title-cstm">Shipping Address</h3>
                                </div>

                                <div className="col-lg-3 col-md-6 mb-2">
                                    <p className="fs-14 mb-1">Name : <span className="text-black">{shipping_name}</span></p>
                                </div>

                                <div className="col-lg-3 col-md-6 mb-2">
                                    <p className="fs-14 mb-1">Phone : <span className="text-black">{shipping_phone}</span></p>
                                </div>

                                <div className="col-lg-3 col-md-6 mb-2">
                                    <p className="fs-14 mb-1">Company Name : <span className="text-black">{shipping_companyName}</span></p>
                                </div>

                                <div className="col-lg-3 col-md-6 mb-2">
                                    <p className="fs-14 mb-1">Company Id : <span className="text-black">{shipping_companyId}</span></p>
                                </div>

                                <div className="col-lg-3 col-md-6 mb-2">
                                    <p className="fs-14 mb-1">Vat Id : <span className="text-black">{shipping_vatId}</span></p>
                                </div>

                                <div className="col-lg-3 col-md-6 mb-2">
                                    <p className="fs-14 mb-1">Country : <span className="text-black">{shipping_country}</span></p>
                                </div>

                                <div className="col-lg-3 col-md-6 mb-2">
                                    <p className="fs-14 mb-1">State : <span className="text-black">{shipping_state}</span></p>
                                </div>

                                <div className="col-lg-3 col-md-6 mb-2">
                                    <p className="fs-14 mb-1">City : <span className="text-black">{shipping_city}</span></p>
                                </div>

                                <div className="col-lg-3 col-md-6 mb-2">
                                    <p className="fs-14 mb-1">Zip Code : <span className="text-black">{shipping_zipCode}</span></p>
                                </div>

                                <div className="col-lg-3 col-md-6 mb-2">
                                    <p className="fs-14 mb-1">Address : <span className="text-black">{shipping_address}</span></p>
                                </div>

                                <div className="col-lg-3 col-md-6 mb-2">
                                    <p className="fs-14 mb-1">Address 2 : <span className="text-black">{shipping_address2}</span></p>
                                </div>

                                <div className="col-lg-12 col-md-12 mb-2">
                                    <h3 class="modal-title-cstm">Other Details</h3>
                                </div>

                                <div className="col-lg-3 col-md-6 mb-2">
                                    <p className="fs-14 mb-1">Shipping Required : <span className="text-black">{shippingRequired}</span></p>
                                </div>

                                <div className="col-lg-3 col-md-6 mb-2">
                                    <p className="fs-14 mb-1">Weight : <span className="text-black">{weight}</span></p>
                                </div>

                                <div className="col-lg-3 col-md-6 mb-2">
                                    <p className="fs-14 mb-1">Weight Unit : <span className="text-black">{weightUnit}</span></p>
                                </div>

                                <div className="col-lg-3 col-md-6 mb-2">
                                    <p className="fs-14 mb-1">Sub Total : <span className="text-black">{subTotal}</span></p>
                                </div>

                                <div className="col-lg-3 col-md-6 mb-2">
                                    <p className="fs-14 mb-1">Total : <span className="text-black">{total}</span></p>
                                </div>

                                <div className="col-lg-3 col-md-6 mb-2">
                                    <p className="fs-14 mb-1">Discount Code : <span className="text-black">{discountCode}</span></p>
                                </div>

                                <div className="col-lg-3 col-md-6 mb-2">
                                    <p className="fs-14 mb-1">Discount Type : <span className="text-black">{discountType}</span></p>
                                </div>

                                <div className="col-lg-3 col-md-6 mb-2">
                                    <p className="fs-14 mb-1">Transaction Id : <span className="text-black">{transactionId}</span></p>
                                </div>

                                <div className="col-lg-3 col-md-6 mb-2">
                                    <p className="fs-14 mb-1">Shipping Amount : <span className="text-black">{shippingAmount}</span></p>
                                </div>

                                <div className="col-lg-3 col-md-6 mb-2">
                                    <p className="fs-14 mb-1">Shipping Name : <span className="text-black">{shippingName}</span></p>
                                </div>

                                <div className="col-lg-3 col-md-6 mb-2">
                                    <p className="fs-14 mb-1">Paid : <span className="text-black">{paid}</span></p>
                                </div>

                                <div className="col-lg-3 col-md-6 mb-2">
                                    <p className="fs-14 mb-1">Payment Method : <span className="text-black">{paymentMethod}</span></p>
                                </div>

                                <div className="col-lg-3 col-md-6 mb-2">
                                    <p className="fs-14 mb-1">Status : <span className="text-black">{status}</span></p>
                                </div>

                                <div className="col-lg-3 col-md-6 mb-2">
                                    <p className="fs-14 mb-1">Discount Amount : <span className="text-black">{discountAmount}</span></p>
                                </div>

                                <div className="col-lg-3 col-md-6 mb-2">
                                    <p className="fs-14 mb-1">Taxes : <span className="text-black">{taxes}</span></p>
                                </div> */}

                            </div>
                        </div>
                    </div>
                </div>
            </Modal>
        </div>
    )

}
export default Cards_Contact_Details;