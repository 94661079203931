import React, { Fragment, useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Form from 'react-bootstrap/Form';
import profile from "../../../images/profile/profile.png";
import { useTranslation } from "react-i18next";
import Snackbars from '../../pages/Snackbars';
import axios from 'axios';
import Loader from '../../pages/Loader';
import validator from 'validator';

const Profile = () => {
	const { t } = useTranslation();
	const [activeToggle, setActiveToggle] = useState("posts");
	const [first_name, setFirst_name] = useState("");
	const [last_name, setLastName] = useState("");
	const [email, setEmail] = useState("");
	const [phonecode, setPhonecode] = useState("");
	const [phonenumber, setPhoneNumber] = useState("");
	const [timezone, setTimezone] = useState("Not Set Yet");
	const [address, setAddress] = useState("Not Set Yet");
	const [error, setError] = useState(false);
	const [error_msg, setErrorMsg] = useState("");
	const [success, setSuccess] = useState(false);
	const [success_msg, setSuccessMsg] = useState("");
	const [LoginChecked, setLoginchecked] = useState(false);
	const [LoginType, setLoginType] = useState("");
	const [TransactionChecked, setTransactionChecked] = useState(false);
	const [TransactionType, setTransactionType] = useState("");
	const [loader, setLoader] = useState(false);
	const [getphoto, setPhoto] = useState("");

	const [old_password, setold_password] = useState("");
	const [new_password, setnew_password] = useState("");
	const [confirm_password, setconfirm_password] = useState("");

	const [old_password_error, setold_password_error] = useState("");
	const [new_password_error, setnew_password_error] = useState("");
	const [confirm_password_error, setconfirm_password_error] = useState("");

	useEffect(() => {
		handleLoad();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);




	const handleLoad = () => {
		const headers = {
			'Content-Type': 'application/json',
			'manageraccesstoken': process.env.REACT_APP_MANAGER_ACCESS_TOKEN,
			'managerauthtoken': 'Bearer ' + window.sessionStorage.getItem("manager_jwt"),
		}
		setLoader(true);
		axios.get(process.env.REACT_APP_API_URL + 'getManagerProfileDetails/' + window.sessionStorage.getItem("manager_id"), { headers: headers })
			.then(function (res) {
				res = res.data;
				if (res.code === "200") {

					setFirst_name(res.data.first_name);
					setLastName(res.data.last_name);
					setEmail(res.data.email_id);
					setPhonecode(res.data.phonecode);
					setPhoneNumber(res.data.phone_number);
					setAddress(res.data.address);
					setTimezone(res.data.timezone);
					if (res.data.notification[1].notification_status === "Enable") {
						setLoginchecked(true);
					} else {
						setLoginchecked(false);
					}
					setLoginType(res.data.notification[1].notification_type);
					setTransactionType(res.data.notification[0].notification_type);

					if (res.data.notification[0].notification_status === "Enable") {
						setTransactionChecked(true);
					} else {
						setTransactionChecked(false);
					}

					if (res.data.profile_photo === null || res.data.profile_photo === "") {
						setPhoto(profile);
					} else {
						setPhoto(process.env.REACT_APP_FILE_PATH + "" + res.data.profile_photo);
					}
					setLoader(false);
				} else {
					setLoader(false);
					setError(true);
					setErrorMsg(res.message);
					setTimeout(() => {
						setError(false);
					}, 3000);
				}
			})
			.catch(function (error) {
				setLoader(false);
				setError(true);
				setErrorMsg(t('ERROR_MSG.SOMETHING'));
				setTimeout(() => {
					setError(false);
				}, 3000);
			});
	}

	const handleChangeLogin = () => {
		var changel = "Enable";
		if (LoginChecked === true) {
			changel = "Disable";
		}

		const headers = {
			'Content-Type': 'application/json',
			'manageraccesstoken': process.env.REACT_APP_MANAGER_ACCESS_TOKEN,
			'managerauthtoken': 'Bearer ' + window.sessionStorage.getItem("manager_jwt"),
		}

		var jsonpar = {
			"manager_id": window.sessionStorage.getItem("manager_id"),
			"notification_type": LoginType,
			"status": changel,
		}

		axios.post(process.env.REACT_APP_API_URL + 'updateManagerNotification', jsonpar, { headers: headers })
			.then(function (res) {
				res = res.data;
				if (res.code === "200") {
					setSuccess(true);
					setSuccessMsg(res.message);
					setTimeout(() => {
						setSuccess(false);
						setLoader(false);
						handleLoad();
					}, 3000);
				} else {
					setError(true);
					setLoader(false);
					setErrorMsg(res.message);
					setTimeout(() => {
						setError(false);
					}, 3000);
				}
			})
			.catch(function (error) {
				setLoader(false);
				setError(true);
				setErrorMsg(t('ERROR_MSG.SOMETHING'));
				setTimeout(() => {
					setError(false);
				}, 3000);
			});
	}

	const handleChangeTransaction = () => {
		var changeT = "Enable";
		if (TransactionChecked === true) {
			changeT = "Disable";
		}

		var jsonpar = {
			"manager_id": window.sessionStorage.getItem("manager_id"),
			"notification_type": TransactionType,
			"status": changeT,
		}
		const headers = {
			'Content-Type': 'application/json',
			'manageraccesstoken': process.env.REACT_APP_MANAGER_ACCESS_TOKEN,
			'managerauthtoken': 'Bearer ' + window.sessionStorage.getItem("manager_jwt"),
		}

		setLoader(true);
		axios.post(process.env.REACT_APP_API_URL + 'updateManagerNotification', jsonpar, { headers: headers })
			.then(function (res) {
				res = res.data;
				if (res.code === "200") {
					setSuccess(true);
					setSuccessMsg(res.message);
					setTimeout(() => {
						setSuccess(false);
						setLoader(false);
						handleLoad();
					}, 3000);
				} else {
					setError(true);
					setLoader(false);
					setErrorMsg(res.message);
					setTimeout(() => {
						setError(false);
					}, 3000);
				}
			})
			.catch(function (error) {
				setError(true);
				setLoader(false);
				setErrorMsg(t('ERROR_MSG.SOMETHING'));
				setTimeout(() => {
					setError(false);
				}, 3000);
			});

	}

	const validateold_password = (value) => {
		setold_password(value);
		if (!validator.isEmpty(value)) {
			setold_password_error("");
			return true;
		} else {
			setold_password_error("Enter current password.");
			return false;
		}
	}

	const validatenew_password = (value) => {
		setnew_password(value);
		if (!validator.isEmpty(value)) {
			setnew_password_error("");
			return true;
		} else {
			setnew_password_error("Enter new password.");
			return false;
		}
	}

	const validateconfirm_password = (value) => {
		setconfirm_password(value);
		if (!validator.isEmpty(value)) {
			setconfirm_password_error("");
			return true;
		} else if (new_password === value) {
			setconfirm_password_error("Do not match password.");
			return false;
		} else {
			setconfirm_password_error("Enter confirm password.");
			return false;
		}
	}


	const submitpassword = () => {
		if (validateold_password(old_password) === false) {
			setold_password_error("Enter current password.");
			return false;
		} else if (validatenew_password(new_password) === false) {
			setold_password_error("");
			setnew_password_error("Enter new password.");
			return false;
		}
		else if (validateconfirm_password(confirm_password) === false) {
			setnew_password_error("");
			setconfirm_password_error("Enter confirm password.");
			return false;
		} else {
			setconfirm_password_error("");

			if (new_password === confirm_password) {
				var jsonpar = {
					manager_id: window.sessionStorage.getItem("manager_id"),
					old_password: old_password,
					new_password: new_password,
					confirm_password: confirm_password,
				}
				const headers = {
					'Content-Type': 'application/json',
					'manageraccesstoken': process.env.REACT_APP_MANAGER_ACCESS_TOKEN,
					'managerauthtoken': 'Bearer ' + window.sessionStorage.getItem("manager_jwt"),
				}
				setLoader(true);

				axios.post(process.env.REACT_APP_API_URL + 'updatepassword', jsonpar, { headers: headers })
					.then(function (res) {
						res = res.data;
						if (res.code === "200") {
							setLoader(false);
							setSuccess(true);
							setold_password("");
							setnew_password("");
							setconfirm_password("");
							setSuccessMsg(res.message);
							setTimeout(() => {
								handleLoad();
								setSuccess(false);
							}, 1000);
						} else {
							setLoader(false);
							setError(true);
							setErrorMsg(res.message);
							setTimeout(() => {
								setError(false);
							}, 3000);
						}

					})
					.catch(function (error) {
						setLoader(false);
						setError(true);
						setErrorMsg(t('ERROR_MSG.SOMETHING'));
						setTimeout(() => {
							setError(false);
						}, 3000);
					});
			} else {
				setconfirm_password_error("Do not match password.");
				return false;
			}
		}
	}




	return (
		<Fragment>
			<div>
				<div className="row">
					{error ? <Snackbars open_close={error} type="error" message={error_msg} /> : ""}
					{success ? <Snackbars open_close={success} type="success" message={success_msg} /> : ""}
					<div className="col-lg-12">
						<div className="page-titles mb-0	">
							<h2 className="text-black font-w600 mb-0">{t('PROFILE.MAIN_TITLE')}</h2>
						</div>
					</div>

					<div className="col-lg-12">
						<div className="profile card card-body px-3 pt-3 pb-0">
							<div className="profile-head">
								<div className="profile-info">
									<div className="profile-photo">
										<img src={getphoto} className="img-fluid rounded-circle" alt="profile" />
									</div>
									<div className="profile-details">
										<div className="profile-name px-5 pt-2">
											<h4 className="text-primary mb-0">{first_name + " " + last_name}</h4>
											<p>{t('PROFILE.FULLNAME')}</p>
										</div>
										<div className="profile-name px-5 pt-2">
											<h4 className="text-primary mb-0">{email}</h4>
											<p>{t('PROFILE.EMAIL')}</p>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				{loader === true ?
					<div>
						<Loader open_close={loader} />
					</div>
					:
					<div className="row">
						<div className="col-xl-12">
							<div className="card">
								<div className="card-body">
									<div className="profile-tab">
										<div className="custom-tab-1">
											<ul className="nav nav-tabs">
												<li className="nav-item" onClick={() => setActiveToggle("posts")}>
													<Link to="#01" data-toggle="tab" className={`nav-link ${activeToggle === "posts" ? "active show" : ""}`}>{t('PROFILE.PERSONAL_INFO')}</Link>
												</li>
												{/* <li className="nav-item" onClick={() => setActiveToggle("aboutMe")}>
													<Link to="#02" data-toggle="tab" className={`nav-link ${activeToggle === "aboutMe" ? "active show" : ""}`}>{t('PROFILE.SECURITY')}</Link>
												</li> */}
												{/* <li className="nav-item">
													<Link to="#03" data-toggle="tab" onClick={() => setActiveToggle("setting")} className={`nav-link ${activeToggle === "setting" ? "active show" : ""}`}>{t('PROFILE.ALERTS')}</Link>
												</li> */}
												{/* <li className="nav-item">
													<Link to="#04" data-toggle="tab" onClick={() => setActiveToggle("Enable")} className={`nav-link ${activeToggle === "Enable" ? "active show" : ""}`}>{t('PROFILE.ACCESS')}</Link>
												</li> */}
												<li className="nav-item">
													<Link to="#05" data-toggle="tab" onClick={() => setActiveToggle("Notification")} className={`nav-link ${activeToggle === "Notification" ? "active show" : ""}`}>{t('PROFILE.NOTIFICATION')}</Link>
												</li>
												<li className="nav-item">
													<Link to="#06" data-toggle="tab" onClick={() => setActiveToggle("Password")} className={`nav-link ${activeToggle === "Password" ? "active show" : ""}`}>Change Password</Link>
												</li>
											</ul>
											<div className="tab-content">
												<div id="01" className={`tab-pane fade ${activeToggle === "posts" ? "active show" : ""}`} >
													<div className="profile-personal-info mt-4">
														<div className="row mb-2">
															<div className="col-md-3">
																<h5 className="f-w-500"> {t('PROFILE.FULLNAME')}<span className="pull-right">:</span></h5>
															</div>
															<div className="col-md-9">
																<span>{first_name + " " + last_name}</span>
															</div>
														</div>
														<div className="row mb-2">
															<div className="col-md-3">
																<h5 className="f-w-500">{t('PROFILE.EMAIL')} <span className="pull-right">:</span></h5>
															</div>
															<div className="col-md-9">
																<span>{email}</span>
															</div>
														</div>
														<div className="row mb-2">
															<div className="col-md-3">
																<h5 className="f-w-500"> {t('LABEL.LABEL_PHONE_NUMBER')}<span className="pull-right">:</span></h5>
															</div>
															<div className="col-md-9">
																<span>+{phonecode} {phonenumber}</span>
															</div>
														</div>
														<div className="row mb-2">
															<div className="col-md-3">
																<h5 className="f-w-500">{t('PROFILE.TIMEZONE')}<span className="pull-right">:</span></h5>
															</div>
															<div className="col-md-9">
																<span>{timezone === "" ? "Not Set Yet" : timezone}</span>
															</div>
														</div>

														<div className="row mb-2">
															<div className="col-md-3">
																<h5 className="f-w-500">{t('PROFILE.ADDRESS')}<span className="pull-right">:</span></h5>
															</div>
															<div className="col-md-9">
																<span>{address === "" ? "Not Set Yet" : address}</span>
															</div>
														</div>
														<div className="mb-2 mt-4">
															<Link to="/editprofile" className="btn btn-primary btn-rounded">{t('LINK.EDITPROFILE')}</Link>
														</div>
													</div>
												</div>
												<div id="02" className={`tab-pane fade ${activeToggle === "aboutMe" ? "active show" : ""}`}>
													<div className="profile-personal-info mt-4">
														<div className="row mb-2">
															<div className="col-12">
																<h5 className="f-w-500">{t('ERROR_MSG.NODATA_FOUND')}</h5>
															</div>
														</div>
													</div>
												</div>
												<div id="03" className={`tab-pane fade ${activeToggle === "setting" ? "active show" : ""}`}>
													<div className="profile-personal-info mt-4">
														<div className="row mb-2">
															<div className="col-12">
																<h5 className="f-w-500">{t('ERROR_MSG.NODATA_FOUND')}</h5>
															</div>
														</div>
													</div>
												</div>
												<div id="04" className={`tab-pane fade ${activeToggle === "Enable" ? "active show" : ""}`}>
													<div className="profile-personal-info mt-4">
														<div className="row mb-2">
															<div className="col-12">
																<h5 className="f-w-500">{t('ERROR_MSG.NODATA_FOUND')}</h5>
															</div>
														</div>
													</div>
												</div>
												<div id="05" className={`tab-pane fade ${activeToggle === "Notification" ? "active show" : ""}`}>
													<div className="profile-personal-info mt-4">
														<div className="row mb-2">
															<div className="col-12">
																<div className="Setting-tones">
																	<label className="lable-text">{t('PROFILE.NOTIFICATION_TONES')} ( {t('PROFILE.LOGIN_TEXT')} )</label>
																	<Form.Check type="switch" onChange={handleChangeLogin} id="custom-switch" className="coin-swithc-cstm" checked={LoginChecked} />
																</div>
																<hr />
																<div className="Setting-tones">
																	<label className="lable-text">{t('PROFILE.NOTIFICATION_TONES')} ( {t('PROFILE.TRANSACTION_TEXT')} )</label>
																	<Form.Check type="switch" onChange={handleChangeTransaction} id="custom-switch1" className="coin-swithc-cstm" checked={TransactionChecked} />
																</div>
															</div>
														</div>
													</div>
												</div>

												<div id="06" className={`tab-pane fade ${activeToggle === "Password" ? "active show" : ""}`}>
													<div className="profile-personal-info mt-4">
														<div className="row">
															<div className="col-md-4">
																<div className="form-group position-relative">
																	<label className="mb-2 ">Current Password</label>
																	<input type="password" value={old_password} onChange={(e) => validateold_password(e.target.value)} className="form-control" placeholder="Please Enter Current Password" />
																	{old_password_error !== "" ? <label className='text-red'>{old_password_error}</label> : ""}
																</div>
															</div>
															<div className="col-md-4">
																<div className="form-group position-relative">
																	<label className="mb-2 ">New Password</label>
																	<input type="password" value={new_password} onChange={(e) => validatenew_password(e.target.value)} className="form-control" placeholder="Please Enter New Password" />
																	{new_password_error !== "" ? <label className='text-red'>{new_password_error}</label> : ""}
																</div>
															</div>
															<div className="col-md-4">
																<div className="form-group position-relative">
																	<label className="mb-2 ">Confirm Password</label>
																	<input type="password" value={confirm_password} onChange={(e) => validateconfirm_password(e.target.value)} className="form-control" placeholder="Please Enter Confirm Password" />
																	{confirm_password_error !== "" ? <label className='text-red'>{confirm_password_error}</label> : ""}
																</div>
															</div>
															<div className="col-md-12 mt-2">
																<a href="#" onClick={submitpassword} className="btn btn-primary btn-rounded">Update</a>
																<button type="button" className="btn btn-outline-light ml-3 btn-rounded">Cancel</button>
															</div>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				}
			</div>
		</Fragment>
	);
};

export default Profile;
