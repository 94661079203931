import React, { useState, useEffect } from "react";
import { Link } from 'react-router-dom';
import MaterialTable from "@material-table/core";
import { ExportCsv, ExportPdf } from "@material-table/exporters";
import { Modal } from 'react-bootstrap';
import Snackbars from '../../../jsx/pages/Snackbars';
import { useTranslation } from "react-i18next";
import axios from 'axios';
import moment from 'moment';
import Loader from '../../pages/Loader';
import validator from 'validator';


export const Customer_List = () => {
    const { t } = useTranslation();
    const [loader, setLoader] = useState(false);
    const [error, setError] = useState(false);
    const [success, setSuccess] = useState(false);
    const [error_msg, setErrorMsg] = useState("");
    const [success_msg, setSuccessMsg] = useState("");


    const [CountryData, setCountryData] = useState([]);
    const [Modal1, setModal1] = useState(false);
    const [ResultData, setResultData] = useState([]);
    const [FilterData, setFilterData] = useState([]);
    const [CustomerData, setCustomerData] = useState([]);
    const [Customer, setCustomer] = useState("");
    const [FromDate, setFromDate] = useState("");
    const [ToDate, setToDate] = useState("");
    const [FirstName, setFirstName] = useState("");
    const [FirstNameError, setFirstNameError] = useState("");
    const [LastName, setLastName] = useState("");
    const [LastNameError, setLastNameError] = useState("");
    const [Email, setEmail] = useState("");
    const [EmailError, setEmailError] = useState("");
    const [CountryCode, setCountryCode] = useState("1");
    const [Phone, setPhone] = useState("");
    const [PhoneError, setPhoneError] = useState("");
    const [allusergroup, setallusergroup] = useState([]);
    const [AddGroup, setAddGroup] = useState("");
    const [AddGroupError, setAddGroupError] = useState("");
    const [alluser, setalluser] = useState([]);


    const FieldRequiredError = t('LABEL.FIELD_REQUIERD');
    const validateFirstName = (value) => {
        setFirstName(value);
        if (validator.isEmpty(value)) {
            setFirstNameError(FieldRequiredError);
            return false;
        } else {
            setFirstNameError("");
            return true;
        }
    }

    const validateAddGroup = (value) => {
        setAddGroup(value);
        if (validator.isEmpty(value)) {
            setAddGroupError("Select user group.");
            return false;
        } else {
            setAddGroupError("");
            return true;
        }
    }


    const validateLastName = (value) => {
        setLastName(value);
        if (validator.isEmpty(value)) {
            setLastNameError(FieldRequiredError);
            return false;
        } else {
            setLastNameError("");
            return true;
        }
    }
    const validateEmail = (value) => {
        setEmail(value);
        if (validator.isEmpty(value)) {
            setEmailError(FieldRequiredError);
            return false;
        } else if (!validator.isEmail(value)) {
            setEmailError(t('ERROR_MSG.CORRECT_EMAIL'));
            return false;
        } else {
            setEmailError("");
            return true;
        }
    }
    const validateCountryCode = (value) => {
        setCountryCode(value);
    }
    const validatePhone = (value) => {
        setPhone(value);
        if (validator.isEmpty(value)) {
            setPhoneError(FieldRequiredError);
            return false;
        } if (!validator.isNumeric(value)) {
            setPhoneError(t('PLACEHOLDER.PLACEHOLDER_PHONE_NUMBER'));
            return false;
        } else {
            setPhoneError("");
            return true;
        }
    }
    const handlesubmit = () => {
        if (!validateFirstName(FirstName)) {
            return false;
        } else if (!validateLastName(LastName)) {
            return false;
        } else if (!validateEmail(Email)) {
            return false;
        } else if (!validatePhone(Phone)) {
            return false;
        } else if (!validateAddGroup(AddGroup)) {
            return false;
        } else {
            setModal1(false);
            setLoader(true);

            var jsonpar = JSON.stringify({
                'manager_id': window.sessionStorage.getItem("manager_id"),
                'first_name': FirstName,
                'last_name': LastName,
                'email_id': Email,
                'country_code': CountryCode,
                'phone_number': Phone,
                'usergroup': AddGroup,
            });
            const headers = {
                'Content-Type': 'application/json',
                'manageraccesstoken': process.env.REACT_APP_MANAGER_ACCESS_TOKEN,
                'managerauthtoken': 'Bearer ' + window.sessionStorage.getItem("manager_jwt"),
            }
            axios.post(process.env.REACT_APP_API_URL + 'addManagerCustomer', jsonpar, {
                headers: headers
            })
                .then(function (res) {
                    res = res.data;
                    setLoader(false);

                    if (res.code === "200") {
                        setFirstName("");
                        setLastName("");
                        setEmail("");
                        setCountryCode("1");
                        setPhone("");
                        setAddGroup("");

                        setSuccess(true);
                        setSuccessMsg(res.message);
                        setTimeout(() => {
                            setSuccess(false);
                        }, 3000);
                    } else {
                        setError(true);
                        setErrorMsg(res.message);
                        setTimeout(() => {
                            setError(false);
                        }, 3000);
                    }

                })
                .catch(function (error) {
                    setLoader(false);

                    setError(true);
                    setErrorMsg(t('ERROR_MSG.SOMETHING'));
                    setTimeout(() => {
                        setError(false);
                    }, 3000);
                });
        }
    }

    const handleChangeFromDate = (val) => {
        setFromDate(val);
        filter("1," + val, "", "");
    }
    const handleChangeToDate = (val) => {
        setToDate(val);
        filter("", "1," + val, "");
    }
    const handleChangeCustmer = (val) => {
        setCustomer(val);
        filter("", "", "1," + val);
    }
    const filter = (from_date = "", to_date = "", customer_email = "") => {
        var tempFromDate = FromDate;
        var tempToDate = ToDate;
        var tempCustomerEmail = Customer;

        if (from_date !== "") {
            if (from_date.split(",")[0] === "1" && from_date.split(",")[1] !== "") {
                tempFromDate = from_date.split(",")[1];
            }
            else if (from_date.split(",")[0] === "1" && from_date.split(",")[1] === "") {
                tempFromDate = "";
            }
        }

        if (to_date !== "") {
            if (to_date.split(",")[0] === "1" && to_date.split(",")[1] !== "") {
                tempToDate = to_date.split(",")[1];
            }
            else if (to_date.split(",")[0] === "1" && to_date.split(",")[1] === "") {
                tempToDate = "";
            }
        }

        if (customer_email !== "") {
            if (customer_email.split(",")[0] === "1" && customer_email.split(",")[1] !== "") {
                tempCustomerEmail = customer_email.split(",")[1];
            }
            else if (customer_email.split(",")[0] === "1" && customer_email.split(",")[1] === "") {
                tempCustomerEmail = "";
            }
        }

        let TempArr = [];
        ResultData.forEach(ResultData_ => {
            if (tempFromDate === "" && tempToDate === "" && (tempCustomerEmail !== "" && tempCustomerEmail === ResultData_.EmailID)) {
                TempArr.push(ResultData_);
            }
            else if ((tempFromDate !== "" && moment(ResultData_.created_datetime).format('YYYY-MM-DD') >= moment(tempFromDate).format('YYYY-MM-DD')) && tempToDate === "" && tempCustomerEmail === "") {
                TempArr.push(ResultData_);
            }
            else if (tempFromDate === "" && (tempToDate !== "" && moment(ResultData_.created_datetime).format('YYYY-MM-DD') <= moment(tempToDate).format('YYYY-MM-DD')) && tempCustomerEmail === "") {
                TempArr.push(ResultData_);
            }
            else if ((tempFromDate !== "" && moment(ResultData_.created_datetime).format('YYYY-MM-DD') >= moment(tempFromDate).format('YYYY-MM-DD')) && (tempToDate !== "" && moment(ResultData_.created_datetime).format('YYYY-MM-DD') <= moment(tempToDate).format('YYYY-MM-DD')) && tempCustomerEmail === "") {
                TempArr.push(ResultData_);
            }
            else if ((tempFromDate !== "" && moment(ResultData_.created_datetime).format('YYYY-MM-DD') >= moment(tempFromDate).format('YYYY-MM-DD')) && tempToDate === "" && (tempCustomerEmail !== "" && tempCustomerEmail === ResultData_.EmailID)) {
                TempArr.push(ResultData_);
            }
            else if (tempFromDate === "" && (tempToDate !== "" && moment(ResultData_.created_datetime).format('YYYY-MM-DD') <= moment(tempToDate).format('YYYY-MM-DD')) && (tempCustomerEmail !== "" && tempCustomerEmail === ResultData_.EmailID)) {
                TempArr.push(ResultData_);
            }
            else if ((tempFromDate !== "" && moment(ResultData_.created_datetime).format('YYYY-MM-DD') >= moment(tempFromDate).format('YYYY-MM-DD')) && (tempToDate !== "" && moment(ResultData_.created_datetime).format('YYYY-MM-DD') <= moment(tempToDate).format('YYYY-MM-DD')) && (tempCustomerEmail !== "" && tempCustomerEmail === ResultData_.EmailID)) {
                TempArr.push(ResultData_);
            }
            else if (tempFromDate === "" && tempToDate === "" && tempCustomerEmail === "") {
                TempArr.push(ResultData_);
            }
        });
        setFilterData(TempArr);
    }

    const handleLoad = () => {
        var jsonpar = JSON.stringify({
            'manager_id': window.sessionStorage.getItem("manager_id")
        });
        const headers = {
            'Content-Type': 'application/json',
            'manageraccesstoken': process.env.REACT_APP_MANAGER_ACCESS_TOKEN,
            'managerauthtoken': 'Bearer ' + window.sessionStorage.getItem("manager_jwt"),
        }
        axios.post(process.env.REACT_APP_API_URL + 'getAllCustomerWAllAccount', jsonpar, {
            headers: headers
        })
            .then(function (res) {
                res = res.data;
                if (res.code === "200") {
                    setallusergroup(res.allusergrp);
                    setalluser(res.alluser);
                    let CustomerEmailTempArr = [];
                    let CustomerDataTempArr = [];
                    let TempArr = [];
                    res.data.forEach(element => {
                        if (!CustomerEmailTempArr.includes(element.customer_email)) {
                            var CustomerTempArr = {
                                CustomerNameNEmail: element.customer_name + " (" + element.customer_email + ")",
                                CustomerEmail: element.customer_email,
                            }
                            CustomerDataTempArr.push(CustomerTempArr);
                            CustomerEmailTempArr.push(element.customer_email);
                        }

                        let temp = {
                            No: element.account_id_pk,
                            CustomerName: element.customer_name,
                            EmailID: element.customer_email,
                            Phoneno: "+" + element.customer_phone,
                            AccountNo: element.account_number,
                            Currency: element.account_currency_type,
                            created_datetime: element.created_datetime
                        }
                        TempArr.push(temp);
                    });
                    setResultData(TempArr);
                    setFilterData(TempArr);
                    setCustomerData(CustomerDataTempArr);

                    setTimeout(() => {
                        setLoader(false);
                    }, 500);
                } else {
                    setLoader(false);
                }
            })
            .catch(function (error) {
                setLoader(false);
            });
    }

    const handleLoadGetAllCountry = () => {
        const headers = {
            'Content-Type': 'application/json',
            'manageraccesstoken': process.env.REACT_APP_MANAGER_ACCESS_TOKEN,
            'managerauthtoken': 'Bearer ' + window.sessionStorage.getItem("manager_jwt"),
        }
        axios.get(process.env.REACT_APP_API_URL + 'getAllCountry', {
            headers: headers
        })
            .then(function (res) {
                res = res.data;
                if (res.code === "200") {
                    handleLoad();
                    setCountryData(res.data);
                } else {
                    handleLoad();
                }
            })
            .catch(function (error) {
                setLoader(false);
            });
    }


    useEffect(() => {
        setLoader(true);
        handleLoadGetAllCountry();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    return (
        <div>
            <div className="card">
                <div className="card-header page-title-space mobile-card-header">
                    <h2 className="text-black font-w600 mb-0">{t("ACCOUNT_TRANSACTIONS.CUSTOMER")}</h2>
                    <div className="mobileFullWidth">
                        <Link to="#" className="btn btn-primary btn-rounded mr-3" onClick={() => setModal1(true)}>
                            {t("ACCOUNT_TRANSACTIONS.ADDCUSTOMER")}
                        </Link>
                        <Modal className="modal fade" show={Modal1} size="lg">
                            <div className="modal-dialog-centered " >
                                <div className="modal-content">
                                    <div className="modal-header">
                                        <h3 className="modal-title">{t("ACCOUNT_TRANSACTIONS.ADDCUSTOMER")}</h3>
                                        <button type="button" className="close" onClick={() => setModal1(false)}><span>&times;</span>
                                        </button>
                                    </div>
                                    <div className="modal-body">
                                        <div className="row">
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label className="mb-2 ">{t("LABEL.LABEL_FIRST_NAME")}</label>
                                                    <input type="text" className="form-control" placeholder={t("PLACEHOLDER.PLACEHOLDER_FIRST_NAME")} value={FirstName} onChange={(e) => validateFirstName(e.target.value)} />
                                                    {FirstNameError !== "" ? <label className='text-red'>{FirstNameError}</label> : ""}
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label className="mb-2 ">{t("LABEL.LABEL_LAST_NAME")}</label>
                                                    <input type="text" className="form-control" placeholder={t("PLACEHOLDER.PLACEHOLDER_LAST_NAME")} value={LastName} onChange={(e) => validateLastName(e.target.value)} />
                                                    {LastNameError !== "" ? <label className='text-red'>{LastNameError}</label> : ""}
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label className="mb-2 ">{t("LABEL.LABEL_EMAIL")}</label>
                                                    <input type="email" className="form-control" placeholder={t("PLACEHOLDER.PLACEHOLDER_EMAIL")} value={Email} onChange={(e) => validateEmail(e.target.value)} />
                                                    {EmailError !== "" ? <label className='text-red'>{EmailError}</label> : ""}
                                                </div>
                                            </div>

                                            <div className="col-md-6 position-relative">
                                                <label className="mb-2 ">
                                                    <span>User Group</span>
                                                </label>
                                                <select className="form-control webkit-none" value={AddGroup} onChange={(e) => validateAddGroup(e.target.value)}>
                                                    <option value="">Select User Group</option>
                                                    {allusergroup && allusergroup.map((row) => (
                                                        <option value={row.group_id} key={row.group_id}>{row.group_name}</option>
                                                    ))}
                                                </select>
                                                <i className="fa fa-caret-down down-arrow select-arrow1" aria-hidden="true"></i>
                                            </div>
                                            {AddGroupError !== "" ? <label className='text-red'>{AddGroupError}</label> : ""}

                                            <div className="col-md-12">
                                                <div className='form-group mb-2 fullWidth'>
                                                    <label className='mb-1 fullWidth'>{t("LABEL.LABEL_PHONE_NUMBER")} </label>
                                                    <div className="row">
                                                        <div className="form-group position-relative col-md-6">
                                                            <select className="form-control webkit-none" value={CountryCode} onChange={(e) => validateCountryCode(e.target.value)}>
                                                                {CountryData && CountryData.map((row) => (
                                                                    <option value={row.phonecode} key={row.country_id_pk}>{"+" + row.phonecode + " (" + row.name + ")"}</option>
                                                                ))}
                                                            </select>
                                                            <i className="fa fa-caret-down down-arrow" aria-hidden="true" style={{marginRight:"10px"}}></i>
                                                        </div>
                                                        <div className='col-md-6'>
                                                            <input type="number" className="form-control" placeholder={t("PLACEHOLDER.PLACEHOLDER_PHONE_NUMBER")} value={Phone} onChange={(e) => validatePhone(e.target.value)} />
                                                            {PhoneError !== "" ? <label className='text-red'>{PhoneError}</label> : ""}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="modal-footer">
                                        <button type="button" className="btn btn-light btn-rounded light" onClick={() => setModal1(false)}>{t("BUTTONS.CANCEL")}</button>
                                        <button type="button" className="btn btn-primary btn-rounded" onClick={handlesubmit}>{t("ACCOUNT_TRANSACTIONS.ADDCUSTOMER")}</button>
                                    </div>
                                </div>
                            </div>
                        </Modal>
                    </div>
                </div>
            </div>

            {error ? <Snackbars open_close={error} type="error" message={error_msg} /> : ""}
            {success ? <Snackbars open_close={success} type="success" message={success_msg} /> : ""}

            {loader === true ?
                <div>
                    <Loader open_close={loader} />
                </div>
                :
                <div className="card">
                    <div className="card-body bdr-btm-card">
                        <div className="row">
                            <div className="col-md-3">
                                <div className="form-group position-relative">
                                    <label className="mb-2 ">
                                        <span>{t('TRANSACTION.FROM_DATE')}</span>
                                    </label>
                                    <div className="col-xs-12">
                                        <input type="date" onChange={(e) => handleChangeFromDate(e.target.value)} value={FromDate} className="form-control" />
                                    </div>
                                </div>
                            </div>

                            <div className="col-md-3">
                                <div className="form-group position-relative">
                                    <label className="mb-2 ">
                                        <span>{t('TRANSACTION.TO_DATE')}</span>
                                    </label>
                                    <div className="col-xs-12">
                                        <input type="date" onChange={(e) => handleChangeToDate(e.target.value)} value={ToDate} className="form-control" />
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <label className="mb-2 ">
                                    <span>{t('ACCOUNT_TRANSACTIONS.CUSTOMER_NAME')}</span>
                                </label>
                                <div className="form-group position-relative">
                                    <select className="form-control webkit-none" onChange={(e) => handleChangeCustmer(e.target.value)} value={Customer}>
                                        <option value="">{t('ACCOUNT_TRANSACTIONS.SELECT_ALL')}</option>
                                        {CustomerData && CustomerData.map((row) => (
                                            <option value={row.CustomerEmail} key={row.CustomerEmail}>{row.CustomerNameNEmail}</option>
                                        ))}
                                    </select>
                                    <i className="fa fa-caret-down down-arrow select-arrow1" aria-hidden="true"></i>
                                </div>
                            </div>

                        </div>
                        <div className="table-responsive">

                        </div>
                    </div>
                    <div className="card-body">
                        <div className="position-relative">
                            <div className="table-responsive">
                                {FilterData.length > 0 ?
                                    <MaterialTable
                                        title="Customer Account List"
                                        columns={[
                                            { title: t('ACCOUNT_TRANSACTIONS.CUSTOMER_NAME'), field: 'CustomerName' },
                                            { title: t('ACCOUNT_TRANSACTIONS.ACCOUNT_NO'), field: 'AccountNo' },
                                            { title: t('FIAT.CURRENCY_TITLE'), field: 'Currency' },
                                            // { title: t('HEADER.CUS_EMAIL'), field: 'EmailID' },
                                            // { title: t('HEADER.CUS_PHONE'), field: 'Phoneno' },
                                        ]}
                                        data={FilterData}
                                        options={{
                                            exportMenu: [
                                                { label: "Export PDF", exportFunc: (cols, datas) => ExportPdf(cols, datas, "Customer List") },
                                                { label: "Export CSV", exportFunc: (cols, datas) => ExportCsv(cols, datas, "Customer List") },
                                            ], paging: true, pageSize: 5, pageSizeOptions: [5, 10, 15], emptyRowsWhenPaging: false,
                                        }}
                                    />
                                    : <div className="d-sm-flex d-block border-0 pb-0">
                                        <div className="pr-3 mr-auto mb-sm-0 mb-3 nofoundFullWidth">
                                            <h4 className="fs-20 text-black mb-1 nofoundTitle">Customer List Overview</h4>
                                            <span className="fs-12 nofoundText">No Data Found</span>
                                        </div>
                                    </div>}
                            </div>
                        </div>
                    </div>

                    <div className="card-body">
                        <div className="position-relative">
                            <div className="table-responsive">
                                {alluser.length > 0 ?
                                    <MaterialTable
                                        title="All Customer List"
                                        columns={[
                                            { title: "First Name", field: 'first_name' },
                                            { title: "Last Name", field: 'last_name' },
                                        ]}
                                        data={alluser}
                                        options={{
                                            exportMenu: [
                                                { label: "Export PDF", exportFunc: (cols, datas) => ExportPdf(cols, datas, "Customer List") },
                                                { label: "Export CSV", exportFunc: (cols, datas) => ExportCsv(cols, datas, "Customer List") },
                                            ], paging: true, pageSize: 5, pageSizeOptions: [5, 10, 15], emptyRowsWhenPaging: false,
                                        }}
                                    />
                                    : <div className="d-sm-flex d-block border-0 pb-0">
                                        <div className="pr-3 mr-auto mb-sm-0 mb-3 nofoundFullWidth">
                                            <h4 className="fs-20 text-black mb-1 nofoundTitle">Customer List Overview</h4>
                                            <span className="fs-12 nofoundText">No Data Found</span>
                                        </div>
                                    </div>}
                            </div>
                        </div>
                    </div>


                </div>
            }
        </div>
    )

}
export default Customer_List;