import React, { useState, useEffect } from "react";
import { Link } from 'react-router-dom';
import Snackbars from '../../../jsx/pages/Snackbars';
import { useTranslation } from "react-i18next";
import axios from 'axios';
import moment from 'moment';
import Loader from '../../pages/Loader';

export const News_Details = (props) => {

    const { t } = useTranslation();
    const [title, setTitle] = useState("");
    const [date, setDatesss] = useState("");
    const [desc, setDesc] = useState("");
    const [error, setError] = useState(false);
    const [error_msg, setErrorMsg] = useState("");
    const [loader, setLoader] = useState(false);

    useEffect(() => {
        handleLoad();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleLoad = () => {

        if (props.match.params.id === "" || props.match.params.id === null || props.match.params.id === undefined) {
            setError(true);
            setErrorMsg(t('ERROR_MSG.SOMETHING'));
            setTimeout(() => {
                setError(false);
            }, 6000);
        } else {
            const headers = {
                'Content-Type': 'application/json',
                'manageraccesstoken': process.env.REACT_APP_MANAGER_ACCESS_TOKEN,
                'managerauthtoken': 'Bearer ' + window.sessionStorage.getItem("manager_jwt"),
            }
            setLoader(true);
            axios.get(process.env.REACT_APP_API_URL + 'findNewsById/' + props.match.params.id, { headers: headers })
                .then(function (res) {
                    res = res.data;
                    if (res.code === "200") {
                        setTitle(res.data.title);
                        setDatesss(res.data.created_datetime);
                        setDesc(res.data.description);
                        setTimeout(() => {
                            setLoader(false);
                        }, 500);
                    } else {
                        setError(true);
                        setErrorMsg(res.message);
                        setTimeout(() => {
                            setError(false);
                        }, 6000);
                    }
                })
                .catch(function (error) {
                    setError(true);
                    setErrorMsg(t('ERROR_MSG.SOMETHING'));
                    setTimeout(() => {
                        setError(false);
                    }, 6000);
                });
        }
    }

    return (
        <div>
            <div className="card">
                <div className="card-header page-title-space">
                    <h2 className="text-black font-w600 mb-0">{t('NEWS_DETAILS')}</h2>
                    <Link to="/news" className="back-to-page"> <i className="font-bold fa fa-angle-left"></i>&nbsp;&nbsp;{t('LINK.BACK')}</Link>
                </div>
            </div>
            {error ? <Snackbars open_close={error} type="error" message={error_msg} /> : ""}
            {loader === true ?
                <div>
                    <Loader open_close={loader} />
                </div>
                :
                <div className="card widget-stat widget-stat-custom card">
                    <div className="card-body">
                        <div className="read-content">
                            <div className="media mb-2">
                                <div className="media-body">
                                    <h4 className="my-1 text-primary">{title}</h4>
                                    <p className="read-content-email">{moment(date).format('MM / DD / YYYY hh:mm:ss')}</p>
                                </div>
                            </div>
                            <div className="read-content-body">
                                <p className="mb-3">
                                <div dangerouslySetInnerHTML={{ __html: desc }} />
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            }
        </div>

    )

}
export default News_Details;