import React, { useState, useEffect } from "react";
import { Link } from 'react-router-dom';
import { useTranslation } from "react-i18next";
import Snackbars from '../../pages/Snackbars';
import axios from 'axios';
import Loader from '../../pages/Loader';
import moment from 'moment';
import { Tabs, Tab } from 'react-bootstrap'


export const KYCDetails = (props) => {
    const { t } = useTranslation();
    const [error, setError] = useState(false);
    const [error_msg, setErrorMsg] = useState("");
    const [allcountry, setAllCountry] = useState([]);
    const [allstate, setAllState] = useState([]);
    const [first_name, setFirst_name] = useState("");
    const [middle_name, setMiddleName] = useState("");
    const [last_name, setLastName] = useState("");
    const [email, setEmail] = useState("");
    const [phonecode, setPhonecode] = useState("");
    const [phonenumber, setPhoneNumber] = useState("");
    const [dob, setDob] = useState("");
    const [taxssn, setTexssn] = useState("");
    const [taxcountry, setTaxcountry] = useState("");
    const [taxstate, setTaxstate] = useState("");
    const [country, setCountry] = useState("");
    const [addressline1, setAddressline1] = useState("");
    const [addressline2, setAddressline2] = useState("");
    const [city, setCity] = useState("");
    const [region, setRegion] = useState("");
    const [zipcode, setZipcode] = useState("");

    const [aml_kyc_status, setAml_kyc_status] = useState("");
    const [cip_kyc_status, setCip_kyc_status] = useState("");
    const [iddoc_kyc_status, setIddoc_kyc_status] = useState("");
    const [address_kyc_status, setAddress_kyc_status] = useState("");
    const [aml_approved_datetime, setAml_approved_datetime] = useState("");
    const [cip_approved_datetime, setCip_approved_datetime] = useState("");
    const [iddoc_approved_datetime, setIddoc_approved_datetime] = useState("");
    const [address_approved_datetime, setAddress_approved_datetime] = useState("");
    const [created_at, setCreated_at] = useState("");
    const [updated_at, setUpdated_at] = useState("");
    const [kyc_staus, setKyc_staus] = useState("");
    const [kyc_id, setKyc_id] = useState(0);
    const [loader, setLoader] = useState(false);
    const [documentdetails, setDocumentDetails] = useState([]);
    const [documentcountryname, setDocumentCountryname] = useState("");
    const [documenttypename, Setdocumenttypename] = useState("");
    const [documentfrontfilename, setdocumentfrontfilename] = useState("");
    const [documentbackfilename, setdocumentbackfilename] = useState("");


    useEffect(() => {
        handleLoad();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleLoad = () => {
        const jsonpar = {
            "user_id": atob(props.match.params.id)
        }
        const headers = {
            'Content-Type': 'application/json',
            'manageraccesstoken': process.env.REACT_APP_MANAGER_ACCESS_TOKEN,
            'managerauthtoken': 'Bearer ' + window.sessionStorage.getItem("manager_jwt"),
        }
        setLoader(true);
        axios.post(process.env.REACT_APP_API_URL + 'getKYCDetails', jsonpar, { headers: headers })
            .then(function (res) {
                res = res.data;
                if (res.code === "200") {
                    setAllCountry(res.data.countryarray);
                    if (res.data.kyc_id <= 0) {
                        setPhonecode("+91");
                    }
                    setAllState(res.data.statearray);
                    if (res.data.kyc_id > 0) {

                        setKyc_id(res.data.kyc_id);
                        setFirst_name(res.data.first_name);
                        setMiddleName(res.data.middle_name);
                        setLastName(res.data.last_name);
                        setEmail(res.data.email);
                        setPhonecode(res.data.phonecode);

                        setPhoneNumber(res.data.phone_number);
                        if (res.data.dob === null) {
                            setDob("");
                        } else {
                            setDob(res.data.dob);
                        }
                        setTexssn(res.data.taxssn);
                        setTaxcountry(res.data.tax_country_id);
                        setTaxstate(res.data.tax_state_id);
                        setAddressline1(res.data.address1);
                        setAddressline2(res.data.address2);
                        setCountry(res.data.country_id);
                        setRegion(res.data.region);
                        setZipcode(res.data.postalcode);
                        setCity(res.data.city);
                        setAml_kyc_status(res.data.aml_kyc_status);
                        setCip_kyc_status(res.data.cip_kyc_status);
                        setIddoc_kyc_status(res.data.iddoc_kyc_status);
                        setAddress_kyc_status(res.data.address_kyc_status);
                        setAml_approved_datetime(res.data.aml_approved_datetime);
                        setCip_approved_datetime(res.data.cip_approved_datetime);
                        setIddoc_approved_datetime(res.data.iddoc_approved_datetime);
                        setAddress_approved_datetime(res.data.address_approved_datetime);
                        setCreated_at(res.data.created_at);
                        setUpdated_at(res.data.updated_at);
                        setKyc_staus(res.data.mainkycstatus);
                        setDocumentDetails(res.data.documentdetails);
                        setDocumentCountryname(res.data.documentcountryname);
                        Setdocumenttypename(res.data.documenttypename);
                        setdocumentfrontfilename(res.data.documenttypefrontfilename);
                        setdocumentbackfilename(res.data.documenttypebackfilename);

                    }
                    setLoader(false);

                } else {
                    setLoader(false);
                    setError(true);
                    setErrorMsg(res.message);
                    setTimeout(() => {
                        setError(false);
                    }, 6000);
                }
            })
            .catch(function (error) {
                setLoader(false);
                setError(true);
                setErrorMsg(t('ERROR_MSG.SOMETHING'));
                setTimeout(() => {
                    setError(false);
                }, 6000);
            });
    }


    return (
        <div>
            <div className="card">
                <div className="card-header mobile-card-header">
                    <h2 className="text-black font-w600 mb-0">{t('KYC.KYC_DETATILS')}</h2>
                    <div className="mobileFullWidth">
                        <div className="form-group position-relative mb-0">
                            <Link to="/kyc" className="back-to-page"> <i className="font-bold fa fa-angle-left"></i>&nbsp;&nbsp;Back</Link>
                        </div>
                    </div>
                </div>
            </div>

            {error ? <Snackbars open_close={error} type="error" message={error_msg} /> : ""}
            {kyc_id > 0 ?

                <div className="card">
                    {loader === true ?
                        <div>
                            <Loader open_close={loader} />
                        </div>
                        :
                        <div className="card-body p-0">
                            <div className="profile-head">
                                <div className="profile-info">
                                    <div className="profile-details mt-3">
                                        <div className="profile-name px-4">
                                            <h4 className="mb-0 text-success">{t('KYC.AML')}:  {moment(aml_approved_datetime).format('MM / DD / YYYY hh:mm:ss')}</h4>
                                            <p className="mb-0">{aml_kyc_status}</p>
                                        </div>
                                        <div className="profile-email px-4">
                                            <h4 className="mb-0 text-success">{t('KYC.CIP')}: {moment(cip_approved_datetime).format('MM / DD / YYYY hh:mm:ss')}</h4>
                                            <p className="mb-0">{cip_kyc_status}</p>
                                        </div>
                                        <div className="profile-email px-4">
                                            <h4 className="mb-0 text-warning">{t('KYC.IDDOC')}: {moment(iddoc_approved_datetime).format('MM / DD / YYYY hh:mm:ss')}</h4>
                                            <p className="mb-0">{iddoc_kyc_status}</p>
                                        </div>
                                        <div className="profile-email px-4">
                                            <h4 className="mb-0 text-warning">{t('KYC.ADDRESS')}: {moment(address_approved_datetime).format('MM / DD / YYYY hh:mm:ss')}</h4>
                                            <p className="mb-0">{address_kyc_status}</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="profile-info">
                                    <div className="profile-details mb-3">
                                        <div className="profile-name px-4">
                                            <h5 className="mb-0">{t('KYC.CREATED_AT')}: {created_at === "" ? t('KYC.NOT_KYC_START') : moment(created_at).format('MM / DD / YYYY hh:mm:ss')}</h5>
                                        </div>
                                        <div className="profile-email px-4">
                                            <h5 className="mb-0">{t('KYC.UPDATED_AT')}: {updated_at === "" ? t('KYC.NOT_KYC_START') : moment(updated_at).format('MM / DD / YYYY hh:mm:ss')}</h5>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    }
                </div>
                : ""}
            <div className="card mb-1">
                {loader === true ?
                    <div>
                        <Loader open_close={loader} />
                    </div>
                    :
                    <div className="card-body">
                        <div className="row">
                            <div className="col-lg-4 col-sm-6">
                                <div className="form-group position-relative">
                                    <label className="mb-2">{t('LABEL.LABEL_FIRST_NAME')}</label>
                                    <input type="text" className="form-control text-black" value={first_name} placeholder={t('PLACEHOLDER.PLACEHOLDER_FIRST_NAME')} readOnly />
                                </div>
                            </div>
                            <div className="col-lg-4 col-sm-6">
                                <div className="form-group position-relative">
                                    <label className="mb-2">{t('KYC.MIDDLE_NAME')}</label>
                                    <input type="text" className="form-control text-black" value={middle_name} placeholder={t('KYC.MIDDLE_PLACE')} readOnly />

                                </div>
                            </div>
                            <div className="col-lg-4 col-sm-6">
                                <div className="form-group position-relative">
                                    <label className="mb-2">{t('LABEL.LABEL_LAST_NAME')}</label>
                                    <input type="text" className="form-control text-black" value={last_name} placeholder={t('PLACEHOLDER.PLACEHOLDER_LAST_NAME')} readOnly />

                                </div>
                            </div>
                            <div className="col-lg-4 col-sm-6">
                                <div className="form-group position-relative">
                                    <label className="mb-2">{t('LABEL.LABEL_EMAIL')}</label>
                                    <input type="email" className="form-control text-black" value={email} placeholder={t('PLACEHOLDER.PLACEHOLDER_EMAIL')} readOnly />

                                </div>
                            </div>
                            <div className="col-lg-6 col-sm-6">
                                <div className='form-group mb-2 fullWidth'>
                                    <label className='mb-1 fullWidth'>{t('LABEL.LABEL_PHONE_NUMBER')}</label>
                                    <div className="row">
                                        <div className="form-group position-relative col-md-6">
                                            <select className="form-control webkit-none" value={phonecode} disabled>
                                                {allcountry && allcountry.map((row) => (
                                                    <option value={row.phonecode} key={row.country_id_pk}>{"+" + row.phonecode + " (" + row.name + ")"}</option>
                                                ))}
                                            </select>
                                            <i className="fa fa-caret-down down-arrow" aria-hidden="true" style={{marginRight:"10px"}}></i>
                                        </div>
                                        <div className='col-md-6'>
                                            <input type="text" className="form-control" value={phonenumber} placeholder='Phone number' readOnly />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                }
            </div>
            <div className="card">
                {loader === true ?
                    <div>
                        <Loader open_close={loader} />
                    </div>
                    :
                    <div className="card-body">
                        <div className="row">
                            <div className="col-lg-4 col-sm-6">
                                <div className="form-group position-relative">
                                    <label className="mb-2">{t('PROFILE.DOB')}</label>
                                    <input type="date" className="form-control text-black" placeholder={t('PROFILE.DOB')} value={dob} max={new Date().toISOString().split("T")[0]} readOnly />

                                </div>
                            </div>
                            <div className="col-lg-4 col-sm-6">
                                <div className="form-group position-relative">
                                    <label className="mb-2">{t('KYC.TAXID')}</label>
                                    <input type="text" className="form-control text-black" value={taxssn} placeholder={t('KYC.TAXID')} readOnly />

                                </div>
                            </div>
                            <div className="col-lg-4 col-sm-6">
                                <div className="form-group position-relative">
                                    <label className="mb-2">{t('KYC.TAX_COUNTRY')}</label>
                                    <select className="form-control webkit-none" id="sel1" value={taxcountry} disabled>
                                        <option value="">{t('PROFILE.SELECT_COUNTRY')}</option>
                                        {allcountry && allcountry.map((row) => (
                                            <option value={row.country_id_pk} key={row.country_id_pk}>{row.name}</option>
                                        ))}
                                    </select>
                                    <i className="fa fa-caret-down down-arrow" aria-hidden="true"></i>
                                </div>

                            </div>
                            <div className="col-lg-4 col-sm-6">
                                <div className="form-group position-relative">
                                    <label className="mb-2">{t('KYC.TAX_STATE')}</label>
                                    <select className="form-control webkit-none" id="sel1" value={taxstate} disabled>
                                        <option value="">{t('PROFILE.SELECT_STATE')}</option>
                                        {allstate && allstate.map((row) => (
                                            <option value={row.id} key={row.id}>{row.name}</option>
                                        ))}
                                    </select>
                                    <i className="fa fa-caret-down down-arrow" aria-hidden="true"></i>
                                </div>
                            </div>
                            <div className="col-lg-4 col-sm-6">
                                <div className="form-group position-relative">
                                    <label className="mb-2">{t('PROFILE.COUNTRY')}</label>
                                    <select className="form-control webkit-none" id="sel1" value={country} disabled>
                                        <option value="">{t('PROFILE.SELECT_COUNTRY')}</option>
                                        {allcountry && allcountry.map((row) => (
                                            <option value={row.country_id_pk} key={row.country_id_pk}>{row.name}</option>
                                        ))}
                                    </select>
                                    <i className="fa fa-caret-down down-arrow" aria-hidden="true"></i>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-4 col-sm-6">
                                <div className="form-group">
                                    <label className="mb-2">{t('KYC.STREET_ADDRESS')}</label>
                                    <input type="text" className="form-control text-black" placeholder={t('KYC.STREET_ADDRESS')} value={addressline1} readOnly />

                                </div>
                            </div>
                            <div className="col-lg-4 col-sm-6">
                                <div className="form-group">
                                    <label className="mb-2">{t('KYC.STREET_ADDRESS_TWO')}</label>
                                    <input type="text" className="form-control text-black" value={addressline2} placeholder={t('KYC.STREET_ADDRESS_TWO')} readOnly />

                                </div>
                            </div>
                            <div className="col-lg-4 col-sm-6">
                                <div className="form-group">
                                    <label className="mb-2">{t('PROFILE.CITY')}</label>
                                    <input type="text" className="form-control text-black" value={city} placeholder={t('PROFILE.CITY')} readOnly />

                                </div>
                            </div>
                            <div className="col-lg-4 col-sm-6">
                                <div className="form-group position-relative">
                                    <label className="mb-2">{t('KYC.REGION')}</label>
                                    <select className="form-control webkit-none" id="sel1" value={region} disabled>
                                        <option value="">{t('ERROR_MSG.ERROR_REGION')}</option>
                                        {allcountry && allcountry.map((row) => (
                                            <option value={row.country_id_pk} key={row.country_id_pk}>{row.name}</option>
                                        ))}
                                    </select>
                                    <i className="fa fa-caret-down down-arrow" aria-hidden="true"></i>
                                </div>
                            </div>
                            <div className="col-lg-4 col-sm-6">
                                <div className="form-group position-relative">
                                    <label className="mb-2">{t('KYC.POSTAL_CODE')}</label>
                                    <input type="text" className="form-control text-black" value={zipcode} placeholder={t('KYC.POSTAL_CODE')} readOnly />

                                </div>
                            </div>
                        </div>
                    </div>
                }
            </div>
            {kyc_id > 0 ?
                <div className="card">
                    {loader === true ?
                        <div>
                            <Loader open_close={loader} />
                        </div>
                        :
                        <div className="card-body">
                            <div className="col-xs-12">
                                <div className='custom-tab-1'>
                                    {/* <!-- Nav tabs --> */}
                                    <div className='custom-tab-1'>
                                        <Tabs id="controlled-tab-example" className="mb-3">
                                            <Tab eventKey="Documents" title="Documents">
                                                {documentdetails.length > 0 ?
                                                    <div className="me-auto mb-sm-0 mb-3 mt-4">
                                                        <span className="fs-34 text-black font-w600">{documenttypename}</span>
                                                    </div>
                                                    : ""}
                                                {documentdetails.length > 0 ?
                                                    <div className="mt-4 row">
                                                        <div className="col-md-4">
                                                            <div className="pr-3 mb-3">
                                                                <p className="fs-14 mb-1">{t('KYC.STATUS_TITLE')}</p>
                                                                {kyc_staus === "Approved" ?
                                                                    <span className="text-black fs-18 font-w500"><span className="badge light badge-success">{kyc_staus}</span></span>
                                                                    :
                                                                    <span className="text-black fs-18 font-w500"><span className="badge light badge-warning">{kyc_staus}</span></span>
                                                                }
                                                            </div>
                                                            <div className="pr-3 mb-3">
                                                                <p className="fs-14 mb-1">{t('KYC.EXCEPTIONS')}</p>
                                                                <span className="text-black fs-18 font-w500"><span className="badge light badge-success">{t('KYC.NOEXCEPTION')}</span></span>
                                                            </div>
                                                            <div className="pr-3 mb-3">
                                                                <p className="fs-14 mb-1">{t('KYC.PROOF_IDENTITY')}</p>
                                                                <span className="text-black fs-18 font-w500"><span className="badge light badge-success">{t('LINK.SUCCESS')}</span></span>
                                                            </div>
                                                            <div className="mb-3">
                                                                <p className="fs-14 mb-1">{t('KYC.PROOF_ADDRESS')}</p>
                                                                <span className="text-black fs-18 font-w500"><span className="badge light badge-success">{t('LINK.SUCCESS')}</span></span>
                                                            </div>
                                                            <div className="mb-3">
                                                                <p className="fs-14 mb-1">{t('PROFILE.COUNTRY')}</p>
                                                                <span className="text-black fs-18 font-w500"><span className="text-black fs-18 font-w500">{documentcountryname}</span></span>
                                                            </div>
                                                        </div>
                                                        {documentdetails.length > 0 ?
                                                            <div className="col-md-6">
                                                                <div className="d-sm-flex d-block mb-3">
                                                                    <div className="d-flex me-auto mb-sm-0 mb-3 align-items-center">
                                                                        <img src={process.env.REACT_APP_FILE_PATH_KYC + documentfrontfilename} alt="" className="mr-4" width="160" />
                                                                        <p>{documentfrontfilename}</p>
                                                                    </div>
                                                                </div>

                                                                <div className="d-sm-flex d-block mb-3">
                                                                    <div className="d-flex me-auto mb-sm-0 mb-3 align-items-center">
                                                                        <img src={process.env.REACT_APP_FILE_PATH_KYC + documentbackfilename} alt="" className="mr-4" width="160" />
                                                                        <p>{documentbackfilename}</p>
                                                                    </div>
                                                                </div>

                                                            </div>
                                                            : ""}
                                                    </div>
                                                    : ""}
                                                <hr />
                                            </Tab>
                                            <Tab eventKey="CIP" title="CIP">
                                                <div className="row">
                                                    <div className="col-md-4">
                                                        <div className="pr-3 mb-3">
                                                            <p className="fs-14 mb-1">{t('KYC.STATUS_TITLE')}</p>
                                                            {kyc_staus === "Approved" ?
                                                                <span className="text-black fs-18 font-w500"><span className="badge light badge-success"> {t('LINK.SUCCESS')}</span></span>
                                                                :
                                                                <span className="text-black fs-18 font-w500"><span className="badge light badge-warning"> {cip_kyc_status}</span></span>
                                                            }
                                                        </div>
                                                        <div className="pr-3 mb-3">
                                                            <p className="fs-14 mb-1">{t('KYC.AUTOMATED_EX')}</p>
                                                            <span className="text-black fs-18 font-w500"><span className="badge light badge-success">{t('KYC.NO_AUTOMATED_EXCEPTION')}</span></span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row mt-3">
                                                    <div className="mb-3 col-md-12">
                                                        <label className="form-label">{t('KYC.EXCEPTIONS')}</label>
                                                        <input type="text" className="form-control" placeholder={t('KYC.EXCEPTIONS')} readOnly />
                                                    </div>
                                                    <div className="mb-3 col-md-12">
                                                        <label className="form-label">{t('KYC.DOCUMENT_COUNTRY')}</label>
                                                        <textarea className="form-control" placeholder={t('KYC.DOCUMENT_COUNTRY')} readOnly>{t('KYC.R557')}</textarea>
                                                    </div>
                                                </div>
                                            </Tab>
                                            <Tab eventKey="AML" title="AML">
                                                <div className="row">
                                                    <div className="col-md-4">
                                                        <div className="pr-3 mb-3">
                                                            <p className="fs-14 mb-1">{t('KYC.STATUS_TITLE')}</p>
                                                            {kyc_staus === "Approved" ?
                                                                <span className="text-black fs-18 font-w500"><span className="badge light badge-success"> {t('LINK.SUCCESS')}</span></span>
                                                                :
                                                                <span className="text-black fs-18 font-w500"><span className="badge light badge-warning"> {aml_kyc_status}</span></span>
                                                            }
                                                        </div>
                                                        <div className="pr-3 mb-3">
                                                            <p className="fs-14 mb-1">{t('KYC.AUTOMATED_EX')}</p>
                                                            <span className="text-black fs-18 font-w500"><span className="badge light badge-success">{t('KYC.NOEXCEPTION')}</span></span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Tab>
                                            <Tab eventKey="Next Step" title="Next Step">
                                                <div className="row">
                                                    <div className="col-md-12">
                                                        <p>{t('KYC.FOLLOWING_NEXT')}</p>
                                                        <p className="No-Data">{t('KYC.NO_CURRENT_sTEP')}</p>
                                                    </div>
                                                </div>
                                            </Tab>
                                        </Tabs>
                                    </div>
                                </div>
                            </div>
                        </div>
                    }
                </div>
                : ""}
        </div>
    )

}
export default KYCDetails;