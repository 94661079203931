import React, { useState, useEffect } from "react";
import MaterialTable from "@material-table/core";
import { ExportCsv, ExportPdf } from "@material-table/exporters";
import { useTranslation } from "react-i18next";
import axios from 'axios';
import moment from 'moment';
import Loader from '../../pages/Loader';


export const Rewards_Transaction = () => {
    const { t } = useTranslation();
    const [loader, setLoader] = useState(false);
    const [SettingData, setSettingData] = useState([]);
    const [ResultData, setResultData] = useState([]);
    const [FilterData, setFilterData] = useState([]);
    const [CustomerData, setCustomerData] = useState([]);
    const [Customer, setCustomer] = useState("");
    const [FromDate, setFromDate] = useState("");
    const [ToDate, setToDate] = useState("");


    const handleChangeFromDate = (val) => {
        setFromDate(val);
        filter("1," + val, "", "");
    }
    const handleChangeToDate = (val) => {
        setToDate(val);
        filter("", "1," + val, "");
    }
    const handleChangeCustmer = (val) => {
        setCustomer(val);
        filter("", "", "1," + val);
    }
    const filter = (from_date = "", to_date = "", customer_email = "") => {
        var tempFromDate = FromDate;
        var tempToDate = ToDate;
        var tempCustomerEmail = Customer;

        if (from_date !== "") {
            if (from_date.split(",")[0] === "1" && from_date.split(",")[1] !== "") {
                tempFromDate = from_date.split(",")[1];
            }
            else if (from_date.split(",")[0] === "1" && from_date.split(",")[1] === "") {
                tempFromDate = "";
            }
        }

        if (to_date !== "") {
            if (to_date.split(",")[0] === "1" && to_date.split(",")[1] !== "") {
                tempToDate = to_date.split(",")[1];
            }
            else if (to_date.split(",")[0] === "1" && to_date.split(",")[1] === "") {
                tempToDate = "";
            }
        }

        if (customer_email !== "") {
            if (customer_email.split(",")[0] === "1" && customer_email.split(",")[1] !== "") {
                tempCustomerEmail = customer_email.split(",")[1];
            }
            else if (customer_email.split(",")[0] === "1" && customer_email.split(",")[1] === "") {
                tempCustomerEmail = "";
            }
        }

        let TempArr = [];
        ResultData.forEach(ResultData_ => {
            if (tempFromDate === "" && tempToDate === "" && (tempCustomerEmail !== "" && tempCustomerEmail === ResultData_.CustomerEmailID)) {
                TempArr.push(ResultData_);
            }
            else if ((tempFromDate !== "" && moment(ResultData_.created_datetime).format('YYYY-MM-DD') >= moment(tempFromDate).format('YYYY-MM-DD')) && tempToDate === "" && tempCustomerEmail === "") {
                TempArr.push(ResultData_);
            }
            else if (tempFromDate === "" && (tempToDate !== "" && moment(ResultData_.created_datetime).format('YYYY-MM-DD') <= moment(tempToDate).format('YYYY-MM-DD')) && tempCustomerEmail === "") {
                TempArr.push(ResultData_);
            }
            else if ((tempFromDate !== "" && moment(ResultData_.created_datetime).format('YYYY-MM-DD') >= moment(tempFromDate).format('YYYY-MM-DD')) && (tempToDate !== "" && moment(ResultData_.created_datetime).format('YYYY-MM-DD') <= moment(tempToDate).format('YYYY-MM-DD')) && tempCustomerEmail === "") {
                TempArr.push(ResultData_);
            }
            else if ((tempFromDate !== "" && moment(ResultData_.created_datetime).format('YYYY-MM-DD') >= moment(tempFromDate).format('YYYY-MM-DD')) && tempToDate === "" && (tempCustomerEmail !== "" && tempCustomerEmail === ResultData_.CustomerEmailID)) {
                TempArr.push(ResultData_);
            }
            else if (tempFromDate === "" && (tempToDate !== "" && moment(ResultData_.created_datetime).format('YYYY-MM-DD') <= moment(tempToDate).format('YYYY-MM-DD')) && (tempCustomerEmail !== "" && tempCustomerEmail === ResultData_.CustomerEmailID)) {
                TempArr.push(ResultData_);
            }
            else if ((tempFromDate !== "" && moment(ResultData_.created_datetime).format('YYYY-MM-DD') >= moment(tempFromDate).format('YYYY-MM-DD')) && (tempToDate !== "" && moment(ResultData_.created_datetime).format('YYYY-MM-DD') <= moment(tempToDate).format('YYYY-MM-DD')) && (tempCustomerEmail !== "" && tempCustomerEmail === ResultData_.CustomerEmailID)) {
                TempArr.push(ResultData_);
            }
            else if (tempFromDate === "" && tempToDate === "" && tempCustomerEmail === "") {
                TempArr.push(ResultData_);
            }
        });
        setFilterData(TempArr);
    }

    const handleLoad = (Setting_Data = []) => {
        let SettingDataTemp = Setting_Data;
        if (Setting_Data.length === 0) {
            SettingDataTemp = SettingData;
        }

        var jsonpar = JSON.stringify({
            'manager_id': window.sessionStorage.getItem("manager_id")
        });
        const headers = {
            'Content-Type': 'application/json',
            'manageraccesstoken': process.env.REACT_APP_MANAGER_ACCESS_TOKEN,
            'managerauthtoken': 'Bearer ' + window.sessionStorage.getItem("manager_jwt"),
        }
        axios.post(process.env.REACT_APP_API_URL + 'getAllManagerCustomerRewardsTransaction', jsonpar, {
            headers: headers
        })
            .then(function (res) {
                res = res.data;
                if (res.code === "200") {

                    let CustomerEmailTempArr = [];
                    let CustomerDataTempArr = [];
                    let TempArr = [];
                    res.data.forEach(element => {
                        if (!CustomerEmailTempArr.includes(element.user_email_id)) {
                            var CustomerTempArr = {
                                CustomerNameNEmail: element.user_first_name + " " + element.user_last_name + " (" + element.user_email_id + ")",
                                CustomerEmail: element.user_email_id,
                            }
                            CustomerDataTempArr.push(CustomerTempArr);
                            CustomerEmailTempArr.push(element.user_email_id);
                        }

                        let BrokerName = "-";
                        if (element.broker_first_name !== null && element.broker_last_name !== null) {
                            BrokerName = element.broker_first_name + " " + element.broker_last_name;
                        }

                        let Fees = element.fees_percent + "%";
                        if (element.fee_type === "Fixed") {
                            Fees = element.fees_amount;
                        }

                        let CustomerName = element.user_first_name + " " + element.user_last_name;
                        let CustomerEmail = element.user_email_id;
                        if (SettingDataTemp.setting_value === "No") {
                            CustomerName = "*****";
                            CustomerEmail = "*****";
                            BrokerName = "*****";
                        }

                        let temp = {
                            No: element.customer_tra_fee_id,
                            Date: moment(element.created_datetime).format('MM / DD / YYYY hh:mm:ss'),
                            TransactionType: element.transaction_type,
                            CustomerName: CustomerName,
                            CustomerEmailID: CustomerEmail,
                            BrokerName: BrokerName,
                            Amount: element.total_amount + " " + element.from_cur,
                            FeeType: element.fee_type,
                            Fees: Fees,
                            TotalFees: element.fees_amount,
                            created_datetime: element.created_datetime
                        }
                        TempArr.push(temp);
                    });
                    setResultData(TempArr);
                    setFilterData(TempArr);
                    setCustomerData(CustomerDataTempArr);

                    setTimeout(() => {
                        setLoader(false);
                    }, 500);
                } else {
                    setLoader(false);
                }
            })
            .catch(function (error) {
                setLoader(false);
            });
    }

    const handleLoadSetting = () => {
        var jsonpar = JSON.stringify({
            'setting_id': 1
        });
        const headers = {
            'Content-Type': 'application/json',
            'manageraccesstoken': process.env.REACT_APP_MANAGER_ACCESS_TOKEN,
            'managerauthtoken': 'Bearer ' + window.sessionStorage.getItem("manager_jwt"),
        }
        axios.post(process.env.REACT_APP_API_URL + 'getSettgingInformation', jsonpar, {
            headers: headers
        })
            .then(function (res) {
                res = res.data;
                if (res.code === "200") {
                    setSettingData(res.data);
                    handleLoad(res.data);

                    setTimeout(() => {
                        setLoader(false);
                    }, 500);
                } else {
                    handleLoad();
                    setLoader(false);
                }
            })
            .catch(function (error) {
                setLoader(false);
            });
    }


    useEffect(() => {
        setLoader(true);
        handleLoadSetting();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    return (
        <div>
            <div className="card">
                <div className="card-header page-title-space mobile-card-header">
                    <h2 className="text-black font-w600 mb-0">{t('LABEL.MANAGER_CUS_TRA')}</h2>
                </div>
            </div>

            {loader === true ?
                <div>
                    <Loader open_close={loader} />
                </div>
                :
                <div className="card">
                    <div className="card-body bdr-btm-card">
                        <div className="row">
                            <div className="col-md-3">
                                <div className="form-group position-relative">
                                    <label className="mb-2 ">
                                        <span>{t('TRANSACTION.FROM_DATE')}</span>
                                    </label>
                                    <div className="col-xs-12">
                                        <input type="date" onChange={(e) => handleChangeFromDate(e.target.value)} value={FromDate} className="form-control" />
                                    </div>
                                </div>
                            </div>

                            <div className="col-md-3">
                                <div className="form-group position-relative">
                                    <label className="mb-2 ">
                                        <span>{t('TRANSACTION.TO_DATE')}</span>
                                    </label>
                                    <div className="col-xs-12">
                                        <input type="date" onChange={(e) => handleChangeToDate(e.target.value)} value={ToDate} className="form-control" />
                                    </div>
                                </div>
                            </div>
                            {SettingData.setting_value === "Yes" ?
                                <div className="col-md-6">
                                    <label className="mb-2 ">
                                        <span>{t('ACCOUNT_TRANSACTIONS.CUSTOMER')}</span>
                                    </label>
                                    <div className="form-group position-relative">
                                        <select className="form-control webkit-none" onChange={(e) => handleChangeCustmer(e.target.value)} value={Customer}>
                                            <option value="">{t('ACCOUNT_TRANSACTIONS.SELECT_ALL')}</option>
                                            {CustomerData && CustomerData.map((row) => (
                                                <option value={row.CustomerEmail} key={row.CustomerEmail}>{row.CustomerNameNEmail}</option>
                                            ))}
                                        </select>
                                        <i className="fa fa-caret-down down-arrow select-arrow1" aria-hidden="true"></i>
                                    </div>
                                </div>
                                : ""}
                        </div>
                        <div className="table-responsive">

                        </div>
                    </div>
                    <div className="card-body">
                        <div className="position-relative">
                            <div className="table-responsive">
                                {FilterData.length > 0 ?
                                    <MaterialTable
                                        title="Customer Transactions"
                                        columns={[
                                            { title: t('ACCOUNT_TRANSACTIONS.DATE'), field: 'Date' },
                                            { title: t('TRANSACTION.TRANSACTION_TYPE'), field: 'TransactionType' },
                                            { title: t('ACCOUNT_TRANSACTIONS.CUSTOMER_NAME'), field: 'CustomerName' },
                                            { title: t('LABEL.BROKER_NAME'), field: 'BrokerName', },
                                            { title: t('TRANSACTION.AMOUNT'), field: 'Amount', },
                                            { title: t('LABEL.FEES_TYPE'), field: 'FeeType', },
                                            { title: t('LABEL.FEES_FIX'), field: 'Fees', },
                                            { title: t('LABEL.TOTAL_FEES'), field: 'TotalFees' },
                                        ]}
                                        data={FilterData}
                                        options={{
                                            exportMenu: [
                                                { label: "Export PDF", exportFunc: (cols, datas) => ExportPdf(cols, datas, "Customer Transactions") },
                                                { label: "Export CSV", exportFunc: (cols, datas) => ExportCsv(cols, datas, "Customer Transactions") },
                                            ], paging: true, pageSize: 5, pageSizeOptions: [5, 10, 15], emptyRowsWhenPaging: false,
                                        }}
                                    />
                                    : <div className="d-sm-flex d-block border-0 pb-0">
                                        <div className="pr-3 mr-auto mb-sm-0 mb-3 nofoundFullWidth">
                                            <h4 className="fs-20 text-black mb-1 nofoundTitle">Customer Transactions Overview</h4>
                                            <span className="fs-12 nofoundText">No Data Found</span>
                                        </div>
                                    </div>}
                            </div>

                        </div>
                    </div>
                </div>
            }
        </div>
    )

}
export default Rewards_Transaction;