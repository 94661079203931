import React, { useState, useEffect } from "react";
import { Modal } from 'react-bootstrap';
import MaterialTable from "@material-table/core";
import { ExportCsv, ExportPdf } from "@material-table/exporters";
import { useTranslation } from "react-i18next";
import axios from 'axios';
import moment from 'moment';
import Loader from '../../pages/Loader';
import { CsvBuilder } from 'filefy';
import jsPDF from 'jspdf';
import 'jspdf-autotable';

export const Cards_Listing = () => {
    const [selectedOption, setSelectedOption] = useState(null)
    const [RequestCard, setRequestCard] = useState(false);

    const { t } = useTranslation();
    const [requestarray, setrequestarray] = useState([]);
    const [originalarray, setoriginalarray] = useState([]);
    const [loader, setLoader] = useState(false);
    const [CustomerData, setCustomerData] = useState([]);
    const [FromDate, setFromDate] = useState("");
    const [ToDate, setToDate] = useState("");
    const [Customer, setCustomer] = useState("");

    const [customername, setcustomername] = useState("");
    const [card_currancy, setcard_currancy] = useState("");
    const [cardtype, setcardtype] = useState("");
    const [account_number, setaccount_number] = useState("");
    const [exp_month, setexp_month] = useState("");
    const [exp_year, setexp_year] = useState("");
    const [international_ciruit, setinternational_ciruit] = useState("");
    const [pos_circuit, setpos_circuit] = useState("");
    const [symbole, setsymbole] = useState("");
    const [overalllimit, setoveralllimit] = useState("");
    const [contactless_enabled, setcontactless_enabled] = useState("");
    const [internet_enabled, setinternet_enabled] = useState("");
    const [withdraw_enabled, setwithdraw_enabled] = useState("");
    const [smsalert, setsmsalert] = useState("");

    const [cardbalance, setcardbalance] = useState("");
    const [dailypurchase, setdailypurchase] = useState("");
    const [dailyinternet, setdailyinternet] = useState("");
    const [dailycontactless, setdailycontactless] = useState("");
    const [dailywithdraw, setdailywithdraw] = useState("");

    const [weeklypurchase, setweeklypurchase] = useState("");
    const [weeklyinternet, setweeklyinternet] = useState("");
    const [weeklycontactless, setweeklycontactless] = useState("");
    const [weeklywithdraw, setweeklywithdraw] = useState("");

    const [monthlypurchase, setmonthlypurchase] = useState("");
    const [monthlyinternet, setmonthlyinternet] = useState("");
    const [monthlycontactless, setmonthlycontactless] = useState("");
    const [monthlywithdraw, setmonthlywithdraw] = useState("");

    const [transactionpurchase, settransactionpurchase] = useState("");
    const [transactioninternet, settransactioninternet] = useState("");
    const [transactioncontactless, settransactioncontactless] = useState("");
    const [transactionwithdraw, settransactionwithdraw] = useState("");

    const [checkuserdata, setcheckuserdata] = useState([]);

    const [AddressLine1, setAddressLine1] = useState("");
    const [AddressLine2, setAddressLine2] = useState("");
    const [PostalCode, setPostalCode] = useState("");
    const [City, setCity] = useState("");
    const [DeliveryOption, setDeliveryOption] = useState("");

    useEffect(() => {
        handleLoad();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleChangeCSVNdPDF = (val) => {
        var FileName = "Card List";

        var columns = [
            { title: 'Card Type', field: 'CardType' },
            { title: 'Account Number', field: 'AccountNumber' },
            { title: 'Customer Name', field: 'CustomerName' },
            { title: 'Card Number', field: 'CardNumber' },
        ];

        const columnTitles = columns.map(columnDef => columnDef.title);

        // const FileData = FilterData.map(rowData =>
        //     columns.map(columnDef => rowData[columnDef.field]),
        // );
        const FileData = requestarray.map(rowData =>
            [rowData["CardType"],
            rowData["AccountNumber"],
            rowData["CustomerName"],
            rowData["CardNumber"]]
        );
        if (val === "CSV") {
            const builder = new CsvBuilder(FileName + `.csv`)
                .setColumns(columnTitles)
                .addRows(FileData)
                .exportFile();

            return builder;
        }
        else if (val === "PDF") {
            const doc = new jsPDF();

            doc.autoTable({
                head: [columnTitles],
                body: FileData,
            });

            doc.save(FileName + `.pdf`);
        }
    }

    const handleLoad = () => {
        var jsonpar = {
            managerid: window.sessionStorage.getItem("manager_id"),
        }
        const headers = {
            'Content-Type': 'application/json',
            'manageraccesstoken': process.env.REACT_APP_MANAGER_ACCESS_TOKEN,
            'managerauthtoken': 'Bearer ' + window.sessionStorage.getItem("manager_jwt"),
        }
        setLoader(true);
        axios.post(process.env.REACT_APP_API_URL + 'getuserCards', jsonpar, { headers: headers })
            .then(function (res) {
                res = res.data;
                if (res.code === "200") {
                    setoriginalarray(res.data);
                    setCustomerData(res.userdata);
                    setcheckuserdata(res.checkuser);
                    var TempArr = [];
                    res.data.forEach(element => {
                        var Status = <div><button className="btn btn-outline-success btn-rounded">Active</button> </div>;
                        if (element.usercard_status === "Approved") {
                            var Status = <div><button className="btn btn-outline-success btn-rounded">Approved</button> </div>;
                        } else if (element.usercard_status === "Blocked") {
                            var Status = <div><button className="btn btn-outline-danger btn-rounded">Blocked</button> </div>;
                        } else if (element.usercard_status === "Rejected") {
                            var Status = <div><button className="btn btn-outline-danger btn-rounded">Rejected</button> </div>;
                        } else if (element.usercard_status === "Pending") {
                            var Status = <div><button className="btn btn-outline-warning btn-rounded">Pending</button> </div>;
                        }

                        if (res.checkuser.includes(element.user_id_fk)) {
                            var temp = {
                                CardType: element.cardtypename,
                                AccountNumber: element.account_number,
                                CustomerName: element.card_customer_name,
                                CardNumber: "**** **** **** " + String(element.card_number).slice(-4),
                                Status: Status,
                                StatusText: element.usercard_status,
                                Action: <div><button onClick={(e) => viewdata(element.card_id)} className="btn btn-primary btn-xs btn-rounded"><i className="fa fa-eye"></i></button> </div>
                            };
                            TempArr.push(temp);
                        }
                    });
                    setrequestarray(TempArr);
                    setTimeout(() => {
                        setLoader(false);
                    }, 1000);
                } else {
                    setLoader(false);
                }
            })
            .catch(function (error) {
                setLoader(false);
            });
    }

    const viewdata = (val) => {
        var jsonpar = {
            card_id: val,
        }
        const headers = {
            'Content-Type': 'application/json',
            'manageraccesstoken': process.env.REACT_APP_MANAGER_ACCESS_TOKEN,
            'managerauthtoken': 'Bearer ' + window.sessionStorage.getItem("manager_jwt"),
        }
        setLoader(true);
        axios.post(process.env.REACT_APP_API_URL + 'getuserCardsDetailsByID', jsonpar, { headers: headers })
            .then(function (res) {
                res = res.data;
                if (res.code === "200") {
                    setcustomername(res.data[0].card_customer_name);
                    setcard_currancy(res.data[0].card_currancy);
                    setcardtype(res.data[0].cardtypename);
                    setaccount_number(res.data[0].account_number);
                    setexp_month(res.data[0].exp_month);
                    setexp_year(res.data[0].exp_year);
                    setinternational_ciruit(res.data[0].atminternationallimit);
                    setpos_circuit(res.data[0].poscircuitlimit);
                    if (res.data[0].card_currancy === "EUR") {
                        setsymbole('€');
                    } else {
                        setsymbole('$');
                    }
                    setoveralllimit(res.data[0].overall_limits_enabled);
                    setcontactless_enabled(res.data[0].contactless_enabled);
                    setinternet_enabled(res.data[0].internet_purchase_enabled);
                    setwithdraw_enabled(res.data[0].withdrawal_enabled);
                    setsmsalert(res.data[0].smsalert);

                    setcardbalance(res.data[0].card_balance);
                    setdailypurchase(res.data[0].daily_limits.purchase);
                    setdailyinternet(res.data[0].daily_limits.internet);
                    setdailycontactless(res.data[0].daily_limits.contactless);
                    setdailywithdraw(res.data[0].daily_limits.withdraw);

                    setweeklypurchase(res.data[0].weekly_limits.purchase);
                    setweeklyinternet(res.data[0].weekly_limits.internet);
                    setweeklycontactless(res.data[0].weekly_limits.contactless);
                    setweeklywithdraw(res.data[0].weekly_limits.withdraw);

                    setmonthlypurchase(res.data[0].monthly_limits.purchase);
                    setmonthlyinternet(res.data[0].monthly_limits.internet);
                    setmonthlycontactless(res.data[0].monthly_limits.contactless);
                    setmonthlywithdraw(res.data[0].monthly_limits.withdraw);

                    settransactionpurchase(res.data[0].transaction_limits.purchase);
                    settransactioninternet(res.data[0].transaction_limits.internet);
                    settransactioncontactless(res.data[0].transaction_limits.contactless);
                    settransactionwithdraw(res.data[0].transaction_limits.withdraw);
                    setAddressLine1(res.data[0].address1);
                    setAddressLine2(res.data[0].address2);
                    setPostalCode(res.data[0].postalcode);
                    setCity(res.data[0].city);
                    setDeliveryOption(res.data[0].deliverypartner.dname);
                    setRequestCard(true);
                    setTimeout(() => {
                        setLoader(false);
                    }, 1000);
                } else {
                    setLoader(false);
                }
            })
            .catch(function (error) {
                setLoader(false);
            });

    }

    const handleChangeFromDate = (val) => {
        setFromDate(val);
        filter("1," + val, "", "");
    }
    const handleChangeToDate = (val) => {
        setToDate(val);
        filter("", "1," + val, "");
    }
    const handleChangeCustmer = (val) => {
        setCustomer(val);
        filter("", "", "1," + val);
    }


    const filter = (from_date = "", to_date = "", customer_email = "") => {
        var tempFromDate = FromDate;
        var tempToDate = ToDate;
        var tempCustomerEmail = Customer;

        if (from_date !== "") {
            if (from_date.split(",")[0] === "1" && from_date.split(",")[1] !== "") {
                tempFromDate = from_date.split(",")[1];
            }
            else if (from_date.split(",")[0] === "1" && from_date.split(",")[1] === "") {
                tempFromDate = "";
            }
        }

        if (to_date !== "") {
            if (to_date.split(",")[0] === "1" && to_date.split(",")[1] !== "") {
                tempToDate = to_date.split(",")[1];
            }
            else if (to_date.split(",")[0] === "1" && to_date.split(",")[1] === "") {
                tempToDate = "";
            }
        }

        if (customer_email !== "") {
            if (customer_email.split(",")[0] === "1" && customer_email.split(",")[1] !== "") {
                tempCustomerEmail = customer_email.split(",")[1];
            }
            else if (customer_email.split(",")[0] === "1" && customer_email.split(",")[1] === "") {
                tempCustomerEmail = "";
            }
        }

        let TempArr = [];
        originalarray.forEach(ResultData_ => {
            var Status = <div><button className="btn btn-outline-success btn-rounded">Active</button> </div>;
            if (ResultData_.usercard_status === "Approved") {
                var Status = <div><button className="btn btn-outline-success btn-rounded">Approved</button> </div>;
            } else if (ResultData_.usercard_status === "Blocked") {
                var Status = <div><button className="btn btn-outline-danger btn-rounded">Blocked</button> </div>;
            } else if (ResultData_.usercard_status === "Rejected") {
                var Status = <div><button className="btn btn-outline-danger btn-rounded">Rejected</button> </div>;
            } else if (ResultData_.usercard_status === "Pending") {
                var Status = <div><button className="btn btn-outline-warning btn-rounded">Pending</button> </div>;
            }

            var temp = {
                CardType: ResultData_.cardtypename,
                AccountNumber: ResultData_.account_number,
                CustomerName: ResultData_.card_customer_name,
                CardNumber: "**** **** **** " + String(ResultData_.card_number).slice(-4),
                Status: Status,
                Action: <div><button onClick={(e) => viewdata(ResultData_.card_id)} className="btn btn-primary btn-xs btn-rounded"><i className="fa fa-eye"></i></button> </div>
            };

            if (tempFromDate === "" && tempToDate === "" && (tempCustomerEmail !== "" && tempCustomerEmail === ResultData_.user_id_fk)) {
                if (checkuserdata.includes(ResultData_.user_id_fk)) {
                    TempArr.push(temp);
                }
            }
            else if ((tempFromDate !== "" && moment(ResultData_.created_datetime).format('YYYY-MM-DD') >= moment(tempFromDate).format('YYYY-MM-DD')) && tempToDate === "" && tempCustomerEmail === "") {
                if (checkuserdata.includes(ResultData_.user_id_fk)) {
                    TempArr.push(temp);
                }
            }
            else if (tempFromDate === "" && (tempToDate !== "" && moment(ResultData_.created_datetime).format('YYYY-MM-DD') <= moment(tempToDate).format('YYYY-MM-DD')) && tempCustomerEmail === "") {
                if (checkuserdata.includes(ResultData_.user_id_fk)) {
                    TempArr.push(temp);
                }
            }
            else if ((tempFromDate !== "" && moment(ResultData_.created_datetime).format('YYYY-MM-DD') >= moment(tempFromDate).format('YYYY-MM-DD')) && (tempToDate !== "" && moment(ResultData_.created_datetime).format('YYYY-MM-DD') <= moment(tempToDate).format('YYYY-MM-DD')) && tempCustomerEmail === "") {
                if (checkuserdata.includes(ResultData_.user_id_fk)) {
                    TempArr.push(temp);
                }
            }
            else if ((tempFromDate !== "" && moment(ResultData_.created_datetime).format('YYYY-MM-DD') >= moment(tempFromDate).format('YYYY-MM-DD')) && tempToDate === "" && (tempCustomerEmail !== "" && tempCustomerEmail === ResultData_.user_id_fk)) {
                if (checkuserdata.includes(ResultData_.user_id_fk)) {
                    TempArr.push(temp);
                }
            }
            else if (tempFromDate === "" && (tempToDate !== "" && moment(ResultData_.created_datetime).format('YYYY-MM-DD') <= moment(tempToDate).format('YYYY-MM-DD')) && (tempCustomerEmail !== "" && tempCustomerEmail === ResultData_.user_id_fk)) {
                if (checkuserdata.includes(ResultData_.user_id_fk)) {
                    TempArr.push(temp);
                }
            }
            else if ((tempFromDate !== "" && moment(ResultData_.created_datetime).format('YYYY-MM-DD') >= moment(tempFromDate).format('YYYY-MM-DD')) && (tempToDate !== "" && moment(ResultData_.created_datetime).format('YYYY-MM-DD') <= moment(tempToDate).format('YYYY-MM-DD')) && (tempCustomerEmail !== "" && tempCustomerEmail === ResultData_.user_id_fk)) {
                if (checkuserdata.includes(ResultData_.user_id_fk)) {
                    TempArr.push(temp);
                }
            }
            else if (tempFromDate === "" && tempToDate === "" && tempCustomerEmail === "") {
                if (checkuserdata.includes(ResultData_.user_id_fk)) {
                    TempArr.push(temp);
                }
            }


        });
        setrequestarray(TempArr);
    }

    return (
        <div>
            <div className="card">
                <div className="card-header page-title-space mobile-card-header">
                    <h2 className="text-black font-w600 mb-0">Cards Listing</h2>
                    <div className="mobileFullWidth">

                    </div>
                </div>
            </div>
            {loader === true ?
                <div>
                    <Loader open_close={loader} />
                </div>
                :
                <div className="card">
                    <div className="card-body bdr-btm-card">
                        <div className="row">
                            <div className="col-md-3">
                                <div className="form-group position-relative">
                                    <label className="mb-2 ">
                                        <span>From Date</span>
                                    </label>
                                    <div className="col-xs-12">
                                        <input type="date" onChange={(e) => handleChangeFromDate(e.target.value)} value={FromDate} className="form-control" />
                                    </div>
                                </div>
                            </div>

                            <div className="col-md-3">
                                <div className="form-group position-relative">
                                    <label className="mb-2 ">
                                        <span>To Date</span>
                                    </label>
                                    <div className="col-xs-12">
                                        <input type="date" onChange={(e) => handleChangeToDate(e.target.value)} value={ToDate} className="form-control" />
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6 fullWidth">
                                <label className="mb-2 ">
                                    <span>Customer Name</span>
                                </label>
                                <div className="form-group position-relative">
                                    <select className="form-control webkit-none" onChange={(e) => handleChangeCustmer(e.target.value)} value={Customer}>
                                        <option value="">{t('ACCOUNT_TRANSACTIONS.SELECT_ALL')}</option>
                                        {CustomerData && CustomerData.map((row) => (
                                            <option value={row.user_id_pk} key={row.user_id_pk}>{row.first_name + " " + row.last_name + " (" + row.email_id + ")"}</option>
                                        ))}
                                    </select>
                                    <i className="fa fa-caret-down down-arrow select-arrow1" aria-hidden="true"></i>
                                </div>
                            </div>

                        </div>
                        <div className="table-responsive">

                        </div>
                    </div>
                    <div className="card-body">
                        <div className="position-relative">
                            <div className="table-responsive">
                            {requestarray.length > 0 ?
                                <MaterialTable
                                    title="Cards List"
                                    columns={[
                                        { title: 'Card Type', field: 'CardType' },
                                        { title: 'Account Number', field: 'AccountNumber' },
                                        { title: 'Customer Name', field: 'CustomerName' },
                                        { title: 'Card Number', field: 'CardNumber' },
                                        { title: 'Action', field: 'Action' },
                                    ]}
                                    data={requestarray}
                                    options={{
                                        exportMenu: [
                                            { label: "Export PDF", exportFunc: (cols, datas) => handleChangeCSVNdPDF("PDF") },
                                            { label: "Export CSV", exportFunc: (cols, datas) => handleChangeCSVNdPDF("CSV") },
                                        ], paging: true, pageSize: 5, pageSizeOptions: [5, 10, 15], emptyRowsWhenPaging: false,
                                    }}
                                />
                                : <div className="d-sm-flex d-block border-0 pb-0">
                                <div className="pr-3 mr-auto mb-sm-0 mb-3 nofoundFullWidth">
                                    <h4 className="fs-20 text-black mb-1 nofoundTitle">Cards List Overview</h4>
                                    <span className="fs-12 nofoundText">No Data Found</span>
                                </div>
                            </div>}
                            </div>
                        </div>
                    </div>
                </div>
            }
            <Modal className="modal fade" show={RequestCard} size={"xl"}>
                <div className="modal-dialog-centered " >
                    <div className="modal-content">
                        <div className="modal-header">
                            <h3 className="modal-title">{customername} ( {cardtype} )</h3>
                            <button type="button" className="close" onClick={() => setRequestCard(false)}><span>&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <div className="row">
                                <div className="col-lg-4 col-md-6 mb-3">
                                    <p className="fs-14 mb-1">Card Type</p>
                                    <span className="text-black">{cardtype}</span>
                                </div>
                                <div className="col-lg-4 col-md-6 mb-3">
                                    <p className="fs-14 mb-1">Bank account</p>
                                    <span className="text-black">{account_number} ( {card_currancy} )</span>
                                </div>
                                <div className="col-lg-4 col-md-6 mb-3">
                                    <p className="fs-14 mb-1">Namein Card</p>
                                    <span className="text-black">{customername}</span>
                                </div>
                                <div className="col-lg-4 col-md-6 mb-3">
                                    <p className="fs-14 mb-1">Valid thru</p>
                                    <span className="text-black">{exp_month}/{exp_year}</span>
                                </div>


                                <div className="col-lg-4 col-md-6 mb-3">
                                    <p className="fs-14 mb-1">ATM International circuit</p>
                                    <span className="text-black">{symbole}{international_ciruit}</span>
                                </div>
                                <div className="col-lg-4 col-md-6 mb-3">
                                    <p className="fs-14 mb-1">POS International circuit</p>
                                    <span className="text-black">{symbole}{pos_circuit}</span>
                                </div>

                                <div className="col-lg-4 col-md-6 mb-3">
                                    <p className="fs-14 mb-1">Card Balance</p>
                                    <span className="text-black">{symbole}{cardbalance}</span>
                                </div>

                                <div className="col-lg-4 col-md-6 mb-3">
                                    <p className="fs-14 mb-1">Overall Limits</p>
                                    <span className="text-black">{overalllimit === "Yes" ? "Enabled" : "Disabled"}</span>
                                </div>

                                <div className="col-lg-4 col-md-6 mb-3">
                                    <p className="fs-14 mb-1">Contactless Enabled</p>
                                    <span className="text-black">{contactless_enabled === "Yes" ? "Enabled" : "Disabled"}</span>
                                </div>
                                <div className="col-lg-4 col-md-6 mb-3">
                                    <p className="fs-14 mb-1">Withdrawal Enabled</p>
                                    <span className="text-black">{withdraw_enabled === "Yes" ? "Enabled" : "Disabled"}</span>
                                </div>
                                <div className="col-lg-4 col-md-6 mb-3">
                                    <p className="fs-14 mb-1">Internet Purchase Enabled</p>
                                    <span className="text-black">{internet_enabled === "Yes" ? "Enabled" : "Disabled"}</span>
                                </div>
                                <div className="col-lg-4 col-md-6 mb-3">
                                    <p className="fs-14 mb-1">SMS Alert</p>
                                    <span className="text-black">{smsalert === "Yes" ? "Enabled" : "Disabled"}</span>
                                </div>

                                <div className="col-lg-4 col-md-6 mb-3">
                                    <p className="fs-14 mb-1">Daily Purchase</p>
                                    <span className="text-black">{symbole}{dailypurchase}</span>
                                </div>
                                <div className="col-lg-4 col-md-6 mb-3">
                                    <p className="fs-14 mb-1">Daily Internet</p>
                                    <span className="text-black">{symbole}{dailyinternet}</span>
                                </div>
                                <div className="col-lg-4 col-md-6 mb-3">
                                    <p className="fs-14 mb-1">Daily Withdraw</p>
                                    <span className="text-black">{symbole}{dailywithdraw}</span>
                                </div>
                                <div className="col-lg-4 col-md-6 mb-3">
                                    <p className="fs-14 mb-1">Daily Contactless</p>
                                    <span className="text-black">{symbole}{dailycontactless}</span>
                                </div>

                                <div className="col-lg-4 col-md-6 mb-3">
                                    <p className="fs-14 mb-1">Weekly Purchase</p>
                                    <span className="text-black">{symbole}{weeklypurchase}</span>
                                </div>
                                <div className="col-lg-4 col-md-6 mb-3">
                                    <p className="fs-14 mb-1">Weekly Internet</p>
                                    <span className="text-black">{symbole}{weeklyinternet}</span>
                                </div>
                                <div className="col-lg-4 col-md-6 mb-3">
                                    <p className="fs-14 mb-1">Weekly Withdraw</p>
                                    <span className="text-black">{symbole}{weeklywithdraw}</span>
                                </div>
                                <div className="col-lg-4 col-md-6 mb-3">
                                    <p className="fs-14 mb-1">Weekly Contactless</p>
                                    <span className="text-black">{symbole}{weeklycontactless}</span>
                                </div>

                                <div className="col-lg-4 col-md-6 mb-3">
                                    <p className="fs-14 mb-1">Monthly Purchase</p>
                                    <span className="text-black">{symbole}{monthlypurchase}</span>
                                </div>
                                <div className="col-lg-4 col-md-6 mb-3">
                                    <p className="fs-14 mb-1">Monthly Internet</p>
                                    <span className="text-black">{symbole}{monthlyinternet}</span>
                                </div>
                                <div className="col-lg-4 col-md-6 mb-3">
                                    <p className="fs-14 mb-1">Monthly Withdraw</p>
                                    <span className="text-black">{symbole}{monthlywithdraw}</span>
                                </div>
                                <div className="col-lg-4 col-md-6 mb-3">
                                    <p className="fs-14 mb-1">Monthly Contactless</p>
                                    <span className="text-black">{symbole}{monthlycontactless}</span>
                                </div>

                                <div className="col-lg-4 col-md-6 mb-3">
                                    <p className="fs-14 mb-1">Transaction Purchase</p>
                                    <span className="text-black">{symbole}{transactionpurchase}</span>
                                </div>
                                <div className="col-lg-4 col-md-6 mb-3">
                                    <p className="fs-14 mb-1">Transaction Internet</p>
                                    <span className="text-black">{symbole}{transactioninternet}</span>
                                </div>
                                <div className="col-lg-4 col-md-6 mb-3">
                                    <p className="fs-14 mb-1">Transaction Withdraw</p>
                                    <span className="text-black">{symbole}{transactionwithdraw}</span>
                                </div>
                                <div className="col-lg-4 col-md-6 mb-3">
                                    <p className="fs-14 mb-1">Transaction Contactless</p>
                                    <span className="text-black">{symbole}{transactioncontactless}</span>
                                </div>
                                <div className="col-lg-4 col-md-6 mb-3">
                                    <p className="fs-14 mb-1">Address Line 1</p>
                                    <span className="text-black">{AddressLine1}</span>
                                </div>
                                <div className="col-lg-4 col-md-6 mb-3">
                                    <p className="fs-14 mb-1">Address Line 2</p>
                                    <span className="text-black">{AddressLine2}</span>
                                </div>
                                <div className="col-lg-4 col-md-6 mb-3">
                                    <p className="fs-14 mb-1">Postal Code</p>
                                    <span className="text-black">{PostalCode}</span>
                                </div>
                                <div className="col-lg-4 col-md-6 mb-3">
                                    <p className="fs-14 mb-1">City</p>
                                    <span className="text-black">{City}</span>
                                </div>
                                <div className="col-lg-4 col-md-6 mb-3">
                                    <p className="fs-14 mb-1">Delivery Option</p>
                                    <span className="text-black">{DeliveryOption}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>
        </div>
    )

}
export default Cards_Listing;