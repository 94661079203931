import React, { useState, useEffect } from "react";
import MaterialTable from "@material-table/core";
import { ExportCsv, ExportPdf } from "@material-table/exporters";
import { Modal } from 'react-bootstrap';
import Snackbars from '../../../jsx/pages/Snackbars';
import { useTranslation } from "react-i18next";
import axios from 'axios';
import moment from 'moment';
import Loader from '../../pages/Loader';
import validator from 'validator';


export const Rewards_Fees_Earned = () => {
    const { t } = useTranslation();
    const [loader, setLoader] = useState(false);
    const [error, setError] = useState(false);
    const [success, setSuccess] = useState(false);
    const [error_msg, setErrorMsg] = useState("");
    const [success_msg, setSuccessMsg] = useState("");
    const [SettingData, setSettingData] = useState([]);
    const [ResultData, setResultData] = useState([]);
    const [FilterData, setFilterData] = useState([]);
    const [CustomerData, setCustomerData] = useState([]);
    const [Customer, setCustomer] = useState("");
    const [FromDate, setFromDate] = useState("");
    const [ToDate, setToDate] = useState("");
    const [TotalReward, setTotalReward] = useState("");
    const [USDAccountBalance, setUSDAccountBalance] = useState("");
    const [EUROAccountBalance, setEUROAccountBalance] = useState("");


    const [ManagerRewardID, setManagerRewardID] = useState("");
    const [TableType, setTableType] = useState("");
    const [UserID, setUserID] = useState("");
    const [AccountID, setAccountID] = useState("");
    const [DisplayTotalAmount, setDisplayTotalAmount] = useState("");
    const [TotalAmount, setTotalAmount] = useState("");
    const [CoinCode, setCoinCode] = useState("");
    const [WithdrawalAmount, setWithdrawalAmount] = useState("");
    const [WithdrawalAmountError, setWithdrawalAmountError] = useState("");
    const [Modal1, setModal1] = useState(false);
    const openModal1 = (element) => {
        setWithdrawalAmount("");
        setWithdrawalAmountError("");
        setManagerRewardID(element.manager_fiatreward_id);
        setTableType("fiat");
        setUserID(element.user_id_fk);
        setAccountID(element.account_id_pk);
        setDisplayTotalAmount(element.fiat_currancy_symbole + element.reward);
        setTotalAmount(element.reward);
        setCoinCode(element.coin_code);
        setModal1(true);
    }
    const openModal2 = (element) => {
        setWithdrawalAmount("");
        setWithdrawalAmountError("");
        setManagerRewardID(element.manager_reward_id);
        setTableType("crypto");
        setUserID(element.user_id_fk);
        setAccountID(element.account_id_pk);
        setDisplayTotalAmount(element.reward + " " + element.coin_code);
        setTotalAmount(element.reward);
        setCoinCode(element.coin_code);
        setModal1(true);
    }
    const FieldRequiredError = t('LABEL.FIELD_REQUIERD');
    const validateWithdrawalAmount = (value) => {

        var checkTotalAmount = 0;
        ManagerWithdrawalRequestData.forEach(element => {
            if (element.manager_reward_id === ManagerRewardID && element.manager_withdraw_status === "Pending") {
                checkTotalAmount = checkTotalAmount + parseFloat(element.withdraw_amount);
            }
        });

        var total = parseFloat(TotalAmount) - parseFloat(checkTotalAmount);
        var display = calc(total);
        setWithdrawalAmount(value);
        if (validator.isEmpty(value)) {
            setWithdrawalAmountError(FieldRequiredError);
            return false;
        } else if (parseFloat(value) > parseFloat(display)) {
            setWithdrawalAmountError("You are withdraw maximum " + display + " Amount.");
            return false;
        }
        else if (!validator.isFloat(value)) {
            setWithdrawalAmountError(t('LABEL.INVALID_WITH'));
            return false;
        } else if (parseFloat(value) > parseFloat(TotalAmount)) {
            setWithdrawalAmountError(t('LABEL.GRETER_WITH'));
            return false;
        } else {
            setWithdrawalAmountError("");
            return true;
        }
    }
    function calc(theform) {
        var num = theform, rounded = theform.rounded
        var with2Decimals = num.toString().match(/^-?\d+(?:\.\d{0,2})?/)[0]
        return with2Decimals;
    }
    const handlesubmit = () => {
        if (!validateWithdrawalAmount(WithdrawalAmount)) {
            return false;
        } else {
            if (WithdrawalAmount <= 0) {
                setWithdrawalAmountError("Withdrawal amount is greater than 0.");
                return false;
            }
            setModal1(false);
            setLoader(true);

            var jsonpar = JSON.stringify({
                'manager_id': window.sessionStorage.getItem("manager_id"),
                'manager_reward_id': ManagerRewardID,
                'table_type': TableType,
                'user_id': UserID,
                'account_id': AccountID,
                'withdraw_amount': WithdrawalAmount,
            });
            const headers = {
                'Content-Type': 'application/json',
                'manageraccesstoken': process.env.REACT_APP_MANAGER_ACCESS_TOKEN,
                'managerauthtoken': 'Bearer ' + window.sessionStorage.getItem("manager_jwt"),
            }
            axios.post(process.env.REACT_APP_API_URL + 'addManagerWithdrawalRequest2', jsonpar, {
                headers: headers
            })
                .then(function (res) {
                    res = res.data;
                    setLoader(false);

                    if (res.code === "200") {
                        setWithdrawalAmount("");
                        setWithdrawalAmountError("");
                        handleLoadGetAllManagerWithdrawalRequest();

                        setSuccess(true);
                        setSuccessMsg(res.message);
                        setTimeout(() => {
                            setSuccess(false);
                        }, 6000);
                    } else {
                        setWithdrawalAmount("");
                        setWithdrawalAmountError("");

                        setError(true);
                        setErrorMsg(res.message);
                        setTimeout(() => {
                            setError(false);
                        }, 6000);
                    }

                })
                .catch(function (error) {
                    setWithdrawalAmount("");
                    setWithdrawalAmountError("");
                    setLoader(false);

                    setError(true);
                    setErrorMsg(t('ERROR_MSG.SOMETHING'));
                    setTimeout(() => {
                        setError(false);
                    }, 3000);
                });
        }
    }


    const handleChangeFromDate = (val) => {
        setFromDate(val);
        filter("1," + val, "", "");
    }
    const handleChangeToDate = (val) => {
        setToDate(val);
        filter("", "1," + val, "");
    }
    const handleChangeCustmer = (val) => {
        setCustomer(val);
        filter("", "", "1," + val);
    }
    const filter = (from_date = "", to_date = "", customer_email = "") => {
        var tempFromDate = FromDate;
        var tempToDate = ToDate;
        var tempCustomerEmail = Customer;

        if (from_date !== "") {
            if (from_date.split(",")[0] === "1" && from_date.split(",")[1] !== "") {
                tempFromDate = from_date.split(",")[1];
            }
            else if (from_date.split(",")[0] === "1" && from_date.split(",")[1] === "") {
                tempFromDate = "";
            }
        }

        if (to_date !== "") {
            if (to_date.split(",")[0] === "1" && to_date.split(",")[1] !== "") {
                tempToDate = to_date.split(",")[1];
            }
            else if (to_date.split(",")[0] === "1" && to_date.split(",")[1] === "") {
                tempToDate = "";
            }
        }

        if (customer_email !== "") {
            if (customer_email.split(",")[0] === "1" && customer_email.split(",")[1] !== "") {
                tempCustomerEmail = customer_email.split(",")[1];
            }
            else if (customer_email.split(",")[0] === "1" && customer_email.split(",")[1] === "") {
                tempCustomerEmail = "";
            }
        }

        let TempArr = [];
        ResultData.forEach(ResultData_ => {
            if (tempFromDate === "" && tempToDate === "" && (tempCustomerEmail !== "" && tempCustomerEmail === ResultData_.CustomerEmail)) {
                TempArr.push(ResultData_);
            }
            else if ((tempFromDate !== "" && moment(ResultData_.created_datetime).format('YYYY-MM-DD') >= moment(tempFromDate).format('YYYY-MM-DD')) && tempToDate === "" && tempCustomerEmail === "") {
                TempArr.push(ResultData_);
            }
            else if (tempFromDate === "" && (tempToDate !== "" && moment(ResultData_.created_datetime).format('YYYY-MM-DD') <= moment(tempToDate).format('YYYY-MM-DD')) && tempCustomerEmail === "") {
                TempArr.push(ResultData_);
            }
            else if ((tempFromDate !== "" && moment(ResultData_.created_datetime).format('YYYY-MM-DD') >= moment(tempFromDate).format('YYYY-MM-DD')) && (tempToDate !== "" && moment(ResultData_.created_datetime).format('YYYY-MM-DD') <= moment(tempToDate).format('YYYY-MM-DD')) && tempCustomerEmail === "") {
                TempArr.push(ResultData_);
            }
            else if ((tempFromDate !== "" && moment(ResultData_.created_datetime).format('YYYY-MM-DD') >= moment(tempFromDate).format('YYYY-MM-DD')) && tempToDate === "" && (tempCustomerEmail !== "" && tempCustomerEmail === ResultData_.CustomerEmail)) {
                TempArr.push(ResultData_);
            }
            else if (tempFromDate === "" && (tempToDate !== "" && moment(ResultData_.created_datetime).format('YYYY-MM-DD') <= moment(tempToDate).format('YYYY-MM-DD')) && (tempCustomerEmail !== "" && tempCustomerEmail === ResultData_.CustomerEmail)) {
                TempArr.push(ResultData_);
            }
            else if ((tempFromDate !== "" && moment(ResultData_.created_datetime).format('YYYY-MM-DD') >= moment(tempFromDate).format('YYYY-MM-DD')) && (tempToDate !== "" && moment(ResultData_.created_datetime).format('YYYY-MM-DD') <= moment(tempToDate).format('YYYY-MM-DD')) && (tempCustomerEmail !== "" && tempCustomerEmail === ResultData_.CustomerEmail)) {
                TempArr.push(ResultData_);
            }
            else if (tempFromDate === "" && tempToDate === "" && tempCustomerEmail === "") {
                TempArr.push(ResultData_);
            }
        });
        setFilterData(TempArr);
    }

    const handleLoad = (Setting_Data = []) => {
        let SettingDataTemp = Setting_Data;
        if (Setting_Data.length === 0) {
            SettingDataTemp = SettingData;
        }

        var jsonpar = JSON.stringify({
            'manager_id': window.sessionStorage.getItem("manager_id")
        });
        const headers = {
            'Content-Type': 'application/json',
            'manageraccesstoken': process.env.REACT_APP_MANAGER_ACCESS_TOKEN,
            'managerauthtoken': 'Bearer ' + window.sessionStorage.getItem("manager_jwt"),
        }
        axios.post(process.env.REACT_APP_API_URL + 'getAllManagerRewardsTransaction', jsonpar, {
            headers: headers
        })
            .then(function (res) {
                res = res.data;
                if (res.code === "200") {
                    setTotalReward("$" + res.data1[0].total_reward);
                    res.data1.forEach(element => {
                        switch (element.user_acc_account_currency_type) {
                            case "EUR":
                                setEUROAccountBalance("€" + element.user_acc_account_balance);
                                break;

                            default:
                                setUSDAccountBalance("$" + element.user_acc_account_balance);
                                break;
                        }
                    });


                    let CustomerEmailTempArr = [];
                    let CustomerDataTempArr = [];
                    let TempArr = [];
                    res.data.forEach(element => {
                        if (!CustomerEmailTempArr.includes(element.user_email_id)) {
                            var CustomerTempArr = {
                                CustomerNameNEmail: element.user_first_name + " " + element.user_last_name + " (" + element.user_email_id + ")",
                                CustomerEmail: element.user_email_id,
                            }
                            CustomerDataTempArr.push(CustomerTempArr);
                            CustomerEmailTempArr.push(element.user_email_id);
                        }

                        let BrokerName = "-";
                        if (element.broker_first_name !== null && element.broker_last_name !== null) {
                            BrokerName = element.broker_first_name + " " + element.broker_last_name;
                        }

                        let CustomerName = element.user_first_name + " " + element.user_last_name;
                        let CustomerEmail = element.user_email_id;
                        if (SettingDataTemp.setting_value === "No") {
                            CustomerName = "*****";
                            CustomerEmail = "*****";
                            BrokerName = "*****";
                        }

                        let temp = {
                            No: element.manager_earn_id,
                            Date: moment(element.created_datetime).format('MM / DD / YYYY hh:mm:ss'),
                            CustomerName: CustomerName,
                            CustomerEmail: CustomerEmail,
                            BrokerName: BrokerName,
                            TotalReward: element.total_reward + " " + element.coin_code,
                            TransactionReward: element.fees_percentage + "%",
                            TotalFees: element.fees + " " + element.coin_code,
                            created_datetime: element.created_datetime
                        }
                        TempArr.push(temp);
                    });
                    setResultData(TempArr);
                    setFilterData(TempArr);
                    setCustomerData(CustomerDataTempArr);

                    setTimeout(() => {
                        setLoader(false);
                    }, 500);
                } else {
                    setLoader(false);
                }
            })
            .catch(function (error) {
                setLoader(false);
            });
    }

    const handleLoadSetting = () => {
        var jsonpar = JSON.stringify({
            'setting_id': 1
        });
        const headers = {
            'Content-Type': 'application/json',
            'manageraccesstoken': process.env.REACT_APP_MANAGER_ACCESS_TOKEN,
            'managerauthtoken': 'Bearer ' + window.sessionStorage.getItem("manager_jwt"),
        }
        axios.post(process.env.REACT_APP_API_URL + 'getSettgingInformation', jsonpar, {
            headers: headers
        })
            .then(function (res) {
                res = res.data;
                if (res.code === "200") {
                    setSettingData(res.data);
                    handleLoad(res.data);

                    setTimeout(() => {
                        setLoader(false);
                    }, 500);
                } else {
                    handleLoad();
                    setLoader(false);
                }
            })
            .catch(function (error) {
                setLoader(false);
            });
    }

    const [ManagerFiatRewardData, setManagerFiatRewardData] = useState([]);
    const handleLoadGetManagerFiatReward = () => {
        var jsonpar = JSON.stringify({
            'manager_id': window.sessionStorage.getItem("manager_id")
        });
        const headers = {
            'Content-Type': 'application/json',
            'manageraccesstoken': process.env.REACT_APP_MANAGER_ACCESS_TOKEN,
            'managerauthtoken': 'Bearer ' + window.sessionStorage.getItem("manager_jwt"),
        }
        axios.post(process.env.REACT_APP_API_URL + 'getManagerFiatReward', jsonpar, {
            headers: headers
        })
            .then(function (res) {
                res = res.data;
                if (res.code === "200") {
                    let TempArr = [];
                    res.data.forEach(element => {
                        var ActionBTN = <div>
                            <button className="btn btn-primary shadow btn-xs edit-shape" title="Create withdraw request" onClick={(e) => openModal1(element)}>Withdraw</button>
                        </div>;

                        let temp = {
                            No: element.manager_fiatreward_id,
                            Currancy: element.coin_code,
                            TotalReward: element.fiat_currancy_symbole + element.reward,
                            Balance: element.fiat_currancy_symbole + element.balance,
                            Action: ActionBTN,
                        }
                        TempArr.push(temp);
                    });
                    setManagerFiatRewardData(TempArr);

                    setTimeout(() => {
                        setLoader(false);
                    }, 500);
                } else {
                    // setLoader(false);
                }
            })
            .catch(function (error) {
                // setLoader(false);
            });
    }

    const [ManagerCryptoRewardData, setManagerCryptoRewardData] = useState([]);
    const handleLoadGetManagerCryptoReward = () => {
        var jsonpar = JSON.stringify({
            'manager_id': window.sessionStorage.getItem("manager_id")
        });
        const headers = {
            'Content-Type': 'application/json',
            'manageraccesstoken': process.env.REACT_APP_MANAGER_ACCESS_TOKEN,
            'managerauthtoken': 'Bearer ' + window.sessionStorage.getItem("manager_jwt"),
        }
        axios.post(process.env.REACT_APP_API_URL + 'getManagerCryptoReward', jsonpar, {
            headers: headers
        })
            .then(function (res) {
                res = res.data;
                if (res.code === "200") {
                    let TempArr = [];
                    res.data.forEach(element => {
                        var ActionBTN = <div>
                            <button className="btn btn-primary shadow btn-xs edit-shape" title="Create withdraw request" onClick={(e) => openModal2(element)}>Withdraw</button>
                        </div>;

                        let temp = {
                            No: element.manager_reward_id,
                            Currancy: element.coin_code,
                            TotalReward: element.reward + " " + element.coin_code,
                            Balance: element.balance + " " + element.coin_code,
                            Action: ActionBTN,
                        }
                        TempArr.push(temp);
                    });
                    setManagerCryptoRewardData(TempArr);

                    setTimeout(() => {
                        setLoader(false);
                    }, 500);
                } else {
                    // setLoader(false);
                }
            })
            .catch(function (error) {
                // setLoader(false);
            });
    }

    const [ManagerWithdrawalRequestData, setManagerWithdrawalRequestData] = useState([]);
    const handleLoadGetAllManagerWithdrawalRequest = () => {
        var jsonpar = JSON.stringify({
            'manager_id': window.sessionStorage.getItem("manager_id")
        });
        const headers = {
            'Content-Type': 'application/json',
            'manageraccesstoken': process.env.REACT_APP_MANAGER_ACCESS_TOKEN,
            'managerauthtoken': 'Bearer ' + window.sessionStorage.getItem("manager_jwt"),
        }
        axios.post(process.env.REACT_APP_API_URL + 'getAllManagerWithdrawalRequest', jsonpar, {
            headers: headers
        })
            .then(function (res) {
                res = res.data;
                if (res.code === "200") {
                    setManagerWithdrawalRequestData(res.data);

                    setTimeout(() => {
                        setLoader(false);
                    }, 500);
                } else {
                    // setLoader(false);
                }
            })
            .catch(function (error) {
                // setLoader(false);
            });
    }

    useEffect(() => {
        setLoader(true);
        handleLoadSetting();
        handleLoadGetManagerFiatReward();
        handleLoadGetManagerCryptoReward();
        handleLoadGetAllManagerWithdrawalRequest();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    return (
        <div>
            <div className="card">
                <div className="card-header page-title-space mobile-card-header">
                    <h2 className="text-black font-w600 mb-0">{t('LABEL.EARNED_FEES')}</h2>
                </div>
            </div>

            {error ? <Snackbars open_close={error} type="error" message={error_msg} /> : ""}
            {success ? <Snackbars open_close={success} type="success" message={success_msg} /> : ""}


            <Modal className="modal fade" show={Modal1} size="lg">
                <div className="modal-dialog-centered " >
                    <div className="modal-content">
                        <div className="modal-header">
                            <h3 className="modal-title">{t('LABEL.WITH_REQ')}</h3>
                            <button type="button" className="close" onClick={() => setModal1(false)}><span>&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <div className="row">
                                <div className="col-md-12">
                                    <div class="card-body available-balance">
                                        <div class="media align-items-center invoice-card">
                                            <div class="media-body">
                                                <h2 class="fs-38 text-black font-w600">{DisplayTotalAmount}</h2>
                                                <span class="fs-18">{t('LABEL.TOTAL_AMOUNT')}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-12">
                                    <div className="form-group">
                                        <label className="mb-2 ">{t('LABEL.WITH_AMOUNT')}</label>
                                        <input type="number" value={WithdrawalAmount} onChange={(e) => validateWithdrawalAmount(e.target.value)} className="form-control text-black" placeholder={t('LABEL.ENTER_WITH_AMOUNT')} />
                                        {WithdrawalAmountError !== "" ? <label className='text-red'>{WithdrawalAmountError}</label> : ""}
                                    </div>
                                    {/* <p class="WNote">{t('LABEL.WITH_NOTE')}</p> */}
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-light btn-rounded light" onClick={() => setModal1(false)}>{t('BUTTONS.CANCEL')}</button>
                            <button type="button" className="btn btn-primary btn-rounded" onClick={handlesubmit}>{t('LABEL.SEND_REQ')}</button>
                        </div>
                    </div>
                </div>
            </Modal>

            {loader === true ?
                <div>
                    <Loader open_close={loader} />
                </div>
                :
                <div>
                    {/* <div className="row">

                        <div className="col-xl-4 col-sm-6">
                            <div className="card">
                                <div className="card-body">
                                    <div className="media align-items-center invoice-card">
                                        <div className="media-body">
                                            <h2 className="fs-38 text-black font-w600">{TotalReward}</h2>
                                            <span className="fs-18">{t('LABEL.TOTAL_REWARD')}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-xl-4 col-sm-6">
                            <div className="card">
                                <div className="card-body">
                                    <div className="media align-items-center invoice-card">
                                        <div className="media-body">
                                            <h2 className="fs-38 text-black font-w600">{USDAccountBalance}</h2>
                                            <span className="fs-18">{t('LABEL.USD_ACC_BALANCE')}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-xl-4 col-sm-6">
                            <div className="card">
                                <div className="card-body">
                                    <div className="media align-items-center invoice-card">
                                        <div className="media-body">
                                            <h2 className="fs-38 text-black font-w600">{EUROAccountBalance}</h2>
                                            <span className="fs-18">{t('LABEL.EUR_ACC_BALANCE')}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div> */}

                    <div className="card">
                        <div className="card-body">
                            <div className="position-relative">
                                <div className="table-responsive">
                                {ManagerFiatRewardData.length > 0 ?
                                    <MaterialTable
                                        title="Fiat Rewards"
                                        options={{ search: false, exportButton: false, paging: false, pageSize: 5, pageSizeOptions: [5, 10, 15], emptyRowsWhenPaging: false, }}
                                        columns={[
                                            { title: t('KYC.ACTION'), field: 'Action' },
                                            { title: 'Currancy', field: 'Currancy' },
                                            { title: t('LABEL.TOTAL_REWARD'), field: 'TotalReward' },
                                            { title: "Account Balance", field: 'Balance' },
                                        ]}
                                        data={ManagerFiatRewardData}
                                    />
                                    : <div className="d-sm-flex d-block border-0 pb-0">
                                    <div className="pr-3 mr-auto mb-sm-0 mb-3 nofoundFullWidth">
                                        <h4 className="fs-20 text-black mb-1 nofoundTitle">Fiat Rewards List Overview</h4>
                                        <span className="fs-12 nofoundText">No Data Found</span>
                                    </div>
                                </div>}
                                </div>

                            </div>
                        </div>
                    </div>

                    <div className="card">
                        <div className="card-body">
                            <div className="position-relative">
                                <div className="table-responsive">
                                    <MaterialTable
                                        title="Crypto Rewards"
                                        options={{ search: false, exportButton: false, paging: false, pageSize: 5, pageSizeOptions: [5, 10, 15], emptyRowsWhenPaging: false, }}
                                        columns={[
                                            { title: t('KYC.ACTION'), field: 'Action' },
                                            { title: 'Currancy', field: 'Currancy' },
                                            { title: t('LABEL.TOTAL_REWARD'), field: 'TotalReward' },
                                            { title: "Wallet Balance", field: 'Balance' },
                                        ]}
                                        data={ManagerCryptoRewardData}
                                    />
                                </div>

                            </div>
                        </div>
                    </div>

                    <div className="card">
                        <div className="card-body bdr-btm-card">
                            <div className="row">
                                <div className="col-md-3">
                                    <div className="form-group position-relative">
                                        <label className="mb-2 ">
                                            <span>{t('TRANSACTION.FROM_DATE')}</span>
                                        </label>
                                        <div className="col-xs-12">
                                            <input type="date" onChange={(e) => handleChangeFromDate(e.target.value)} value={FromDate} className="form-control" />
                                        </div>
                                    </div>
                                </div>

                                <div className="col-md-3">
                                    <div className="form-group position-relative">
                                        <label className="mb-2 ">
                                            <span>{t('TRANSACTION.TO_DATE')}</span>
                                        </label>
                                        <div className="col-xs-12">
                                            <input type="date" onChange={(e) => handleChangeToDate(e.target.value)} value={ToDate} className="form-control" />
                                        </div>
                                    </div>
                                </div>
                                {SettingData.setting_value === "Yes" ?
                                    <div className="col-md-6">
                                        <label className="mb-2">
                                            <span>{t('HEADER.CUS_NAME')}</span>
                                        </label>
                                        <div className="form-group position-relative">
                                            <select className="form-control webkit-none" onChange={(e) => handleChangeCustmer(e.target.value)} value={Customer}>
                                                <option value="">{t('ACCOUNT_TRANSACTIONS.SELECT_ALL')}</option>
                                                {CustomerData && CustomerData.map((row) => (
                                                    <option value={row.CustomerEmail} key={row.CustomerEmail}>{row.CustomerNameNEmail}</option>
                                                ))}
                                            </select>
                                            <i className="fa fa-caret-down down-arrow" aria-hidden="true"></i>
                                        </div>
                                    </div>
                                    : ""}
                            </div>
                            <div className="table-responsive">

                            </div>
                        </div>
                        <div className="card-body">
                            <div className="position-relative">
                                <div className="table-responsive">
                                    <MaterialTable
                                        title="Fees Earned Transactions"
                                        columns={[
                                            // { title: t('LABEL.NOA'), field: 'No', },
                                            { title: t('ACCOUNT_TRANSACTIONS.DATE'), field: 'Date' },
                                            { title: t('HEADER.CUS_NAME'), field: 'CustomerName' },
                                            { title: t('LABEL.BROKER_NAME'), field: 'BrokerName' },
                                            { title: t('LABEL.TRANSCT_REWARD'), field: 'TransactionReward' },
                                            { title: t('LABEL.TOTAL_FEES'), field: 'TotalFees' },
                                            { title: t('LABEL.TOTAL_REWARD'), field: 'TotalReward' },
                                        ]}
                                        data={FilterData}
                                        options={{
                                            exportMenu: [
                                                { label: "Export PDF", exportFunc: (cols, datas) => ExportPdf(cols, datas, "Fees Earned Transactions") },
                                                { label: "Export CSV", exportFunc: (cols, datas) => ExportCsv(cols, datas, "Fees Earned Transactions") },
                                            ], paging: true, pageSize: 5, pageSizeOptions: [5, 10, 15], emptyRowsWhenPaging: false,
                                        }}
                                    />
                                </div>

                            </div>
                        </div>
                    </div>



                </div>
            }
        </div>
    )

}
export default Rewards_Fees_Earned;