import React, { useState, Fragment, useEffect } from "react";
import loadable from "@loadable/component";
import { Link } from 'react-router-dom';
import Select from 'react-select';
import { DatePicker } from "@y0c/react-datepicker";
import pMinDelay from "p-min-delay";
import TransferBarGraph from './../Mophy/Dashboard/TransferBarGraph';
import { CopyToClipboard } from "react-copy-to-clipboard";
import MaterialTable from "@material-table/core";
import { ExportCsv, ExportPdf } from "@material-table/exporters";
import axios from 'axios';
import moment from 'moment';
import Loader from '../../pages/Loader';
import { useTranslation } from "react-i18next";



const USDIncome = loadable(() =>
	pMinDelay(import("../Mophy/Dashboard/USDIncome"), 1000)
);

const EURIncome = loadable(() =>
	pMinDelay(import("../Mophy/Dashboard/EURIncome"), 1000)
);

const USDOutcome = loadable(() =>
	pMinDelay(import("../Mophy/Dashboard/USDOutcome"), 1000)
);

const EUROutcome = loadable(() =>
	pMinDelay(import("../Mophy/Dashboard/EUROutcome"), 1000)
);

const options = [
	{ value: 'All', label: 'All' },
	{ value: 'ThisWeek', label: 'This Week' },
	{ value: 'ThisMonth', label: 'This Month' },
	{ value: 'This3Months', label: 'Last 3 Months' },
	{ value: 'CustomPerformance', label: 'Custom Performance' },
]

const colourStyles = {
	menuList: styles => ({
		...styles,
		background: '#ffffff',
	}),
	option: (styles, { isFocused, isSelected }) => ({
		...styles,
		background: isFocused
			? '#0091F9'
			: isSelected
				? '#0091F9'
				: undefined,
		zIndex: 1,
		color: isFocused
			? '#fff'
			: isSelected
				? '#fff'
				: undefined,
	}),
	menu: base => ({
		...base,
		zIndex: 100,
	}),
}


const Home = () => {
	const { t } = useTranslation();
	const [loader, setLoader] = useState(false);
	const [SettingData, setSettingData] = useState([]);
	const [FilterData, setFilterData] = useState([]);
	const [TotalCustomer, setTotalCustomer] = useState("");
	const [TotalReward, setTotalReward] = useState("");
	const [USDAccountBalance, setUSDAccountBalance] = useState("");
	// const [EUROAccountBalance, setEUROAccountBalance] = useState("");
	const [ShareLink, setShareLink] = useState("");
	const [fiatrewards_array, setfiatrewards_array] = useState([]);
	const [cryptorewards_array, setcryptorewards_array] = useState([]);

	const [UsdIncome, setUsdIncome] = useState(0);
	const [UsdOutcome, setUsdOutcome] = useState(0);
	const [EurIncome, setEurIncome] = useState(0);
	const [EurOutcome, setEurOutcome] = useState(0);

	const [UsdIncome_chartdata, setUsdIncome_chartdata] = useState();
	const [UsdOutcome_chartdata, setUsdOutcome_chartdata] = useState();
	const [EurIncome_chartdata, setEurIncome_chartdata] = useState();
	const [EurOutcome_chartdata, setEurOutcome_chartdata] = useState();


	const [userscheckarray, setuserscheckarray] = useState([]);
	const [activeuserscheckarray, setactiveuserscheckarray] = useState([]);
	const [kyccheckarray, setkyccheckarray] = useState([]);
	const [transactioncheckarray, settransactioncheckarray] = useState([]);

	const [FromDate, setFromDate] = useState("");
	const [ToDate, setToDate] = useState("");


	const [counteroneshow, setcounteroneshow] = useState(0);
	const [countertwoshow, setcountertwoshow] = useState(0);
	const [counterthreeshow, setcounterthreeshow] = useState(0);
	const [counterfourshow, setcounterfourshow] = useState(0);
	const [counterfiveshow, setcounterfiveshow] = useState(0);
	const [countersixshow, setcountersixshow] = useState(0);
	const [countersevenshow, setcountersevenshow] = useState(0);


	const [euroincomeshow, seteuroincomeshow] = useState(0);
	const [eurooutcomeshow, seteurooutcomeshow] = useState(0);
	const [usdincomeshow, setusdincomeshow] = useState(0);
	const [usdoutcomeshow, setusdoutcomeshow] = useState(0);


	const handleChangeFromDate = (val) => {
		setFromDate(val);
		filterdd(val, ToDate);
	}
	const handleChangeToDate = (val) => {
		setToDate(val);
		filterdd(FromDate, val);
	}

	const filterdd = (from, to) => {
		var newusercounter = 0;
		var activeusercounter = 0;
		var kyccounter = 0;
		var fiattransactioncounter = 0;
		var cryptotransactioncounter = 0;

		userscheckarray.forEach(ResultData_ => {
			if (moment(ResultData_.created_datetime).format('YYYY-MM-DD') >= moment(from).format('YYYY-MM-DD') && moment(ResultData_.created_datetime).format('YYYY-MM-DD') <= moment(to).format('YYYY-MM-DD')) {
				newusercounter++;
			}
		});

		activeuserscheckarray.forEach(ResultData_ => {
			if (moment(ResultData_.created_datetime).format('YYYY-MM-DD') >= moment(from).format('YYYY-MM-DD') && moment(ResultData_.created_datetime).format('YYYY-MM-DD') <= moment(to).format('YYYY-MM-DD')) {
				activeusercounter++;
			}
		});

		kyccheckarray.forEach(ResultData_ => {
			if (moment(ResultData_.created_datetime).format('YYYY-MM-DD') >= moment(from).format('YYYY-MM-DD') && moment(ResultData_.created_datetime).format('YYYY-MM-DD') <= moment(to).format('YYYY-MM-DD')) {
				kyccounter++;
			}
		});

		transactioncheckarray.forEach(ResultData_ => {
			if (moment(ResultData_.created_datetime).format('YYYY-MM-DD') >= moment(from).format('YYYY-MM-DD') && moment(ResultData_.created_datetime).format('YYYY-MM-DD') <= moment(to).format('YYYY-MM-DD')) {
				if (ResultData_.transaction_type === 'Outgoing' || ResultData_.transaction_type === 'Internal') {
					fiattransactioncounter++;
				} else {
					cryptotransactioncounter++;
				}

			}
		});

		const TempValues = [...RevenuePerformance];
		TempValues[0]["NewUsers"] = newusercounter;
		TempValues[0]["ActiveUsers"] = activeusercounter;
		TempValues[0]["KYCSubmitted"] = kyccounter;
		TempValues[0]["FiatTransactions"] = fiattransactioncounter;
		TempValues[0]["CryptoTransactions"] = cryptotransactioncounter;
		setRevenuePerformance(TempValues);
	}



	const [TODO, setTODO] = useState([{
		TransactionRequests: 0,
		CardApprovalRequests: 0,
		UsersRequiredKYClookup: 0,
	}]);
	const [RevenuePerformance, setRevenuePerformance] = useState([{
		NewUsers: 0,
		ActiveUsers: 0,
		KYCSubmitted: 0,
		CryptoTransactions: 0,
		FiatTransactions: 0,
	}]);
	const [GeneratedRevenues, setGeneratedRevenues] = useState([{
		GeneratedRevenueEUR: 0,
		GeneratedRevenueUSD: 0,
		GeneratedRevenueBTC: 0,
		GeneratedRevenueETH: 0,
		GeneratedRevenueUSDT: 0,
	}]);
	const [RevenuesAccountBalance, setRevenuesAccountBalance] = useState([{
		AccountBalanceEUR: 0,
		AccountBalanceUSD: 0,
		AccountBalanceBTC: 0,
		AccountBalanceETH: 0,
		AccountBalanceUSDT: 0,
	}]);
	const [selectedOption, setSelectedOption] = useState(null);
	const [activeToggle, setActiveToggle] = useState("ToDo");

	const handleChangeSelectedOption = (e) => {
		var startweek = moment().day(1).format("YYYY-MM-DD");
		var endweek = moment().day(7).format("YYYY-MM-DD");
		const startOfMonth = moment().startOf('month').format('YYYY-MM-DD');
		const endOfMonth = moment().endOf('month').format('YYYY-MM-DD');
		var last3monthdate = moment(startOfMonth).subtract(3, 'months').format('YYYY-MM-DD');

		setSelectedOption(e);

		switch (e.value) {
			case "CustomPerformance":
				document.getElementById("CustomPerformance").style.display = "block";
				break;
			case "ThisWeek":
				document.getElementById("CustomPerformance").style.display = "none";

				var newusercounter = 0;
				var activeusercounter = 0;
				var kyccounter = 0;
				var fiattransactioncounter = 0;
				var cryptotransactioncounter = 0;

				userscheckarray.forEach(ResultData_ => {
					if (moment(ResultData_.created_datetime).format('YYYY-MM-DD') >= moment(startweek).format('YYYY-MM-DD') && moment(ResultData_.created_datetime).format('YYYY-MM-DD') <= moment(endweek).format('YYYY-MM-DD')) {
						newusercounter++;
					}
				});

				activeuserscheckarray.forEach(ResultData_ => {
					if (moment(ResultData_.created_datetime).format('YYYY-MM-DD') >= moment(startweek).format('YYYY-MM-DD') && moment(ResultData_.created_datetime).format('YYYY-MM-DD') <= moment(endweek).format('YYYY-MM-DD')) {
						activeusercounter++;
					}
				});

				kyccheckarray.forEach(ResultData_ => {
					if (moment(ResultData_.created_datetime).format('YYYY-MM-DD') >= moment(startweek).format('YYYY-MM-DD') && moment(ResultData_.created_datetime).format('YYYY-MM-DD') <= moment(endweek).format('YYYY-MM-DD')) {
						kyccounter++;
					}
				});

				transactioncheckarray.forEach(ResultData_ => {
					if (moment(ResultData_.created_datetime).format('YYYY-MM-DD') >= moment(startweek).format('YYYY-MM-DD') && moment(ResultData_.created_datetime).format('YYYY-MM-DD') <= moment(endweek).format('YYYY-MM-DD')) {
						if (ResultData_.transaction_type === 'Outgoing' || ResultData_.transaction_type === 'Internal') {
							fiattransactioncounter++;
						} else {
							cryptotransactioncounter++;
						}

					}
				});

				const TempValuesweek = [...RevenuePerformance];
				TempValuesweek[0]["NewUsers"] = newusercounter;
				TempValuesweek[0]["ActiveUsers"] = activeusercounter;
				TempValuesweek[0]["KYCSubmitted"] = kyccounter;
				TempValuesweek[0]["FiatTransactions"] = fiattransactioncounter;
				TempValuesweek[0]["CryptoTransactions"] = cryptotransactioncounter;
				setRevenuePerformance(TempValuesweek);
				break;
			case "ThisMonth":
				document.getElementById("CustomPerformance").style.display = "none";
				var newusercounter = 0;
				var activeusercounter = 0;
				var kyccounter = 0;
				var fiattransactioncounter = 0;
				var cryptotransactioncounter = 0;

				userscheckarray.forEach(ResultData_ => {
					if (moment(ResultData_.created_datetime).format('YYYY-MM-DD') >= moment(startOfMonth).format('YYYY-MM-DD') && moment(ResultData_.created_datetime).format('YYYY-MM-DD') <= moment(endOfMonth).format('YYYY-MM-DD')) {
						newusercounter++;
					}
				});

				activeuserscheckarray.forEach(ResultData_ => {
					if (moment(ResultData_.created_datetime).format('YYYY-MM-DD') >= moment(startOfMonth).format('YYYY-MM-DD') && moment(ResultData_.created_datetime).format('YYYY-MM-DD') <= moment(endOfMonth).format('YYYY-MM-DD')) {
						activeusercounter++;
					}
				});

				kyccheckarray.forEach(ResultData_ => {
					if (moment(ResultData_.created_datetime).format('YYYY-MM-DD') >= moment(startOfMonth).format('YYYY-MM-DD') && moment(ResultData_.created_datetime).format('YYYY-MM-DD') <= moment(endOfMonth).format('YYYY-MM-DD')) {
						kyccounter++;
					}
				});

				transactioncheckarray.forEach(ResultData_ => {
					if (moment(ResultData_.created_datetime).format('YYYY-MM-DD') >= moment(startOfMonth).format('YYYY-MM-DD') && moment(ResultData_.created_datetime).format('YYYY-MM-DD') <= moment(endOfMonth).format('YYYY-MM-DD')) {
						if (ResultData_.transaction_type === 'Outgoing' || ResultData_.transaction_type === 'Internal') {
							fiattransactioncounter++;
						} else {
							cryptotransactioncounter++;
						}

					}
				});

				const TempValues = [...RevenuePerformance];
				TempValues[0]["NewUsers"] = newusercounter;
				TempValues[0]["ActiveUsers"] = activeusercounter;
				TempValues[0]["KYCSubmitted"] = kyccounter;
				TempValues[0]["FiatTransactions"] = fiattransactioncounter;
				TempValues[0]["CryptoTransactions"] = cryptotransactioncounter;
				setRevenuePerformance(TempValues);
				break;
			case "This3Months":
				document.getElementById("CustomPerformance").style.display = "none";

				var newusercounter = 0;
				var activeusercounter = 0;
				var kyccounter = 0;
				var fiattransactioncounter = 0;
				var cryptotransactioncounter = 0;

				userscheckarray.forEach(ResultData_ => {
					if (moment(ResultData_.created_datetime).format('YYYY-MM-DD') >= moment(last3monthdate).format('YYYY-MM-DD') && moment(ResultData_.created_datetime).format('YYYY-MM-DD') <= moment(endOfMonth).format('YYYY-MM-DD')) {
						newusercounter++;
					}
				});

				activeuserscheckarray.forEach(ResultData_ => {
					if (moment(ResultData_.created_datetime).format('YYYY-MM-DD') >= moment(last3monthdate).format('YYYY-MM-DD') && moment(ResultData_.created_datetime).format('YYYY-MM-DD') <= moment(endOfMonth).format('YYYY-MM-DD')) {
						activeusercounter++;
					}
				});

				kyccheckarray.forEach(ResultData_ => {
					if (moment(ResultData_.created_datetime).format('YYYY-MM-DD') >= moment(last3monthdate).format('YYYY-MM-DD') && moment(ResultData_.created_datetime).format('YYYY-MM-DD') <= moment(endOfMonth).format('YYYY-MM-DD')) {
						kyccounter++;
					}
				});

				transactioncheckarray.forEach(ResultData_ => {
					if (moment(ResultData_.created_datetime).format('YYYY-MM-DD') >= moment(last3monthdate).format('YYYY-MM-DD') && moment(ResultData_.created_datetime).format('YYYY-MM-DD') <= moment(endOfMonth).format('YYYY-MM-DD')) {
						if (ResultData_.transaction_type === 'Outgoing' || ResultData_.transaction_type === 'Internal') {
							fiattransactioncounter++;
						} else {
							cryptotransactioncounter++;
						}

					}
				});

				const TempValues1 = [...RevenuePerformance];
				TempValues1[0]["NewUsers"] = newusercounter;
				TempValues1[0]["ActiveUsers"] = activeusercounter;
				TempValues1[0]["KYCSubmitted"] = kyccounter;
				TempValues1[0]["FiatTransactions"] = fiattransactioncounter;
				TempValues1[0]["CryptoTransactions"] = cryptotransactioncounter;
				setRevenuePerformance(TempValues1);
				break;
			case "All":
				document.getElementById("CustomPerformance").style.display = "none";
				var newusercounter = 0;
				var fiattransactioncounter = 0;
				var cryptotransactioncounter = 0;

				userscheckarray.forEach(ResultData_ => {
					if (moment(ResultData_.created_datetime).format('YYYY-MM-DD') === moment(new Date).format('YYYY-MM-DD')) {
						newusercounter++;
					}
				});

				transactioncheckarray.forEach(ResultData_ => {
					if (ResultData_.transaction_type === 'Outgoing' || ResultData_.transaction_type === 'Internal') {
						fiattransactioncounter++;
					} else {
						cryptotransactioncounter++;
					}
				});

				const TempValueAll = [...RevenuePerformance];
				TempValueAll[0]["NewUsers"] = newusercounter;
				TempValueAll[0]["ActiveUsers"] = activeuserscheckarray.length;
				TempValueAll[0]["KYCSubmitted"] = kyccheckarray.length;
				TempValueAll[0]["FiatTransactions"] = fiattransactioncounter;
				TempValueAll[0]["CryptoTransactions"] = cryptotransactioncounter;
				setRevenuePerformance(TempValueAll);
				break;
			default:
				document.getElementById("CustomPerformance").style.display = "none";
				break;
		}
	}

	const handleLoad = (Setting_Data = []) => {
		let SettingDataTemp = Setting_Data;
		if (Setting_Data.length === 0) {
			SettingDataTemp = SettingData;
		}

		var jsonpar = JSON.stringify({
			'broker_id': window.sessionStorage.getItem("manager_id")
		});
		const headers = {
			'Content-Type': 'application/json',
			'manageraccesstoken': process.env.REACT_APP_MANAGER_ACCESS_TOKEN,
			'managerauthtoken': 'Bearer ' + window.sessionStorage.getItem("manager_jwt"),
		}
		axios.post(process.env.REACT_APP_API_URL + 'getAllBrokerRewardsTransactionFDashboard', jsonpar, {
			headers: headers
		})
			.then(function (res) {
				res = res.data;
				if (res.code === "200") {
					setTotalCustomer(res.userData.length);
					setTotalReward("$" + res.brokerData[0].total_reward);
					setfiatrewards_array(res.brokerfiatrewards);
					setcryptorewards_array(res.brokercryptorewards);
					res.brokerData.forEach(element => {
						switch (element.user_acc_account_currency_type) {
							case "EUR":
								// setEUROAccountBalance("€" + element.user_acc_account_balance);
								break;

							default:
								setUSDAccountBalance("$" + element.user_acc_account_balance);
								break;
						}
					});
					setShareLink(process.env.REACT_APP_SITE_URL + "?ref_code=" + res.brokerData[0].ref_code);

					let TempArr = [];
					res.data.forEach(element => {
						let ManagerName = "-";
						if (element.manager_first_name !== null && element.manager_last_name !== null) {
							ManagerName = element.manager_first_name + " " + element.manager_last_name;
						}

						let CustomerName = element.user_first_name + " " + element.user_last_name;
						let CustomerEmail = element.user_email_id;
						if (SettingDataTemp.setting_value === "No") {
							CustomerName = "*****";
							CustomerEmail = "*****";
							ManagerName = "*****";
						}

						let temp = {
							No: element.broker_earn_id,
							Date: moment(element.created_datetime).format('MM / DD / YYYY hh:mm:ss'),
							CustomerName: CustomerName,
							CustomerEmail: CustomerEmail,
							ManagerName: ManagerName,
							TotalReward: element.total_reward + " " + element.coin_code,
							TransactionReward: element.fees_percentage + "%",
							TotalFees: element.fees + " " + element.coin_code,
							created_datetime: element.created_datetime
						}
						TempArr.push(temp);
					});
					setFilterData(TempArr);

					setTimeout(() => {
						setLoader(false);
					}, 1000);
				} else {
					setLoader(false);
				}
			})
			.catch(function (error) {
				setLoader(false);
			});
	}

	const handleLoadSetting = () => {
		var jsonpar = JSON.stringify({
			'setting_id': 1
		});
		const headers = {
			'Content-Type': 'application/json',
			'manageraccesstoken': process.env.REACT_APP_MANAGER_ACCESS_TOKEN,
			'managerauthtoken': 'Bearer ' + window.sessionStorage.getItem("manager_jwt"),
		}
		axios.post(process.env.REACT_APP_API_URL + 'getSettgingInformation', jsonpar, {
			headers: headers
		})
			.then(function (res) {
				res = res.data;
				if (res.code === "200") {
					setSettingData(res.data);
					handleLoad(res.data);

					setTimeout(() => {
						setLoader(false);
					}, 5100);
				} else {
					handleLoad();
					setLoader(false);
				}
			})
			.catch(function (error) {
				setLoader(false);
			});
	}

	const handleLoadGetAllReward = () => {
		var jsonpar = JSON.stringify({
			'broker_id': window.sessionStorage.getItem("manager_id")
		});
		const headers = {
			'Content-Type': 'application/json',
			'manageraccesstoken': process.env.REACT_APP_MANAGER_ACCESS_TOKEN,
			'managerauthtoken': 'Bearer ' + window.sessionStorage.getItem("manager_jwt"),
		}
		axios.post(process.env.REACT_APP_API_URL + 'getAllReward', jsonpar, {
			headers: headers
		})
			.then(function (res) {
				res = res.data;
				if (res.code === "200") {
					let fiat_reward = res.data.fiat_reward;
					let crypto_reward = res.data.crypto_reward;

					const TempValues = [...RevenuesAccountBalance];
					fiat_reward.forEach(element => {
						switch (element.coin_code) {
							case "EUR":
								TempValues[0]["AccountBalanceEUR"] = element.reward;
								break;

							case "USD":
								TempValues[0]["AccountBalanceUSD"] = element.reward;
								break;

							default:
								break;
						}
					});
					crypto_reward.forEach(element => {
						switch (element.coin_code) {
							case "BTC":
								TempValues[0]["AccountBalanceBTC"] = element.reward;
								break;

							case "ETH":
								TempValues[0]["AccountBalanceETH"] = element.reward;
								break;

							case "USDT":
								TempValues[0]["AccountBalanceUSDT"] = element.reward;
								break;

							default:
								break;
						}
					});
					setRevenuesAccountBalance(TempValues);

					setTimeout(() => {
						setLoader(false);
					}, 1000);
				} else {
					setLoader(false);
				}
			})
			.catch(function (error) {
				setLoader(false);
			});
	}

	const handleLoadGetAllCardApprovalRequest = () => {
		var jsonpar = JSON.stringify({
			'broker_id': window.sessionStorage.getItem("manager_id")
		});
		const headers = {
			'Content-Type': 'application/json',
			'manageraccesstoken': process.env.REACT_APP_MANAGER_ACCESS_TOKEN,
			'managerauthtoken': 'Bearer ' + window.sessionStorage.getItem("manager_jwt"),
		}
		axios.post(process.env.REACT_APP_API_URL + 'getAllCardApprovalRequest', jsonpar, {
			headers: headers
		})
			.then(function (res) {
				res = res.data;
				if (res.code === "200") {
					let resData = res.data;

					const TempValues = [...TODO];
					TempValues[0]["CardApprovalRequests"] = resData.length;
					setTODO(TempValues);

					setTimeout(() => {
						setLoader(false);
					}, 1000);
				} else {
					setLoader(false);
				}
			})
			.catch(function (error) {
				setLoader(false);
			});
	}

	const handleLoadGetAllUserKYCApprovalRequest = () => {
		var jsonpar = JSON.stringify({
			'broker_id': window.sessionStorage.getItem("manager_id")
		});
		const headers = {
			'Content-Type': 'application/json',
			'manageraccesstoken': process.env.REACT_APP_MANAGER_ACCESS_TOKEN,
			'managerauthtoken': 'Bearer ' + window.sessionStorage.getItem("manager_jwt"),
		}
		axios.post(process.env.REACT_APP_API_URL + 'getAllUserKYCApprovalRequest', jsonpar, {
			headers: headers
		})
			.then(function (res) {
				res = res.data;
				if (res.code === "200") {
					let resData = res.data;

					const TempValues = [...TODO];
					TempValues[0]["UsersRequiredKYClookup"] = resData.length;
					TempValues[0]["TransactionRequests"] = res.total;
					setTODO(TempValues);

					setTimeout(() => {
						setLoader(false);
					}, 1000);
				} else {
					setLoader(false);
				}
			})
			.catch(function (error) {
				setLoader(false);
			});
	}

	const handleLoadGetAllNewUser = () => {
		var jsonpar = JSON.stringify({
			'broker_id': window.sessionStorage.getItem("manager_id")
		});
		const headers = {
			'Content-Type': 'application/json',
			'manageraccesstoken': process.env.REACT_APP_MANAGER_ACCESS_TOKEN,
			'managerauthtoken': 'Bearer ' + window.sessionStorage.getItem("manager_jwt"),
		}
		axios.post(process.env.REACT_APP_API_URL + 'getAllNewUser', jsonpar, {
			headers: headers
		})
			.then(function (res) {
				res = res.data;
				setuserscheckarray(res.data);
				if (res.code === "200") {
					let resData = res.data;
					var tempFromDate = moment(new Date).format("YYYY-MM-DD");
					var counter = 0;
					resData.forEach(ResultData_ => {
						if (moment(ResultData_.created_datetime).format('YYYY-MM-DD') >= moment(tempFromDate).format('YYYY-MM-DD') && moment(ResultData_.created_datetime).format('YYYY-MM-DD') <= moment(tempFromDate).format('YYYY-MM-DD')) {
							counter = counter + 1;
						}
					});
					const TempValues = [...RevenuePerformance];
					TempValues[0]["NewUsers"] = counter;
					setRevenuePerformance(TempValues);

					setTimeout(() => {
						setLoader(false);
					}, 1000);
				} else {
					setLoader(false);
				}
			})
			.catch(function (error) {
				setLoader(false);
			});
	}

	const handleLoadGetAllActiveUser = () => {
		var jsonpar = JSON.stringify({
			'broker_id': window.sessionStorage.getItem("manager_id")
		});
		const headers = {
			'Content-Type': 'application/json',
			'manageraccesstoken': process.env.REACT_APP_MANAGER_ACCESS_TOKEN,
			'managerauthtoken': 'Bearer ' + window.sessionStorage.getItem("manager_jwt"),
		}
		axios.post(process.env.REACT_APP_API_URL + 'getAllActiveUser', jsonpar, {
			headers: headers
		})
			.then(function (res) {
				res = res.data;
				setactiveuserscheckarray(res.data);
				if (res.code === "200") {
					let resData = res.data;

					const TempValues = [...RevenuePerformance];
					TempValues[0]["ActiveUsers"] = resData.length;
					setRevenuePerformance(TempValues);

					setTimeout(() => {
						setLoader(false);
					}, 1000);
				} else {
					setLoader(false);
				}
			})
			.catch(function (error) {
				setLoader(false);
			});
	}

	const handleLoadGetAllKYCUserSubmitted = () => {
		var jsonpar = JSON.stringify({
			'broker_id': window.sessionStorage.getItem("manager_id")
		});
		const headers = {
			'Content-Type': 'application/json',
			'manageraccesstoken': process.env.REACT_APP_MANAGER_ACCESS_TOKEN,
			'managerauthtoken': 'Bearer ' + window.sessionStorage.getItem("manager_jwt"),
		}
		axios.post(process.env.REACT_APP_API_URL + 'getAllKYCUserSubmitted', jsonpar, {
			headers: headers
		})
			.then(function (res) {
				res = res.data;
				setkyccheckarray(res.data);
				if (res.code === "200") {
					let resData = res.data;

					const TempValues = [...RevenuePerformance];
					TempValues[0]["KYCSubmitted"] = resData.length;
					setRevenuePerformance(TempValues);

					setTimeout(() => {
						setLoader(false);
					}, 1000);
				} else {
					setLoader(false);
				}
			})
			.catch(function (error) {
				setLoader(false);
			});
	}

	const handleLoadGetAllTransations = () => {

		var firstday = moment().day(1).format("YYYY-MM-DD");
		var secondday = moment().day(2).format("YYYY-MM-DD");
		var thirdday = moment().day(3).format("YYYY-MM-DD");
		var fourday = moment().day(4).format("YYYY-MM-DD");
		var fiveday = moment().day(5).format("YYYY-MM-DD");
		var sixday = moment().day(6).format("YYYY-MM-DD");
		var sevenday = moment().day(7).format("YYYY-MM-DD");

		var jsonpar = JSON.stringify({
			'broker_id': window.sessionStorage.getItem("manager_id")
		});
		const headers = {
			'Content-Type': 'application/json',
			'manageraccesstoken': process.env.REACT_APP_MANAGER_ACCESS_TOKEN,
			'managerauthtoken': 'Bearer ' + window.sessionStorage.getItem("manager_jwt"),
		}
		axios.post(process.env.REACT_APP_API_URL + 'getAllTransations', jsonpar, {
			headers: headers
		})
			.then(function (res) {
				res = res.data;
				if (res.code === "200") {
					let resData = res.data;
					settransactioncheckarray(res.data);
					let FiatTransactions = 0;
					let CryptoTransactionsCounter = 0;
					var today = moment(new Date).format('YYYY-MM-DD h:i:s');


					var counterone = 0;
					var countertwo = 0;
					var counterthree = 0;
					var counterfour = 0;
					var counterfive = 0;
					var countersix = 0;
					var counterseven = 0;

					var counterone_crypto = 0;
					var countertwo_crypto = 0;
					var counterthree_crypto = 0;
					var counterfour_crypto = 0;
					var counterfive_crypto = 0;
					var countersix_crypto = 0;
					var counterseven_crypto = 0;

					resData.forEach(element => {
						if (element.transaction_type === 'Outgoing' || element.transaction_type === 'Internal') {
							FiatTransactions++;
							if (moment(element.created_datetime).format('YYYY-MM-DD') === moment(firstday).format('YYYY-MM-DD')) {
								counterone = counterone + 1;
							}

							if (moment(element.created_datetime).format('YYYY-MM-DD') === moment(secondday).format('YYYY-MM-DD')) {
								countertwo = countertwo + 1;
							}

							if (moment(element.created_datetime).format('YYYY-MM-DD') === moment(thirdday).format('YYYY-MM-DD')) {
								counterthree = counterthree + 1;
							}

							if (moment(element.created_datetime).format('YYYY-MM-DD') === moment(fourday).format('YYYY-MM-DD')) {
								counterfour = counterfour + 1;
							}

							if (moment(element.created_datetime).format('YYYY-MM-DD') === moment(fiveday).format('YYYY-MM-DD')) {
								counterfive = counterfive + 1;
							}

							if (moment(element.created_datetime).format('YYYY-MM-DD') === moment(sixday).format('YYYY-MM-DD')) {
								countersix = countersix + 1;
							}

							if (moment(element.created_datetime).format('YYYY-MM-DD') === moment(sevenday).format('YYYY-MM-DD')) {
								counterseven = counterseven + 1;
							}



						}
						else {
							CryptoTransactionsCounter++;

							if (moment(element.created_datetime).format('YYYY-MM-DD') === moment(firstday).format('YYYY-MM-DD')) {
								counterone_crypto = counterone_crypto + 1;
							}

							if (moment(element.created_datetime).format('YYYY-MM-DD') === moment(secondday).format('YYYY-MM-DD')) {
								countertwo_crypto = countertwo_crypto + 1;
							}

							if (moment(element.created_datetime).format('YYYY-MM-DD') === moment(thirdday).format('YYYY-MM-DD')) {
								counterthree_crypto = counterthree_crypto + 1;
							}

							if (moment(element.created_datetime).format('YYYY-MM-DD') === moment(fourday).format('YYYY-MM-DD')) {
								counterfour_crypto = counterfour_crypto + 1;
							}

							if (moment(element.created_datetime).format('YYYY-MM-DD') === moment(fiveday).format('YYYY-MM-DD')) {
								counterfive_crypto = counterfive_crypto + 1;
							}

							if (moment(element.created_datetime).format('YYYY-MM-DD') === moment(sixday).format('YYYY-MM-DD')) {
								countersix_crypto = countersix_crypto + 1;
							}

							if (moment(element.created_datetime).format('YYYY-MM-DD') === moment(sevenday).format('YYYY-MM-DD')) {
								counterseven_crypto = counterseven_crypto + 1;
							}
						}
					});

					setcounteroneshow(counterone);
					setcountertwoshow(countertwo);
					setcounterthreeshow(counterthree);
					setcounterfourshow(counterfour);
					setcounterfiveshow(counterfive);
					setcountersixshow(countersix);
					setcountersevenshow(counterseven);

					const TempValues = [...RevenuePerformance];
					TempValues[0]["FiatTransactions"] = FiatTransactions;
					TempValues[0]["CryptoTransactions"] = CryptoTransactionsCounter;
					TempValues[0]["fiatval"] = [parseInt(counterseven), parseInt(counterone), parseInt(countertwo), parseInt(counterthree), parseInt(counterfour), parseInt(counterfive), parseInt(countersix)];
					TempValues[0]["cryptoval"] = [parseInt(counterseven_crypto), parseInt(counterone_crypto), parseInt(countertwo_crypto), parseInt(counterthree_crypto), parseInt(counterfour_crypto), parseInt(counterfive_crypto), parseInt(countersix_crypto)];

					setRevenuePerformance(TempValues);

					setTimeout(() => {
						setLoader(false);
					}, 1000);
				} else {
					setLoader(false);
				}
			})
			.catch(function (error) {
				setLoader(false);
			});
	}

	const handleLoadGetAllTransationsRevenue = () => {
		var jsonpar = JSON.stringify({
			'broker_id': window.sessionStorage.getItem("manager_id")
		});
		const headers = {
			'Content-Type': 'application/json',
			'manageraccesstoken': process.env.REACT_APP_MANAGER_ACCESS_TOKEN,
			'managerauthtoken': 'Bearer ' + window.sessionStorage.getItem("manager_jwt"),
		}
		axios.post(process.env.REACT_APP_API_URL + 'getAllTransationsRevenue', jsonpar, {
			headers: headers
		})
			.then(function (res) {
				res = res.data;
				if (res.code === "200") {
					let resData = res.data;

					let TempGeneratedRevenueEUR = 0;
					let TempGeneratedRevenueUSD = 0;
					let TempGeneratedRevenueBTC = 0;
					let TempGeneratedRevenueETH = 0;
					let TempGeneratedRevenueUSDT = 0;
					resData.forEach(element => {
						switch (element.coin_code) {
							case "EUR":
								TempGeneratedRevenueEUR = TempGeneratedRevenueEUR + parseFloat(element.fees);
								break;

							case "USD":
								TempGeneratedRevenueUSD = TempGeneratedRevenueUSD + parseFloat(element.fees);
								break;

							case "BTC":
								TempGeneratedRevenueBTC = TempGeneratedRevenueBTC + parseFloat(element.fees);
								break;

							case "ETH":
								TempGeneratedRevenueETH = TempGeneratedRevenueETH + parseFloat(element.fees);
								break;

							case "USDT":
								TempGeneratedRevenueUSDT = TempGeneratedRevenueUSDT + parseFloat(element.fees);
								break;

							default:
								break;
						}
					});
					const TempValues = [...GeneratedRevenues];
					TempValues[0]["GeneratedRevenueEUR"] = TempGeneratedRevenueEUR;
					TempValues[0]["GeneratedRevenueUSD"] = TempGeneratedRevenueUSD;
					TempValues[0]["GeneratedRevenueBTC"] = TempGeneratedRevenueBTC;
					TempValues[0]["GeneratedRevenueETH"] = TempGeneratedRevenueETH;
					TempValues[0]["GeneratedRevenueUSDT"] = TempGeneratedRevenueUSDT;
					setGeneratedRevenues(TempValues);

					setTimeout(() => {
						setLoader(false);
					}, 1000);
				} else {
					setLoader(false);
				}
			})
			.catch(function (error) {
				setLoader(false);
			});
	}

	const handlechartdata = () => {
		var jsonpar = JSON.stringify({
			'broker_id': window.sessionStorage.getItem("manager_id")
		});
		const headers = {
			'Content-Type': 'application/json',
			'manageraccesstoken': process.env.REACT_APP_MANAGER_ACCESS_TOKEN,
			'managerauthtoken': 'Bearer ' + window.sessionStorage.getItem("manager_jwt"),
		}
		axios.post(process.env.REACT_APP_API_URL + 'getchartdatafiat', jsonpar, {
			headers: headers
		})
			.then(function (res) {
				res = res.data;
				if (res.code === "200") {
					setUsdIncome(res.total_usd_income);
					setEurIncome(res.total_eur_income);
					setUsdOutcome(res.total_usd_outcome);
					setEurOutcome(res.total_eur_outcome);

					setUsdIncome_chartdata(res.usdarray);
					setUsdOutcome_chartdata(res.usdarray_outcome);
					setEurIncome_chartdata(res.eurarray);
					setEurOutcome_chartdata(res.eurarray_outcome);

					setTimeout(() => {
						seteurooutcomeshow(1);
						seteuroincomeshow(1);
						setusdincomeshow(1);
						setusdoutcomeshow(1);
					}, 1000);
				}
			})
			.catch(function (error) {
			});
	}


	useEffect(() => {
		setLoader(true);
		handleLoadSetting();
		handleLoadGetAllCardApprovalRequest();
		handleLoadGetAllUserKYCApprovalRequest();
		handleLoadGetAllReward();
		handleLoadGetAllNewUser();
		handleLoadGetAllActiveUser();
		handleLoadGetAllKYCUserSubmitted();
		handleLoadGetAllTransations();
		handleLoadGetAllTransationsRevenue();
		handlechartdata();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);


	return (
		<Fragment>
			<div className="form-head mb-4">
				<h2 className="text-black font-w600 mb-0">{t('KYC.MYACCOUNT')}</h2>
			</div>
			{loader === true ?
				<div>
					<Loader open_close={loader} />
				</div>
				:
				<div>
					<div className='row'>
						<div className="col-xl-4 col-sm-6">
							<div className="card">
								<div className="card-body">
									<div className="media align-items-center">
										<div className="media-body">
											<h2 className="fs-38 text-black font-w600">{TotalCustomer}</h2>
											<span className="fs-18">{t('LABEL.TOTAL_CUSTOMER')}</span>
										</div>
										<span className="dash-icon">
											<i className="flaticon-381-user-2"></i>
										</span>
									</div>
								</div>
							</div>
						</div>

						{fiatrewards_array && fiatrewards_array.map((row) => (

							<div className="col-xl-4 col-sm-6">
								<div className="card">
									<div className="card-body">
										<div className="media align-items-center">
											<div className="media-body">
												<h2 className="fs-38 text-black font-w600">{row.symbole} {row.total_balance}</h2>
												<span className="fs-18">{row.curracycode} Account Balance</span>
											</div>
											<span className="dash-icon">
												<i className="flaticon-381-diamond"></i>
											</span>
										</div>
									</div>
								</div>
							</div>
						))}

						{cryptorewards_array && cryptorewards_array.map((row) => (

							<div className="col-xl-4 col-sm-6">
								<div className="card">
									<div className="card-body">
										<div className="media align-items-center">
											<div className="media-body">
												<h2 className="fs-38 text-black font-w600">{row.total_balance} {row.coin_code} </h2>
												<span className="fs-18">{row.coin_code} Account Balance</span>
											</div>
											<span className="dash-icon">
												<i className="flaticon-381-diamond"></i>
											</span>
										</div>
									</div>
								</div>
							</div>
						))}

						{/* {fiatrewards_array && fiatrewards_array.map((row) => (

							<div className="col-xl-4 col-sm-6">
								<div className="card">
									<div className="card-body">
										<div className="media align-items-center">
											<div className="media-body">
												<h2 className="fs-38 text-black font-w600">{row.symbole} {row.total_rewards}</h2>
												<span className="fs-18">{row.curracycode} Total Rewards</span>
											</div>
											<span className="dash-icon">
												<i className="flaticon-381-diamond"></i>
											</span>
										</div>
									</div>
								</div>
							</div>
						))}

						{cryptorewards_array && cryptorewards_array.map((row) => (

							<div className="col-xl-4 col-sm-6">
								<div className="card">
									<div className="card-body">
										<div className="media align-items-center">
											<div className="media-body">
												<h2 className="fs-38 text-black font-w600">{row.total_rewards} {row.coin_code} </h2>
												<span className="fs-18">{row.coin_code} Total Rewards</span>
											</div>
											<span className="dash-icon">
												<i className="flaticon-381-diamond"></i>
											</span>
										</div>
									</div>
								</div>
							</div>
						))} */}




					</div>

					<div className="row">
						<div className="col-12">
							<div className="card">
								<div className="card-body">
									<div className="custom-tab-1">
										<ul className="nav nav-tabs">
											<li className="nav-item" onClick={() => setActiveToggle("ToDo")}>
												<Link to="#01" data-toggle="tab" className={`nav-link ${activeToggle === "ToDo" ? "active show" : ""}`}>To Do</Link>
											</li>
											<li className="nav-item" onClick={() => setActiveToggle("RevenuePerformance")}>
												<Link to="#02" data-toggle="tab" className={`nav-link ${activeToggle === "RevenuePerformance" ? "active show" : ""}`}>Revenue Performance</Link>
											</li>
										</ul>
										<div className="tab-content">

											<div id="01" className={`tab-pane fade ${activeToggle === "ToDo" ? "active show" : ""}`} >

												<div className="profile-personal-info mt-4 row">
													<div className="col-xl-3 col-sm-6 col-12">
														<Link to="account-transactions" className="card bg-gray">
															<div className="card-body pad-18">
																<div className="media align-items-center">
																	<div className="media-body">
																		<h2 className="fs-32 text-black font-w600">{TODO[0].TransactionRequests}</h2>
																		<span className="fs-17">Total Transaction requests</span>
																	</div>
																</div>
															</div>
														</Link>
													</div>

													<div className="col-xl-3 col-sm-6 col-12">
														<Link to="request-cards" className="card bg-gray">
															<div className="card-body pad-18">
																<div className="media align-items-center">
																	<div className="media-body">
																		<h2 className="fs-32 text-black font-w600">{TODO[0].CardApprovalRequests}</h2>
																		<span className="fs-17">Card Approval Requests</span>
																	</div>
																</div>
															</div>
														</Link>
													</div>

													<div className="col-xl-3 col-sm-6 col-12">
														<Link to="kyc" className="card bg-gray">
															<div className="card-body pad-18">
																<div className="media align-items-center">
																	<div className="media-body">
																		<h2 className="fs-32 text-black font-w600">{TODO[0].UsersRequiredKYClookup}</h2>
																		<span className="fs-17">Users Required KYC lookup</span>
																	</div>
																</div>
															</div>
														</Link>
													</div>
												</div>

											</div>

											<div id="02" className={`tab-pane fade ${activeToggle === "RevenuePerformance" ? "active show" : ""}`}>
												<div className="profile-personal-info mt-4 row">
													<div className="col-lg-4 col-12 position-relative mb-3">
														<label className="mb-2">
															<span>Select Time Period</span>
														</label>
														<Select styles={colourStyles} className="webkit-none" defaultValue={selectedOption} onChange={(e) => handleChangeSelectedOption(e)} options={options} />
														<i className="fa fa-caret-down down-arrow select-arrow1" aria-hidden="true"></i>
													</div>
													<div className="col-lg-8 col-12 position-relative" id='CustomPerformance' style={{ display: 'none' }}>
														<div className="row">
															<div className="col-lg-6 col-12">
																<div className="form-group position-relative">
																	<label className="mb-2">
																		<span>From Date</span>
																	</label>
																	<div className="col-xs-12">
																		<input type="date" onChange={(e) => handleChangeFromDate(e.target.value)} value={FromDate} className="form-control" />
																	</div>
																</div>
															</div>

															<div className="col-lg-6 col-12">
																<div className="form-group position-relative">
																	<label className="mb-2 ">
																		<span>To Date</span>
																	</label>
																	<div className="col-xs-12">
																		<input type="date" onChange={(e) => handleChangeToDate(e.target.value)} value={ToDate} className="form-control" />
																	</div>
																</div>
															</div>
														</div>
													</div>
												</div>

												<div className="profile-personal-info mt-2 row">
													<div className="col col-sm-12">
														<div className="card bg-gray">
															<div className="card-body pad-18">
																<div className="media align-items-center">
																	<div className="media-body">
																		<h2 className="fs-32 text-black font-w600">{RevenuePerformance[0].NewUsers}</h2>
																		<span className="fs-17">New Users</span>
																	</div>
																</div>
															</div>
														</div>
													</div>

													<div className="col col-sm-12">
														<div className="card bg-gray">
															<div className="card-body pad-18">
																<div className="media align-items-center">
																	<div className="media-body">
																		<h2 className="fs-32 text-black font-w600">{RevenuePerformance[0].ActiveUsers}</h2>
																		<span className="fs-17">Active Users</span>
																	</div>
																</div>
															</div>
														</div>
													</div>

													<div className="col col-sm-12">
														<div className="card bg-gray">
															<div className="card-body pad-18">
																<div className="media align-items-center">
																	<div className="media-body">
																		<h2 className="fs-32 text-black font-w600">{RevenuePerformance[0].KYCSubmitted}</h2>
																		<span className="fs-17">KYC submitted</span>
																	</div>
																</div>
															</div>
														</div>
													</div>

													<div className="col col-sm-12">
														<div className="card bg-gray">
															<div className="card-body pad-18">
																<div className="media align-items-center">
																	<div className="media-body">
																		<h2 className="fs-32 text-black font-w600">{RevenuePerformance[0].CryptoTransactions}</h2>
																		<span className="fs-17">Crypto Transactions</span>
																	</div>
																</div>
															</div>
														</div>
													</div>

													<div className="col col-sm-12">
														<div className="card bg-gray">
															<div className="card-body pad-18">
																<div className="media align-items-center">
																	<div className="media-body">
																		<h2 className="fs-32 text-black font-w600">{RevenuePerformance[0].FiatTransactions}</h2>
																		<span className="fs-17">Fiat Transactions</span>
																	</div>
																</div>
															</div>
														</div>
													</div>
												</div>

												<div className="row">
													<div className="col-md-6 col-12 mb-4">
														<div className="col-12 p-0 bdr_none">
															<p className="request-title">Generated Revenues</p>
														</div>
														{fiatrewards_array && fiatrewards_array.map((row) => (
															<div className="col-12 border_none">
																<div className="row">
																	<div className="col-md-4 col-12">
																		<div class="Details-title">{row.curracycode}</div>
																	</div>
																	<div className="col-md-8 col-12">
																		<div class="Details-value">{row.symbole} {row.total_earn}</div>
																	</div>
																</div>
															</div>
														))}

														{cryptorewards_array && cryptorewards_array.map((row) => (
															<div className="col-12 border_none">
																<div className="row">
																	<div className="col-md-4 col-12">
																		<div class="Details-title">{row.coin_code}</div>
																	</div>
																	<div className="col-md-8 col-12">
																		<div class="Details-value">{row.total_earn} {row.coin_code} </div>
																	</div>
																</div>
															</div>
														))}
													</div>

													<div className="col-md-6 col-12 mb-4">
														<div className="col-12 p-0 bdr_none">
															<p className="request-title">Revenues Account Balance</p>
														</div>
														{fiatrewards_array && fiatrewards_array.map((row) => (
															<div className="col-12 border_none">
																<div className="row">
																	<div className="col-md-4 col-12">
																		<div class="Details-title">{row.curracycode}</div>
																	</div>
																	<div className="col-md-8 col-12">
																		<div class="Details-value">{row.symbole} {row.total_rewards}</div>
																	</div>
																</div>
															</div>
														))}

														{cryptorewards_array && cryptorewards_array.map((row) => (
															<div className="col-12 border_none">
																<div className="row">
																	<div className="col-md-4 col-12">
																		<div class="Details-title">{row.coin_code}</div>
																	</div>
																	<div className="col-md-8 col-12">
																		<div class="Details-value">{row.total_rewards} {row.coin_code} </div>
																	</div>
																</div>
															</div>
														))}
													</div>
												</div>

											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>

					<div className="row">
						<div className="col-xl-12">
							<div className="row">
								<div className="col-12">
									<div className="card height-cstm-chart">
										<div className="card-header d-sm-flex d-block border-0 pb-0">
											<div className="pr-3 mr-auto mb-sm-0 mb-3">
												<h4 className="fs-20 text-black mb-1">Transaction Overview</h4>
												<span className="fs-12">Number of transaction crypto and Fiat Overview</span>
											</div>
										</div>
										<div className="card-body">
											<div id="chartBar">
												{counteroneshow > 0 || countertwoshow > 0 || counterthreeshow > 0 || counterfourshow > 0 || counterfiveshow > 0 || countersixshow > 0 || countersevenshow > 0 ?
													<TransferBarGraph chartdata={RevenuePerformance} />
													: "No transactions found"}
											</div>
										</div>
									</div>
								</div>
								<div className="col-xl-3 col-sm-6 col-12">
									<div className="card graphsHeight">
										<div className="card-header flex-wrap border-0 pb-0">
											<div className="mr-3 mb-2">
												<p className="fs-14 mb-1">EURO Income</p>
												<span className="fs-24 text-black font-w600">€ {parseFloat(EurIncome).toFixed(2)}</span>
											</div>
										</div>
										<div className="card-body p-0">
											{euroincomeshow > 0 ?
												<EURIncome chartdata={EurIncome_chartdata} />
												: ""}
										</div>
									</div>
								</div>
								<div className="col-xl-3 col-sm-6 col-12">
									<div className="card graphsHeight">
										<div className="card-header flex-wrap border-0 pb-0">
											<div className="mr-3 mb-2">
												<p className="fs-14 mb-1">EURO Outcome</p>
												<span className="fs-24 text-black font-w600">€ {parseFloat(EurOutcome).toFixed(2)}</span>
											</div>
										</div>
										<div className="card-body p-0">
											{eurooutcomeshow > 0 ?
												<EUROutcome chartdata={EurOutcome_chartdata} />
												: ""}
										</div>
									</div>
								</div>
								<div className="col-xl-3 col-sm-6 col-12">
									<div className="card graphsHeight">
										<div className="card-header flex-wrap border-0 pb-0">
											<div className="mr-3 mb-2">
												<p className="fs-14 mb-1">USD Income</p>
												<span className="fs-24 text-black font-w600">$ {parseFloat(UsdIncome).toFixed(2)}</span>
											</div>
										</div>
										<div className="card-body p-0">
											{usdincomeshow > 0 ?
												<USDIncome chartdata={UsdIncome_chartdata} />
												: ""}
										</div>
									</div>
								</div>
								<div className="col-xl-3 col-sm-6 col-12">
									<div className="card graphsHeight">
										<div className="card-header flex-wrap border-0 pb-0">
											<div className="mr-3 mb-2">
												<p className="fs-14 mb-1">USD Outcome</p>
												<span className="fs-24 text-black font-w600">$ {parseFloat(UsdOutcome).toFixed(2)}</span>
											</div>
										</div>
										<div className="card-body p-0">
											{usdoutcomeshow > 0 ?
												<USDOutcome chartdata={UsdOutcome_chartdata} />
												: ""}

										</div>
									</div>
								</div>
							</div>
						</div>

					</div>

					<div className="row">
						<div className="col-sm-12">
							<div className="card height-cstm-chart">
								<div className="card-body">
									<div className="table-responsive">
										{FilterData.length > 0 ?
											<MaterialTable
												title="Fees Earned Transactions"
												columns={[
													{ title: t('ACCOUNT_TRANSACTIONS.DATE'), field: 'Date' },
													{ title: t('HEADER.CUS_NAME'), field: 'CustomerName' },
													{ title: t('LABEL.MANAGER_NAME'), field: 'ManagerName' },
													{ title: t('LABEL.TRANSCT_REWARD'), field: 'TransactionReward' },
													{ title: t('LABEL.TOTAL_FEES'), field: 'TotalFees' },
													{ title: t('LABEL.TOTAL_REWARD'), field: 'TotalReward' },
												]}
												data={FilterData}
												options={{
													exportMenu: [
														{ label: "Export PDF", exportFunc: (cols, datas) => ExportPdf(cols, datas, "Fees Earned Transactions") },
														{ label: "Export CSV", exportFunc: (cols, datas) => ExportCsv(cols, datas, "Fees Earned Transactions") },
													], paging: true, pageSize: 5, pageSizeOptions: [5, 10, 15], emptyRowsWhenPaging: false,
												}}

											/>
											: <div className="d-sm-flex d-block border-0 pb-0">
												<div className="pr-3 mr-auto mb-sm-0 mb-3 nofoundFullWidth">
													<h4 className="fs-20 text-black mb-1 nofoundTitle">Fees Earned Transactions Overview</h4>
													<span className="fs-12 nofoundText">No Data Found</span>
												</div>
											</div>}
									</div>
								</div>
							</div>
						</div>
					</div>

				</div>
			}
		</Fragment>
	)
}
export default Home;
