/// Menu
import MetisMenu from "metismenujs";
import React, { Component } from "react";
/// Scroll
import PerfectScrollbar from "react-perfect-scrollbar";
/// Link
import { Link, withRouter } from "react-router-dom";
import $ from 'jquery';

class MM extends Component {
  componentDidMount() {
    this.$el = this.el;
    this.mm = new MetisMenu(this.$el);
  }
  componentWillUnmount() {
  }
  render() {
    return (
      <div className="mm-wrapper">
        <ul className="metismenu" ref={(el) => (this.el = el)}>
          {this.props.children}
        </ul>
      </div>
    );
  }
}

class SideBar extends Component {

  toggleFunc2() {
    if (navigator.userAgent.match(/Android/i)
      || navigator.userAgent.match(/webOS/i)
      || navigator.userAgent.match(/iPhone/i)
      || navigator.userAgent.match(/iPad/i)
      || navigator.userAgent.match(/iPod/i)
      || navigator.userAgent.match(/BlackBerry/i)
      || navigator.userAgent.match(/Windows Phone/i)) {
      $(".nav-control").trigger("click");
    }
  }
  /// Open menu
  componentDidMount() {
    // sidebar open/close
    var btn = document.querySelector(".nav-control");
    var aaa = document.querySelector("#main-wrapper");
    function toggleFunc() {
      return aaa.classList.toggle("menu-toggle");
    }
    btn.addEventListener("click", toggleFunc);
  }
  state = {
    loveEmoji: false,
  };
  render() {
    /// Path
    let path = window.location.pathname;
    path = path.split("/");
    path = path[path.length - 1];

    /// Active menu
    let
      dashBoard = [
        "",
        "dashboard",
        "my-wallet",
        "invoices",
        "cards-center",
        "transactions",
        "transactions-details",
        "task",
      ],
      reward = [
        "rewards-fees-earned",
        "rewards-withdrawal-request",
        "rewards-transaction"
      ],

      customer = [
        "customer-list",
      ],

      broker = [
        "broker-list",
        "broker-request",
        "broker-fees-earned",
        "broker-withdrawal-request",
        "broker-customer-transaction"
      ],

      accountcard = [
        "request-cards",
        "cards-listing",
        "cards-transaction",
        "cards-issuance"
      ],

      transaction = [
        "account-transactions",
        "internal-transfers",
        "outgoing-transfers",
        "crypto-exchange-transactions",
        "deposit-transactions",
        "withdrawal-transactions",
        "transfer-transactions",
        "swap-transactions"
      ],

      usersactivitylogs = [
        "users-activity-logs",
      ],

      mylinks = [
        "mylinks",
      ],

      management = [
        "news",
        "news-details",
        "FAQ",
        "feedback",
        "inquiry-log",
        "kyc"
      ]


    return (
      <div className="deznav">
        <PerfectScrollbar className="deznav-scroll">
          <MM className="metismenu" id="menu">

            <li className={dashBoard.includes(path) ? "mm-active" : ""}>
              <Link className={path === "dashboard" ? "mm-active" : ""} to="/dashboard" onClick={(e) => this.toggleFunc2()}>
                <i className="flaticon-381-home-2"></i>
                <span className="nav-text">Dashboard</span>
              </Link>
            </li>

            <li className={reward.includes(path) ? "mm-active" : ""}>
              <Link className="has-arrow ai-icon" to="#" >
                <i className="flaticon-381-diamond"></i>
                <span className="nav-text">Rewards</span>
              </Link>
              <ul className={`${reward.includes(path) ? "mm-collapse mm-show" : "mm-collapse"}`}>
                <li>
                  <Link className={path === "rewards-fees-earned" ? "mm-active" : ""} to="/rewards-fees-earned" onClick={(e) => this.toggleFunc2()}>Fees Earned</Link>
                </li>
                <li>
                  <Link className={path === "rewards-withdrawal-request" ? "mm-active" : ""} to="/rewards-withdrawal-request" onClick={(e) => this.toggleFunc2()}>Withdrawal Requests</Link>
                </li>
                <li>
                  <Link className={path === "rewards-transaction" ? "mm-active" : ""} to="/rewards-transaction" onClick={(e) => this.toggleFunc2()}>Transactions</Link>
                </li>
              </ul>
            </li>

            <li className={customer.includes(path) ? "mm-active" : ""}>
              <Link className={path === "customer-list" ? "mm-active" : ""} to="/customer-list" onClick={(e) => this.toggleFunc2()}>
                <i className="flaticon-381-user-4"></i>
                <span className="nav-text">Customers</span>
              </Link>
            </li>

            <li className={broker.includes(path) ? "mm-active" : ""}>
              <Link className="has-arrow ai-icon" to="#" >
                <i className="flaticon-381-user-1"></i>
                <span className="nav-text">Brokers</span>
              </Link>
              <ul className={`${broker.includes(path) ? "mm-collapse mm-show" : "mm-collapse"}`}>
                <li>
                  <Link className={path === "broker-list" ? "mm-active" : ""} to="/broker-list" onClick={(e) => this.toggleFunc2()}>Broker Lists</Link>
                </li>
                <li>
                  <Link className={path === "broker-request" ? "mm-active" : ""} to="/broker-request" onClick={(e) => this.toggleFunc2()}>Broker Requests</Link>
                </li>
                {/* <li>
                  <Link className={path === "broker-customer-transaction" ? "mm-active" : ""} to="/broker-customer-transaction" onClick={(e) => this.toggleFunc2()}>Customer Transaction</Link>
                </li> */}

              </ul>
            </li>



            <li className={accountcard.includes(path) ? "mm-active" : ""}>
              <Link className="has-arrow ai-icon" to="#" >
                <i className="flaticon-381-id-card"></i>
                <span className="nav-text">Account Card</span>
              </Link>
              <ul className={`${accountcard.includes(path) ? "mm-collapse mm-show" : "mm-collapse"}`}>
                <li>
                  <Link className={path === "request-cards" ? "mm-active" : ""} to="/request-cards" onClick={(e) => this.toggleFunc2()}>Request Cards</Link>
                </li>
                <li>
                  <Link className={path === "cards-listing" ? "mm-active" : ""} to="/cards-listing" onClick={(e) => this.toggleFunc2()}>Cards Listing</Link>
                </li>
                <li>
                  <Link className={path === "cards-transaction" ? "mm-active" : ""} to="/cards-transaction" onClick={(e) => this.toggleFunc2()}>Cards Transaction</Link>
                </li>
                <li>
                  <Link className={path === "cards-issuance" ? "mm-active" : ""} to="/cards-issuance" onClick={(e) => this.toggleFunc2()}>Cards Issuance fee Transaction</Link>
                </li>

              </ul>
            </li>


            <li className={transaction.includes(path) ? "mm-active" : ""}>
              <Link className="has-arrow ai-icon" to="#" >
                <i className="flaticon-381-user"></i>
                <span className="nav-text">Transactions</span>
              </Link>
              <ul className={`${transaction.includes(path) ? "mm-collapse mm-show" : "mm-collapse"}`}>
                <li>
                  <Link className={path === "account-transactions" ? "mm-active" : ""} to="/account-transactions" onClick={(e) => this.toggleFunc2()}>Account Transactions</Link>
                </li>
                <li>
                  <Link className={path === "internal-transfers" ? "mm-active" : ""} to="/internal-transfers" onClick={(e) => this.toggleFunc2()}>Internal Transfers</Link>
                </li>
                <li>
                  <Link className={path === "outgoing-transfers" ? "mm-active" : ""} to="/outgoing-transfers" onClick={(e) => this.toggleFunc2()}>Outgoing Transfers</Link>
                </li>
                <li>
                  <Link className={path === "crypto-exchange-transactions" ? "mm-active" : ""} to="/crypto-exchange-transactions" onClick={(e) => this.toggleFunc2()}>Crypto Exchange</Link>
                </li>
                <li>
                  <Link className={path === "deposit-transactions" ? "mm-active" : ""} to="/deposit-transactions" onClick={(e) => this.toggleFunc2()}>Deposit</Link>
                </li>
                <li>
                  <Link className={path === "withdrawal-transactions" ? "mm-active" : ""} to="/withdrawal-transactions" onClick={(e) => this.toggleFunc2()}>Withdrawal</Link>
                </li>
                <li>
                  <Link className={path === "transfer-transactions" ? "mm-active" : ""} to="/transfer-transactions" onClick={(e) => this.toggleFunc2()}>Transfer</Link>
                </li>
                <li>
                  <Link className={path === "swap-transactions" ? "mm-active" : ""} to="/swap-transactions" onClick={(e) => this.toggleFunc2()}>Swap</Link>
                </li>

              </ul>
            </li>

            <li className={usersactivitylogs.includes(path) ? "mm-active" : ""}>
              <Link className={path === "users-activity-logs" ? "mm-active" : ""} to="/users-activity-logs" onClick={(e) => this.toggleFunc2()}>
                <i className="flaticon-381-note"></i>
                <span className="nav-text">Activity Logs</span>
              </Link>
            </li>

            <li className={mylinks.includes(path) ? "mm-active" : ""}>
              <Link className={path === "mylinks" ? "mm-active" : ""} to="/mylinks" onClick={(e) => this.toggleFunc2()}>
                <i className="flaticon-381-note"></i>
                <span className="nav-text">MY Links</span>
              </Link>
            </li>

            <li className={management.includes(path) ? "mm-active" : ""}>
              <Link className="has-arrow ai-icon" to="#" >
                <i className="flaticon-381-news"></i>
                <span className="nav-text">Managements</span>
              </Link>
              <ul className={`${management.includes(path) ? "mm-collapse mm-show" : "mm-collapse"}`}>
                <li>
                  <Link className={path === "news" ? "mm-active" : ""} to="/news" onClick={(e) => this.toggleFunc2()}>News</Link>
                </li>
                <li>
                  <Link className={path === "kyc" ? "mm-active" : ""} to="/kyc" onClick={(e) => this.toggleFunc2()}>KYC List</Link>
                </li>
                <li>
                  <Link className={path === "inquiry-log" ? "mm-active" : ""} to="/inquiry-log" onClick={(e) => this.toggleFunc2()}>Inquiry</Link>
                </li>

              </ul>
            </li>

          </MM>
          <div className="copyright">
            <p> <strong>{process.env.REACT_APP_NAME} </strong> © {new Date().getFullYear()} All Rights Reserved </p>
          </div>
        </PerfectScrollbar>
      </div>
    );
  }
}

export default withRouter(SideBar);
