import React, { useState, useEffect } from "react";
import { Link } from 'react-router-dom';
import { Modal } from 'react-bootstrap';
import MaterialTable from "@material-table/core";
import { ExportCsv, ExportPdf } from "@material-table/exporters";
import Snackbars from '../../../jsx/pages/Snackbars';
import { useTranslation } from "react-i18next";
import axios from 'axios';
import moment from 'moment';
import Loader from '../../pages/Loader';
import validator from 'validator';
import { CsvBuilder } from 'filefy';
import jsPDF from 'jspdf';
import 'jspdf-autotable';


export const Rewards_Withdrawal_Request = () => {
    const { t } = useTranslation();
    const [loader, setLoader] = useState(false);
    const [error, setError] = useState(false);
    const [success, setSuccess] = useState(false);
    const [error_msg, setErrorMsg] = useState("");
    const [success_msg, setSuccessMsg] = useState("");


    const [Modal1, setModal1] = useState(false);
    const [ResultData1, setResultData1] = useState([]);
    const [ResultData, setResultData] = useState([]);
    const [FilterData, setFilterData] = useState([]);
    const [Customer, setCustomer] = useState("");
    const [FromDate, setFromDate] = useState("");
    const [ToDate, setToDate] = useState("");
    const [TotalAmount, setTotalAmount] = useState("");
    const [checkTotalAmount, setcheckTotalAmount] = useState("");
    const [WithdrawalAmount, setWithdrawalAmount] = useState("");
    const [WithdrawalAmountError, setWithdrawalAmountError] = useState("");


    const FieldRequiredError = t('LABEL.FIELD_REQUIERD');
    const validateWithdrawalAmount = (value) => {

        var total = parseFloat(TotalAmount) - parseFloat(checkTotalAmount);
        var display = calc(total);
        setWithdrawalAmount(value);
        if (validator.isEmpty(value)) {
            setWithdrawalAmountError(FieldRequiredError);
            return false;
        } else if (parseFloat(value) > parseFloat(display)) {
            setWithdrawalAmountError("You are withdraw maximum " + display + " Amount.");
            return false;
        }
        else if (!validator.isFloat(value)) {
            setWithdrawalAmountError(t('LABEL.INVALID_WITH'));
            return false;
        } else if (parseFloat(value) > parseFloat(TotalAmount)) {
            setWithdrawalAmountError(t('LABEL.GRETER_WITH'));
            return false;
        } else {
            setWithdrawalAmountError("");
            return true;
        }
    }

    function calc(theform) {
        var num = theform, rounded = theform.rounded
        var with2Decimals = num.toString().match(/^-?\d+(?:\.\d{0,2})?/)[0]
        return with2Decimals;
    }

    const handlesubmit = () => {
        if (!validateWithdrawalAmount(WithdrawalAmount)) {
            return false;
        } else {
            if (WithdrawalAmount <= 0) {
                setWithdrawalAmountError("Withdrawal amount is greater than 0.");
                return false;
            }
            setModal1(false);
            setLoader(true);

            var jsonpar = JSON.stringify({
                'manager_id': window.sessionStorage.getItem("manager_id"),
                'user_id': ResultData1.user_acc_user_id,
                'account_id': ResultData1.user_acc_account_id_pk,
                'withdraw_amount': WithdrawalAmount,
            });
            const headers = {
                'Content-Type': 'application/json',
                'manageraccesstoken': process.env.REACT_APP_MANAGER_ACCESS_TOKEN,
                'managerauthtoken': 'Bearer ' + window.sessionStorage.getItem("manager_jwt"),
            }
            axios.post(process.env.REACT_APP_API_URL + 'addManagerWithdrawalRequest', jsonpar, {
                headers: headers
            })
                .then(function (res) {
                    res = res.data;
                    setLoader(false);

                    if (res.code === "200") {
                        handleLoad();
                        setWithdrawalAmount("");

                        setSuccess(true);
                        setSuccessMsg(res.message);
                        setTimeout(() => {
                            setSuccess(false);
                        }, 6000);
                    } else {
                        setWithdrawalAmount("");
                        setError(true);
                        setErrorMsg(res.message);
                        setTimeout(() => {
                            setError(false);
                        }, 6000);
                    }

                })
                .catch(function (error) {
                    setLoader(false);
                    setWithdrawalAmount("");
                    setError(true);
                    setErrorMsg(t('ERROR_MSG.SOMETHING'));
                    setTimeout(() => {
                        setError(false);
                    }, 3000);
                });
        }
    }

    const handleChangeFromDate = (val) => {
        setFromDate(val);
        filter("1," + val, "", "");
    }
    const handleChangeToDate = (val) => {
        setToDate(val);
        filter("", "1," + val, "");
    }
    const handleChangeCustmer = (val) => {
        setCustomer(val);
        filter("", "", "1," + val);
    }
    const filter = (from_date = "", to_date = "", customer_email = "") => {
        var tempFromDate = FromDate;
        var tempToDate = ToDate;
        var tempCustomerEmail = Customer;

        if (from_date !== "") {
            if (from_date.split(",")[0] === "1" && from_date.split(",")[1] !== "") {
                tempFromDate = from_date.split(",")[1];
            }
            else if (from_date.split(",")[0] === "1" && from_date.split(",")[1] === "") {
                tempFromDate = "";
            }
        }

        if (to_date !== "") {
            if (to_date.split(",")[0] === "1" && to_date.split(",")[1] !== "") {
                tempToDate = to_date.split(",")[1];
            }
            else if (to_date.split(",")[0] === "1" && to_date.split(",")[1] === "") {
                tempToDate = "";
            }
        }

        if (customer_email !== "") {
            if (customer_email.split(",")[0] === "1" && customer_email.split(",")[1] !== "") {
                tempCustomerEmail = customer_email.split(",")[1];
            }
            else if (customer_email.split(",")[0] === "1" && customer_email.split(",")[1] === "") {
                tempCustomerEmail = "";
            }
        }

        let TempArr = [];
        ResultData.forEach(ResultData_ => {
            if (tempFromDate === "" && tempToDate === "" && (tempCustomerEmail !== "" && tempCustomerEmail === ResultData_.manager_withdraw_status)) {
                TempArr.push(ResultData_);
            }
            else if ((tempFromDate !== "" && moment(ResultData_.created_datetime).format('YYYY-MM-DD') >= moment(tempFromDate).format('YYYY-MM-DD')) && tempToDate === "" && tempCustomerEmail === "") {
                TempArr.push(ResultData_);
            }
            else if (tempFromDate === "" && (tempToDate !== "" && moment(ResultData_.created_datetime).format('YYYY-MM-DD') <= moment(tempToDate).format('YYYY-MM-DD')) && tempCustomerEmail === "") {
                TempArr.push(ResultData_);
            }
            else if ((tempFromDate !== "" && moment(ResultData_.created_datetime).format('YYYY-MM-DD') >= moment(tempFromDate).format('YYYY-MM-DD')) && (tempToDate !== "" && moment(ResultData_.created_datetime).format('YYYY-MM-DD') <= moment(tempToDate).format('YYYY-MM-DD')) && tempCustomerEmail === "") {
                TempArr.push(ResultData_);
            }
            else if ((tempFromDate !== "" && moment(ResultData_.created_datetime).format('YYYY-MM-DD') >= moment(tempFromDate).format('YYYY-MM-DD')) && tempToDate === "" && (tempCustomerEmail !== "" && tempCustomerEmail === ResultData_.manager_withdraw_status)) {
                TempArr.push(ResultData_);
            }
            else if (tempFromDate === "" && (tempToDate !== "" && moment(ResultData_.created_datetime).format('YYYY-MM-DD') <= moment(tempToDate).format('YYYY-MM-DD')) && (tempCustomerEmail !== "" && tempCustomerEmail === ResultData_.manager_withdraw_status)) {
                TempArr.push(ResultData_);
            }
            else if ((tempFromDate !== "" && moment(ResultData_.created_datetime).format('YYYY-MM-DD') >= moment(tempFromDate).format('YYYY-MM-DD')) && (tempToDate !== "" && moment(ResultData_.created_datetime).format('YYYY-MM-DD') <= moment(tempToDate).format('YYYY-MM-DD')) && (tempCustomerEmail !== "" && tempCustomerEmail === ResultData_.manager_withdraw_status)) {
                TempArr.push(ResultData_);
            }
            else if (tempFromDate === "" && tempToDate === "" && tempCustomerEmail === "") {
                TempArr.push(ResultData_);
            }
        });
        setFilterData(TempArr);
    }

    const handleLoad = () => {
        var jsonpar = JSON.stringify({
            'manager_id': window.sessionStorage.getItem("manager_id")
        });
        const headers = {
            'Content-Type': 'application/json',
            'manageraccesstoken': process.env.REACT_APP_MANAGER_ACCESS_TOKEN,
            'managerauthtoken': 'Bearer ' + window.sessionStorage.getItem("manager_jwt"),
        }
        axios.post(process.env.REACT_APP_API_URL + 'getAllManagerWithdrawalRequest', jsonpar, {
            headers: headers
        })
            .then(function (res) {
                res = res.data;
                var pendingamount = 0;
                if (res.code === "200") {
                    setTotalAmount(res.managerData[0].total_reward);
                    res.managerData.forEach(element => {
                        if (element.user_acc_account_currency_type === "USD") {
                            setResultData1(element);
                        }
                    });


                    let TempArr = [];
                    res.data.forEach(element => {

                        var StatusBTN = "-";
                        switch (element.manager_withdraw_status) {
                            case "Completed":
                                StatusBTN = <div><button className="btn btn-outline-success btn-rounded">Success</button></div>
                                break;

                            case "Pending":
                                StatusBTN = <div><button className="btn btn-outline-warning btn-rounded">Pending</button></div>
                                break;

                            default:
                                StatusBTN = <div><button className="btn btn-outline-danger btn-rounded">Declined</button></div>
                                break;
                        }

                        if (element.manager_withdraw_status === "Pending") {
                            pendingamount = parseFloat(pendingamount) + parseFloat(element.withdraw_amount);
                        }
                        let temp = {
                            ID: element.manager_withdraw_id,
                            Date: moment(element.created_datetime).format('MM / DD / YYYY hh:mm:ss'),
                            RequestType: element.withdraw_type,
                            WithdrawalAmount: element.withdraw_amount + " " + element.coin_code,
                            Status: StatusBTN,
                            created_datetime: element.created_datetime,
                            manager_withdraw_status: element.manager_withdraw_status,
                            StatusText: element.manager_withdraw_status,
                        }
                        TempArr.push(temp);
                    });
                    setResultData(TempArr);
                    setFilterData(TempArr);

                    setcheckTotalAmount(pendingamount);

                    setTimeout(() => {
                        setLoader(false);
                    }, 500);
                } else {
                    setLoader(false);
                }
            })
            .catch(function (error) {
                setLoader(false);
            });
    }


    useEffect(() => {
        setLoader(true);
        handleLoad();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleChangeCSVNdPDF = (val) => {
        var FileName = "Withdrawal Request Transactions";

        var columns = [
            { title: t('TRANSACTION.DATE_LA'), field: 'Date' },
            { title: t('LABEL.REQ_TYPE'), field: 'RequestType' },
            { title: t('LABEL.WITH_AMOUNT'), field: 'WithdrawalAmount' },
            { title: t('TRANSACTION.STATUS_LA'), field: 'Status' },
        ];

        const columnTitles = columns.map(columnDef => columnDef.title);

        // const FileData = FilterData.map(rowData =>
        //     columns.map(columnDef => rowData[columnDef.field]),
        // );
        const FileData = FilterData.map(rowData =>
            [rowData["Date"],
            rowData["RequestType"],
            rowData["WithdrawalAmount"],
            rowData["StatusText"]]
        );
        if (val === "CSV") {
            const builder = new CsvBuilder(FileName + `.csv`)
                .setColumns(columnTitles)
                .addRows(FileData)
                .exportFile();

            return builder;
        }
        else if (val === "PDF") {
            const doc = new jsPDF();

            doc.autoTable({
                head: [columnTitles],
                body: FileData,
            });

            doc.save(FileName + `.pdf`);
        }
    }



    return (
        <div>
            <div className="card">
                <div className="card-header page-title-space mobile-card-header">
                    <h2 className="text-black font-w600 mb-0">{t('LABEL.WITH_REQ')}</h2>
                    <div className="mobileFullWidth">

                        <Modal className="modal fade" show={Modal1} size="lg">
                            <div className="modal-dialog-centered " >
                                <div className="modal-content">
                                    <div className="modal-header">
                                        <h3 className="modal-title">{t('LABEL.WITH_REQ')}</h3>
                                        <button type="button" className="close" onClick={() => setModal1(false)}><span>&times;</span>
                                        </button>
                                    </div>
                                    <div className="modal-body">
                                        <div className="row">
                                            <div className="col-md-12">
                                                <div class="card-body available-balance">
                                                    <div class="media align-items-center invoice-card">
                                                        <div class="media-body">
                                                            <h2 class="fs-38 text-black font-w600">${TotalAmount}</h2>
                                                            <span class="fs-18">{t('LABEL.TOTAL_AMOUNT')}</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-12">
                                                <div className="form-group">
                                                    <label className="mb-2 ">{t('LABEL.WITH_AMOUNT')}</label>
                                                    <input type="number" value={WithdrawalAmount} onChange={(e) => validateWithdrawalAmount(e.target.value)} className="form-control text-black" placeholder={t('LABEL.ENTER_WITH_AMOUNT')} />
                                                    {WithdrawalAmountError !== "" ? <label className='text-red'>{WithdrawalAmountError}</label> : ""}
                                                </div>
                                                <p class="WNote">{t('LABEL.WITH_NOTE')}</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="modal-footer">
                                        <button type="button" className="btn btn-light btn-rounded light" onClick={() => setModal1(false)}>{t('BUTTONS.CANCEL')}</button>
                                        <button type="button" className="btn btn-primary btn-rounded" onClick={handlesubmit}>{t('LABEL.SEND_REQ')}</button>
                                    </div>
                                </div>
                            </div>
                        </Modal>
                    </div>
                </div>
            </div>

            {error ? <Snackbars open_close={error} type="error" message={error_msg} /> : ""}
            {success ? <Snackbars open_close={success} type="success" message={success_msg} /> : ""}

            {loader === true ?
                <div>
                    <Loader open_close={loader} />
                </div>
                :
                <div className="card">
                    <div className="card-body bdr-btm-card">
                        <div className="row">
                            <div className="col-md-3">
                                <div className="form-group position-relative">
                                    <label className="mb-2 ">
                                        <span>{t('TRANSACTION.FROM_DATE')}</span>
                                    </label>
                                    <div className="col-xs-12">
                                        <input type="date" onChange={(e) => handleChangeFromDate(e.target.value)} value={FromDate} className="form-control" />
                                    </div>
                                </div>
                            </div>

                            <div className="col-md-3">
                                <div className="form-group position-relative">
                                    <label className="mb-2 ">
                                        <span>{t('TRANSACTION.TO_DATE')}</span>
                                    </label>
                                    <div className="col-xs-12">
                                        <input type="date" onChange={(e) => handleChangeToDate(e.target.value)} value={ToDate} className="form-control" />
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6 position-relative">
                                <div className="form-group position-relative">
                                    <label className="mb-2 ">
                                        <span>{t('TRANSACTION.STATUS_LA')}</span>
                                    </label>
                                    <select className="form-control webkit-none" onChange={(e) => handleChangeCustmer(e.target.value)} value={Customer}>
                                        <option value="">{t('ACCOUNT_TRANSACTIONS.SELECT_ALL')}</option>
                                        <option value={"Completed"}>Success</option>
                                        <option value={"Pending"}>Pending</option>
                                        <option value={"Failed"}>Declined</option>
                                    </select>
                                    <i className="fa fa-caret-down down-arrow" aria-hidden="true"></i>
                                </div>
                            </div>

                        </div>
                        <div className="table-responsive">

                        </div>
                    </div>
                    <div className="card-body">
                        <div className="position-relative">
                            <div className="table-responsive">
                                {FilterData.length > 0 ?
                                    <MaterialTable
                                        title="Withdrawal Request Transactions"
                                        columns={[
                                            { title: t('TRANSACTION.DATE_LA'), field: 'Date' },
                                            { title: t('LABEL.REQ_TYPE'), field: 'RequestType' },
                                            { title: t('LABEL.WITH_AMOUNT'), field: 'WithdrawalAmount' },
                                            { title: t('TRANSACTION.STATUS_LA'), field: 'Status' },
                                        ]}
                                        data={FilterData}
                                        options={{
                                            exportMenu: [
                                                { label: "Export PDF", exportFunc: (cols, datas) => handleChangeCSVNdPDF("PDF") },
                                                { label: "Export CSV", exportFunc: (cols, datas) => handleChangeCSVNdPDF("CSV") },
                                            ], paging: true, pageSize: 5, pageSizeOptions: [5, 10, 15], emptyRowsWhenPaging: false,
                                        }}
                                    />
                                    : <div className="d-sm-flex d-block border-0 pb-0">
                                        <div className="pr-3 mr-auto mb-sm-0 mb-3 nofoundFullWidth">
                                            <h4 className="fs-20 text-black mb-1 nofoundTitle">Withdrawal Request Transactions Overview</h4>
                                            <span className="fs-12 nofoundText">No Data Found</span>
                                        </div>
                                    </div>}
                            </div>
                        </div>
                    </div>
                </div>
            }
        </div>
    )

}
export default Rewards_Withdrawal_Request;