import { lazy, Suspense, useEffect, useState } from 'react';
import i18next from "i18next";
/// Components
import Index from './jsx/index';
import { Route, Switch } from 'react-router-dom';
/// Style
import "./vendor/bootstrap-select/dist/css/bootstrap-select.min.css";
import "./css/style.css";
import "./css/custom.css";


const SignUp = lazy(() => import('./jsx/pages/Registration'));
const ForgotPassword = lazy(() => import('./jsx/pages/ForgotPassword'));
// Custom Pages
const RegisterVerifyOTP = lazy(() => import('./jsx/pages/RegisterVerifyOTP'));
const RegisterGoogleAuth = lazy(() => import('./jsx/pages/RegisterGoogleAuth'));
const Error404 = lazy(() => import('./jsx/pages/Error404'));

const Login = lazy(() => {
    return new Promise(resolve => {
        setTimeout(() => resolve(import('./jsx/pages/Login')), 500);
    });
});
function App(props) {
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [islogin, setislogin] = useState(0);
    useEffect(() => {
        if (window.sessionStorage.getItem("languagesessionuser")) {
            i18next.changeLanguage(window.sessionStorage.getItem("languagesessionuser"));
        } else {
            i18next.changeLanguage("en");
        }


    }, []);


    let routes = (
        <Switch>
            <Route exact path='/' component={Login} />
            <Route exact path='/login' component={Login} />
            <Route exact path='/page-register' component={SignUp} />
            <Route exact path='/page-forgot-password' component={ForgotPassword} />
            <Route exact path='/register-google-auth' component={RegisterGoogleAuth} />
            <Route exact path="/register-verify-otp">
                <RegisterVerifyOTP setIsLoggedIn={setIsLoggedIn} />
            </Route>
            {isLoggedIn === false ?
                <Route exact path='*' component={Login} />
                : ""}
        </Switch>
    );
    if (window.sessionStorage.getItem("manager_id") > 0) {
        return (
            <>
                <Suspense fallback={
                    <div id="preloader">
                        <div className="sk-three-bounce">
                            <div className="sk-child sk-bounce1"></div>
                            <div className="sk-child sk-bounce2"></div>
                            <div className="sk-child sk-bounce3"></div>
                        </div>
                    </div>
                }
                >
                    <Index />

                </Suspense>
            </>
        );

    } else {
        return (
            <div className="vh-100">
                <Suspense fallback={
                    <div id="preloader">
                        <div className="sk-three-bounce">
                            <div className="sk-child sk-bounce1"></div>
                            <div className="sk-child sk-bounce2"></div>
                            <div className="sk-child sk-bounce3"></div>
                        </div>
                    </div>
                }
                >
                    {routes}
                </Suspense>
            </div>
        );
    }
};

export default App;
