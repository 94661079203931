import React, { useState, useEffect } from "react";
import { Modal } from 'react-bootstrap';
import MaterialTable from "@material-table/core";
import { ExportCsv, ExportPdf } from "@material-table/exporters";
import { Link, useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import axios from 'axios';
import Loader from '../../pages/Loader';

export const Cards_Contact = (props) => {
    const { t } = useTranslation();
    const history = useHistory();
    const [requestarray, setrequestarray] = useState([]);
    const [loader, setLoader] = useState(false);
    const [ViewDetails, setViewDetails] = useState(false);

    const [name, setname] = useState("");
    const [email, setemail] = useState("");
    const [phone, setphone] = useState("");
    const [address, setaddress] = useState("");
    const [city, setcity] = useState("");
    const [state, setstate] = useState("");
    const [zip, setzip] = useState("");
    const [country, setcountry] = useState("");
    const [companyName, setcompanyName] = useState("");


    useEffect(() => {
        handleLoad();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleLoad = () => {
        console.log(props);
        var jsonpar = {
            broker_id: props.location.state.broker_id,
        }
        const headers = {
            'Content-Type': 'application/json',
            'manageraccesstoken': process.env.REACT_APP_MANAGER_ACCESS_TOKEN,
            'managerauthtoken': 'Bearer ' + window.sessionStorage.getItem("manager_jwt"),
        }
        setLoader(true);
        axios.post(process.env.REACT_APP_API_URL + 'getbrokercontact', jsonpar, { headers: headers })
            .then(function (res) {
                res = res.data;
                if (res.code === "200") {
                    var TempArr = [];
                    res.data.forEach(element => {
                        var temp = {
                            no: "#" + element.item_id,
                            name: element.name,
                            email: element.email,
                            phonenumber: element.phone,
                            Action: <div><button onClick={(e) => viewdata(element.contact_id)} className="btn btn-primary btn-xs btn-rounded" title="View Contact Details"><i className="fa fa-eye"></i></button> <button onClick={(e) => vieworder(element.item_id)} className="btn btn-primary btn-xs btn-rounded" title="View Contact Orders"><i className="fa fa-first-order"></i></button> </div>
                        };
                        TempArr.push(temp);
                    });
                    setrequestarray(TempArr);
                    setTimeout(() => {
                        setLoader(false);
                    }, 1000);
                } else {
                    setLoader(false);
                }
            })
            .catch(function (error) {
                setLoader(false);
            });
    }

    const vieworder = (val) => {
        history.push({
            pathname: '/cards-contact-details',
            state: { contact_id: val, broker_id: props.location.state.broker_id }
        });
    }

    const viewdata = (val) => {
        var jsonpar = {
            contact_id: val,
        }
        const headers = {
            'Content-Type': 'application/json',
            'manageraccesstoken': process.env.REACT_APP_MANAGER_ACCESS_TOKEN,
            'managerauthtoken': 'Bearer ' + window.sessionStorage.getItem("manager_jwt"),
        }
        setLoader(true);
        axios.post(process.env.REACT_APP_API_URL + 'viewcontactdetails', jsonpar, { headers: headers })
            .then(function (res) {
                res = res.data;
                if (res.code === "200") {
                    setname(res.data[0].name);
                    setemail(res.data[0].email);
                    setphone(res.data[0].phone);
                    setaddress(res.data[0].address);
                    setcity(res.data[0].city);
                    setstate(res.data[0].state);
                    setzip(res.data[0].zip);
                    setcountry(res.data[0].country);
                    setcompanyName(res.data[0].companyName);
                    setViewDetails(true);
                    setTimeout(() => {
                        setLoader(false);
                    }, 1000);
                } else {
                    setLoader(false);
                }
            })
            .catch(function (error) {
                setLoader(false);
            });

    }

    return (
        <div>
            <div className="card">
                <div className="card-header page-title-space mobile-card-header">
                    <h2 className="text-black font-w600 mb-0">NBC Card Contacts</h2>
                    <Link to="/broker-list"><i class="fa fa-arrow-left"></i> Back</Link>
                </div>
            </div>
            {loader === true ?
                <div>
                    <Loader open_close={loader} />
                </div>
                :
                <div className="card">

                    <div className="card-body">
                        <div className="position-relative">
                            <div className="table-responsive">
                                {requestarray.length > 0 ?
                                    <MaterialTable
                                        title="NBC Card Contacts"
                                        columns={[
                                            { title: '#', field: 'no' },
                                            { title: 'Name', field: 'name' },
                                            { title: 'Email', field: 'email' },
                                            { title: 'Phone Number ', field: 'phonenumber' },
                                            { title: 'Action', field: 'Action', align: 'center', },
                                        ]}
                                        data={requestarray}
                                        options={{
                                            exportMenu: [
                                                { label: "Export PDF", exportFunc: (cols, datas) => ExportPdf(cols, datas, "NBCCard_Contacts") },
                                                { label: "Export CSV", exportFunc: (cols, datas) => ExportCsv(cols, datas, "NBCCard_Contacts") },
                                            ], paging: true, pageSize: 5, pageSizeOptions: [5, 10, 15], emptyRowsWhenPaging: false,
                                        }}
                                    />
                                    : <div className="d-sm-flex d-block border-0 pb-0">
                                        <div className="pr-3 mr-auto mb-sm-0 mb-3 nofoundFullWidth">
                                            <h4 className="fs-20 text-black mb-1 nofoundTitle">NBC Card Contacts Overview</h4>
                                            <span className="fs-12 nofoundText">No Data Found</span>
                                        </div>
                                    </div>}
                            </div>
                        </div>
                    </div>
                </div>
            }
            <Modal className="modal fade" show={ViewDetails} size={"xl"}>
                <div className="modal-dialog-centered " >
                    <div className="modal-content">
                        <div className="modal-header">
                            <h3 className="modal-title">{name}</h3>
                            <button type="button" className="close" onClick={() => setViewDetails(false)}><span>&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <div className="row">
                                <div className="col-lg-4 col-md-6 mb-3">
                                    <p className="fs-14 mb-1">Email ID</p>
                                    <span className="text-black">{email}</span>
                                </div>
                                <div className="col-lg-4 col-md-6 mb-3">
                                    <p className="fs-14 mb-1">Phone no.</p>
                                    <span className="text-black">{phone}</span>
                                </div>

                                <div className="col-lg-4 col-md-6 mb-3">
                                    <p className="fs-14 mb-1">Address</p>
                                    <span className="text-black">{address}</span>
                                </div>


                                <div className="col-lg-4 col-md-6 mb-3">
                                    <p className="fs-14 mb-1">City</p>
                                    <span className="text-black">{city}</span>
                                </div>
                                <div className="col-lg-4 col-md-6 mb-3">
                                    <p className="fs-14 mb-1">State</p>
                                    <span className="text-black">{state}</span>
                                </div>

                                <div className="col-lg-4 col-md-6 mb-3">
                                    <p className="fs-14 mb-1">Zipcode</p>
                                    <span className="text-black">{zip}</span>
                                </div>

                                <div className="col-lg-4 col-md-6 mb-3">
                                    <p className="fs-14 mb-1">Country</p>
                                    <span className="text-black">{country}</span>
                                </div>
                                <div className="col-lg-4 col-md-6 mb-3">
                                    <p className="fs-14 mb-1">Company Name</p>
                                    <span className="text-black">{companyName}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>
        </div>
    )

}
export default Cards_Contact;