import React, { useState, forwardRef, useEffect } from "react";
import { Link } from 'react-router-dom';
import { Modal } from 'react-bootstrap';
import MaterialTable from "@material-table/core";
import { ExportCsv, ExportPdf } from "@material-table/exporters";
import { useTranslation } from "react-i18next";
import axios from 'axios';
import moment from 'moment';
import Loader from '../../pages/Loader';
import { CsvBuilder } from 'filefy';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import Snackbars from '../../pages/Snackbars';
import validator from 'validator';

export const KYC = () => {
    const { t } = useTranslation();
    const [loader, setLoader] = useState(false);
    const [ResultData, setResultData] = useState([]);
    const [kycids, setkycids] = useState("");
    const [kycstatus, setkycstatus] = useState("");
    const [StatusModal, setStatusModal] = useState(false);
    const [kycstatus_error, setkycstatus_error] = useState("");
    const [error, setError] = useState(false);
    const [error_msg, setErrorMsg] = useState("");
    const [success, setSuccess] = useState(false);
    const [success_msg, setSuccessMsg] = useState("");

    const handleChangeCSVNdPDF = (val) => {
        var FileName = t('KYC.KYC_LIST');

        var columns = [
            { title: t('KYC.DATE'), field: 'Date' },
            { title: t('KYC.USER_NAME'), field: 'UserName' },
            { title: t('KYC.USER_EMAIL_ID'), field: 'UserEmailID' },
            { title: t('KYC.STATUS'), field: 'Status' },
        ];

        const columnTitles = columns.map(columnDef => columnDef.title);

        // const FileData = FilterData.map(rowData =>
        //     columns.map(columnDef => rowData[columnDef.field]),
        // );
        const FileData = ResultData.map(rowData =>
            [rowData["Date"],
            rowData["UserName"],
            rowData["UserEmailID"],
            rowData["StatusText"]]
        );
        if (val === "CSV") {
            const builder = new CsvBuilder(FileName + `.csv`)
                .setColumns(columnTitles)
                .addRows(FileData)
                .exportFile();

            return builder;
        }
        else if (val === "PDF") {
            const doc = new jsPDF();

            doc.autoTable({
                head: [columnTitles],
                body: FileData,
            });

            doc.save(FileName + `.pdf`);
        }
    }

    const handlepopup = (element) => {
        setkycids(element.user_kyc_kyc_id);
        setStatusModal(true);
    }


    const handleLoad = () => {
        var jsonpar = JSON.stringify({
            'manager_id': window.sessionStorage.getItem("manager_id")
        });
        const headers = {
            'Content-Type': 'application/json',
            'manageraccesstoken': process.env.REACT_APP_MANAGER_ACCESS_TOKEN,
            'managerauthtoken': 'Bearer ' + window.sessionStorage.getItem("manager_jwt"),
        }
        axios.post(process.env.REACT_APP_API_URL + 'getAllUsers', jsonpar, {
            headers: headers
        })
            .then(function (res) {
                res = res.data;
                if (res.code === "200") {

                    let TempArr = [];
                    res.data.forEach(element => {
                        let ActionBTN = <div><Link to={"/kyc-details/" + btoa(element.user_id_pk)} className="btn btn-success shadow btn-xs edit-shape"><i className="fa fa-eye"></i></Link></div>;

                        let StatusBTN = "-";
                        switch (element.user_kyc_kyc_status) {
                            case "Approved":
                                StatusBTN = <div><Link to="#" className="btn btn-outline-success btn-rounded">Approved</Link></div>;
                                break;

                            case "Pending":
                                StatusBTN = <div><button onClick={() => handlepopup(element)} className="btn btn-outline-warning btn-rounded">Pending</button></div>;
                                break;

                            default:
                                StatusBTN = <div><button onClick={() => handlepopup(element)} className="btn btn-outline-danger btn-rounded">Declined</button></div>;
                                break;
                        }

                        let temp = {
                            No: element.user_id_pk,
                            Date: moment(element.created_datetime).format('MM / DD / YYYY hh:mm:ss'),
                            UserName: element.first_name + " " + element.last_name,
                            UserEmailID: element.email_id,
                            Action: ActionBTN,
                            Status: StatusBTN,
                            StatusText: element.user_kyc_kyc_status,
                        }
                        TempArr.push(temp);
                    });
                    setResultData(TempArr);

                    setTimeout(() => {
                        setLoader(false);
                    }, 1000);
                } else {
                    setLoader(false);
                }
            })
            .catch(function (error) {
                setLoader(false);
            });
    }


    useEffect(() => {
        setLoader(true);
        handleLoad();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const validateStatus = (value) => {
        setkycstatus(value);
        if (!validator.isEmpty(value)) {
            setkycstatus_error("");
            return true;
        } else {
            setkycstatus_error("Please select kyc status");
            return false;
        }
    }

    const submitKycStatus = () => {
        if (!validateStatus(kycstatus)) {
            setkycstatus_error("Please select kyc status");
            return false;
        } else {
            setkycstatus_error("");
            setStatusModal(false);
            setLoader(true);
            let jsonpar = {
                kycid: kycids,
                kycstatus: kycstatus,
            }
            const headers = {
                'Content-Type': 'application/json',
                'manageraccesstoken': process.env.REACT_APP_MANAGER_ACCESS_TOKEN,
                'managerauthtoken': 'Bearer ' + window.sessionStorage.getItem("manager_jwt"),
            }
            axios.post(process.env.REACT_APP_API_URL + 'updatekycstatus', jsonpar, {
                headers: headers
            })
                .then(function (res) {
                    res = res.data;
                    if (res.code === "200") {
                        setkycids("");
                        setkycstatus("");
                        setLoader(false);
                        setSuccess(true);
                        setSuccessMsg(res.message);
                        handleLoad();
                        setTimeout(() => {
                            setSuccess(false);
                        }, 3000);
                    } else {
                        setLoader(false);
                        setError(true);
                        setErrorMsg(res.message);
                        setTimeout(() => {
                            setError(false);
                        }, 3000);
                    }
                })
                .catch(function (error) {
                    setLoader(false);
                });
        }
    }


    return (
        <div>
            {error ? <Snackbars open_close={error} type="error" message={error_msg} /> : ""}
            {success ? <Snackbars open_close={success} type="success" message={success_msg} /> : ""}
            <div className="card">
                <div className="card-header page-title-space mobile-card-header">
                    <h2 className="text-black font-w600 mb-0">{t('KYC.KYC')}</h2>
                </div>
            </div>
            <Modal className="modal fade" show={StatusModal} size="lg">
                <div className="modal-dialog-centered " >
                    <div className="modal-content">
                        <div className="modal-header">
                            <h3 className="modal-title">KYC Status</h3>
                            <button type="button" className="close" onClick={() => setStatusModal(false)}><span>&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="form-group position-relative">
                                        <label className="mb-2">Change KYC Status</label>
                                        <select className="form-control webkit-none" onChange={(e) => validateStatus(e.target.value)} value={kycstatus}>
                                            <option value=" ">Select KYC Status</option>
                                            <option value="Approved">Approved</option>
                                            <option value="Rejected">Reject</option>
                                        </select>
                                        <i className="fa fa-caret-down down-arrow" aria-hidden="true"></i>
                                    </div>
                                    {kycstatus_error !== "" ? <label className='text-red'>{kycstatus_error}</label> : ""}
                                </div>

                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-light btn-rounded light" onClick={() => setStatusModal(false)}>Close</button>
                            <button type="button" onClick={submitKycStatus} className="btn btn-primary btn-rounded">Submit</button>
                        </div>
                    </div>
                </div>
            </Modal>
            {loader === true ?
                <div>
                    <Loader open_close={loader} />
                </div>
                :
                <div className="card">
                    <div className="card-body">
                        <div className="position-relative">
                            <div className="table-responsive">
                                {ResultData.length > 0 ?
                                    <MaterialTable
                                        title={t('KYC.KYC_LIST')}
                                        columns={[
                                            { title: t('KYC.DATE'), field: 'Date' },
                                            { title: t('KYC.USER_NAME'), field: 'UserName' },
                                            { title: t('KYC.USER_EMAIL_ID'), field: 'UserEmailID' },
                                            { title: t('KYC.ACTION'), field: 'Action' },
                                            { title: t('KYC.STATUS'), field: 'Status' },
                                        ]}
                                        data={ResultData}
                                        options={{
                                            exportMenu: [
                                                { label: "Export PDF", exportFunc: (cols, datas) => handleChangeCSVNdPDF("PDF") },
                                                { label: "Export CSV", exportFunc: (cols, datas) => handleChangeCSVNdPDF("CSV") },
                                            ], paging: true, pageSize: 5, pageSizeOptions: [5, 10, 15], emptyRowsWhenPaging: false,
                                        }}
                                    />
                                    : <div className="d-sm-flex d-block border-0 pb-0">
                                        <div className="pr-3 mr-auto mb-sm-0 mb-3 nofoundFullWidth">
                                            <h4 className="fs-20 text-black mb-1 nofoundTitle">{t('KYC.KYC_LIST')} Overview</h4>
                                            <span className="fs-12 nofoundText">No Data Found</span>
                                        </div>
                                    </div>}
                            </div>
                        </div>
                    </div>
                </div>
            }
        </div>

    )

}
export default KYC;