import React, { useState, forwardRef } from "react";
import Select from 'react-select'
import { DatePicker } from "@y0c/react-datepicker";
import { Dropdown } from 'react-bootstrap';
import MaterialTable from "material-table";
import { Search, ViewColumn, SaveAlt, Remove, LastPage, FirstPage, FilterList, Edit, DeleteOutline, Clear, ChevronRight, ChevronLeft, Check, ArrowDownward, AddBox } from '@material-ui/icons';

const options = [
    { value: 'Kaushal Sadhu ( kaushal@nbcbanq.com )', label: 'Kaushal Sadhu ( kaushal@nbcbanq.com )' },
    { value: 'Hitesh Sadhu ( hitesh@nbcbanq.com )', label: 'Hitesh Sadhu ( hitesh@nbcbanq.com )' },
]

const materialTableIcons = {
    Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
    Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
    Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
    DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
    Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
    Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
    FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
    LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
    NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
    ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
    SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
    ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
    ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />)
};


export const Broker_Customer_Transaction = () => {
    const [selectedOption, setSelectedOption] = useState(null)

    return (
        <div>
            <div className="card">
                <div className="card-header page-title-space mobile-card-header">
                    <h2 className="text-black font-w600 mb-0">Broker Customer Transaction</h2>
                    <div className="mobileFullWidth">
                        <Dropdown className="custom-dropdown mb-0" >
                            <Dropdown.Toggle className="btn btn-primary btn-rounded i-false" variant='' >
                                <i className="las la-download scale5 mr-3"></i> Download Report
                                <i className="fa fa-caret-down text-white ml-3" aria-hidden="true"></i>
                            </Dropdown.Toggle>
                            <Dropdown.Menu className="dropdown-menu-right right-cstm">
                                <Dropdown.Item className="">CSV</Dropdown.Item >
                                <Dropdown.Item className="">PDF</Dropdown.Item >
                            </Dropdown.Menu>
                        </Dropdown>
                    </div>
                </div>
            </div>
            <div className="card">
                <div className="card-body bdr-btm-card">
                    <div className="row">
                        <div className="col-md-3">
                            <div className="form-group position-relative">
                                <label className="mb-2 ">
                                    <span>From Date</span>
                                </label>
                                <div className="col-xs-12">
                                    <DatePicker className="form-control" />
                                </div>
                            </div>
                        </div>

                        <div className="col-md-3">
                            <div className="form-group position-relative">
                                <label className="mb-2 ">
                                    <span>To Date</span>
                                </label>
                                <div className="col-xs-12">
                                    <DatePicker className="form-control" />
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 position-relative">
                            <label className="mb-2 ">
                                <span>Broker</span>
                            </label>
                            <Select className="webkit-none" defaultValue={selectedOption} onChange={setSelectedOption} options={options} />
                            <i className="fa fa-caret-down down-arrow select-arrow1" aria-hidden="true"></i>
                        </div>

                    </div>
                    <div className="table-responsive">

                    </div>
                </div>
                <div className="card-body">
                    <div className="position-relative">
                        <div className="table-responsive">
                            <MaterialTable
                                title="Customer Transaction"
                                icons={materialTableIcons}
                                options={{ exportButton: true, paging: true, pageSize: 5, pageSizeOptions: [5, 10, 15], emptyRowsWhenPaging: false, size: 'medium' }}
                                columns={[
                                    { title: 'Transaction ID', field: 'TransactionID', },
                                    { title: 'Date', field: 'Date' },
                                    { title: 'Transaction Type', field: 'TransactionType' },
                                    { title: 'Broker Name', field: 'BrokerName' },
                                    { title: 'Broker Email ID', field: 'BrokerEmailID' },
                                    { title: 'Customer Name', field: 'CustomerName' },
                                    { title: 'Manager Name', field: 'ManagerName' },
                                    { title: 'Amount', field: 'Amount', },
                                    { title: 'Fees %', field: 'Fees', },
                                    { title: 'Total Fees', field: 'TotalFees', },
                                ]}
                                data={[
                                    {
                                        "TransactionID": "#786541",
                                        "Date": "May 25, 2020, 08:22 AM",
                                        "TransactionType": "Internal Transfer",
                                        "BrokerName": "Kaushal Sadhu",
                                        "BrokerEmailID": "kaushal@domain.com",
                                        "CustomerName": "Rushi Sukla",
                                        "ManagerName": "Mehul Jain",
                                        "Amount": "100.00",
                                        "Fees": "10%",
                                        "TotalFees": "10.00",
                                    },
                                ]}
                            />
                        </div>

                    </div>
                </div>
            </div>

        </div>
    )

}
export default Broker_Customer_Transaction;