import React, { useState, useEffect } from "react";
import { Modal } from 'react-bootstrap';
import MaterialTable from "@material-table/core";
import { ExportCsv, ExportPdf } from "@material-table/exporters";
import { useTranslation } from "react-i18next";
import axios from 'axios';
import moment from 'moment';
import Loader from '../../pages/Loader';
import Snackbars from '../../../jsx/pages/Snackbars';
import { CsvBuilder } from 'filefy';
import jsPDF from 'jspdf';
import 'jspdf-autotable';

export const Request_Cards = () => {
    const [RequestCard, setRequestCard] = useState(false);

    const { t } = useTranslation();
    const [requestarray, setrequestarray] = useState([]);
    const [originalarray, setoriginalarray] = useState([]);
    const [loader, setLoader] = useState(false);
    const [CustomerData, setCustomerData] = useState([]);
    const [FromDate, setFromDate] = useState("");
    const [ToDate, setToDate] = useState("");
    const [Customer, setCustomer] = useState("");
    const [statusval, setstatusval] = useState("");
    const [status_id, setstatus_id] = useState("");

    const [checkuserdata, setcheckuserdata] = useState([]);

    const [error, setError] = useState(false);
    const [success, setSuccess] = useState(false);
    const [error_msg, setErrorMsg] = useState("");
    const [success_msg, setSuccessMsg] = useState("");

    const handleChangeCSVNdPDF = (val) => {
        var FileName = "Request List";

        var columns = [
            { title: 'Card Type', field: 'CardType' },
            { title: 'Account Number', field: 'AccountNumber' },
            { title: 'Customer Name', field: 'CustomerName' },
            { title: 'Card Number', field: 'CardNumber' },
            { title: 'Status', field: 'Status' },
        ];

        const columnTitles = columns.map(columnDef => columnDef.title);

        // const FileData = FilterData.map(rowData =>
        //     columns.map(columnDef => rowData[columnDef.field]),
        // );
        const FileData = requestarray.map(rowData =>
            [rowData["CardType"],
            rowData["AccountNumber"],
            rowData["CustomerName"],
            rowData["CardNumber"],
            rowData["StatusText"]]
        );
        if (val === "CSV") {
            const builder = new CsvBuilder(FileName + `.csv`)
                .setColumns(columnTitles)
                .addRows(FileData)
                .exportFile();

            return builder;
        }
        else if (val === "PDF") {
            const doc = new jsPDF();

            doc.autoTable({
                head: [columnTitles],
                body: FileData,
            });

            doc.save(FileName + `.pdf`);
        }
    }

    useEffect(() => {
        handleLoad();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleLoad = () => {
        var jsonpar = {
            managerid: window.sessionStorage.getItem("manager_id"),
        }
        const headers = {
            'Content-Type': 'application/json',
            'manageraccesstoken': process.env.REACT_APP_MANAGER_ACCESS_TOKEN,
            'managerauthtoken': 'Bearer ' + window.sessionStorage.getItem("manager_jwt"),
        }
        setLoader(true);
        axios.post(process.env.REACT_APP_API_URL + 'getAllPendingCardRequest', jsonpar, { headers: headers })
            .then(function (res) {
                res = res.data;
                if (res.code === "200") {
                    setoriginalarray(res.data);
                    setCustomerData(res.userdata);
                    
                    var TempArrUser = [];
                    res.userdata.forEach(element => {
                        TempArrUser.push(element.user_id_pk);
                    })
                    setcheckuserdata(TempArrUser);
                    var TempArr = [];
                    res.data.forEach(element => {
                        var Status = <div><button onClick={(e) => changestatus(element.card_id)} className="btn btn-outline-success btn-rounded">Active</button> </div>;
                        if (element.usercard_status === "Approved") {
                            var Status = <div><button onClick={(e) => changestatus(element.card_id)} className="btn btn-outline-success btn-rounded">Approved</button> </div>;
                        } else if (element.usercard_status === "Blocked") {
                            var Status = <div><button onClick={(e) => changestatus(element.card_id)} className="btn btn-outline-danger btn-rounded">Blocked</button> </div>;
                        } else if (element.usercard_status === "Rejected") {
                            var Status = <div><button onClick={(e) => changestatus(element.card_id)} className="btn btn-outline-danger btn-rounded">Rejected</button> </div>;
                        } else if (element.usercard_status === "Pending") {
                            var Status = <div><button onClick={(e) => changestatus(element.card_id)} className="btn btn-outline-warning btn-rounded">Pending</button> </div>;
                        }
                        if (TempArrUser.includes(element.user_id_fk)) {
                            var temp = {
                                CardType: element.cardtypename,
                                AccountNumber: element.account_number,
                                CustomerName: element.card_customer_name,
                                CardNumber: "**** **** **** " + String(element.card_number).slice(-4),
                                Status: Status,
                                StatusText: element.usercard_status,
                            };
                            TempArr.push(temp);
                        }
                    });
                    setrequestarray(TempArr);
                    setTimeout(() => {
                        setLoader(false);
                    }, 1000);
                } else {
                    setLoader(false);
                }
            })
            .catch(function (error) {
                setLoader(false);
            });
    }

    const changestatus = (value) => {
        setstatus_id(value);
        setRequestCard(true);
    }

    const handleChangeFromDate = (val) => {
        setFromDate(val);
        filter("1," + val, "", "");
    }
    const handleChangeToDate = (val) => {
        setToDate(val);
        filter("", "1," + val, "");
    }
    const handleChangeCustmer = (val) => {
        setCustomer(val);
        filter("", "", "1," + val);
    }


    const filter = (from_date = "", to_date = "", customer_email = "") => {
        var tempFromDate = FromDate;
        var tempToDate = ToDate;
        var tempCustomerEmail = Customer;

        if (from_date !== "") {
            if (from_date.split(",")[0] === "1" && from_date.split(",")[1] !== "") {
                tempFromDate = from_date.split(",")[1];
            }
            else if (from_date.split(",")[0] === "1" && from_date.split(",")[1] === "") {
                tempFromDate = "";
            }
        }

        if (to_date !== "") {
            if (to_date.split(",")[0] === "1" && to_date.split(",")[1] !== "") {
                tempToDate = to_date.split(",")[1];
            }
            else if (to_date.split(",")[0] === "1" && to_date.split(",")[1] === "") {
                tempToDate = "";
            }
        }

        if (customer_email !== "") {
            if (customer_email.split(",")[0] === "1" && customer_email.split(",")[1] !== "") {
                tempCustomerEmail = customer_email.split(",")[1];
            }
            else if (customer_email.split(",")[0] === "1" && customer_email.split(",")[1] === "") {
                tempCustomerEmail = "";
            }
        }
        
        let TempArr = [];
        originalarray.forEach(ResultData_ => {
            var Status = <div><button onClick={(e) => changestatus(ResultData_.card_id)} className="btn btn-outline-success btn-rounded">Active</button> </div>;
            if (ResultData_.usercard_status === "Approved") {
                var Status = <div><button onClick={(e) => changestatus(ResultData_.card_id)} className="btn btn-outline-success btn-rounded">Approved</button> </div>;
            } else if (ResultData_.usercard_status === "Blocked") {
                var Status = <div><button onClick={(e) => changestatus(ResultData_.card_id)} className="btn btn-outline-danger btn-rounded">Blocked</button> </div>;
            } else if (ResultData_.usercard_status === "Rejected") {
                var Status = <div><button onClick={(e) => changestatus(ResultData_.card_id)} className="btn btn-outline-danger btn-rounded">Rejected</button> </div>;
            } else if (ResultData_.usercard_status === "Pending") {
                var Status = <div><button onClick={(e) => changestatus(ResultData_.card_id)} className="btn btn-outline-warning btn-rounded">Pending</button> </div>;
            }

            var temp = {
                CardType: ResultData_.cardtypename,
                AccountNumber: ResultData_.account_number,
                CustomerName: ResultData_.card_customer_name,
                CardNumber: "**** **** **** " + String(ResultData_.card_number).slice(-4),
                Status: Status,
            };

            if (tempFromDate === "" && tempToDate === "" && (tempCustomerEmail !== "" && tempCustomerEmail === ResultData_.user_id_fk)) {
                if (checkuserdata.includes(ResultData_.user_id_fk)) {
                    TempArr.push(temp);
                }
            }
            else if ((tempFromDate !== "" && moment(ResultData_.created_datetime).format('YYYY-MM-DD') >= moment(tempFromDate).format('YYYY-MM-DD')) && tempToDate === "" && tempCustomerEmail === "") {
                if (checkuserdata.includes(ResultData_.user_id_fk)) {
                    TempArr.push(temp);
                }
            }
            else if (tempFromDate === "" && (tempToDate !== "" && moment(ResultData_.created_datetime).format('YYYY-MM-DD') <= moment(tempToDate).format('YYYY-MM-DD')) && tempCustomerEmail === "") {
                if (checkuserdata.includes(ResultData_.user_id_fk)) {
                    TempArr.push(temp);
                }
            }
            else if ((tempFromDate !== "" && moment(ResultData_.created_datetime).format('YYYY-MM-DD') >= moment(tempFromDate).format('YYYY-MM-DD')) && (tempToDate !== "" && moment(ResultData_.created_datetime).format('YYYY-MM-DD') <= moment(tempToDate).format('YYYY-MM-DD')) && tempCustomerEmail === "") {
                if (checkuserdata.includes(ResultData_.user_id_fk)) {
                    TempArr.push(temp);
                }
            }
            else if ((tempFromDate !== "" && moment(ResultData_.created_datetime).format('YYYY-MM-DD') >= moment(tempFromDate).format('YYYY-MM-DD')) && tempToDate === "" && (tempCustomerEmail !== "" && tempCustomerEmail === ResultData_.user_id_fk)) {
                if (checkuserdata.includes(ResultData_.user_id_fk)) {
                    TempArr.push(temp);
                }
            }
            else if (tempFromDate === "" && (tempToDate !== "" && moment(ResultData_.created_datetime).format('YYYY-MM-DD') <= moment(tempToDate).format('YYYY-MM-DD')) && (tempCustomerEmail !== "" && tempCustomerEmail === ResultData_.user_id_fk)) {
                if (checkuserdata.includes(ResultData_.user_id_fk)) {
                    TempArr.push(temp);
                }
            }
            else if ((tempFromDate !== "" && moment(ResultData_.created_datetime).format('YYYY-MM-DD') >= moment(tempFromDate).format('YYYY-MM-DD')) && (tempToDate !== "" && moment(ResultData_.created_datetime).format('YYYY-MM-DD') <= moment(tempToDate).format('YYYY-MM-DD')) && (tempCustomerEmail !== "" && tempCustomerEmail === ResultData_.user_id_fk)) {
                if (checkuserdata.includes(ResultData_.user_id_fk)) {
                    TempArr.push(temp);
                }
            }
            else if (tempFromDate === "" && tempToDate === "" && tempCustomerEmail === "") {
                if (checkuserdata.includes(ResultData_.user_id_fk)) {
                    TempArr.push(temp);
                }
            }


        });
        setrequestarray(TempArr);
    }

    const submitstatus = () => {

        setRequestCard(false);
        var jsonpar = {
            card_id: status_id,
            statusval: statusval,
        }
        const headers = {
            'Content-Type': 'application/json',
            'manageraccesstoken': process.env.REACT_APP_MANAGER_ACCESS_TOKEN,
            'managerauthtoken': 'Bearer ' + window.sessionStorage.getItem("manager_jwt"),
        }
        setLoader(true);
        axios.post(process.env.REACT_APP_API_URL + 'updatecardstatus', jsonpar, { headers: headers })
            .then(function (res) {
                res = res.data;
                if (res.code === "200") {
                    setSuccess(true);
                    setSuccessMsg(res.message);
                    setLoader(false);
                    setstatusval("");
                    setstatus_id("");
                    handleLoad();
                    setTimeout(() => {
                        setSuccess(false);
                    }, 6000);
                } else {
                    setError(true);
                    setErrorMsg(res.message);
                    setLoader(false);
                    setTimeout(() => {
                        setError(false);
                    }, 6000);
                }
            })
            .catch(function (error) {
                setLoader(false);
            });
    }

    const validateStatus = (value) => {
        setstatusval(value);
    }


    return (
        <div>
            {error ? <Snackbars open_close={error} type="error" message={error_msg} /> : ""}
            {success ? <Snackbars open_close={success} type="success" message={success_msg} /> : ""}
            <div className="card">
                <div className="card-header page-title-space mobile-card-header">
                    <h2 className="text-black font-w600 mb-0">Request Card</h2>
                    <div className="mobileFullWidth">

                    </div>
                </div>
            </div>
            {loader === true ?
                <div>
                    <Loader open_close={loader} />
                </div>
                :
                <div className="card">
                    <div className="card-body bdr-btm-card">
                        <div className="row">
                            <div className="col-md-3">
                                <div className="form-group position-relative">
                                    <label className="mb-2 ">
                                        <span>From Date</span>
                                    </label>
                                    <div className="col-xs-12">
                                        <input type="date" onChange={(e) => handleChangeFromDate(e.target.value)} value={FromDate} className="form-control" />
                                    </div>
                                </div>
                            </div>

                            <div className="col-md-3">
                                <div className="form-group position-relative">
                                    <label className="mb-2 ">
                                        <span>To Date</span>
                                    </label>
                                    <div className="col-xs-12">
                                        <input type="date" onChange={(e) => handleChangeToDate(e.target.value)} value={ToDate} className="form-control" />
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6 fullWidth">
                                <label className="mb-2 ">
                                    <span>Customer Name</span>
                                </label>
                                <div className="form-group position-relative">
                                    <select className="form-control webkit-none" onChange={(e) => handleChangeCustmer(e.target.value)} value={Customer}>
                                        <option value="">{t('ACCOUNT_TRANSACTIONS.SELECT_ALL')}</option>
                                        {CustomerData && CustomerData.map((row) => (
                                            <option value={row.user_id_pk} key={row.user_id_pk}>{row.first_name + " " + row.last_name + " (" + row.email_id + ")"}</option>
                                        ))}
                                    </select>
                                    <i className="fa fa-caret-down down-arrow select-arrow1" aria-hidden="true"></i>
                                </div>
                            </div>
                        </div>
                        <div className="table-responsive">

                        </div>
                    </div>
                    <div className="card-body">
                        <div className="position-relative">
                            <div className="table-responsive">
                                {requestarray.length > 0 ?
                                    <MaterialTable
                                        title="Request List"
                                        columns={[
                                            { title: 'Card Type', field: 'CardType' },
                                            { title: 'Account Number', field: 'AccountNumber' },
                                            { title: 'Customer Name', field: 'CustomerName' },
                                            { title: 'Card Number', field: 'CardNumber' },
                                            { title: 'Status', field: 'Status' },
                                        ]}
                                        data={requestarray}
                                        options={{
                                            exportMenu: [
                                                { label: "Export PDF", exportFunc: (cols, datas) => handleChangeCSVNdPDF("PDF") },
                                                { label: "Export CSV", exportFunc: (cols, datas) => handleChangeCSVNdPDF("CSV") },
                                            ], paging: true, pageSize: 5, pageSizeOptions: [5, 10, 15], emptyRowsWhenPaging: false,
                                        }}
                                    />
                                    : <div className="d-sm-flex d-block border-0 pb-0">
                                        <div className="pr-3 mr-auto mb-sm-0 mb-3 nofoundFullWidth">
                                            <h4 className="fs-20 text-black mb-1 nofoundTitle">Request List Overview</h4>
                                            <span className="fs-12 nofoundText">No Data Found</span>
                                        </div>
                                    </div>}
                            </div>
                        </div>
                    </div>
                </div>
            }
            <Modal className="modal fade" show={RequestCard}>
                <div className="modal-dialog-centered " >
                    <div className="modal-content">
                        <div className="modal-header">
                            <h3 className="modal-title">Change Card Status</h3>
                            <button type="button" className="close" onClick={() => setRequestCard(false)}><span>&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <div className="col-md-12">
                                <div className="form-group position-relative">
                                    <label className="mb-2 ">
                                        <span>Change Card Status</span>
                                    </label>
                                    <select className="form-control webkit-none" id="sel1" onChange={(e) => validateStatus(e.target.value)} value={statusval}>
                                        <option value="Pending" selected={statusval === "Pending" ? "selected" : ""}>Pending</option>
                                        <option value="Approved" selected={statusval === "Approved" ? "selected" : ""}>Approved</option>
                                        <option value="Blocked" selected={statusval === "Blocked" ? "selected" : ""}>Blocked</option>
                                        <option value="Rejected" selected={statusval === "Rejected" ? "selected" : ""}>Rejected</option>
                                    </select>
                                    <i className="fa fa-caret-down down-arrow" aria-hidden="true"></i>
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-light btn-rounded light" onClick={() => setRequestCard(false)}>Cancel</button>
                            <button type="button" onClick={submitstatus} className="btn btn-primary btn-rounded">Submit</button>
                        </div>
                    </div>
                </div>
            </Modal>
        </div>
    )

}
export default Request_Cards;