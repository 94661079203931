import React, { useState, useEffect } from "react";
import MaterialTable from "@material-table/core";
import { ExportCsv, ExportPdf } from "@material-table/exporters";
import { Modal } from 'react-bootstrap';
import Snackbars from '../../../jsx/pages/Snackbars';
import { useTranslation } from "react-i18next";
import axios from 'axios';
import moment from 'moment';
import Loader from '../../pages/Loader';
import { CsvBuilder } from 'filefy';
import jsPDF from 'jspdf';
import 'jspdf-autotable';


export const Broker_Request = () => {
    const { t } = useTranslation();
    const [loader, setLoader] = useState(false);
    const [error, setError] = useState(false);
    const [success, setSuccess] = useState(false);
    const [error_msg, setErrorMsg] = useState("");
    const [success_msg, setSuccessMsg] = useState("");


    const [ResultData, setResultData] = useState([]);
    const [FilterData, setFilterData] = useState([]);
    const [CustomerData, setCustomerData] = useState([]);
    const [Customer, setCustomer] = useState("");
    const [FromDate, setFromDate] = useState("");
    const [ToDate, setToDate] = useState("");
    const [BrokerID, setBrokerID] = useState("");
    const [AdminApproveStatus, setAdminApproveStatus] = useState("");

    const [Modal1, setModal1] = useState(false);
    const openModal1 = (element) => {
        setBrokerID(element.broker_id);
        setAdminApproveStatus(element.admin_approve_status);
        setModal1(true);
    }
    const handlesubmit = () => {
        setModal1(false);
        setLoader(true);

        var jsonpar = JSON.stringify({
            'manager_id': window.sessionStorage.getItem("manager_id"),
            'broker_id': BrokerID,
            'admin_approve_status': AdminApproveStatus,
        });
        const headers = {
            'Content-Type': 'application/json',
            'manageraccesstoken': process.env.REACT_APP_MANAGER_ACCESS_TOKEN,
            'managerauthtoken': 'Bearer ' + window.sessionStorage.getItem("manager_jwt"),
        }
        axios.post(process.env.REACT_APP_API_URL + 'updateManagerBrokerAdminStatus', jsonpar, {
            headers: headers
        })
            .then(function (res) {
                res = res.data;
                if (res.code === "200") {
                    handleLoad();
                    setBrokerID("");
                    setAdminApproveStatus("");
                    setSuccess(true);
                    setSuccessMsg(res.message);
                    setLoader(false);
                    setTimeout(() => {
                        setSuccess(false);
                    }, 6000);
                } else {
                    setLoader(false);
                    setError(true);
                    setErrorMsg(res.message);
                    setTimeout(() => {
                        setError(false);
                    }, 6000);
                }

            })
            .catch(function (error) {
                setLoader(false);

                setError(true);
                setErrorMsg(t('ERROR_MSG.SOMETHING'));
                setTimeout(() => {
                    setError(false);
                }, 6000);
            });
    }

    const handleChangeFromDate = (val) => {
        setFromDate(val);
        filter("1," + val, "", "");
    }
    const handleChangeToDate = (val) => {
        setToDate(val);
        filter("", "1," + val, "");
    }
    const handleChangeCustmer = (val) => {
        setCustomer(val);
        filter("", "", "1," + val);
    }
    const filter = (from_date = "", to_date = "", customer_email = "") => {
        var tempFromDate = FromDate;
        var tempToDate = ToDate;
        var tempCustomerEmail = Customer;

        if (from_date !== "") {
            if (from_date.split(",")[0] === "1" && from_date.split(",")[1] !== "") {
                tempFromDate = from_date.split(",")[1];
            }
            else if (from_date.split(",")[0] === "1" && from_date.split(",")[1] === "") {
                tempFromDate = "";
            }
        }

        if (to_date !== "") {
            if (to_date.split(",")[0] === "1" && to_date.split(",")[1] !== "") {
                tempToDate = to_date.split(",")[1];
            }
            else if (to_date.split(",")[0] === "1" && to_date.split(",")[1] === "") {
                tempToDate = "";
            }
        }

        if (customer_email !== "") {
            if (customer_email.split(",")[0] === "1" && customer_email.split(",")[1] !== "") {
                tempCustomerEmail = customer_email.split(",")[1];
            }
            else if (customer_email.split(",")[0] === "1" && customer_email.split(",")[1] === "") {
                tempCustomerEmail = "";
            }
        }

        let TempArr = [];
        ResultData.forEach(ResultData_ => {
            if (tempFromDate === "" && tempToDate === "" && (tempCustomerEmail !== "" && tempCustomerEmail === ResultData_.EmailID)) {
                TempArr.push(ResultData_);
            }
            else if ((tempFromDate !== "" && moment(ResultData_.created_datetime).format('YYYY-MM-DD') >= moment(tempFromDate).format('YYYY-MM-DD')) && tempToDate === "" && tempCustomerEmail === "") {
                TempArr.push(ResultData_);
            }
            else if (tempFromDate === "" && (tempToDate !== "" && moment(ResultData_.created_datetime).format('YYYY-MM-DD') <= moment(tempToDate).format('YYYY-MM-DD')) && tempCustomerEmail === "") {
                TempArr.push(ResultData_);
            }
            else if ((tempFromDate !== "" && moment(ResultData_.created_datetime).format('YYYY-MM-DD') >= moment(tempFromDate).format('YYYY-MM-DD')) && (tempToDate !== "" && moment(ResultData_.created_datetime).format('YYYY-MM-DD') <= moment(tempToDate).format('YYYY-MM-DD')) && tempCustomerEmail === "") {
                TempArr.push(ResultData_);
            }
            else if ((tempFromDate !== "" && moment(ResultData_.created_datetime).format('YYYY-MM-DD') >= moment(tempFromDate).format('YYYY-MM-DD')) && tempToDate === "" && (tempCustomerEmail !== "" && tempCustomerEmail === ResultData_.EmailID)) {
                TempArr.push(ResultData_);
            }
            else if (tempFromDate === "" && (tempToDate !== "" && moment(ResultData_.created_datetime).format('YYYY-MM-DD') <= moment(tempToDate).format('YYYY-MM-DD')) && (tempCustomerEmail !== "" && tempCustomerEmail === ResultData_.EmailID)) {
                TempArr.push(ResultData_);
            }
            else if ((tempFromDate !== "" && moment(ResultData_.created_datetime).format('YYYY-MM-DD') >= moment(tempFromDate).format('YYYY-MM-DD')) && (tempToDate !== "" && moment(ResultData_.created_datetime).format('YYYY-MM-DD') <= moment(tempToDate).format('YYYY-MM-DD')) && (tempCustomerEmail !== "" && tempCustomerEmail === ResultData_.EmailID)) {
                TempArr.push(ResultData_);
            }
            else if (tempFromDate === "" && tempToDate === "" && tempCustomerEmail === "") {
                TempArr.push(ResultData_);
            }
        });
        setFilterData(TempArr);
    }

    const handleLoad = () => {
        var jsonpar = JSON.stringify({
            'manager_id': window.sessionStorage.getItem("manager_id")
        });
        const headers = {
            'Content-Type': 'application/json',
            'manageraccesstoken': process.env.REACT_APP_MANAGER_ACCESS_TOKEN,
            'managerauthtoken': 'Bearer ' + window.sessionStorage.getItem("manager_jwt"),
        }
        axios.post(process.env.REACT_APP_API_URL + 'getAllBrokerRequest', jsonpar, {
            headers: headers
        })
            .then(function (res) {
                res = res.data;
                if (res.code === "200") {

                    let CustomerEmailTempArr = [];
                    let CustomerDataTempArr = [];
                    let TempArr = [];
                    res.data.forEach(element => {
                        if (!CustomerEmailTempArr.includes(element.email_id)) {
                            var CustomerTempArr = {
                                CustomerNameNEmail: element.first_name + " " + element.last_name + " (" + element.email_id + ")",
                                CustomerEmail: element.email_id,
                            }
                            CustomerDataTempArr.push(CustomerTempArr);
                            CustomerEmailTempArr.push(element.email_id);
                        }

                        var StatusBTN = "-";
                        switch (element.admin_approve_status) {
                            case "Approved":
                                StatusBTN = <div><button onClick={(e) => openModal1(element)} className="btn btn-outline-success btn-rounded">Completed</button></div>
                                break;

                            case "Pending":
                                StatusBTN = <div><button onClick={(e) => openModal1(element)} className="btn btn-outline-warning btn-rounded">Pending</button></div>
                                break;

                            default:
                                StatusBTN = <div><button onClick={(e) => openModal1(element)} className="btn btn-outline-danger btn-rounded">Declined</button></div>
                                break;
                        }

                        let temp = {
                            No: element.broker_id,
                            BrokerName: element.first_name + " " + element.last_name,
                            EmailID: element.email_id,
                            Phoneno: "+" + element.country_code + element.phone_number,
                            Status: StatusBTN,
                            created_datetime: element.created_datetime,
                            StatusText: element.admin_approve_status,
                        }
                        TempArr.push(temp);
                    });
                    setResultData(TempArr);
                    setFilterData(TempArr);
                    setCustomerData(CustomerDataTempArr);

                    setTimeout(() => {
                        setLoader(false);
                    }, 500);
                } else {
                    setLoader(false);
                }
            })
            .catch(function (error) {
                setLoader(false);
            });
    }


    useEffect(() => {
        setLoader(true);
        handleLoad();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    const handleChangeCSVNdPDF = (val) => {
        var FileName = "Broker Request List";

        var columns = [
            { title: t('LABEL.BROKER_NAME'), field: 'BrokerName' },
            { title: t('LABEL.BROKER_EMAIL_ID'), field: 'EmailID' },
            { title: t('HEADER.CUS_PHONE'), field: 'Phoneno' },
            { title: t('KYC.STATUS_TITLE'), field: 'Status' },
        ];

        const columnTitles = columns.map(columnDef => columnDef.title);

        // const FileData = FilterData.map(rowData =>
        //     columns.map(columnDef => rowData[columnDef.field]),
        // );
        const FileData = FilterData.map(rowData =>
            [rowData["BrokerName"],
            rowData["EmailID"],
            rowData["Phoneno"],
            rowData["StatusText"]]
        );
        if (val === "CSV") {
            const builder = new CsvBuilder(FileName + `.csv`)
                .setColumns(columnTitles)
                .addRows(FileData)
                .exportFile();

            return builder;
        }
        else if (val === "PDF") {
            const doc = new jsPDF();

            doc.autoTable({
                head: [columnTitles],
                body: FileData,
            });

            doc.save(FileName + `.pdf`);
        }
    }


    return (
        <div>
            <div className="card">
                <div className="card-header page-title-space mobile-card-header">
                    <h2 className="text-black font-w600 mb-0">{t('LABEL.BROKER_REQ_LIST')}</h2>
                </div>
            </div>

            {error ? <Snackbars open_close={error} type="error" message={error_msg} /> : ""}
            {success ? <Snackbars open_close={success} type="success" message={success_msg} /> : ""}

            <Modal className="modal fade" show={Modal1}>
                <div className="modal-dialog-centered " >
                    <div className="modal-content">
                        <div className="modal-header">
                            <h3 className="modal-title">{t('LABEL.BROKER_STATUS')}</h3>
                            <button type="button" className="close" onClick={(e) => setModal1(false)}><span>&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <div className="col-md-12">
                                <div className="form-group position-relative">
                                    <label className="mb-2 ">
                                        <span>{t('LABEL.BROKER_SELECT_STATUS')}</span>
                                    </label>
                                    <select className="form-control webkit-none" value={AdminApproveStatus} onChange={(e) => setAdminApproveStatus(e.target.value)}>
                                        <option value={"Pending"}>Pending</option>
                                        <option value={"Approved"}>Approved</option>
                                        <option value={"Declined"}>Declined</option>
                                    </select>
                                    <i className="fa fa-caret-down down-arrow" aria-hidden="true"></i>
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-light btn-rounded light" onClick={() => setModal1(false)}>{t('BUTTONS.CANCEL')}</button>
                            <button type="button" className="btn btn-primary btn-rounded" onClick={handlesubmit}>{t('BUTTONS.UPDATE')}</button>
                        </div>
                    </div>
                </div>
            </Modal>

            {loader === true ?
                <div>
                    <Loader open_close={loader} />
                </div>
                :
                <div className="card">
                    <div className="card-body bdr-btm-card">
                        <div className="row">
                            <div className="col-md-3">
                                <div className="form-group position-relative">
                                    <label className="mb-2 ">
                                        <span>{t('TRANSACTION.FROM_DATE')}</span>
                                    </label>
                                    <div className="col-xs-12">
                                        <input type="date" onChange={(e) => handleChangeFromDate(e.target.value)} value={FromDate} className="form-control" />
                                    </div>
                                </div>
                            </div>

                            <div className="col-md-3">
                                <div className="form-group position-relative">
                                    <label className="mb-2 ">
                                        <span>{t('TRANSACTION.TO_DATE')}</span>
                                    </label>
                                    <div className="col-xs-12">
                                        <input type="date" onChange={(e) => handleChangeToDate(e.target.value)} value={ToDate} className="form-control" />
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6 position-relative">
                                <label className="mb-2 ">
                                    <span>{t('LABEL.BROKER')} </span>
                                </label>
                                <select className="form-control webkit-none" onChange={(e) => handleChangeCustmer(e.target.value)} value={Customer}>
                                    <option value="">{t('ACCOUNT_TRANSACTIONS.SELECT_ALL')}</option>
                                    {CustomerData && CustomerData.map((row) => (
                                        <option value={row.CustomerEmail} key={row.CustomerEmail}>{row.CustomerNameNEmail}</option>
                                    ))}
                                </select>
                                <i className="fa fa-caret-down down-arrow select-arrow1" aria-hidden="true"></i>
                            </div>

                        </div>
                        <div className="table-responsive">

                        </div>
                    </div>
                    <div className="card-body">
                        <div className="position-relative">
                            <div className="table-responsive">
                                {FilterData.length > 0 ?
                                    <MaterialTable
                                        title="Request Lists"
                                        columns={[
                                            // { title: '#', field: 'No', width: 70 },
                                            { title: t('LABEL.BROKER_NAME'), field: 'BrokerName' },
                                            { title: t('LABEL.BROKER_EMAIL_ID'), field: 'EmailID' },
                                            { title: t('HEADER.CUS_PHONE'), field: 'Phoneno' },
                                            { title: t('KYC.STATUS_TITLE'), field: 'Status' },
                                        ]}
                                        data={FilterData}
                                        options={{
                                            exportMenu: [
                                                { label: "Export PDF", exportFunc: (cols, datas) => handleChangeCSVNdPDF("PDF") },
                                                { label: "Export CSV", exportFunc: (cols, datas) => handleChangeCSVNdPDF("CSV") },
                                            ], paging: true, pageSize: 5, pageSizeOptions: [5, 10, 15], emptyRowsWhenPaging: false,
                                        }}
                                    />
                                    : <div className="d-sm-flex d-block border-0 pb-0">
                                        <div className="pr-3 mr-auto mb-sm-0 mb-3 nofoundFullWidth">
                                            <h4 className="fs-20 text-black mb-1 nofoundTitle">Request List Overview</h4>
                                            <span className="fs-12 nofoundText">No Data Found</span>
                                        </div>
                                    </div>}
                            </div>
                        </div>
                    </div>
                </div>
            }
        </div>
    )

}
export default Broker_Request;