import React, { forwardRef, useState, useEffect } from "react";
import MaterialTable from "material-table";
import { Search, ViewColumn, SaveAlt, Remove, LastPage, FirstPage, FilterList, Edit, DeleteOutline, Clear, ChevronRight, ChevronLeft, Check, ArrowDownward, AddBox } from '@material-ui/icons';
//import { useTranslation } from "react-i18next";
import axios from 'axios';
import moment from 'moment';
import Loader from '../../pages/Loader';

const materialTableIcons = {
    Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
    Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
    Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
    DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
    Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
    Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
    FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
    LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
    NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
    ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
    SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
    ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
    ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />)
};



export const Inquiry_Logs = () => {
    //const { t } = useTranslation();
    const [loginarray, setLoginArray] = useState([]);
    const [loader, setLoader] = useState(false);

    useEffect(() => {
        handleLoad();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleLoad = () => {
        var jsonpar = {
            manager_id: window.sessionStorage.getItem("manager_id")
        }
        const headers = {
            'Content-Type': 'application/json',
            'manageraccesstoken': process.env.REACT_APP_MANAGER_ACCESS_TOKEN,
            'managerauthtoken': 'Bearer ' + window.sessionStorage.getItem("manager_jwt"),
        }
        setLoader(true);
        axios.post(process.env.REACT_APP_API_URL + 'getAllInquiry', jsonpar, { headers: headers })
            .then(function (res) {
                res = res.data;
                if (res.code === "200") {
                    var TempArr = [];
                    res.data.forEach(element => {
                        var temp = {
                            firstname: element.first_name,
                            lastname: element.last_name,
                            email_id: element.email_id,
                            phone_number: element.phone_number,
                            country_code: element.country_code,
                            created_datetime: moment(element.created_datetime).format('MM / DD / YYYY hh:mm:ss'),
                            created_ip: element.created_ip,
                        };
                        TempArr.push(temp);
                    });
                    setLoginArray(TempArr);
                    setTimeout(() => {
                        setLoader(false);
                    }, 500);
                } else {
                    setLoader(false);
                }
            })
            .catch(function (error) {
                setLoader(false);
            });
    }
    return (
        <div>
            <div className="card">
                <div className="card-header page-title-space mobile-card-header">
                    <h2 className="text-black font-w600 mb-0">Inquiry</h2>
                    <div className="mobileFullWidth">

                    </div>
                </div>
            </div>
            {loader === true ?
                <div>
                    <Loader open_close={loader} />
                </div>
                :
                <div className="card">
                    <div className="card-body">
                        <div className="position-relative">
                            <div className="table-responsive">
                            {loginarray.length > 0 ?
                                <MaterialTable
                                    title="Inquiry"
                                    icons={materialTableIcons}
                                    options={{ exportButton: true, paging: true, pageSize: 5, pageSizeOptions: [5, 10, 15], emptyRowsWhenPaging: false, size: 'medium' }}
                                    columns={[
                                        { title: 'First Name', field: 'firstname' },
                                        { title: 'Last Name', field: 'lastname' },
                                        { title: 'Email', field: 'email_id' },
                                        { title: 'Phone Number', field: 'phone_number' },
                                        { title: 'Country', field: 'country_code' },
                                        { title: 'DateTime', field: 'created_datetime' },
                                        { title: 'Ip Address', field: 'created_ip' },
                                    ]}
                                    data={loginarray}
                                />
                                : <div className="d-sm-flex d-block border-0 pb-0">
                                <div className="pr-3 mr-auto mb-sm-0 mb-3 nofoundFullWidth">
                                    <h4 className="fs-20 text-black mb-1 nofoundTitle">Inquiry List Overview</h4>
                                    <span className="fs-12 nofoundText">No Data Found</span>
                                </div>
                            </div>}
                            </div>
                        </div>
                    </div>
                </div>
            }
        </div>
    )

}
export default Inquiry_Logs;