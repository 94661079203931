import React, { useState, useEffect } from "react";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { Link } from 'react-router-dom';
import { useTranslation } from "react-i18next";
import MaterialTable from "@material-table/core";
import { ExportCsv, ExportPdf } from "@material-table/exporters";
import axios from 'axios';
import Loader from '../../../jsx/pages/Loader';
import validator from 'validator';
import Snackbars from '../../pages/Snackbars';
import {
    EmailShareButton,
    EmailIcon,
    FacebookShareButton,
    FacebookIcon,
    LinkedinShareButton,
    LinkedinIcon,
    TelegramShareButton,
    TelegramIcon,
    TwitterShareButton,
    TwitterIcon,
    WhatsappShareButton,
    WhatsappIcon,

} from "react-share";

export const MyLinks = () => {
    const { t } = useTranslation();
    const [loader, setLoader] = useState(false);
    const [linkarr, setlinkarr] = useState([]);

    const [error, setError] = useState(false);
    const [error_msg, setErrorMsg] = useState("");
    const [success, setSuccess] = useState(false);
    const [success_msg, setSuccessMsg] = useState("");

    useEffect(() => {
        handleLoad();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handlealert = () => {
        setSuccess(true);
        setSuccessMsg("Link Copied Successfully");
        setTimeout(() => {
            setSuccess(false);
        }, 3000);

    }
    const handleLoad = () => {
        const headers = {
            'Content-Type': 'application/json',
            'manageraccesstoken': process.env.REACT_APP_MANAGER_ACCESS_TOKEN,
            'managerauthtoken': 'Bearer ' + window.sessionStorage.getItem("manager_jwt"),
        }
        setLoader(true);
        axios.get(process.env.REACT_APP_API_URL + 'getmylinks', { headers: headers })
            .then(function (res) {
                res = res.data;
                if (res.code === "200") {
                    let TempArr = [];
                    res.data.forEach(element => {
                        let ActionBTN = <div>
                            <EmailShareButton
                                url={element.linkurl + window.sessionStorage.getItem("manager_id")}
                                quote={"MyLink"}
                            >
                                <EmailIcon size={32} round />
                            </EmailShareButton>

                            <FacebookShareButton
                                style={{ marginLeft: "5px" }}
                                url={element.linkurl + window.sessionStorage.getItem("manager_id")}
                                quote={"MyLink"}
                            >
                                <FacebookIcon size={32} round />
                            </FacebookShareButton>

                            <LinkedinShareButton
                                style={{ marginLeft: "5px" }}
                                url={element.linkurl + window.sessionStorage.getItem("manager_id")}
                                quote={"MyLink"}
                            >
                                <LinkedinIcon size={32} round />
                            </LinkedinShareButton>

                            <TelegramShareButton
                                style={{ marginLeft: "5px" }}
                                url={element.linkurl + window.sessionStorage.getItem("manager_id")}
                                quote={"MyLink"}
                            >
                                <TelegramIcon size={32} round />
                            </TelegramShareButton>

                            <TwitterShareButton
                                style={{ marginLeft: "5px" }}
                                url={element.linkurl + window.sessionStorage.getItem("manager_id")}
                                quote={"MyLink"}
                            >
                                <TwitterIcon size={32} round />
                            </TwitterShareButton>

                            <WhatsappShareButton
                                style={{ marginLeft: "5px" }}
                                url={element.linkurl + window.sessionStorage.getItem("manager_id")}
                                quote={"MyLink"}
                            >
                                <WhatsappIcon size={32} round />
                            </WhatsappShareButton>
                            <Link style={{ float: "left", background: "#273593", borderRadius: "50%", color: "#fff", padding: "5px 9px", marginRight: "5px" }} href="#" onClick={() => handlealert()}>
                                <CopyToClipboard text={element.linkurl + window.sessionStorage.getItem("manager_id")}>
                                    <div className='tooltip-box'>
                                        <div title="Copy Address">
                                            <span><i class="fa fa-clone" aria-hidden="true"></i> </span>
                                        </div>
                                    </div>
                                </CopyToClipboard>
                            </Link>
                        </div>;
                        let temp = {
                            Link: element.linkurl + window.sessionStorage.getItem("manager_id"),
                            action: ActionBTN,
                        }
                        TempArr.push(temp);
                    });
                    setlinkarr(TempArr);
                }
                setTimeout(() => {
                    setLoader(false);
                }, 500);
            })
            .catch(function (error) {
                setLoader(false);
            });
    }

    return (
        <>

            <div className="card">
                <div className="card-header page-title-space">
                    <h2 className="text-black font-w600 mb-0">My Links</h2>
                </div>
            </div>
            {error ? <Snackbars open_close={error} type="error" message={error_msg} /> : ""}
            {success ? <Snackbars open_close={success} type="success" message={success_msg} /> : ""}
            {loader === true ?
                <div>
                    <Loader open_close={loader} />
                </div>
                :
                <div className="card">
                    <div className="card-body">
                        <div className="position-relative">
                            <div className="table-responsive">
                                {linkarr.length > 0 ?
                                    <MaterialTable
                                        title="MY Links"
                                        columns={[
                                            { title: 'Link', field: 'Link' },
                                            { title: 'Action', field: 'action' },
                                        ]}
                                        data={linkarr}
                                        options={{
                                            paging: true, pageSize: 5, pageSizeOptions: [5, 10, 15], emptyRowsWhenPaging: false,
                                        }}
                                    />
                                    : <div className="d-sm-flex d-block border-0 pb-0">
                                        <div className="pr-3 mr-auto mb-sm-0 mb-3 nofoundFullWidth">
                                            <h4 className="fs-20 text-black mb-1 nofoundTitle">MY Links Overview</h4>
                                            <span className="fs-12 nofoundText">No Data Found</span>
                                        </div>
                                    </div>}
                            </div>
                        </div>
                    </div>
                </div>
            }

        </>
    )

}
export default MyLinks;