import React, { useState, useEffect } from "react";
import MaterialTable from "@material-table/core";
import { ExportCsv, ExportPdf } from "@material-table/exporters";
import Snackbars from '../../../jsx/pages/Snackbars';
import { useTranslation } from "react-i18next";
import axios from 'axios';
import moment from 'moment';
import Loader from '../../pages/Loader';



export const Users_Activity_Logs = () => {
    const { t } = useTranslation();
    const [loginarray, setLoginArray] = useState([]);
    const [error, setError] = useState(false);
    const [error_msg, setErrorMsg] = useState("");
    const [loader, setLoader] = useState(false);

    useEffect(() => {
        handleLoad();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleLoad = () => {
        var jsonpar = JSON.stringify({
            'manager_id': window.sessionStorage.getItem("manager_id"),
        });
        const headers = {
            'Content-Type': 'application/json',
            'manageraccesstoken': process.env.REACT_APP_MANAGER_ACCESS_TOKEN,
            'managerauthtoken': 'Bearer ' + window.sessionStorage.getItem("manager_jwt"),
        }
        setLoader(true);
        axios.post(process.env.REACT_APP_API_URL + 'getManagerLoginlogs', jsonpar, { headers: headers })
            .then(function (res) {
                res = res.data;
                if (res.code === "200") {
                    var TempArr = [];
                    res.data.forEach(element => {
                        var temp = {
                            login_log_pk: element.login_log_pk,
                            login_datetime: moment(element.login_datetime).format('MM / DD / YYYY hh:mm:ss'),
                            login_log_ip: String(element.login_log_ip).replace("::ffff:", " "),
                            login_browser: element.login_browser,
                            login_os: element.login_os,
                        };
                        TempArr.push(temp);
                    });
                    setLoginArray(TempArr);
                    setTimeout(() => {
                        setLoader(false);
                    }, 1000);
                } else {
                    setLoader(false);
                    setError(true);
                    setErrorMsg(res.message);
                    setTimeout(() => {
                        setError(false);
                    }, 6000);
                }
            })
            .catch(function (error) {
                setLoader(false);
                setError(true);
                setErrorMsg(t('ERROR_MSG.SOMETHING'));
                setTimeout(() => {
                    setError(false);
                }, 6000);
            });
    }
    return (
        <div>
            <div className="card">
                <div className="card-header page-title-space">
                    <h2 className="text-black font-w600 mb-0">{t('LOGIN_LOGS.TITLE')}</h2>
                </div>
            </div>
            {error ? <Snackbars open_close={error} type="error" message={error_msg} /> : ""}
            {loader === true ?
                <div>
                    <Loader open_close={loader} />
                </div>
                :
                <div className="card">
                    <div className="card-body">
                        <div className="position-relative">
                            <div className="table-responsive">
                                {loginarray.length > 0 ?
                                    <MaterialTable
                                        title={t('LOGIN_LOGS.TITLE')}
                                        columns={[
                                            { title: t('LOGIN_LOGS.DATETIME'), field: 'login_datetime' },
                                            { title: t('LOGIN_LOGS.IP'), field: 'login_log_ip' },
                                            { title: t('LOGIN_LOGS.BROWSER'), field: 'login_browser' },
                                            { title: t('LOGIN_LOGS.OS'), field: 'login_os' },
                                        ]}
                                        data={loginarray}
                                        options={{
                                            exportMenu: [
                                                { label: "Export PDF", exportFunc: (cols, datas) => ExportPdf(cols, datas, "Activity_Logs") },
                                                { label: "Export CSV", exportFunc: (cols, datas) => ExportCsv(cols, datas, "Activity_Logs") },
                                            ], paging: true, pageSize: 5, pageSizeOptions: [5, 10, 15], emptyRowsWhenPaging: false,
                                        }}
                                    />
                                    : <div className="d-sm-flex d-block border-0 pb-0">
                                        <div className="pr-3 mr-auto mb-sm-0 mb-3 nofoundFullWidth">
                                            <h4 className="fs-20 text-black mb-1 nofoundTitle">{t('LOGIN_LOGS.TITLE')}</h4>
                                            <span className="fs-12 nofoundText">No Data Found</span>
                                        </div>
                                    </div>}
                            </div>
                        </div>
                    </div>
                </div>
            }
        </div>
    )

}
export default Users_Activity_Logs;