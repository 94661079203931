import React, { useState, useEffect } from "react";
import { Link } from 'react-router-dom';
import Snackbars from '../../../jsx/pages/Snackbars';
import { useTranslation } from "react-i18next";
import axios from 'axios';
import moment from 'moment';
import Loader from '../../pages/Loader';


export const News = () => {
    const { t } = useTranslation();
    const [newsarray, setNewsarray] = useState([]);
    const [error, setError] = useState(false);
    const [error_msg, setErrorMsg] = useState("");
    const [loader, setLoader] = useState(false);

    useEffect(() => {
        handleLoad();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleLoad = () => {
        const headers = {
            'Content-Type': 'application/json',
            'manageraccesstoken': process.env.REACT_APP_MANAGER_ACCESS_TOKEN,
            'managerauthtoken': 'Bearer ' + window.sessionStorage.getItem("manager_jwt"),
        }
        setLoader(true);
        axios.get(process.env.REACT_APP_API_URL + 'findAllNews', { headers: headers })
            .then(function (res) {
                res = res.data;
                if (res.code === "200") {
                    setNewsarray(res.data);
                    setTimeout(() => {
                        setLoader(false);
                    }, 500);
                } else {
                    setError(true);
                    setErrorMsg(res.message);
                    setTimeout(() => {
                        setError(false);
                    }, 6000);
                }
            })
            .catch(function (error) {
                setError(true);
                setErrorMsg(t('ERROR_MSG.SOMETHING'));
                setTimeout(() => {
                    setError(false);
                }, 6000);
            });
    }


    return (
        <div>
            <div className="card">
                <div className="card-header page-title-space">
                    <h2 className="text-black font-w600 mb-0">{t('NEWS')}</h2>
                </div>
            </div>
            {error ? <Snackbars open_close={error} type="error" message={error_msg} /> : ""}
            {loader === true ?
                <div>
                    <Loader open_close={loader} />
                </div>
                :
                newsarray && newsarray.map((row) => (
                    <div className="card widget-stat widget-stat-custom card" key={row.news_id}>
                        <div className="card-body">
                            <div className="read-content">
                                <div className="media mb-2">
                                    <div className="media-body">
                                        <h4 className="my-1 text-primary">{row.title} { }</h4>
                                        <p className="read-content-email">{moment(row.created_datetime).format('MM / DD / YYYY hh:mm:ss')}</p>
                                    </div>
                                </div>
                                <div className="read-content-body">
                                    <p className="mb-3">
                                        <div dangerouslySetInnerHTML={{ __html: row.description }} />

                                    </p>
                                    <Link to={"/news-details/" + row.news_id} className="text-primary">{t('READ_MORE')}</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                ))
            }
        </div>

    )

}
export default News;