import React from 'react';
import { Link, withRouter, useHistory } from 'react-router-dom';
import { useTranslation } from "react-i18next";

function LogoutPage(props) {
    const { t } = useTranslation();
    const history = useHistory();
    function onLogout() {
        window.sessionStorage.removeItem("temp_manager_id");
        window.sessionStorage.removeItem("manager_id");
        window.sessionStorage.removeItem("manager_jwt");
        window.location.href = process.env.REACT_APP_SITE_URL + "login";
    }
    return (
        <>
            <Link to="#" className="dropdown-item ai-icon" onClick={onLogout}>
                <svg
                    id="icon-logout" xmlns="http://www.w3.org/2000/svg"
                    className="text-danger" width={18} height={18} viewBox="0 0 24 24"
                    fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round"
                >
                    <path d="M9 21H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h4" />
                    <polyline points="16 17 21 12 16 7" />
                    <line x1={21} y1={12} x2={9} y2={12} />
                </svg>
                <span className="ml-2" >{t('BUTTONS.LOGOUT')} </span>
            </Link>
        </>
    )
}

export default withRouter((LogoutPage));