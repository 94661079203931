import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import axios from 'axios';
import Loader from '../../../jsx/pages/Loader';
import validator from 'validator';
import Snackbars from '../../pages/Snackbars';

export const Contactus = () => {
    const { t } = useTranslation();
    const [loader, setLoader] = useState(false);
    const [desc, setdesc] = useState("");
    const [fullname, setFullname] = useState("");
    const [emailid, setEmailid] = useState("");
    const [subject, setSubject] = useState("");
    const [details, setDetails] = useState("");

    const [fullname_error, setFullname_error] = useState("");
    const [emailid_error, setEmailid_error] = useState("");
    const [subject_error, setSubject_error] = useState("");
    const [details_error, setDetails_error] = useState("");

    const [error, setError] = useState(false);
    const [error_msg, setErrorMsg] = useState("");
    const [success, setSuccess] = useState(false);
    const [success_msg, setSuccessMsg] = useState("");

    useEffect(() => {
        handleLoad();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    const handleLoad = () => {
        const headers = {
            'Content-Type': 'application/json',
            'brokeraccesstoken': process.env.REACT_APP_BROKER_ACCESS_TOKEN,
            'brokerauthtoken': 'Bearer ' + window.sessionStorage.getItem("broker_jwt"),
        }
        setLoader(true);
        axios.get(process.env.REACT_APP_API_URL + '../settings/getpages/3', { headers: headers })
            .then(function (res) {
                res = res.data;
                if (res.code === "200") {
                    setdesc(res.data[0].page_description);
                }
                setTimeout(() => {
                    setLoader(false);
                }, 500);
            })
            .catch(function (error) {
                setLoader(false);
            });
    }

    const validateFullname = (value) => {
        setFullname(value);
        if (!validator.isEmpty(value)) {
            setFullname_error("");
            return true;
        } else {
            setFullname_error(t("PROFILE.ENTER_FULLNAME"));
            return false;
        }
    }

    const validateSubject = (value) => {
        setSubject(value);
        if (!validator.isEmpty(value)) {
            setSubject_error("");
            return true;
        } else {
            setSubject_error(t("FIAT.SUBJECT_PLACE"));
            return false;
        }
    }

    const validateDetails = (value) => {
        setDetails(value);
        if (!validator.isEmpty(value)) {
            setDetails_error("");
            return true;
        } else {
            setDetails_error(t("FIAT.DETAILS_PLACE"));
            return false;
        }
    }

    const validateEmail = (value) => {
        setEmailid(value);
        if (validator.isEmail(value)) {
            setEmailid_error("");
            return true;
        } else {
            setEmailid_error(t('FIAT.EMAIL_ADDRESS_PLACE'));
            return false;
        }
    }

    const handlesubmit = () => {
        if (validateFullname(fullname) === false) {
            setFullname_error(t("PROFILE.ENTER_FULLNAME"));
            return false;
        } else if (validateEmail(emailid) === false) {
            setFullname_error("");
            setEmailid_error(t('FIAT.EMAIL_ADDRESS_PLACE'));
            return false;
        } else if (validateSubject(subject) === false) {
            setEmailid_error("");
            setSubject_error(t("FIAT.SUBJECT_PLACE"));
            return false;
        } else if (validateDetails(details) === false) {
            setSubject_error("");
            setDetails_error(t("FIAT.DETAILS_PLACE"));
            return false;
        } else {
            setDetails_error("");

            var jsonpar = {
                fullname: fullname,
                emailid: emailid,
                subject: subject,
                details: details,
            }
            const headers = {
                'Content-Type': 'application/json',
                'manageraccesstoken': process.env.REACT_APP_MANAGER_ACCESS_TOKEN,
                'managerauthtoken': 'Bearer ' + window.sessionStorage.getItem("manager_jwt"),
            }
            setLoader(true);

            axios.post(process.env.REACT_APP_API_URL + 'SubmitContact', jsonpar, { headers: headers })
                .then(function (res) {
                    res = res.data;
                    if (res.code === "200") {
                        setFullname("");
                        setEmailid("");
                        setSubject("");
                        setDetails("");
                        setLoader(false);
                        setSuccess(true);
                        setSuccessMsg(res.message);
                        setTimeout(() => {
                            setSuccess(false);
                        }, 6000);
                    } else {
                        setLoader(false);
                        setError(true);
                        setErrorMsg(res.message);
                        setTimeout(() => {
                            setError(false);
                        }, 6000);
                    }

                })
                .catch(function (error) {
                    setLoader(false);
                    setError(true);
                    setErrorMsg(t('ERROR_MSG.SOMETHING'));
                    setTimeout(() => {
                        setError(false);
                    }, 6000);
                });
        }
    }
    return (
        <>

            <div className="card">
                <div className="card-header page-title-space">
                    <h2 className="text-black font-w600 mb-0">{t("CONTACT_US_TITLE")}</h2>
                </div>
            </div>
            {error ? <Snackbars open_close={error} type="error" message={error_msg} /> : ""}
            {success ? <Snackbars open_close={success} type="success" message={success_msg} /> : ""}
            {loader === true ?
                <div>
                    <Loader open_close={loader} />
                </div>
                :
                <div className="row">
                    <div className="col-md-8">
                        <div className="card">
                            <div className="card-body">
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="form-group">
                                            <label className="mb-2">{t("PROFILE.FULLNAME")}</label>
                                            <input type="text" className="form-control" value={fullname} onChange={(e) => validateFullname(e.target.value)} placeholder={t("PROFILE.ENTER_FULLNAME")} />
                                            {fullname_error !== "" ? <label className='text-red'>{fullname_error}</label> : ""}
                                        </div>
                                    </div>
                                    <div className="col-md-12">
                                        <div className="form-group">
                                            <label className="mb-2">{t("HEADER.CUS_EMAIL")}</label>
                                            <input type="text" className="form-control" value={emailid} onChange={(e) => validateEmail(e.target.value)} placeholder={t("FIAT.EMAIL_ADDRESS_PLACE")} />
                                            {emailid_error !== "" ? <label className='text-red'>{emailid_error}</label> : ""}
                                        </div>
                                    </div>
                                    <div className="col-md-12">
                                        <div className="form-group">
                                            <label className="mb-2">{t("FIAT.SUBJECT")}</label>
                                            <input type="text" className="form-control" value={subject} onChange={(e) => validateSubject(e.target.value)} placeholder={t("FIAT.SUBJECT_PLACE")} />
                                            {subject_error !== "" ? <label className='text-red'>{subject_error}</label> : ""}
                                        </div>
                                    </div>
                                    <div className="col-md-12">
                                        <div className="form-group">
                                            <label className="mb-2">{t("FIAT.DETAILS")}</label>
                                            <textarea value={details} onChange={(e) => validateDetails(e.target.value)} placeholder={t("FIAT.DETAILS_PLACE")} className='form-control' rows='4' ></textarea>
                                            {details_error !== "" ? <label className='text-red'>{details_error}</label> : ""}
                                        </div>
                                    </div>
                                    <div className="col-md-12 mt-2">
                                        <button type="button" onClick={handlesubmit} className="btn btn-primary btn-rounded">{t("BUTTONS.SUBMIT")}</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div className="card">
                            <div className="card-body">
                                <div dangerouslySetInnerHTML={{ __html: desc }} />
                            </div>
                        </div>
                    </div>
                </div>
            }
        </>
    )

}
export default Contactus;