import React, { useState, forwardRef, useEffect } from "react";
import { Search, ViewColumn, SaveAlt, Remove, LastPage, FirstPage, FilterList, Edit, DeleteOutline, Clear, ChevronRight, ChevronLeft, Check, ArrowDownward, AddBox } from '@material-ui/icons';
import { Link, useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import moment from 'moment';
import Loader from '../../pages/Loader';

export const Order_Details = (props) => {
    const { t } = useTranslation();
    const history = useHistory();
    const [loader, setLoader] = useState(false);

    const [customerName, setcustomerName] = useState("");
    const [total, settotal] = useState("");
    const [paid, setpaid] = useState("");
    const [status, setstatus] = useState("");
    const [paymentMethod, setpaymentMethod] = useState("");
    const [orderid, setorderid] = useState("");
    const [created, setcreated] = useState("");
    const [contact_id, setcontact_id] = useState("");
    const [broker_id, setbroker_id] = useState("");
    const [qty, setqty] = useState("");


    useEffect(() => {
        setcustomerName(props.location.state.customerName);
        settotal(props.location.state.total);
        setpaid(props.location.state.paid);
        setstatus(props.location.state.status);
        setpaymentMethod(props.location.state.paymentMethod);
        setorderid(props.location.state.orderid);
        setcreated(props.location.state.created);
        setcontact_id(props.location.state.contact_id);
        setbroker_id(props.location.state.broker_id);
        setqty(props.location.state.qty);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const backfun = () => {
        history.push({
            pathname: '/cards-contact-details',
            state: { contact_id: contact_id, broker_id: broker_id }
        });
    }
    return (
        <div>
            <div className="card">
                <div className="card-header page-title-space mobile-card-header">
                    <h2 className="text-black font-w600 mb-0">Order Information</h2>
                    <button className="btn btn-primary" onClick={() => backfun()}><i class="fa fa-arrow-left"></i> Back</button>
                </div>
            </div>
            {loader === true ?
                <div>
                    <Loader open_close={loader} />
                </div>
                :
                <div className="card">
                    <div className="card-body">
                        <div className="position-relative">
                            <div className="row">

                                <div className="col-lg-12 col-md-6 mb-2">
                                    <p className="fs-14 mb-1">Customer Name : <span className="text-black">{customerName}</span></p>
                                </div>

                                <div className="col-lg-6 col-md-6 mb-2">
                                    <p className="fs-14 mb-1">Order Number : <span className="text-black">{orderid}</span></p>
                                </div>
                                <div className="col-lg-6 col-md-6 mb-2">
                                    <p className="fs-14 mb-1">Total : <span className="text-black">{total}</span></p>
                                </div>

                                <div className="col-lg-6 col-md-6 mb-2">
                                    <p className="fs-14 mb-1">Paid Status : <span className="text-black">{paid}</span></p>
                                </div>

                                <div className="col-lg-6 col-md-6 mb-2">
                                    <p className="fs-14 mb-1">Status : <span className="text-black">{status}</span></p>
                                </div>

                                <div className="col-lg-6 col-md-6 mb-2">
                                    <p className="fs-14 mb-1">Create : <span className="text-black">{created}</span></p>
                                </div>

                                <div className="col-lg-6 col-md-6 mb-2">
                                    <p className="fs-14 mb-1">Payment Type : <span className="text-black">{paymentMethod}</span></p>
                                </div>

                                <div className="col-lg-6 col-md-6 mb-2">
                                    <p className="fs-14 mb-1">Quantity : <span className="text-black">{qty}</span></p>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            }

        </div>
    )

}
export default Order_Details;