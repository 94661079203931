import React, { useState, useEffect } from "react";
import MaterialTable from "@material-table/core";
import { ExportCsv, ExportPdf } from "@material-table/exporters";
import { useTranslation } from "react-i18next";
import axios from 'axios';
import moment from 'moment';
import Loader from '../../pages/Loader';
import { CsvBuilder } from 'filefy';
import jsPDF from 'jspdf';
import 'jspdf-autotable';

export const Cards_Transaction = () => {
    const { t } = useTranslation();
    const [requestarray, setrequestarray] = useState([]);
    const [originalarray, setoriginalarray] = useState([]);
    const [loader, setLoader] = useState(false);
    const [CustomerData, setCustomerData] = useState([]);
    const [FromDate, setFromDate] = useState("");
    const [ToDate, setToDate] = useState("");
    const [Customer, setCustomer] = useState("");

    const [checkuserdata, setcheckuserdata] = useState([]);

    useEffect(() => {
        handleLoad();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    const handleChangeCSVNdPDF = (val) => {
        var FileName = "Card Transactions";

        var columns = [
            { title: 'Hash', field: 'Refno' },
            { title: 'Date', field: 'Date' },
            { title: 'Card Type', field: 'CardType' },
            { title: 'Account Number', field: 'AccountNumber' },
            { title: 'Customer Name', field: 'CustomerName' },
            { title: 'Card Number', field: 'CardNumber' },
            { title: 'Debit/Credit', field: 'DebitCredit' },
            { title: 'Amount', field: 'Amount' },
            { title: 'Fees', field: 'Fees' },
            { title: 'Status', field: 'Status' },
        ];

        const columnTitles = columns.map(columnDef => columnDef.title);

        // const FileData = FilterData.map(rowData =>
        //     columns.map(columnDef => rowData[columnDef.field]),
        // );
        const FileData = requestarray.map(rowData =>
            [rowData["Refno"],
            rowData["Date"],
            rowData["CardType"],
            rowData["AccountNumber"],
            rowData["CustomerName"],
            rowData["CardNumber"],
            rowData["DebitCredit"],
            rowData["Amount"],
            rowData["Fees"],
            rowData["StatusText"]]
        );
        if (val === "CSV") {
            const builder = new CsvBuilder(FileName + `.csv`)
                .setColumns(columnTitles)
                .addRows(FileData)
                .exportFile();

            return builder;
        }
        else if (val === "PDF") {
            const doc = new jsPDF();

            doc.autoTable({
                head: [columnTitles],
                body: FileData,
            });

            doc.save(FileName + `.pdf`);
        }
    }

    const handleLoad = () => {
        var jsonpar = {
            managerid: window.sessionStorage.getItem("manager_id"),
        }
        const headers = {
            'Content-Type': 'application/json',
            'manageraccesstoken': process.env.REACT_APP_MANAGER_ACCESS_TOKEN,
            'managerauthtoken': 'Bearer ' + window.sessionStorage.getItem("manager_jwt"),
        }
        setLoader(true);
        axios.post(process.env.REACT_APP_API_URL + 'getuserCardsTransactions', jsonpar, { headers: headers })
            .then(function (res) {
                res = res.data;
                if (res.code === "200") {
                    setoriginalarray(res.data);
                    setCustomerData(res.userdata);
                    setcheckuserdata(res.checkuser);
                    var TempArr = [];
                    res.data.forEach(element => {
                        var Status = <div><button className="btn btn-outline-success btn-rounded">Completed</button> </div>;
                        if (element.issue_status === "Pending") {
                            Status = <div><button className="btn btn-outline-warning btn-rounded">Pending</button> </div>;
                        }
                        var symbole = '$';
                        if (element.card_currancy === "EUR") {
                            symbole = '€';
                        }
                        var temp = {
                            Refno: element.hash,
                            Date: moment(element.created_datetime).format('MM / DD / YYYY hh:mm:ss'),
                            AccountNumber: element.account_number,
                            CustomerName: element.card_customer_name,
                            CardType: element.cardtypename,
                            CardNumber: "**** **** **** " + String(element.card_number).slice(-4),
                            DebitCredit: element.cardtra_type,
                            Amount: symbole + element.amount,
                            Fees: symbole + element.fees,
                            Status: Status,
                            StatusText: element.issue_status,
                        };
                        if (res.checkuser.includes(element.user_id_fk)) {
                            TempArr.push(temp);
                        }
                    });
                    setrequestarray(TempArr);
                    setTimeout(() => {
                        setLoader(false);
                    }, 1000);
                } else {
                    setLoader(false);
                }
            })
            .catch(function (error) {
                setLoader(false);
            });
    }

    const handleChangeFromDate = (val) => {
        setFromDate(val);
        filter("1," + val, "", "");
    }
    const handleChangeToDate = (val) => {
        setToDate(val);
        filter("", "1," + val, "");
    }
    const handleChangeCustmer = (val) => {
        setCustomer(val);
        filter("", "", "1," + val);
    }


    const filter = (from_date = "", to_date = "", customer_email = "") => {
        var tempFromDate = FromDate;
        var tempToDate = ToDate;
        var tempCustomerEmail = Customer;

        if (from_date !== "") {
            if (from_date.split(",")[0] === "1" && from_date.split(",")[1] !== "") {
                tempFromDate = from_date.split(",")[1];
            }
            else if (from_date.split(",")[0] === "1" && from_date.split(",")[1] === "") {
                tempFromDate = "";
            }
        }

        if (to_date !== "") {
            if (to_date.split(",")[0] === "1" && to_date.split(",")[1] !== "") {
                tempToDate = to_date.split(",")[1];
            }
            else if (to_date.split(",")[0] === "1" && to_date.split(",")[1] === "") {
                tempToDate = "";
            }
        }

        if (customer_email !== "") {
            if (customer_email.split(",")[0] === "1" && customer_email.split(",")[1] !== "") {
                tempCustomerEmail = customer_email.split(",")[1];
            }
            else if (customer_email.split(",")[0] === "1" && customer_email.split(",")[1] === "") {
                tempCustomerEmail = "";
            }
        }

        let TempArr = [];
        originalarray.forEach(ResultData_ => {
            var Status = <div><button className="btn btn-outline-success btn-rounded">Completed</button> </div>;
            if (ResultData_.issue_status === "Pending") {
                Status = <div><button className="btn btn-outline-warning btn-rounded">Pending</button> </div>;
            }
            var symbole = '$';
            if (ResultData_.card_currancy === "EUR") {
                symbole = '€';
            }
            var temp = {
                Refno: ResultData_.hash,
                Date: moment(ResultData_.created_datetime).format('MM / DD / YYYY hh:mm:ss'),
                AccountNumber: ResultData_.account_number,
                CustomerName: ResultData_.card_customer_name,
                CardType: ResultData_.cardtypename,
                CardNumber: "**** **** **** " + String(ResultData_.card_number).slice(-4),
                DebitCredit: ResultData_.cardtra_type,
                Amount: symbole + ResultData_.amount,
                Fees: symbole + ResultData_.fees,
                Status: Status,
            };

            if (tempFromDate === "" && tempToDate === "" && (tempCustomerEmail !== "" && tempCustomerEmail === ResultData_.user_id_fk)) {
                if (checkuserdata.includes(ResultData_.user_id_fk)) {
                    TempArr.push(temp);
                }
            }
            else if ((tempFromDate !== "" && moment(ResultData_.created_datetime).format('YYYY-MM-DD') >= moment(tempFromDate).format('YYYY-MM-DD')) && tempToDate === "" && tempCustomerEmail === "") {
                if (checkuserdata.includes(ResultData_.user_id_fk)) {
                    TempArr.push(temp);
                }
            }
            else if (tempFromDate === "" && (tempToDate !== "" && moment(ResultData_.created_datetime).format('YYYY-MM-DD') <= moment(tempToDate).format('YYYY-MM-DD')) && tempCustomerEmail === "") {
                if (checkuserdata.includes(ResultData_.user_id_fk)) {
                    TempArr.push(temp);
                }
            }
            else if ((tempFromDate !== "" && moment(ResultData_.created_datetime).format('YYYY-MM-DD') >= moment(tempFromDate).format('YYYY-MM-DD')) && (tempToDate !== "" && moment(ResultData_.created_datetime).format('YYYY-MM-DD') <= moment(tempToDate).format('YYYY-MM-DD')) && tempCustomerEmail === "") {
                if (checkuserdata.includes(ResultData_.user_id_fk)) {
                    TempArr.push(temp);
                }
            }
            else if ((tempFromDate !== "" && moment(ResultData_.created_datetime).format('YYYY-MM-DD') >= moment(tempFromDate).format('YYYY-MM-DD')) && tempToDate === "" && (tempCustomerEmail !== "" && tempCustomerEmail === ResultData_.user_id_fk)) {
                if (checkuserdata.includes(ResultData_.user_id_fk)) {
                    TempArr.push(temp);
                }
            }
            else if (tempFromDate === "" && (tempToDate !== "" && moment(ResultData_.created_datetime).format('YYYY-MM-DD') <= moment(tempToDate).format('YYYY-MM-DD')) && (tempCustomerEmail !== "" && tempCustomerEmail === ResultData_.user_id_fk)) {
                if (checkuserdata.includes(ResultData_.user_id_fk)) {
                    TempArr.push(temp);
                }
            }
            else if ((tempFromDate !== "" && moment(ResultData_.created_datetime).format('YYYY-MM-DD') >= moment(tempFromDate).format('YYYY-MM-DD')) && (tempToDate !== "" && moment(ResultData_.created_datetime).format('YYYY-MM-DD') <= moment(tempToDate).format('YYYY-MM-DD')) && (tempCustomerEmail !== "" && tempCustomerEmail === ResultData_.user_id_fk)) {
                if (checkuserdata.includes(ResultData_.user_id_fk)) {
                    TempArr.push(temp);
                }
            }
            else if (tempFromDate === "" && tempToDate === "" && tempCustomerEmail === "") {
                if (checkuserdata.includes(ResultData_.user_id_fk)) {
                    TempArr.push(temp);
                }
            }


        });
        setrequestarray(TempArr);
    }
    return (
        <div>
            <div className="card">
                <div className="card-header page-title-space mobile-card-header">
                    <h2 className="text-black font-w600 mb-0">Cards Transactions</h2>
                    <div className="mobileFullWidth">
                    </div>
                </div>
            </div>
            {loader === true ?
                <div>
                    <Loader open_close={loader} />
                </div>
                :
                <div className="card">
                    <div className="card-body bdr-btm-card">
                        <div className="row">
                            <div className="col-md-3">
                                <div className="form-group position-relative">
                                    <label className="mb-2 ">
                                        <span>From Date</span>
                                    </label>
                                    <div className="col-xs-12">
                                        <input type="date" onChange={(e) => handleChangeFromDate(e.target.value)} value={FromDate} className="form-control" />
                                    </div>
                                </div>
                            </div>

                            <div className="col-md-3">
                                <div className="form-group position-relative">
                                    <label className="mb-2 ">
                                        <span>To Date</span>
                                    </label>
                                    <div className="col-xs-12">
                                        <input type="date" onChange={(e) => handleChangeToDate(e.target.value)} value={ToDate} className="form-control" />
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6 fullWidth">
                                <label className="mb-2 ">
                                    <span>Customer Name</span>
                                </label>
                                <div className="form-group position-relative">
                                    <select className="form-control webkit-none" onChange={(e) => handleChangeCustmer(e.target.value)} value={Customer}>
                                        <option value="">{t('ACCOUNT_TRANSACTIONS.SELECT_ALL')}</option>
                                        {CustomerData && CustomerData.map((row) => (
                                            <option value={row.user_id_pk} key={row.user_id_pk}>{row.first_name + " " + row.last_name + " (" + row.email_id + ")"}</option>
                                        ))}
                                    </select>
                                    <i className="fa fa-caret-down down-arrow select-arrow1" aria-hidden="true"></i>
                                </div>
                            </div>

                        </div>
                        <div className="table-responsive">

                        </div>
                    </div>
                    <div className="card-body">
                        <div className="position-relative">
                            <div className="table-responsive">
                            {requestarray.length > 0 ?
                                <MaterialTable
                                    title="Card Transactions"
                                    columns={[
                                        { title: 'Hash', field: 'Refno' },
                                        { title: 'Date', field: 'Date' },
                                        { title: 'Card Type', field: 'CardType' },
                                        { title: 'Account Number', field: 'AccountNumber' },
                                        { title: 'Customer Name', field: 'CustomerName' },
                                        { title: 'Card Number', field: 'CardNumber' },
                                        { title: 'Debit/Credit', field: 'DebitCredit' },
                                        { title: 'Amount', field: 'Amount' },
                                        { title: 'Fees', field: 'Fees' },
                                        { title: 'Status', field: 'Status' },
                                    ]}
                                    data={requestarray}
                                    options={{
                                        exportMenu: [
                                            { label: "Export PDF", exportFunc: (cols, datas) => handleChangeCSVNdPDF("PDF") },
                                            { label: "Export CSV", exportFunc: (cols, datas) => handleChangeCSVNdPDF("CSV") },
                                        ], paging: true, pageSize: 5, pageSizeOptions: [5, 10, 15], emptyRowsWhenPaging: false,
                                    }}
                                />
                                : <div className="d-sm-flex d-block border-0 pb-0">
                                <div className="pr-3 mr-auto mb-sm-0 mb-3 nofoundFullWidth">
                                    <h4 className="fs-20 text-black mb-1 nofoundTitle">Card Transactions Overview</h4>
                                    <span className="fs-12 nofoundText">No Data Found</span>
                                </div>
                            </div>}
                            </div>
                        </div>
                    </div>
                </div>
            }
        </div>
    )

}
export default Cards_Transaction;